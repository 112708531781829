<template>
  <div class="error-message" data-test-id="error-message">
    <div v-if="message" class="error-message-overlay" data-test-id="error-message-overlay">
      <div class="error-message-modal" data-test-id="error-message-modal">
        <div class="error-message-top-row">
          <div class="error-header" data-test-id="error-message-header">{{ header }}</div>
        </div>

        <div class="error-message-container">
          <div class="error-body" data-test-id="error-message-message">{{ message }}</div>
        </div>

        <div v-if="hasConfirm" class="error-message-close">
          <button :class="errorCss" class="gray-button" @click="confirmError" data-test-id="error-message-confirm">Confirm</button>
          <button :class="errorCss" class="default-button" @click="cancelError" data-test-id="error-message-cancel">Cancel</button>
        </div>
        <div v-else class="error-message-close">
          <button :class="errorCss" class="default-button" @click="confirmError" data-test-id="error-message-confirm">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useErrorStore } from "@/stores/error_store"
import { computed, ref } from "vue"

const errorStore = useErrorStore()
let header = ref(errorStore.getHeader)
let message = ref(errorStore.getMessage)
// success, notice, warning, error, critical
let errorLevel = ref(errorStore.getErrorLevel)
let hasConfirm = ref(errorStore.getConfirmation)

const errorCss = computed(() => {
  return 'bak-' + errorLevel.value + '-3'
})

// const emit = defineEmits(['update'])
errorStore.$subscribe(updateErrorMessage, { detached: false })
function updateErrorMessage() {
  header.value = errorStore.getHeader
  message.value = errorStore.getMessage
  errorLevel.value = errorStore.getErrorLevel
  hasConfirm.value = errorStore.getConfirmation
  // emit('update', 'bak-' + errorLevel.value + '-3')
}

function cancelError() {
  errorStore.cancelError()
}
function confirmError() {
  errorStore.confirmError()
}

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";
.error-message-overlay {
  position: fixed; /* Positioning and size */
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(128,128,128,0.5); /* color */
}
.error-message-modal {
  position: fixed;
  display: block;
  height: 240px;
  width: 600px;
  top: 50vh;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 4px solid $mediumGray1;
  border-radius: 6px;
  background: white;
}
.error-message-top-row {
  background-color: $mediumGray1;
  border-top-left-radius: 3px; // works with 4px border to affect an 8px radius
  border-top-right-radius: 3px;
  height: 36px;
  padding: 5px 20px 0 20px;
}
.error-header {
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  font-size: 18px;
}
.error-body {
  position: absolute;
  top: 60px;
  left: 40px;
  width: calc(100% - 100px);
  height: 148px;
  font-size: 16px;
  overflow: hidden;
}
.error-message-clear {
  position: absolute;
  right: 4px;
  top: 4px;
}
.error-message-close {
  position: absolute;
  right: 4px;
  bottom: 4px;
}
</style>
