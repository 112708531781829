import { currencyFormat, kMillionsWithDecimalsFormat } from "@/assets/js/utils";

export function standardStackedBarChartJson(config) {
  let json = {
    responsive: false,
    maintainAspectRatio: false,
    barPercentage: config.barPercentage,
    categoryPercentage: config.categoryPercentage,
    scales: {
      x: {
        stacked: true,
        display: true,
        ticks: {
          font: {
            size: config.xTicksFontSize || "12",
            family: config.xTicksFont || "Inter-Bold"
          },
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        stacked: true,
        display: true,
        ticks: {
          beginAtZero: true,
          font: {
            size: config.yTicksFontSize || "12",
            family: config.yTicksFont || "Inter",
          },
          padding: 1,
          min: config.yMinTick || 0,
          max: config.yMaxTick || 1000000000,
          autoSkip: false,
          callback: function (label) {
            return kMillionsWithDecimalsFormat(label, 0)
          },
        },
        grid: {
          drawOnChartArea: true,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
        },
        display: false,
      },
      datalabels: {
        display: config.display || false,
        color: config.color || "black",
        anchor: config.anchor || "end",
        align: config.align || "top",
        font: {
          size: config.fontSize || 8
        }
      },
    },
    layout: {
      padding: {
        top: 20,
      }
    }
  }

  if (config.labelForcedLength) {
    // Ensure x-axis is always horizontal to keep chart viewing ratios similar
    json.scales.x.ticks.minRotation = 50
    // Keep the x-axis labels the same length so charts stay in fixed position
    json.scales.x.beforeFit = (categoryScale => {
      categoryScale.ticks.forEach((tick, i) => {
        if (tick.label.length < config.labelForcedLength) {
          categoryScale.ticks[i].label = '  '.repeat(config.labelForcedLength - tick.label.length) + categoryScale.ticks[i].label
        }
      })
      return categoryScale
    })
  }

  if (config.yMinTicksLimit || config.yMinTicksLimit === 0) { json.scales.y.ticks.minTicksLimit = config.yMinTicksLimit }
  if (config.yMaxTicksLimit) { json.scales.y.ticks.maxTicksLimit = config.yMaxTicksLimit }
  if (config.yStepSize) { json.scales.y.ticks.stepSize = config.yStepSize }

  return json
}

export function standardDoughnutChartJson(config) {
  let json = {
    responsive: false,
    maintainAspectRatio: false,
    cutout: 100,
    plugins: {
      title: {
        text: config.doughnutTitle || "",
        align: "center",
        display: false,
      },
      legend: {
        labels: {
          boxWidth: 10,
        },
        font: {
          size: 10,
          family: "Inter",
        },
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function (label) {
            return currencyFormat(label);
          }
        }
      },
      doughnutMiddleTextPlugin: {
        middleTextLabel: null,
        middleTextValue: null,
        fontSize: null
      },
      datalabels: {
        display: config.display || false,
        color: config.color || "black",
        anchor: config.anchor || "center"
      },
    },
  }

  return json
}

export function doughnutMiddleTextPlugin() {
  return {
    id: 'doughnutMiddleTextPlugin',
    beforeDraw: function (chart, args, options) {
      if (chart.config.type == 'doughnut' && options.middleTextLabel != undefined) {

        var width = chart.width;
        var height = chart.height;
        var ctx = chart.ctx;

        ctx.restore();
        var fontSize = options.fontSize;
        ctx.textBaseline = "middle";

        var textValue = options.middleTextValue;
        var textValueX = Math.round((width - ctx.measureText(textValue).width) / 2);
        var textValueY = height / 2;
        ctx.fillStyle = '#6ED0CD';
        ctx.font = 'bold  ' + fontSize + "px Inter";
        ctx.fillText(textValue, textValueX, textValueY - 10);

        var textLabel = options.middleTextLabel;
        var textLabelX = Math.round((width - ctx.measureText(textLabel).width) / 2);
        var textLabelY = height / 2;
        ctx.fillStyle = '#000000'
        ctx.font = 'normal  ' + fontSize + "px Inter";
        ctx.fillText(textLabel, textLabelX, textLabelY + 5);

        ctx.save();
      }
    }
  }
}