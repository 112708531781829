<template>
    <div :class="showModal ? 'open' : 'closed'" class="side-modal">
        <ExpandCollapse name="compsearch-sidemodal" :is-open-initially="false" :is-vertical-center="false"
            open-state="right-left" closed-state="right-left" :show-double-chevron="true"
            @toggle-expand-collapse="toggleModal(); $emit('resize-modal')" :show-circle="true" />
        <div class="modal comp-summary flipped" :class="!showModal ? 'hidden' : ''">
            <div class="content">
                <!-- Header -->
                <div class="header-search">
                    <div>
                        <div class="comp-summary-title left-div">Comp Set Summary</div>
                    </div>
                </div>
                <div class="divider"></div>
                <!-- Budget Overview -->
                <div class="budget-overview-container">
                    <div class="accordion-panel-header">
                        <label for="budgetOverview">Budget Overview</label>
                        <ExpandCollapse name="budgetOverview" :is-open-initially="true" :is-vertical-center="false"
                            open-state="down" closed-state="up" :show-double-chevron="false" :show-circle="false"
                            :chevron-fill="'#4A5FD5'"
                            @toggle-expand-collapse="expandCollapseController('budgetOverview');" />
                    </div>
                    <div v-if="noSelectedCompSet" class="no-selected-data"> No generated charts to show, please select a
                        comp set.</div>
                    <div v-if="currentProjects.length == 0" class="no-selected-data"> No generated charts to show,
                        please refine your search.</div>
                    <div v-if="isDataLoaded && currentProjects.length > 0 && !isNoDataFetch">
                        <div v-if="isBudgetOverviewOpen && !noSelectedCompSet" aria-title="budgetOverview"
                            title="budgetOverview">
                            <div class="view-by-container">
                                <div class="custom-select label-text-spacing">
                                    <select class="right-div" id="budget-type" v-model="viewByTGU">
                                        <option value="viewByTotal">View By Total</option>
                                        <option value="viewByGSF">View per $/GSF</option>
                                        <option value="viewByUnit">View per $/Unit</option>
                                    </select>
                                </div>

                                <div class="custom-select label-text-spacing">
                                    <select class="right-div" id="budget-type" v-model="viewByAM">
                                        <option value="viewByAverage">View By Average</option>
                                        <option value="viewMedian">View Median</option>
                                    </select>
                                </div>
                            </div>
                            <!-- Graphs -->
                            <div class="budget-overview-container">
                                <div class="budget-chart-container">
                                    <!-- Doughnut Chart -->
                                    <div class="doughnut-chart-container">
                                        <div class="chart-label">Total Project Costs</div>
                                        <div class="chart-content">
                                            <div>
                                                <Doughnut class="summary-doughnut-canvas" :height=200 :width=225
                                                    :data="doughnutData" :options="doughnutOptions" :key="counter" />
                                            </div>
                                            <div class="doughnut-chart-legend">
                                                <div v-if="isHCDataLoaded" class="acquisition-legend">Acquisition
                                                    <div class="detail-container">
                                                        <div>{{ doughnutChartPercentage[0] }}</div>
                                                        <div class="vertical-divider"></div>
                                                        <div>{{
        kMillionsWithDecimalsFormat(lcTotalRevisedBudget) }}</div>
                                                    </div>
                                                </div>
                                                <div class="hard-legend">Hard
                                                    <div class="detail-container">
                                                        <div>{{ doughnutChartPercentage[1] }}</div>
                                                        <div class="vertical-divider"></div>
                                                        <div>{{
        kMillionsWithDecimalsFormat(hcTotalRevisedBudget) }}</div>
                                                    </div>
                                                </div>
                                                <div class="soft-legend">Soft
                                                    <div class="detail-container">
                                                        <div>{{ doughnutChartPercentage[2] }}</div>
                                                        <div class="vertical-divider"></div>
                                                        <div>{{
        kMillionsWithDecimalsFormat(scTotalRevisedBudget) }}</div>
                                                    </div>
                                                </div>
                                                <div class="misc-finance-legend">Misc/Finance
                                                    <div class="detail-container">
                                                        <div>{{ doughnutChartPercentage[3] }}</div>
                                                        <div class="vertical-divider"></div>
                                                        <div>{{
        kMillionsWithDecimalsFormat(miscFinancingTotalRevisedBudget)
    }}</div>
                                                    </div>
                                                </div>
                                                <div class="contingencies-legend">Contingencies
                                                    <div class="detail-container">
                                                        <div>{{ doughnutChartPercentage[4] }}</div>
                                                        <div class="vertical-divider"></div>
                                                        <div>{{
        kMillionsWithDecimalsFormat(contingenciesTotalRevisedBudget)
    }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Bar Chart -->
                                    <div class="bar-chart-container">
                                        <div class="chart-label">Hard and Soft Cost Summary</div>
                                        <div>
                                            <Bar class="summary-bar-canvas" :height="200" :width="200" :data="barChartData"
                                                :options="barChartOptions" :key="counter" />
                                        </div>
                                        <div v-if="hcTotalRevisedBudget && scTotalRevisedBudget && viewByTGU === 'viewByTotal'"
                                            class="bar-chart-legend-total">
                                            <span><strong>{{ kMillionsWithDecimalsFormat(hcTotalRevisedBudget)
                                                    }} </strong></span>
                                            <span><strong>{{ kMillionsWithDecimalsFormat(scTotalRevisedBudget)
                                                    }} </strong></span>
                                        </div>
                                        <div v-if="hcSummaryAvgRevised && scSummaryAvgRevised && viewByTGU === 'viewByGSF'"
                                            class="bar-chart-legend-revised">
                                            <span><strong>{{ currencyFormatRoundedDecimal(hcSummaryAvgRevised)
                                                    }}/gsf </strong></span>
                                            <span><strong>{{ currencyFormatRoundedDecimal(scSummaryAvgRevised)
                                                    }}/gsf </strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="go-to-button">
                                    <button class="secondary-action-button go-to-budget-button"
                                        @click="reroute('/compsearch/budget', 'CompSearchDetails')">Go To
                                        Budget
                                        Page</button>
                                </div>
                            </div>
                            <div class="schedule-overview-container">
                                <label>Schedule Overview</label>
                                <!-- AG Grid: Schedule vue -->
                                <div class="grid-container schedule-gantt" id="scheduleViewGrid">
                                    <ag-grid style="height:220px" :row-height="30" :row-data="dataShow"
                                        :column-defs="localColumnDefs" :tree-data="true" :full-scroll="true"
                                        custom-column="buttonGantt" :key="dataShow.length" :groupDefaultExpanded="1"
                                        :is-flat-data="true" />
                                    <ToolTip :is-show="tooltipIsShow" :top="`${tooltipTop}px`"
                                        :left="`${tooltipLeft}px`" :html="tooltipHtml" />
                                </div>
                                <div class="go-to-button">
                                    <button class="secondary-action-button go-to-schedule-button"
                                        @click="reroute('/compsearch/schedule', 'Schedule')">Go
                                        To Schedule
                                        Page</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divider"></div>

                <!--Active Compset-->
                <div class="active-compset-container">
                    <div class="accordion-panel-header header-search">
                        <div class="left-div"><label for="activeCompSet">Active Comp Set</label><span
                                class="comp-set-counter">{{ compSetCount }}</span>
                        </div>
                        <div class="right-div"><button class="secondary-action-button disabled-button">Enlarge List
                                View</button>
                        </div>
                        <ExpandCollapse name="activeCompSet" :is-open-initially="true" :is-vertical-center="false"
                            open-state="down" closed-state="up" :show-double-chevron="false" :show-circle="false"
                            :chevron-fill="'#4A5FD5'"
                            @toggle-expand-collapse="expandCollapseController('activeCompSet');" />
                    </div>
                    <div v-if="isActiveCompSetOpen" aria-title="ActiveCompSet" title="ActiveCompSet">
                        <div class="active-compset-label">Additional comps exist outside search area <span
                                class="disabled-button">Zoom
                                Out</span>
                            for
                            more results</div>
                        <ul v-if="currentProjects?.length">
                            <li class="asset-images-options" v-for="(project, index) in currentProjects" :key="index">
                                <div class="active-compset-details-container">
                                    <!-- todo : if there is no image url just have a default value? -->
                                    <div class="property-thumbnail"><img
                                            :src="project.image ? require(project.image_url) : require(`../../assets/building_assets/property1.jpg`)">
                                    </div>
                                    <div class="property-info">
                                        <div class="property-address">
                                            <strong>{{ project.address_line_1 }}</strong>
                                            <label class="custom-checkbox">
                                                <input type="checkbox" style="display: none;" :id="project.id"
                                                    :value="project.value" @change="toggleSelected(project.id)"
                                                    :checked="isProjectSelected(project.id)">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="property-details">
                                            <div class="property-building-info">
                                                <div class="property-building-label">Building
                                                    Details</div>
                                                <div class="property-info-item">
                                                    <div class="property-info-label">Use Type(s)</div>
                                                    <div>{{ project.building_use_types_abv ? project.building_use_types_abv.join('; ') :
        '-' }}</div>
                                                </div>
                                                <div class="property-info-item">
                                                    <div class="property-info-label">Gross Sq Ft</div>
                                                    <div>{{ project.total_gsf ? numberFormat(project.total_gsf, 0) : '-'
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="property-info-item">
                                                    <div class="property-info-label">Unit Count</div>
                                                    <div>{{ project.total_num_units ? numberFormat(project.total_num_units, 0) : '-'
                                                        }}</div>
                                                </div>
                                                <div class="property-info-item">
                                                    <div class="property-info-label">Stories</div>
                                                    <div>{{ project.num_floors ? numberFormat(project.num_floors, 0) : '-' }}</div>
                                                </div>
                                                <div class="property-info-item">
                                                    <div class="property-info-label">Parking Count</div>
                                                    <div>{{ project.parking_count ? numberFormat(project.parking_count, 0) : '-' }}</div>
                                                </div>
                                            </div>
                                            <div class="property-construction-info">
                                                <div class="property-construction-label">Construction
                                                    Details</div>
                                                <div class="property-info-item">
                                                    <div class="property-info-label">Construction Type</div>
                                                    <div class="property-info-value ">{{ project.project_construction_types_abv ? project.project_construction_types_abv.join('; ') : '-' }}</div>
                                                </div>
                                                <div class="property-info-item">
                                                    <div class="property-info-label">Year Completed</div>
                                                    <div>{{ project.actual_end_date ?
                                                        extractDate(project.actual_end_date)
                                                        : '-' }}</div>
                                                </div>
                                                <div class="property-info-item">
                                                    <div class="property-info-label">Const. Duration (mo)</div>
                                                    <div>{{ project.project_length_months ?
                                                        parseFloat(project.project_length_months).toFixed(0) : '-' }}
                                                    </div>
                                                </div>
                                                <div class="property-info-item">
                                                    <div class="property-info-label">Finish Level</div>
                                                    <div>{{ project.finish_level ? project.finish_level.code : '-' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="index + 1 != currentProjects.length ? 'divider-comp-set' : ''">
                                    <div></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="divider"></div>
                <!-- We're only loading the ag grid on the background here to draw the dougnut chart -->
                <div class="grid-container" id="budgetViewGrid" style="display: none;">
                    <BudgetGrid :shouldCalculateGrandTotal="false"></BudgetGrid>
                </div>
                <!-- Action Buttons -->
                <div class="action-btn-container">
                    <div class="left-div ">
                        <button class="secondary-action-button">Clear Selection</button>
                        <button class="secondary-action-button">Select All</button>
                    </div>
                    <div class="right-div">
                        <button class="primary-action-button"
                            @click="reroute('/compsearch/budget', 'CompSearchDetails')">See Detail</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { doughnutMiddleTextPlugin } from "@/assets/js/chart_config";
import { axiosQuery, currencyFormat, currencyFormatRoundedDecimal, decimalPart, formatDecimals, kMillionsFormat, kMillionsWithDecimalsFormat, numberFormat, percentFormat } from "@/assets/js/utils";
import { useAuthStore } from '@/stores/auth_store';
import { useCompSetStore } from "@/stores/comp_set_store";
import { useBudgetDataStore } from "@/stores/budget_data_store";
import { useErrorStore } from "@/stores/error_store";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { computed, onMounted, onUpdated, reactive, ref, defineEmits } from "vue";
import { Bar, Doughnut } from "vue-chartjs";
import { useRoute, useRouter } from 'vue-router';
import ExpandCollapse from "../helpers/ExpandCollapse.vue";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { cleanupHierarchyNames } from "@/data/data";
import ToolTip from "../../components/helpers/ToolTip.vue";
import GanttCellRenderer from "../../components/renderers/GanttCellRenderer.vue";
import AgGrid from "../../components/AgGrid.vue";
import BudgetGrid from "../../components/helpers/BudgetGrid.vue";
import { useGanttTooltipStore } from "@/stores/gantt_tooltip_store";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    Colors,
    ArcElement,
    doughnutMiddleTextPlugin(),
    ChartDataLabels
);

// Flags for loaded Data
let showModal = ref(false);
let isHCDataLoaded = ref(false);
let isSCDataLoaded = ref(false);
//let isLCDataLoaded = ref(false);
//let isMFDataLoaded = ref(false);
let isHCSCCostPerGSFDataLoaded = ref(false);
//let isContingenciesDataLoaded = ref(false);

// Container values for the charts
let hcTotalOriginalBudget = ref(0);
let hcTotalRevisedBudget = ref(0);
let scTotalOriginalBudget = ref(0);
let scTotalRevisedBudget = ref(0);
let miscFinancingTotalOriginalBudget = ref(0);
let miscFinancingTotalRevisedBudget = ref(0);
let lcTotalOriginalBudget = ref(0);
let lcTotalRevisedBudget = ref(0);
let contingenciesTotalOriginalBudget = ref(0);
let contingenciesTotalRevisedBudget = ref(0);
let avgTotalGSF = ref(0);

let doughnutChartPercentage = ref([]);

let hcAvgOriginalCostPerGSF = ref(0)
let hcAvgRevisedCostPerGSF = ref(0)
let scAvgOriginalCostPerGSF = ref(0)
let scAvgRevisedCostPerGSF = ref(0)

let perProjectGSF = ref({})

let isDataLoaded = computed(() => {
    return isHCDataLoaded.value && isSCDataLoaded.value && isHCSCCostPerGSFDataLoaded.value;
});
let isBudgetOverviewOpen = ref(true)
let isActiveCompSetOpen = ref(true)

let viewByTGU = ref('viewByTotal');
let viewByAM = ref('viewByAverage');

const authStore = useAuthStore();
const errorStore = useErrorStore();
const compSetStore = useCompSetStore()
const budgetDataStore = useBudgetDataStore()
const apiBase = process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/"
let authToken = reactive({});
const allProjects = ref(compSetStore.getAllProjects);
const currentProjects = ref([]);
let projectSizeValue = ref(0);
let noSelectedCompSet = ref(false);

const toggleModal = () => {
    showModal.value = !showModal.value;
}

let compSetCount = "(" + Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]).length + ")"

const toggleSelected = (projectId) => {
    compSetStore.toggleProjectSelection(projectId)
    // Update currentProjects to reflect the selected projects
    currentProjects.value = Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]);
    // Set the number of active comp set
    noSelectedCompSet.value = (Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]).length === 0)
}

function isProjectSelected(projectId) {
    return compSetStore.isProjectSelected(projectId);
}

const expandCollapseController = (element) => {
    switch (element) {
        case 'budgetOverview':
            isBudgetOverviewOpen.value = !isBudgetOverviewOpen.value
            break;
        case 'activeCompSet':
            isActiveCompSetOpen.value = !isActiveCompSetOpen.value
            break;
        default:
            break;
    }
}

onMounted(() => {
    authToken.value = authStore.getAuthTokenHeader
    // Enable auth to load fully before updating data (not sure if this completely makes sense)
    setTimeout(() => {
        updateData()
    }, 0)
})

// The doughnut disappears when we revisit a page unless we use a :key that changes each time.
// The bar chart gets small when we revisit a page unless we use a :key that changes each time.
// If we *always* increment counter, the app gets stuck in an infinite loop on production,
//      so only update if the route has just changed.
const route = useRoute()
let lastRoute = route.name
let counter = ref(0)
onUpdated(() => {
    if (route.name === 'Home' && lastRoute !== route.name) {
        lastRoute = route.name
        counter.value++
    } else if (lastRoute !== route.name) {
        // If we have different route from before, and it is not "Home",
        // update lastRoute so the counter.value++ update will trigger once the current route.name is again "Home"
        lastRoute = route.name
    }
})

// Fetch compset on update
const escalationQuery = ref(compSetStore.getEscalation)
compSetStore.$subscribe(syncProjects, { detached: false })
async function syncProjects() {
    const priorEscalation = escalationQuery.value
    escalationQuery.value = compSetStore.getEscalation ? `&escalation=${compSetStore.getEscalation}` : ''

    allProjects.value = compSetStore.getAllProjects
    const storedProjects = compSetStore.getIncludedProjects
    compSetCount = "(" + Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]).length + ")"

    // See if project array has actually changed, as we only want to query the backend if there was a real change.
    if (JSON.stringify(storedProjects) !== JSON.stringify(currentProjects.value) || priorEscalation !== escalationQuery.value) {
        currentProjects.value = storedProjects

        const transformedData = currentProjects.value.reduce((acc, item) => {
            acc[item.id] = compSetStore.isProjectSelected(item.id);
            return acc;
        }, {});

        compSetStore.setSelectedProjects(transformedData);
        compSetStore.selectedProjects = transformedData;

        await adjustAverages()
        compSetStore.setShowMarkers(true);
        avgTotalGSF = ref(compSetStore.getAvgTotalGSF)
        await resetDataFlags()
        await fetchCompSet()
        await updateData()
    }
}

// Adjust the Comp Set Averages
const adjustAverages = () => {
    const newTotalGSF = [];

    if (compSetStore.getIncludedProjects.length > 0) {
        for (const project of currentProjects.value) {
            // Check if the project is selected
            if (compSetStore.selectedProjects[project.id]) {
                newTotalGSF.push(project.total_gsf);
                perProjectGSF.value[project.id] = project.total_gsf;
            }
        }
        projectSizeValue.value = getAverage(newTotalGSF);
    } else {
        // Reset to zero if none is selected
        projectSizeValue.value = 0;
    }
    avgTotalGSF.value = projectSizeValue.value;
    compSetStore.setAvgTotalGSF(projectSizeValue.value)
    compSetStore.setPerProjectGSF(perProjectGSF.value)
}

// Calculate the average of two or more numbers inside an array
const getAverage = (numbers) => {
    // Calculate the sum of all the numbers
    const sum = numbers.reduce((acc, number) => acc + number, 0);
    // Calculate the average
    const average = sum / numbers.length;
    return average;
}

function resetDataFlags() {
    isHCDataLoaded.value = false;
    isSCDataLoaded.value = false;
}

async function fetchCompSet() {
    const selectedIds = Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]).join(',')
    await fetchHCData(selectedIds);
    await fetchSCData(selectedIds);
    await calculateHCSCAvgSummaryCostsPerGSF(selectedIds);
    showModal.value = true
}

function fetchHCData(selectedIds) {
    axiosQuery(
        `${apiBase}mvw_most_recent_by_projects/fetch_costs_avg_sum?cost_category=hard&project_ids=${selectedIds}${escalationQuery.value}`,
        authToken,
        (data) => {
            hcTotalOriginalBudget.value = parseFloat(data?.original_budget || 0)
            hcTotalRevisedBudget.value = parseFloat(data?.current_budget || 0)
            isHCDataLoaded.value = true;
        },
        errorStore
    );
}

function fetchSCData(selectedIds) {
    axiosQuery(
        `${apiBase}mvw_most_recent_by_projects/fetch_costs_avg_sum?cost_category=soft&project_ids=${selectedIds}${escalationQuery.value}`,
        authToken,
        (data) => {
            scTotalOriginalBudget.value = parseFloat(data?.original_budget || 0)
            scTotalRevisedBudget.value = parseFloat(data?.current_budget || 0)
            isSCDataLoaded.value = true;
        },
        errorStore
    );
}

function calculateHCSCAvgSummaryCostsPerGSF(selectedIds) {
    isHCSCCostPerGSFDataLoaded.value = false
    //const selectedIds = Object.keys(selectedProjects.value).filter(id => selectedProjects.value[id]).join(',')
    let HCTotalOriginalCostPerGSF = 0
    let HCTotalRevisedCostPerGSF = 0
    let SCTotalOriginalCostPerGSF = 0
    let SCTotalRevisedCostPerGSF = 0

    if (selectedIds) {
        axiosQuery(
            `${apiBase}mvw_most_recent_by_projects/fetch_hc_sc_costs_per_project?project_ids=${selectedIds}${escalationQuery.value}`,
            authToken,
            (data) => {
                const validIds = selectedIds.split(',')
                let hardCostsData = data.hard
                let softCostsData = data.soft
                validIds.forEach((id) => {
                    let projectGSF = parseFloat(compSetStore.getPerProjectGSF[id] || 0)
                    HCTotalOriginalCostPerGSF += parseFloat(hardCostsData[id]['total_original'] || 0) / (projectGSF || 1)
                    HCTotalRevisedCostPerGSF += parseFloat(hardCostsData[id]['total_revised'] || 0) / (projectGSF || 1)
                    SCTotalOriginalCostPerGSF += parseFloat(softCostsData[id]['total_original'] || 0) / (projectGSF || 1)
                    SCTotalRevisedCostPerGSF += parseFloat(softCostsData[id]['total_revised'] || 0) / (projectGSF || 1)
                })
                hcAvgOriginalCostPerGSF.value = HCTotalOriginalCostPerGSF / validIds.length
                hcAvgRevisedCostPerGSF.value = HCTotalRevisedCostPerGSF / validIds.length
                scAvgOriginalCostPerGSF.value = SCTotalOriginalCostPerGSF / validIds.length
                scAvgRevisedCostPerGSF.value = SCTotalRevisedCostPerGSF / validIds.length

            },
            errorStore
        );
    }
    isHCSCCostPerGSFDataLoaded.value = true

}
// Doughnut Chart
let lcTotalVariance = ref(0);
let hcTotalVariance = ref(0);
let scTotalVariance = ref(0);
let miscFinancingTotalVariance = ref(0);
let contingenciesTotalVariance = ref(0);
let lcVariancePercent = ref(0);
let hcVariancePercent = ref(0);
let scVariancePercent = ref(0);
let miscFinancingVariancePercent = ref(0);
let contingenciesVariancePercent = ref(0);
let doughnutChartData = ref([])
budgetDataStore.$subscribe(setChartData);
function setChartData() {
    let chartData = budgetDataStore.getAggregatedBudgetChartData;
    // These group ids are constants
    // These should only change if we renamed the parent terms
    const ACQUISITION_GROUP_ID = "row-group-0-ACQUISITION COSTS"
    const HC_GROUP_ID = "row-group-0-HARD COSTS"
    const SC_GROUP_ID = "row-group-0-SOFT COSTS"
    const FINANCING_GROUP_ID = "row-group-0-FINANCING COSTS"
    const CONTINGENCIES_GROUP_ID = "row-group-0-CONTINGENCIES-1-Owner Contingencies"

    // build doughnut data
    // check first if chart data is non null
    if (chartData) {
        let acquisitionGroupData = chartData.find(row => row.id === ACQUISITION_GROUP_ID)
        let hcGroupData = chartData.find(row => row.id === HC_GROUP_ID)
        let scGroupData = chartData.find(row => row.id === SC_GROUP_ID)
        let financingGroupData = chartData.find(row => row.id === FINANCING_GROUP_ID)
        let contingenciesGroupData = chartData.find(row => row.id === CONTINGENCIES_GROUP_ID)

        // TODO : Will the aggregated data ever be NaN / Null
        // TODO : As part of refactoring of charts, this function can be moved as its own module

        lcTotalRevisedBudget.value = acquisitionGroupData ? acquisitionGroupData['aggData']['current_budget'] : 0
        hcTotalRevisedBudget.value = hcGroupData ? hcGroupData['aggData']['current_budget'] : 0
        scTotalRevisedBudget.value = scGroupData ? scGroupData['aggData']['current_budget'] : 0
        miscFinancingTotalRevisedBudget.value = financingGroupData ? financingGroupData['aggData']['current_budget'] : 0
        contingenciesTotalRevisedBudget.value = contingenciesGroupData ? contingenciesGroupData['aggData']['current_budget'] : 0

        lcTotalOriginalBudget.value = acquisitionGroupData ? acquisitionGroupData['aggData']['original_budget'] : 0
        hcTotalOriginalBudget.value = hcGroupData ? hcGroupData['aggData']['original_budget'] : 0
        scTotalOriginalBudget.value = scGroupData ? scGroupData['aggData']['original_budget'] : 0
        miscFinancingTotalOriginalBudget.value = financingGroupData ? financingGroupData['aggData']['original_budget'] : 0
        contingenciesTotalOriginalBudget.value = contingenciesGroupData ? contingenciesGroupData['aggData']['original_budget'] : 0

        lcTotalVariance.value = acquisitionGroupData ? acquisitionGroupData['aggData']['total_adjustment'] : 0
        hcTotalVariance.value = hcGroupData ? hcGroupData['aggData']['total_adjustments'] : 0
        scTotalVariance.value = scGroupData ? scGroupData['aggData']['total_adjustments'] : 0
        miscFinancingTotalVariance.value = financingGroupData ? financingGroupData['aggData']['total_adjustments'] : 0
        contingenciesTotalVariance.value = contingenciesGroupData ? contingenciesGroupData['aggData']['total_adjustments'] : 0

        lcVariancePercent.value = acquisitionGroupData ? acquisitionGroupData['aggData']['variance'] : 0
        hcVariancePercent.value = hcGroupData ? hcGroupData['aggData']['variance'] : 0
        scVariancePercent.value = scGroupData ? scGroupData['aggData']['variance'] : 0
        miscFinancingVariancePercent.value = financingGroupData ? financingGroupData['aggData']['variance'] : 0
        contingenciesVariancePercent.value = contingenciesGroupData ? contingenciesGroupData['aggData']['variance'] : 0

        doughnutChartData.value = [
            lcTotalRevisedBudget.value,
            hcTotalRevisedBudget.value,
            scTotalRevisedBudget.value,
            miscFinancingTotalRevisedBudget.value,
            contingenciesTotalRevisedBudget.value
        ]

        const doughnutChartDataArray = [
            lcTotalRevisedBudget.value,
            hcTotalRevisedBudget.value,
            scTotalRevisedBudget.value,
            miscFinancingTotalRevisedBudget.value,
            contingenciesTotalRevisedBudget.value
        ]

        // Loop through the dougnutChartData
        const totalSumOfDoughnut = doughnutChartDataArray.reduce((a, b) => { return a + b; }).toFixed(0)
        doughnutChartPercentage.value = []
        doughnutChartDataArray.forEach(val => {
            const percentage = val * 100 / totalSumOfDoughnut;
            const roundedPercentage = Math.round(percentage * 100) / 100
            doughnutChartPercentage.value.push(`${roundedPercentage.toFixed(0)}%`);
        });
    }
}

let totalRevisedBudget = computed(() => {
    return lcTotalRevisedBudget.value + hcTotalRevisedBudget.value +
        miscFinancingTotalRevisedBudget.value + contingenciesTotalRevisedBudget.value
})
let doughnutSummaryValue = computed(() => {
    return kMillionsWithDecimalsFormat(totalRevisedBudget.value)
})

const doughnutData = computed(() => {
    return {
        labels: ["Acquisition ", "Hard", "Soft", "Misc/Finance", "Contingencies"],
        datasets: [
            {
                backgroundColor: [
                    "#52596D", // $darkGray1
                    "#4A5FD5", // $brightBlue1
                    "#4A8FD0", // $mediumBlue1
                    "#8A92A9", // $mediumGray1
                ],
                data: doughnutChartData.value
            },
        ],
    };
});

const doughnutOptions = computed(() => {
    let json = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: 70,
        plugins: {
            title: {
                text: 'Test',
                align: "center",
                display: false,
            },
            legend: {
                labels: {
                    boxWidth: 10,
                },
                font: {
                    size: 10,
                    family: "Inter",
                },
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let actual = 0;
                        if (context.label?.includes("Hard")) {
                            actual = currencyFormat(hcTotalRevisedBudget.value)
                        } else if (context.label?.includes("Soft")) {
                            actual = currencyFormat(scTotalRevisedBudget.value)
                        } else if (context.label?.includes("Acquisition")) {
                            actual = currencyFormat(lcTotalRevisedBudget.value)
                        } else if (context.label?.includes("Misc")) {
                            actual = currencyFormat(miscFinancingTotalRevisedBudget.value)
                        } else if (context.label?.includes("Contingencies")) {
                            actual = currencyFormat(contingenciesTotalRevisedBudget.value)
                        }
                        return actual;
                    },
                    footer: function (context) {
                        let variancePerc = 0;
                        let varianceVal = 0;
                        if (context[0].label?.includes("Hard")) {
                            varianceVal = kMillionsFormat(hcTotalRevisedBudget.value - hcTotalOriginalBudget.value);
                            variancePerc = percentFormat(
                                (hcTotalRevisedBudget.value - hcTotalOriginalBudget.value) /
                                (hcTotalOriginalBudget.value || 1)
                            );
                        } else if (context[0].label?.includes("Soft")) {
                            varianceVal = kMillionsFormat(scTotalRevisedBudget.value - scTotalOriginalBudget.value);
                            variancePerc = percentFormat(
                                (scTotalRevisedBudget.value - scTotalOriginalBudget.value) /
                                (scTotalOriginalBudget.value || 1)
                            );
                        } else if (context[0].label?.includes("Acquisition")) {
                            varianceVal = kMillionsFormat(lcTotalRevisedBudget.value - lcTotalOriginalBudget.value);
                            variancePerc = percentFormat(
                                (lcTotalRevisedBudget.value - lcTotalOriginalBudget.value) /
                                (lcTotalOriginalBudget.value || 1)
                            );
                        } else if (context[0].label?.includes("Misc")) {
                            varianceVal = kMillionsFormat(
                                miscFinancingTotalRevisedBudget.value -
                                miscFinancingTotalOriginalBudget.value
                            );
                            variancePerc = percentFormat(
                                (miscFinancingTotalRevisedBudget.value -
                                    miscFinancingTotalOriginalBudget.value) /
                                (miscFinancingTotalOriginalBudget.value || 1)
                            );
                        } else if (context[0].label?.includes("Contingencies")) {
                            varianceVal = kMillionsFormat(
                                contingenciesTotalRevisedBudget.value -
                                contingenciesTotalOriginalBudget.value
                            );
                            variancePerc = percentFormat(
                                (contingenciesTotalRevisedBudget.value -
                                    contingenciesTotalOriginalBudget.value) /
                                (contingenciesTotalOriginalBudget.value || 1)
                            );
                        }
                        return `Variance: ${varianceVal} (${variancePerc})`
                    },
                },
            },
            doughnutMiddleTextPlugin: {
                middleTextLabel: ' Total Project Cost',
                middleTextValue: doughnutSummaryValue.value,
                fontSize: 12
            },
            datalabels: {
                display: false
            }
        },
    }

    return json
});

// Bar Chart
// Uncomment out once perGSF is being used again
// let hcSummaryAvgOriginal = computed(() => {
//     return hcAvgOriginalCostPerGSF.value
// })
let hcSummaryAvgRevised = computed(() => {
    return hcAvgRevisedCostPerGSF.value
})
// let scSummaryAvgOriginal = computed(() => {
//     return scAvgOriginalCostPerGSF.value
// })
let scSummaryAvgRevised = computed(() => {
    return scAvgRevisedCostPerGSF.value
})

const barChartData = computed(() => {
    let hcChangeInBudget = hcTotalRevisedBudget.value - hcTotalOriginalBudget.value
    if (hcChangeInBudget < 0) {
        hcChangeInBudget = 0
    }
    let scChangeInBudget = scTotalRevisedBudget.value - scTotalOriginalBudget.value
    if (scChangeInBudget < 0) {
        scChangeInBudget = 0
    }
    return {
        labels: [
            'Total HC',
            'Total SC',
        ],
        datasets: [
            {
                label: "Original Budget",
                backgroundColor: ["#4A5FD5", "#4A8FD0"],
                data: [hcTotalOriginalBudget.value, scTotalOriginalBudget.value],
            },
            {
                label: "Budget Variance",
                backgroundColor: ["#84CADE", "#84CADE"],
                data: [hcChangeInBudget, scChangeInBudget],
            },
        ]
    }
})
const barChartOptions = computed(() => {
    let json = {
        responsive: true,
        maintainAspectRatio: false,
        barPercentage: 0.8,
        layout: {
            padding: {
                top: 20, // Adjust top padding as needed
            },
        },
        scales: {
            x: {
                stacked: true,
                display: true,
                ticks: {
                    font: {
                        size: '12',
                        family: 'Inter-Bold'
                    },
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
            y: {
                stacked: true,
                display: true,
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: '10',
                        family: 'Inter',
                    },
                    min: 0,
                    max: 1000000000,
                    autoSkip: false,
                    callback: function (label) {
                        return kMillionsWithDecimalsFormat(label)
                    },
                },
                grid: {
                    drawOnChartArea: true,
                },
            },
        },
        plugins: {
            datalabels: {
                color: 'black',
                font: {
                    size: 12
                },
                anchor: 'end',
                align: 'top',
                formatter: function (val, ctx) {
                    // Get only values for Budget Variance
                    if (ctx.dataIndex === 0 && ctx.datasetIndex === 1) {
                        return `${Math.round(parseFloat((
                        (hcTotalRevisedBudget.value - hcTotalOriginalBudget.value) /
                        hcTotalOriginalBudget.value
                        )).toFixed(2) * 100)}%`
                    } else if (ctx.dataIndex === 1 && ctx.datasetIndex === 1) {
                        return `${Math.round(parseFloat((
                        (scTotalRevisedBudget.value - scTotalOriginalBudget.value) /
                        scTotalOriginalBudget.value
                        )).toFixed(2) * 100)}%`
                    } else {
                        return ''
                    }
                }
            },
            title: {
                text: 'Test',
                align: "center",
                display: false,
            },
            legend: {
                labels: {
                    boxWidth: 10,
                },
                font: {
                    size: 10,
                    family: "Inter",
                },
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.label;
                        const value = context.parsed.y;
                        let multilineTooltip = [label + ":"];
                        multilineTooltip.push(currencyFormatRoundedDecimal(value));
                        return multilineTooltip;
                    },
                    footer: function (context) {
                        const index = context[0].dataIndex;
                        let value = null;
                        if (index == 0) {
                            value = "Variance: " +
                                percentFormat(
                                    (hcTotalRevisedBudget.value - hcTotalOriginalBudget.value) /
                                    hcTotalOriginalBudget.value
                                )
                        } else if (index == 1) {
                            value = (
                                "Variance: " +
                                percentFormat(
                                    (scTotalRevisedBudget.value - scTotalOriginalBudget.value) /
                                    scTotalOriginalBudget.value
                                )
                            )
                        }
                        return value
                    }
                }
            },
        },
    }

    return json
})

const router = useRouter()
const emit = defineEmits(['updateBreadCrumb'])
const baseBreadCrumb = 'Comp Search | Comp Search Details'
const reroute = (path, label) => {
    emit('updateBreadCrumb', baseBreadCrumb + " | " + label)
    router.push({ path: path })
}

const extractDate = (dateStr) => {
    if (dateStr) {
        const dateParts = dateStr.split('-');
        return dateParts[0];
    } else {
        return '';
    }
}

// For Schedule Gant Chart - With Quarters (Start)
let aggregateData = {};
let processedAggregateData = {};
let lastProjectsQuery = [];

let isNoSelectedProject = ref(false);
let isNoDataFetch = ref(false);

const dataShow = ref([]);

let localColumnDefs = [
];

function updateData() {
    if (currentProjects.value.length !== 0) {
        fetchGridData()
    } else {
        isNoSelectedProject.value = false
    }
}

// this is fetching data for all cost types
// Process the data for the summed data
function fetchGridData() {
    const selectedIds = Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]).join(',')
    // Do *not* requery data
    if (lastProjectsQuery === selectedIds) {
        return
    } else {
        lastProjectsQuery = selectedIds
    }

    if (selectedIds.length === 0) {
        isNoSelectedProject.value = true
        return
    } else {
        isNoSelectedProject.value = false
        axiosQuery(
            `${apiBase}scope_of_works/summed_dates?project_ids=${selectedIds}`,
            authToken,
            (data) => {
                if (data.length == 0) {
                    isNoDataFetch.value = true
                } else {
                    aggregateData = {}
                    processedAggregateData = {}
                    data?.forEach((row) => {
                        if (row.itemHierarchy && row.actual_project_months) {
                            let quarters = {}
                            Array(maxQuarters * 3).fill(0).forEach((_, quarter) => {
                                // TODO || parts here are to aid in testing. Need more projected start dates/months
                                let projectedStartMonth = parseFloat(row.projected_months_into_project_start || row.actual_months_into_project_start)
                                // TODO uncertain why some are < 0
                                if (projectedStartMonth < 0) projectedStartMonth = 1
                                let projectedEndMonth = projectedStartMonth + parseFloat(row.projected_project_months || row.actual_project_months)
                                let projectedContribution = projectedStartMonth <= (quarter + 1) && projectedEndMonth >= quarter + 2 ? 1 : 0

                                let actualStartMonth = parseFloat(row.actual_months_into_project_start)
                                let actualEndMonth = actualStartMonth + parseFloat(row.actual_project_months)
                                let actualContribution = actualStartMonth <= (quarter + 1) && actualEndMonth >= quarter + 2 ? 1 : 0

                                // Get percentage into month -- divided into 10 pieces
                                if (parseFloat(numberFormat(projectedStartMonth, 0)) === quarter) {
                                    projectedContribution = decimalPart(projectedStartMonth)
                                    // quarters[`month_length_change_${month}_projected_start`] = true
                                }
                                if (parseFloat(numberFormat(actualStartMonth, 0)) === quarter) {
                                    actualContribution = decimalPart(actualStartMonth)
                                    // quarters[`month_length_change_${month}_actual_start`] = true
                                }
                                // If we are on the last month....
                                if (parseFloat(numberFormat(projectedContribution, 0)) === quarter + 1) {
                                    projectedContribution = decimalPart(projectedContribution)
                                }
                                if (parseFloat(numberFormat(actualContribution, 0)) === quarter + 1) {
                                    actualContribution = decimalPart(actualContribution)
                                }
                                quarters[`quarter_length_change_${quarter}`] = [projectedContribution, actualContribution]
                            })

                            cleanupHierarchyNames(row.itemHierarchy).forEach(term => {
                                aggregateData[term] ||= []
                                aggregateData[term].push({
                                    dda_term: term,
                                    projected_project_months: numberFormat(row.projected_project_months),
                                    actual_project_months: numberFormat(row.actual_project_months),
                                    projected_months_into_project_start: numberFormat(row.projected_months_into_project_start),
                                    actual_months_into_project_start: numberFormat(row.actual_months_into_project_start),
                                    variance: numberFormat((row.actual_project_months || 0) - (row.projected_project_months || 0)),
                                    ...quarters
                                })
                            })
                        }
                    });

                    // Process the aggregate data for the subtotal/total rows in the itemHierarchy
                    processRollupData()
                    // Process the averaged data for the leaf nodes
                    processLeafNodeData(data)
                    isNoDataFetch.value = false
                }

                isNoSelectedProject.value = true
            },
            errorStore
        );
    }

}

// The goal is to store in the rolled up terms:
// 1. minimum months into project start, as we want to see the earliest it is projected to or actually started
// 2. maximum length of project. This needs to be the latest the project could/did end minus the earliest it could/did start
function processRollupData() {
    Object.keys(aggregateData).forEach(term => {
        let minActualMonthsIntoProject = 0.0;
        let minProjectedMonthsIntoProject = 0.0;
        let maxActualProjectMonthsLength = 0.0;
        let maxProjectedProjectMonthsLength = 0.0;

        if (aggregateData[term]?.length) {
            aggregateData[term].forEach(value => {
                if (minActualMonthsIntoProject) {
                    minActualMonthsIntoProject = Math.min(minActualMonthsIntoProject, parseFloat(value.actual_months_into_project_start || 0.0))
                } else {
                    minActualMonthsIntoProject = parseFloat(value.actual_months_into_project_start || 0.0)
                }
            })

            aggregateData[term].forEach(value => {
                if (minProjectedMonthsIntoProject) {
                    minProjectedMonthsIntoProject = Math.min(minProjectedMonthsIntoProject, parseFloat(value.projected_months_into_project_start || 0.0))
                } else {
                    minProjectedMonthsIntoProject = parseFloat(value.projected_months_into_project_start || 0.0)
                }
            })

            aggregateData[term].forEach(value => {
                const actualProjectLength = parseFloat(value.actual_months_into_project_start || 0.0) + parseFloat(value.actual_project_months || 0.0)
                if (maxActualProjectMonthsLength) {
                    maxActualProjectMonthsLength = Math.max(maxActualProjectMonthsLength, actualProjectLength)
                } else {
                    maxActualProjectMonthsLength = actualProjectLength
                }
            })
            maxActualProjectMonthsLength -= minActualMonthsIntoProject

            aggregateData[term].forEach(value => {
                const projectedProjectLength = parseFloat(value.projected_months_into_project_start || 0.0) + parseFloat(value.projected_project_months || 0.0)
                if (maxProjectedProjectMonthsLength) {
                    maxProjectedProjectMonthsLength = Math.max(maxProjectedProjectMonthsLength, projectedProjectLength)
                } else {
                    maxProjectedProjectMonthsLength = projectedProjectLength
                }
            })
            maxProjectedProjectMonthsLength -= minProjectedMonthsIntoProject

            // TODO minor hack for now to make data look good since we don't always have projected months
            if (!maxProjectedProjectMonthsLength) { maxProjectedProjectMonthsLength = maxActualProjectMonthsLength }
        }

        processedAggregateData[term] = {
            minActualMonthsIntoProject: minActualMonthsIntoProject,
            minProjectedMonthsIntoProject: minProjectedMonthsIntoProject,
            maxActualProjectMonthsLength: maxActualProjectMonthsLength,
            maxProjectedProjectMonthsLength: maxProjectedProjectMonthsLength
        }
    })
}

// Process the data for the individual nodes
let isAveragedDataLoaded = ref(false)
function processLeafNodeData(data) {
    data?.forEach((row) => {
        if (row.itemHierarchy && row.actual_project_months) {
            let quarters = {}
            Array(maxQuarters / 3).fill(0).forEach((_, quarter) => {
                // TODO || parts here are to aid in testing. Need more projected start dates/months
                let projectedStartMonth = parseFloat(row.projected_months_into_project_start || row.actual_months_into_project_start)
                let projectedProjectMonths = parseFloat(row.projected_project_months || row.actual_project_months)
                if (projectedStartMonth < 0) projectedStartMonth = 1

                let actualStartMonth = parseFloat(row.actual_months_into_project_start)
                let actualProjectMonths = parseFloat(row.actual_project_months)

                // Each month of the Quarter
                let [projectedWidthPctOne, isProjectedRightAlignedOne] = quarterPercent(quarter / 3, projectedStartMonth, projectedProjectMonths)
                let [actualWidthPctOne, isActualRightAlignedOne] = quarterPercent(quarter / 3, actualStartMonth, actualProjectMonths)
                let [projectedWidthPctTwo, isProjectedRightAlignedTwo] = quarterPercent(quarter / 3 + 1, projectedStartMonth, projectedProjectMonths)
                let [actualWidthPctTwo, isActualRightAlignedTwo] = quarterPercent(quarter / 3 + 2, actualStartMonth, actualProjectMonths)
                let [projectedWidthPctThree, isProjectedRightAlignedThree] = quarterPercent(quarter / 3 + 2, projectedStartMonth, projectedProjectMonths)
                let [actualWidthPctThree, isActualRightAlignedThree] = quarterPercent(quarter / 3 + 2, actualStartMonth, actualProjectMonths)

                // Calculate full quarter projected width & percent
                let projectedWidthPct = projectedWidthPctOne
                let isProjectedRightAligned = isProjectedRightAlignedOne
                if (projectedWidthPctOne === 1) {
                    projectedWidthPct += projectedWidthPctTwo
                    isProjectedRightAligned = isProjectedRightAlignedTwo
                }
                if (projectedWidthPctTwo === 1) {
                    projectedWidthPct += projectedWidthPctThree
                    isProjectedRightAligned = isProjectedRightAlignedThree
                }
                projectedWidthPct = projectedWidthPct / 3.0

                // Calculate full quarter actual width & percent
                let actualWidthPct = actualWidthPctOne
                let isActualRightAligned = isActualRightAlignedOne
                if (actualWidthPctOne === 1) {
                    actualWidthPct += actualWidthPctTwo
                    isActualRightAligned = isActualRightAlignedTwo
                }
                if (actualWidthPctTwo === 1) {
                    actualWidthPct += actualWidthPctThree
                    isActualRightAligned = isActualRightAlignedThree
                }
                actualWidthPct = actualWidthPct / 3.0

                quarters[`quarter_length_change_${quarter}`] = [projectedWidthPct, actualWidthPct, isProjectedRightAligned, isActualRightAligned,
                    projectedProjectMonths / 3.0, actualProjectMonths / 3.0, projectedStartMonth / 3.0, actualStartMonth / 3.0]
            })
            dataShow.value.push({
                ...{
                    id: row.id,
                    project_id: row.project_id,
                    dda_term_id: row.dda_term_id,
                    hiddenHierarchy: cleanupHierarchyNames(row.itemHierarchy),
                    itemHierarchy: cleanupHierarchyNames(row.itemHierarchy),
                    projected_project_months: formatDecimals(row.projected_project_months / 3.0),
                    actual_project_months: formatDecimals(row.actual_project_months / 3.0),
                    projected_months_into_project_start: formatDecimals(row.projected_months_into_project_start / 3.0),
                    actual_months_into_project_start: formatDecimals(row.actual_months_into_project_start / 3.0),
                    variance: formatDecimals(((row.actual_project_months || 0) - (row.projected_project_months || 0)) / 3.0),
                }, ...quarters
            })
        }
    });

    // Use with the custom filters but still having the same effect only with just the Hard Cost included
    // Define the values to include
    const includeValues = ['Excavation & Foundation', 'Superstructure', 'Enclosure', 'Rough-Ins', 'Finishes'];

    // New array to hold filtered results
    let filteredResults = [];

    includeValues.forEach(value => {
        // Find the first item where itemHierarchy contains the value
        const foundItem = dataShow.value.find(item => item.itemHierarchy.includes(value));
        // Assuming each item has a unique identifier 'id' for this example
        if (foundItem && !filteredResults.some(item => item.id === foundItem.id)) {
            filteredResults.push(foundItem);
        }
    });

    // Strings to remove from itemHierarchy
    const stringsToRemove = ['Trade Costs  (From Construction Manager)', 'Interior Fit-Out'];

    // Modify itemHierarchy for each item in filteredResults
    filteredResults = filteredResults.map(item => {
        item.itemHierarchy = item.itemHierarchy.filter(hierarchyItem =>
            !stringsToRemove.includes(hierarchyItem)
        );
        return item;
    });

    // Replace dataShow.value with the modified filtered results
    dataShow.value = filteredResults;

    isAveragedDataLoaded.value = true
}

const maxQuarters = 48; // or 16 4 quarters per year over 4 years
let columnGroup = {};
Array(maxQuarters / 2).fill(0).forEach((_, quarter) => {
    if (quarter % 4 === 0) { // Start a new year group every 4 quarters
        if (quarter > 0) {
            // Save old column defs
            localColumnDefs.push(columnGroup);
        }
        columnGroup = {
            groupId: `year_${Math.floor(quarter / 4) + 1}`,
            headerName: `Year ${Math.floor(quarter / 4) + 1}`,
            menuTabs: ['columnsMenuTab'],
            children: []
        };
    }
    // Adjusting for quarters instead of months
    columnGroup.children.push({
        headerName: `Q${quarter % 4 + 1}`,
        field: `quarter_length_change_${quarter}`,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            suppressCount: true,
            checkbox: true,
            innerRenderer: 'GanttCellRenderer',
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true,
        },
        aggFunc: (params => quarterAggregate(params, quarter)), // Adjusted to a new aggregation function for quarters
        editable: false,
        suppressMenu: true,
        sortable: false,
        suppressMovable: true,
        maxWidth: 34,
        headerClass: quarter % 4 === 0 ? 'schedule-sparkline-header year' : 'schedule-sparkline-header',
        cellClass: quarter % 4 === 0 ? 'schedule-sparkline year' : 'schedule-sparkline',
        cellRendererSelector: (params) => {
            return {
                component: GanttCellRenderer,
                params: {
                    topWidthPct: parseFloat(params.value[0]),
                    bottomWidthPct: parseFloat(params.value[1]),
                    isTopRightAligned: params.value[2],
                    isBottomRightAligned: params.value[3],
                    projectedProjectMonths: formatDecimals(parseFloat(params.value[4]), 1) / 3.0,
                    actualProjectMonths: formatDecimals(parseFloat(params.value[5]), 1) / 3.0,
                    // -0.00 sometimes crops up here...
                    projectedMonthsIn: formatDecimals(Math.abs(parseFloat(params.value[6])), 1) / 3.0,
                    actualMonthsIn: formatDecimals(Math.abs(parseFloat(params.value[7])), 1) / 3.0,
                },
            }
        },
        filter: false,
    });
});

// Aggregate to show in column for this month
// Depending on which data is passed in, the goal is the get:
// 1. minimum months into project start, as we want to see the earliest it is projected to or actually started
// 2. maximum length of project. This needs to be the latest the project could/did end minus the earliest it could/did start
function quarterAggregate(params, month) {
    let data = processedAggregateData[params.rowNode.key]

    if (data) {
        let [projectedWidthPct, isProjectedRightAligned] = quarterPercent(month, data.minProjectedMonthsIntoProject / 3.0, data.maxProjectedProjectMonthsLength / 3.0)
        let [actualWidthPct, isActualRightAligned] = quarterPercent(month, data.minActualMonthsIntoProject / 3.0, data.maxActualProjectMonthsLength / 3.0)

        return [projectedWidthPct, actualWidthPct, isProjectedRightAligned, isActualRightAligned,
            data.maxProjectedProjectMonthsLength / 3.0, data.maxActualProjectMonthsLength / 3.0, data.minProjectedMonthsIntoProject / 3.0, data.minActualMonthsIntoProject / 3.0]
    } else {
        return Array(8).fill(0);
    }
}

// Calculates how much of a band to show of a project length
function quarterPercent(quarter, quartersIntoProject, projectquarterLength) {
    let widthPct = 0
    let isRightAligned = false

    if (quartersIntoProject < quarter + 1) {
        if (quartersIntoProject > quarter) {
            // spans part of the first column cell
            widthPct = (quarter + 1) - quartersIntoProject
            isRightAligned = true
        } else if (quartersIntoProject + projectquarterLength > quarter &&
            quartersIntoProject + projectquarterLength < quarter + 1) {
            // spans part of the end column cell
            widthPct = (quartersIntoProject + projectquarterLength) - (quarter)
        } else if (quartersIntoProject <= (quarter + 2) && quartersIntoProject + projectquarterLength > quarter) {
            widthPct = 1
        }
    }

    return [widthPct, isRightAligned];
}

const tooltipStore = useGanttTooltipStore()
let tooltipIsShow = ref(tooltipStore.getIsShow)
let tooltipTop = ref(tooltipStore.getTop)
let tooltipLeft = ref(tooltipStore.getLeft)
let tooltipHtml = ref(tooltipStore.getHtml)
tooltipStore.$subscribe(updateTooltip, { detached: false })
function updateTooltip() {
    tooltipIsShow.value = tooltipStore.getIsShow
    tooltipTop.value = tooltipStore.getTop
    tooltipLeft.value = tooltipStore.getLeft
    tooltipHtml.value = tooltipStore.getHtml
}

// For Schedule Gant Chart - With Quarters (End)

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.side-modal {
    position: fixed;
    display: flex;
    top: 50px;
    right: 0;
    height: 100vh;
    background-color: $white1;
    transition: width 0.5s ease;
    z-index: 5;
}

.comp-summary-title {
    font-size: 16px;
    font-family: 'Inter';
    font-weight: bold;
    color: $navyBlue;
    padding: 20px 0 10px 0;
}

.side-modal.open {
    width: 650px;
    transition: width 0.5s ease;
}

.side-modal.closed {
    width: 20px;
    transition: width 0.5s ease;
}

.hidden {
    display: none;
}

.modal {
    width: 650px;
    box-shadow: 6px 0 18px #BEBFBF;
}

.flipped {
    direction: rtl;
}

.comp-summary {
    padding: 0.25rem 1.5rem;
    font-family: 'Inter';
    overflow-y: scroll;
}

.content {
    direction: ltr;
}

.no-selected-data {
    font-size: 12px;
    color: $navyBlue;
}

.comp-summary::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: $white1;
}

.comp-summary::-webkit-scrollbar-thumb {
    background: $lightGray1;
}

h4 {
    color: $navyBlue;
}

.header-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.left-div .right-div {
    flex: 1;
}

.divider {
    border: 1px solid $lightGray1;
    margin: 5px 0px;
}

.divider-comp-set {
    width: 100%;
    display: flex;
    justify-content: center;

    div {
        border: 1px solid lightgray;
        width: 50%;
        margin: 5px 0;
    }
}

.accordion-panel-header {
    display: flex;
    align-items: center;
    position: relative;
    width: 97%;
    height: 27px;

    label {
        font-size: 14px;
    }
}

.budget-overview-container,
.schedule-overview-container,
.active-compset-container {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    position: relative;

    label {
        margin-bottom: 5px;
        color: $navyBlue;
        font-size: 14px;
        font-weight: bold;
    }
}

.comp-set-counter {
    color: $navyBlue;
    padding-left: 5px;
    font-weight: bold;
}

.budget-overview-container,
.schedule-overview-container {
    img {
        width: 100%;
        height: 100%
    }
}

.bar-chart-legend-total {
    text-align: right;
    font-size: 10px;
    color: $lightBlue1;

    span:first-child {
        padding-right: 2.5rem;
    }

    span:nth-child(2) {
        padding-right: 1.5rem;
    }
}

.bar-chart-legend-revised {
    text-align: right;
    font-size: 10px;
    color: $lightBlue1;

    span:first-child {
        padding-right: 12px;
    }

    span:nth-child(2) {
        padding-right: 7px;
    }
}

// Doughnut Legend
.doughnut-chart-legend {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .acquisition-legend {
        margin: 5px 0;

        .detail-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            color: $darkGray1
        }

        .vertical-divider {
            border-left: 5px solid $darkGray1;
            margin: 0 5px;
        }
    }

    .hard-legend {
        margin: 5px 0;

        .detail-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            color: $brightBlue1;
        }

        .vertical-divider {
            border-left: 5px solid $brightBlue1;
            margin: 0 5px;
        }
    }

    .soft-legend {
        margin: 5px 0;

        .detail-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            color: $mediumBlue1;
        }

        .vertical-divider {
            border-left: 5px solid $mediumBlue1;
            margin: 0 5px;
        }
    }

    .misc-finance-legend {
        margin: 5px 0;

        .detail-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            color: $mediumGray1;
        }

        .vertical-divider {
            border-left: 5px solid $mediumGray1;
            margin: 0 5px;
        }
    }

    .contingencies-legend {
        margin: 5px 0;

        .detail-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            color: #d3d3d3;
        }

        .vertical-divider {
            border-left: 5px solid #d3d3d3;
            margin: 0 5px;
        }
    }
}

.view-by-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    color: $navyBlue;
    width: 45%;

    div:first-child select {
        width: 95%;
    }
}

.active-compset-container .accordion-panel-header {
    label {
        margin-bottom: 5px;
        color: $navyBlue;
        font-size: 16px;
        font-weight: bold;
    }
}

.active-compset-container {
    .active-compset-label {
        color: $darkGray2;
        font-size: 12px;
        padding: 0.5rem 0;

        span {
            color: $brightBlue1;
            padding: 0px 5px;
        }
    }
}

.active-compset-details-container {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 5px;
    color: $navyBlue;

    .property-address {
        font-size: 14px;
        font-weight: bold;
        padding: 5px 0px;
        display: flex;
        justify-content: space-between;
    }

    .property-thumbnail {
        margin: 5px 28px 5px 5px;
        border: .5px solid $lightGray1;
        border-radius: 2px;

        img {
            width: 100px;
            height: 100px;
        }
    }

    .property-details {
        display: flex;
        flex-direction: row;
        flex: 2;

        .property-building-info,
        .property-construction-info {
            font-size: 12px;
            width: 100%;
        }

        .property-building-label,
        .property-construction-label {
            font-size: 13px;
            font-weight: bolder;
            padding-bottom: 5px;
            white-space: nowrap;
        }

        .property-building-info {
            padding-right: 1rem;
        }

        .property-info {
            padding-left: 5px;
        }

        .property-info-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2px 0px;

            .property-info-value {
                text-align: right;
            }
        }
    }
}

.property-info {
    width: 73%;
}

.custom-select {
    width: 100%;
    position: relative;
    font-family: inherit;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 5px;


    select {
        border: 0;
        color: $brightBlue1;
    }

    select:focus {
        border-color: $brightBlue1;
        outline: $brightBlue1;
    }
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

// Action Buttons
.action-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 8vh;
}

.primary-action-button {
    background-color: $mediumBlue2;
    color: white;
    border: 0;
    border-radius: 4px;
    margin: 8px;
    cursor: pointer;
    padding: 10px;
    font-size: 12px;
}

.secondary-action-button {
    background-color: transparent;
    color: $brightBlue1;
    border: 0;
    cursor: pointer;
    padding: 5px;
    font-size: 12px;
    margin-right: 15px;
}

.secondary-action-button:hover {
    text-decoration: underline;
}

// Charts
.budget-chart-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
}

.doughnut-chart-container,
.bar-chart-container {
    flex: 1 0;
    padding: 15px;
    background: $white1;
    border: 1px solid $lightGray1;
    border-radius: 15px;
}

.doughnut-chart-container {
    margin-right: 20px;
}

.bar-chart-container {
    width: 100px;
}

.chart-label {
    font-size: 14px;
    padding-bottom: 20px;
}

.chart-content {
    display: flex;
    padding-top: 10px
}

.go-to-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

// Schedule Gantt Chart Styles
.schedule-gantt .schedule-sparkline {
    // keep border, but as narrow as possible
    border-left-width: 0.5px;
    border-right-width: 0.5px;
    outline: 0;
}

.schedule-gantt .schedule-sparkline.year {
    // keep border slightly wider at the year cutoff
    border-left-width: 1.5px;
}

.schedule-gantt .schedule-sparkline-header {
    // keep sparklines flush against cells
    box-sizing: content-box;
    padding-right: 0;
    padding-left: 4px;
}

.schedule-gantt .schedule-sparkline-header.year {
    // keep border slightly wider at the year cutoff
    border-left-width: 1.5px;
}

.active-compset-label span.disabled-button {
    color: $lightGray1;
}

.disabled-button {
    color: $lightGray1;
    cursor: not-allowed;

    &:hover {
        text-decoration: none;
    }
}
</style>