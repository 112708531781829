export const CONSTANTS = {
  DROPDOWN_CHAR_THRESHOLD: 3,
}

export const ASSET_CATEGORY_TYPES = {
  RESIDENTIAL: 'Residential',
  RETAIL: 'Retail',
  COMMERCIAL: 'Commercial',
  INDUSTRIAL: 'Industrial',
  HOSPITALITY: 'Hospitality',
  CIVIC_AND_CULTURAL: 'Civic & Cultural',
}

export const BUILDING_USE_TYPES_RESIDENTIAL = {
  RENTAL_APARTMENT: 'Rental Apartment',
  CONDOMINIUM: 'Condominium',
  CO_LIVING: 'Co-Living',
  STUDENT_HOUSING: 'Student Housing',
  SENIOR_HOUSING: 'Senior Housing',
  SINGLE_FAMILY_RESIDENCE: 'Single Family Residence',
  MANUFACTURED_HOME: 'Manufactured Home',
  MODULAR_HOME: 'Modular Home',
  MICRO_HOUSING: 'Micro Housing',
}

export const BUILDING_USE_TYPES_COMMERCIAL = {
  OFFICE: 'Office',
  CO_WORKING: 'Co-Working',
  HEALTHCARE_MEDICAL_OFFICE: 'Health Care / Medical Office',
  LIFE_SCIENCE: 'Life Science',
  CREATIVE_OFFICE: 'Creative Office',
  COMMUNITY_FACILITY: 'Community Facility',
  FILM_STUDIO: 'Film Studio',
  SOUND_STAGE: 'Sound Stage',
}

export const BUILDING_USE_TYPES_INDUSTRIAL = {
  WAREHOUSE: 'Warehouse',
  SELF_STORAGE: 'Self-Storage',
  COLD_STORAGE: 'Cold Storage',
  DATA_CENTER: 'Data Center',
  MANUFACTURING: 'Manufacturing',
}

export const BUILDING_USE_TYPES_HOSPITALITY = {
  UPSCALE_LUXURY_HOTEL: 'Upscale / Luxury Hotel',
  ECONOMY_MIDSCALE_HOTEL: 'Economy / Midscale Hotel',
  EXTENDED_STAY_HOTEL: 'Extended Stay Hotel',
  MICRO_HOTEL: 'Micro Hotel',
  BOUTIQUE_HOTEL: 'Boutique Hotel',
  RESORT: 'Resort',
  TIMESHARE: 'Timeshare',
  GOLF_COURSE: 'Golf Course',
  GAMING: 'Gaming',
  MEMBERS_CLUB: `Member's Club`,
}

export const BUILDING_USE_TYPES_RETAIL = {
  INTEGRATED_GROUND_RETAIL: 'Integrated Ground Retail',
  MALL: 'Mall',
  STRIP_CENTER: 'Strip Center',
  RESTAURANT: 'Restaurant',
  THEATER: 'Theater',
  EVENT_SPACE: 'Event Space',
  MARKETPLACE: 'Market Place',
  ART_GALLERY: 'Art Gallery',
}

export const BUILDING_USE_TYPES_CIVIC_AND_CULTURAL = {
  EDUCATION: 'Education',
  HOSPITAL: 'Hospital',
  GOVERNMENT: 'Government',
  RELIGIOUS: 'Religious',
  STADIUM: 'Stadium',
  MUSEUM: 'Museum',
  CULTURAL_CENTER: 'Cultural Center',
  CONVENTION_CENTER: 'Convention Center',
  MUSIC_VENUE: 'Music Venue',
  PUBLIC_PARK: 'Public Park',
}

export const PARKING_TYPES = {
  SUBGRADE_INTEGRATED: 'Subgrade Integrated',
  STRUCTURED_INTEGRATED: 'Structured Integrated',
  STRUCTURED_STANDALONE: 'Structured Standalone',
  SURFACE: 'Surface',
  AUTOMATED: 'Automated'
}

export const CONSULTANT_TYPES = {
  SUBCONTRACTOR: 'Subcontractor',
  OWNERS_REPRESENTATIVE: 'Owners Representative',
  THIRD_PARTY_PROJECT_MANAGER: 'Third Party Project Manager',
  ARCHITECT: 'Architect',
  DESIGN_ARCHITECT: 'Design Architect',
  CIVIL_ENGINEER: 'Civil Engineer',
  STRUCTURAL_ENGINEER: 'Structural Engineer',
  MECHANICAL_ENGINEER: 'Mechanical Engineer',
  ELECTRICAL_ENGINEER: 'Electrical Engineer',
  PLUMBING_ENGINEER: 'Plumbing Engineer',
  FIRE_PROTECTION_ENGINEER: 'Fire Protection Engineer',
  INTERIOR_DESIGN: 'Interior Design',
  LANDSCAPE_ARCHITECT: 'Landscape Architect',
  GEOTECHNICAL_ENGINEER: 'Geotechnical Engineer',
  ENVIRONMENTAL_ENGINEER: 'Environmental Engineer'
}

export const CODES = {
  CONTRACT_TYPE: 'ContractType',
  CONSTRUCTION_TYPE: 'ConstructionType'
}

export const BUILDING_CLASIFICATION = {
  LOW_RISE: 'Low-rise',
  MID_RISE: 'Mid-rise',
  HIGH_RISE: 'High-rise',
  SUPER_TALL: 'Super-tall',
  MEGA_TALL: 'Mega-tall'
}

export const OWNER_ALLOCATIONS = {
  LENDER: 'Lender',
  INVESTOR: 'Investor',
  DEVELOPER: 'Developer'
}

export const BUILDING_USE_TYPES_RESIDENTIAL_ARRAY = Object.values(BUILDING_USE_TYPES_RESIDENTIAL);
export const BUILDING_USE_TYPES_COMMERCIAL_ARRAY = Object.values(BUILDING_USE_TYPES_COMMERCIAL);
export const BUILDING_USE_TYPES_INDUSTRIAL_ARRAY = Object.values(BUILDING_USE_TYPES_INDUSTRIAL)
export const BUILDING_USE_TYPES_HOSPITALITY_ARRAY = Object.values(BUILDING_USE_TYPES_HOSPITALITY)
export const BUILDING_USE_TYPES_RETAIL_ARRAY = Object.values(BUILDING_USE_TYPES_RETAIL)
export const BUILDING_USE_TYPES_CIVIC_AND_CULTURAL_ARRAY = Object.values(BUILDING_USE_TYPES_CIVIC_AND_CULTURAL)
export const PARKING_TYPES_ARRAY = Object.values(PARKING_TYPES)
export const CONSULTANT_TYPES_ARRAY = Object.values(CONSULTANT_TYPES)
export const CODE_ARRAY = Object.values(CODES)