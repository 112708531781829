import { createWebHistory, createRouter } from "vue-router"
import HomeView from "@/views/HomeView.vue"
import AdminView from "@/views/AdminView.vue"
import MyDataView from "@/views/MyDataView.vue"
import ProjectSummary from "@/components/mydata/ProjectSummary.vue"
import CompSearchDetailsPage from "@/components/comp-search-details/CompSearchDetailsPage.vue"
import NewProjectPage from "@/components/add-project/NewProjectPage.vue"
import UploadDocumentsView from "@/components/upload-documents/UploadDocumentsView.vue"
import ReportPage from "@/components/comp-search-details/ReportPage.vue"

const routes = [
  {
    path: "/",
    children: [
      {
        path: "",
        name: "Home",
        component: HomeView
      },
      {
        path: "/compsearch/:selectedTab",
        name: "Comp Search Details",
        component: CompSearchDetailsPage,
      },
    ]
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminView,
    meta: { title: '' }
  },
  {
    path: "/mydata",
    children: [
      {
        path: '',
        name: 'My Data',
        component: MyDataView
      },
      // Will be the main page of all Budget, Schedule, Companies and DOcuments.
      {
        path: "project-summary/:selectedId",
        name: "Project Summary Page",
        component: ProjectSummary
      }
    ]
  },
  {
    path: "/project",
    name: "New Project",
    component: NewProjectPage
  },
  {
    path: "/upload-documents",
    name: "Upload Documents",
    component: UploadDocumentsView
  },
  {
    path: "/reports",
    name: "Report Details",
    component: ReportPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router