<template>
  <div class="autocomplete-container">
    <input v-model="searchQuery" @input="filterItems" @keydown.down.prevent="moveDown" @keydown.up.prevent="moveUp"
      @keydown.enter.prevent="handleEnterKey" :placeholder="props.placeholder" class="autocomplete-input" id="searchQuery" />
    <button v-if="searchQuery.length > 0" class="clear-button" @click.prevent="clearSearch">&#x2715;</button>
    <div v-if="showDropdown && searchQuery.length >= CONSTANTS.DROPDOWN_CHAR_THRESHOLD">
      <ul class="autocomplete-dropdown">
        <li v-for="(item, index) in filteredItems" :key="item.id" @click="selectItem(index)"
          :class="{ 'active': index === activeIndex }">
          {{ item[props.displayKey] }} <!-- Updated to use props.displayKey -->
        </li>
      </ul>
    </div>
    <div v-if="searchQuery.length > 0 && showTypedText">
      <ul class="autocomplete-dropdown">
        <li @click="selectItem(-1)">Use "<strong>{{ searchQuery }}</strong>"</li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed } from 'vue';
import { CONSTANTS } from "@/types/Constants";

const props = defineProps({
  items: Array,
  displayKey: String, // New prop for dynamic key
  placeholder: String,
});

const emitItemSelected = defineEmits(['item-selected']);

const searchQuery = ref('');
const activeIndex = ref(-1);
const showDropdown = ref(false);
const showTypedText = ref(false);
const itemSelected = ref(false);

// Updated to use props.displayKey
const filteredItems = computed(() => {
  const searchWords = searchQuery.value.trim().toLowerCase().split(/\s+/);
  if (!searchWords.length) {
    return [];
  }
  return props.items.filter(item =>
    searchWords.every(word => item[props.displayKey].toLowerCase().includes(word))
  );
});

watch(filteredItems, (newFilteredItems) => {
  if (!itemSelected.value) {
    showDropdown.value = newFilteredItems.length > 0;
    showTypedText.value = newFilteredItems.length == 0;
  }
})

const selectItem = (index = activeIndex.value) => {
  itemSelected.value = true; // Indicate that an item is being selected
  showDropdown.value = false; // Ensure dropdown is hidden on any selection

  if (index >= 0 && index < filteredItems.value.length) {
    const selectedItem = filteredItems.value[index];
    searchQuery.value = selectedItem[props.displayKey];
    emitItemSelected('item-selected', selectedItem);
  } else {
    const customItem = { [props.displayKey]: searchQuery.value, id: null };
    searchQuery.value = customItem[props.displayKey];
    showTypedText.value = false; // Assuming you want to hide typed text in this case as well
    emitItemSelected('item-selected', customItem);
  }

  // Reset itemSelected after a delay to allow for UI updates
  setTimeout(() => {
    itemSelected.value = false;
  }, 100); // Adjust delay as needed
};

function clearSearch() {
  searchQuery.value = '';
  showDropdown.value = false;
  showTypedText.value = false;
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.autocomplete-container {
  position: relative;
  width: 145.5%;
  display: inline-block;
  color: $navyBlue;
}

.autocomplete-input {
  width: 100%;
  height: 10px;
  padding: 5px;
  font-size: 12px;
  border: 1px solid $lightGray1;
  background-color: $lightGray1;
  border-radius: 5px;
  color: $navyBlue;
}

.autocomplete-dropdown {
  position: absolute;
  width: 100%;
  border: 1px solid $lightGray1;
  background-color: $lightGray1;
  border-top: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 0;
  top: 10px;

  /* Ensure it's above other content */
  ul {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    font-size: 12px;
    margin-left: 0;
  }
}

.autocomplete-dropdown li {
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  list-style: none;
}

.autocomplete-dropdown li:hover,
.autocomplete-dropdown li.active {
  background-color: #007BFF;
  color: white;
}

.clear-button {
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 12px;
}
</style>
