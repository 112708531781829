<template>
  <div id="app-container" class="container font-dark-1 bak-light-gray-1">
    <div class="logged-in-check" v-if="!loggedOut && !isVersionOld">
      <div class="header-container">
        <HeaderBar @setHeaders="setHeaders" />
      </div>
      <div class="main-container">
        <div class="views-container">
          <router-view />
        </div>
      </div>
      <div class="footer-container">
        <FooterBar />
      </div>
    </div>
    <div class="logged-in-check" v-else-if="!loggedOut && isVersionOld">
      <error-message class="error-message" />
    </div>
    <div class="logged-in-check" v-else>
      <SessionManager @logged-in-attempt="checkLoggedIn" />
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/auth_store"
import { onMounted, ref } from "vue"
import SessionManager from "./components/SessionManager.vue"
import ErrorMessage from "./components/helpers/ErrorMessage.vue"
import FooterBar from "./components/layout/FooterBar.vue"
import HeaderBar from "./components/layout/HeaderBar.vue"

let breadcrumb = ref("Comp Search | Comp Search Details")
let pageTitle = ref("Comp Search Details")
const authStore = useAuthStore()
let isVersionOld = ref(false) // TODO implement someday
onMounted(() => {
  checkLoggedIn()
})
authStore.$subscribe(checkLoggedIn)
function checkLoggedIn() {
  loggedOut.value = !authStore.isLoggedIn || !authStore.getUserInitials
}

let loggedOut = ref(false)

function setHeaders(event) {
  breadcrumb.value = event.value[0]
  pageTitle.value = event.value[1]
}

</script>

<style lang="scss">
@import "./src/assets/main.scss";

#app-container {
  font-family: $specialFont;
  background-color: $darkWhite1;
}
</style>

<style scoped lang="scss">
.header-container,
.main-container,
.footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

.header-container {
  position: fixed;
  margin: 0 0 50px 0;
  height: auto;
  z-index: 100; // ensure it is above contents
}


.footer-container {
  margin-top: auto;
}
</style>