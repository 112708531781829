import {createApp} from "vue"
import { createPinia } from "pinia"
import App from "./App.vue"
import router from "./router"

import "./assets/main.scss"
import "./assets/colors.scss"
import axios from "axios"
import {useErrorStore} from "@/stores/error_store"
import {useAuthStore} from "@/stores/auth_store"
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.component('VueDatePicker', VueDatePicker)
app.mount("#app")

/**
 * Load JWT from Local Storage on Refresh.
 */
const PREFIX = 'iP4BLqnibqamRTI_'
let localAuthToken = localStorage.getItem(`${PREFIX}auth_token`)
let cookieExists = localAuthToken !== 'undefined' && localAuthToken !== null && localAuthToken !== undefined
if (cookieExists) {
  axios.defaults.headers.common["Authorization"] = 'Bearer ' + localAuthToken.replace(/^Bearer /, '')
}

/*
 * Intercept Axios responses to indicate user login has expired.
 * https://axios-http.com/docs/interceptors
 */
const errorStore = useErrorStore()
const logoutMessage = errorStore.getLoggedOutMessage
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error?.response?.status === 401) {
    errorStore.setError('Login Expired', logoutMessage, 'error', true)
  }
  return Promise.reject(error)
})

// NOTE: we could allow a last save, but given this currently works on the backend at 11:59pm, we shouldn't run into this much/at all.
errorStore.$subscribe(confirmNotLoggedIn)
function confirmNotLoggedIn() {
  if (errorStore.getMessage === logoutMessage && errorStore.getClearedStatus) {
    const authStore = useAuthStore()
    authStore.logoutUser()
  }
}

// Load Google Maps API
// (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
//   key: process.env.VUE_APP_ENV_API_GMAP,
//   v: "weekly",
//   // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
//   // Add other bootstrap parameters as needed, using camel case.
// });
const script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_ENV_API_GMAP}&loading=async&libraries=marker,places,geometry,drawing&v=weekly`;
script.async = true;
script.defer = true;
document.head.appendChild(script);