<template>
  <form class="project-information-form-container">
    <!-- Left Information-->
    <div class="left-container">
      <!-- Capital Level -->
      <div class="information-main-title">Capital Level</div>
      <div class="tile">
        <!-- Debt -->
        <div class="information-subtitle">Debt</div>
        <div class="custom-text-input">
          <label class="left-div">Primary Lender</label>
          <div class="right-div">
            <auto-complete-search-box :items="investorSponsors" :displayKey="'name'"
              @item-selected="event => updatePrimaryLender(event)" />
          </div>
        </div>
        <div v-for="(lender, index) in form.additionalLenders" :key="index" class="custom-text-input">
          <label class="left-div">Additional Lender</label>
          <div class="right-div">
            <auto-complete-search-box :items="investorSponsors" :displayKey="'name'"
              @item-selected="event => updateAdditionalLender(event, index)" />
          </div>
        </div>
        <div class="document-action-button" @click="addLender">
          <div class="action-name">Add another Lender</div>
          <div class="cssCircle plusSign">
            &#43;
          </div>
        </div>

        <div class="information-subtitle">Equity (Non-Developer)</div>
        <div class="custom-text-input">
          <label class="left-div">Primary Investor</label>
          <div class="right-div">
            <auto-complete-search-box :items="investorSponsors" :displayKey="'name'"
              @item-selected="event => updatePrimaryInvestor(event)" />
          </div>
        </div>
        <div v-for="(investor, index) in form.additionalInvestors" :key="index" class="custom-text-input">
          <label class="left-div">Additional Investor</label>
          <div class="right-div">
            <auto-complete-search-box :items="investorSponsors" :displayKey="'name'"
              @item-selected="event => updateAdditionalInvestor(event, index)" />
          </div>
        </div>
        <div class="document-action-button" @click="addInvestor">
          <div class="action-name">Add Another Investor</div>
          <div class="cssCircle plusSign">
            &#43;
          </div>
        </div>
      </div>

      <!-- Owner Level -->
      <div class="information-main-title">Owner Level</div>
      <div class="tile">
        <div class="information-subtitle">Developer</div>
        <div class="custom-text-input">
          <label class="left-div">Primary Developer</label>
          <div class="right-div">
            <auto-complete-search-box :items="investorSponsors" :displayKey="'name'"
              @item-selected="event => updatePrimaryDeveloper(event)" />
          </div>
        </div>
        <div v-for="(codeveloper, index) in form.coDevelopers" :key="index" class="custom-text-input">
          <label class="left-div">Co-Developer</label>
          <div class="right-div">
            <auto-complete-search-box :items="investorSponsors" :displayKey="'name'"
              @item-selected="event => updateCoDeveloper(event, index)" />
          </div>
        </div>
        <div></div>
        <div class="document-action-button" @click="addCoDeveloper">
          <div class="action-name">Add a Co-Developer</div>
          <div class="cssCircle plusSign">
            &#43;
          </div>
        </div>
      </div>
    </div>
    <!-- Right Information-->
    <div class="right-container">
      <!-- Consultants -->
      <div class="information-main-title">Consultants</div>
      <div class="tile">
        <div class="information-subtitle">Third-Party Project Management</div>
        <div class="custom-text-input">
          <label class="left-div">Owner's Representative</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.OWNERS_REPRESENTATIVE)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Addl. Project/Construction Manager</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.THIRD_PARTY_PROJECT_MANAGER)" />
          </div>
        </div>
      </div>

      <div class="tile">
        <div class="information-subtitle">Architect</div>
        <div class="custom-text-input">
          <label class="left-div">Architect of Record</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.ARCHITECT)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Design Architect (if different)</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.DESIGN_ARCHITECT)" />
          </div>
        </div>

        <div class="information-subtitle">Primary Sub-Consultants</div>
        <div class="custom-text-input">
          <label class="left-div">Civil</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.CIVIL_ENGINEER)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Structural</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.STRUCTURAL_ENGINEER)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Mechanical</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.MECHANICAL_ENGINEER)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Electrical</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.ELECTRICAL_ENGINEER)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Plumbing</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.PLUMBING_ENGINEER)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Fire Protection</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.FIRE_PROTECTION_ENGINEER)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Interior Design</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.INTERIOR_DESIGN)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Landscape Architect</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.LANDSCAPE_ARCHITECT)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Geotechnical</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.GEOTECHNICAL_ENGINEER)" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Environmental</label>
          <div class="right-div">
            <auto-complete-search-box :items="consultants" :displayKey="'name'"
              @item-selected="updateConsultantInfo($event, CONSULTANT_TYPES.ENVIRONMENTAL_ENGINEER)" />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { computed, defineExpose, onMounted, ref } from 'vue';
import { useDropdownStore } from "@/stores/dropdown_store";
import AutoCompleteSearchBox from './AutoCompleteSearchBox.vue';
import { CONSULTANT_TYPES } from "@/types/Constants";

const dropdownStore = useDropdownStore();

const form = ref({
  primaryLender: {},
  additionalLenders: [{}],
  primaryInvestor: {},
  additionalInvestors: [{}],
  primaryDeveloper: {},
  coDevelopers: [{}],
  ownersRepresentative: '',
  additionalProjectOrConstructionManager: '',
  architectOfRecord: '',
  designArchitect: '',
  civil: '',
  structural: '',
  mechanical: '',
  electrical: '',
  plumbing: '',
  fireProtection: '',
  interiorDesign: '',
  landscapeArchitect: '',
  geotechnical: '',
  environmental: ''
});

const consultanTypes = computed(() => dropdownStore.consultantTypes);

const investorSponsors = computed(() => dropdownStore.investorSponsors);

const consultants = computed(() => dropdownStore.consultants);

function updatePrimaryLender(event) {
  form.value.primaryLender = {
    name: event.name,
    is_primary: true,
    investor_role: 'Lender'
  };
}

function updatePrimaryInvestor(event) {
  form.value.primaryInvestor = {
    name: event.name,
    is_primary: true,
    investor_role: 'Investor'
  };
}

function updatePrimaryDeveloper(event) {
  form.value.primaryDeveloper = {
    name: event.name,
    is_primary: true,
    investor_role: 'Developer'
  };
}

function updateAdditionalLender(event, index) {
  // Directly update the lender object at the specified index
  const lenderToUpdate = form.value.additionalLenders[index];
  if (lenderToUpdate) {
    lenderToUpdate.name = event.name;
    lenderToUpdate.is_primary = false;
    lenderToUpdate.investor_role = 'Lender';
  } else {
    // If there's no lender at the index, push a new one (optional, based on your logic)
    form.value.additionalLenders.push({
      name: event.name,
      is_primary: false,
      investor_role: 'Lender'
    });
  }
}

function updateAdditionalInvestor(event, index) {
  // Directly update the investor object at the specified index
  const investorToUpdate = form.value.additionalInvestors[index];
  if (investorToUpdate) {
    investorToUpdate.name = event.name;
    investorToUpdate.is_primary = false;
    investorToUpdate.investor_role = 'Investor';
  } else {
    // If there's no investor at the index, push a new one (optional, based on your logic)
    form.value.additionalInvestors.push({
      name: event.name,
      is_primary: false,
      investor_role: 'Investor'
    });
  }

}

function updateCoDeveloper(event, index) {
  // Directly update the co-developer object at the specified index
  const developerToUpdate = form.value.coDevelopers[index];
  if (developerToUpdate) {
    developerToUpdate.name = event.name;
    developerToUpdate.is_primary = false;
    developerToUpdate.investor_role = 'Developer';
  } else {
    // If there's no co-developer at the index, push a new one (optional, based on your logic)
    form.value.coDevelopers.push({
      name: event.name,
      is_primary: false,
      investor_role: 'Developer'
    });
  }

}

// Add a new empty object for a new lender
function addLender() {
  form.value.additionalLenders.push({});
}

// Add a new empty object for a new investor
function addInvestor() {
  form.value.additionalInvestors.push({});
}

// Add a new empty object for a new co-developer
function addCoDeveloper() {
  form.value.coDevelopers.push({});
}

function updateConsultantInfo(event, consultantType) {
  let consultantTypeId = consultanTypes.value.find(consultant => consultant.code === consultantType).id;
  switch (consultantType) {
    case CONSULTANT_TYPES.OWNERS_REPRESENTATIVE:
      form.value.ownersRepresentative = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.THIRD_PARTY_PROJECT_MANAGER:
      form.value.additionalProjectOrConstructionManager = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.ARCHITECT:
      form.value.architectOfRecord = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.DESIGN_ARCHITECT:
      form.value.designArchitect = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.CIVIL_ENGINEER:
      form.value.civil = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.STRUCTURAL_ENGINEER:
      form.value.structural = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.MECHANICAL_ENGINEER:
      form.value.mechanical = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.ELECTRICAL_ENGINEER:
      form.value.electrical = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.PLUMBING_ENGINEER:
      form.value.plumbing = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.FIRE_PROTECTION_ENGINEER:
      form.value.fireProtection = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.INTERIOR_DESIGN:
      form.value.interiorDesign = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.LANDSCAPE_ARCHITECT:
      form.value.landscapeArchitect = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.GEOTECHNICAL_ENGINEER:
      form.value.geotechnical = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    case CONSULTANT_TYPES.ENVIRONMENTAL_ENGINEER:
      form.value.environmental = {
        name: event.name,
        consultant_type_id: consultantTypeId
      };
      break;
    default:
      break;
  }
}

defineExpose({
  form
})

onMounted(() => {
  dropdownStore.getConsultantTypes();
  dropdownStore.getInvestorSponsors();
  dropdownStore.getConsultants();
});

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.project-information-form-container {
  display: flex;
  gap: 25px;

  .left-container,
  .right-container {
    flex: 1;
    min-width: 0;
  }
}

.information-main-title {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
  color: $navyBlue;
}

.information-subtitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0;
  color: $navyBlue;

  span {
    font-size: 12px;
    font-weight: normal;
  }
}

.tile {
  background-color: $white1;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
}

.custom-text-input {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 5px 0px;
  width: 100%;

  .left-div {
    width: 40%;
  }

  .right-div {
    display: flex;
    width: 100%;
  }

  .autocomplete-container {
    width: 97.5%;
  }

  input[type=text] {
    color: $brightBlue1;
    background-color: $lightGray1;
    border: 1px solid $lightGray1;
    border-radius: 5px;
    padding: 5px;
    height: 10px;
    width: 100%;
  }

  input[type=text]:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }

  input[type=number] {
    color: $brightBlue1;
    background-color: $lightGray1;
    border: 1px solid $lightGray1;
    border-radius: 5px;
    padding: 5px;
    height: 10px;
    width: 100%;
  }

  input[type=number]:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }
}

.left-div .right-div {
  flex: 1;
}

.right-div {
  p {
    margin: 5px;
  }
}

/** Custom Dropdown  */
.custom-select {
  width: 100%;
  position: relative;
  font-family: inherit;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 5px 0px;
  color: $navyBlue;

  .left-div {
    width: 40%;
  }

  .right-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  select {
    appearance: none;
    font-family: inherit;
    -webkit-appearance: none;
    padding: 5px;
    color: $brightBlue1;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    line-height: 1;
    border: 0;
    border-radius: 5px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right $lightGray1;
    background-position-x: 5px;
    text-align-last: right;
    background-size: 10px;
  }

  select:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }
}

/** Custom Checkbox */
.construction-type-container {
  color: $navyBlue;


  .custom-checkbox .custom-checkbox-label {
    font-size: 12px;
    font-family: 'Inter';
  }

  .custom-checkbox-sub-label {
    font-size: 8px;
  }

  ul {
    position: relative;
    padding-left: 0;
    font-size: 12px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  li {
    padding-bottom: 10px;
  }
}

/** Document Action Button Style */
.document-action-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .action-name {
    font-size: 14px;
    color: $brightBlue1;
  }

  .cssCircle {
    margin-left: 5px;
  }
}

/** Custom Plus Sign inside a circle */
.cssCircle {
  margin-left: 5px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  border-radius: 999px;

  width: 20px;
  height: 20px;
  padding: 0px;

  background: $white1;
  border: 1px solid $navyBlue;
  color: $navyBlue;
  text-align: center;
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
  transition: color 0.2s linear;

  font: 20px Arial, sans-serif
}

.cssCircle:hover {
  background: $mediumBlue2;
  cursor: pointer;
}

.plusSign {
  line-height: 1em;
}

.plusSign:hover {
  color: $white1;
  border: 1px solid $white1;
}
</style>