<template>
    <div class="companies-container">
        <div class="company-header-container">
            <div>View performance of trades within your comp set.</div>
            <div>Search trades by Company Name and Scope of Work.</div>
            <div>You may also sort results by ascending or descending variance values.</div>
        </div>
        <div class="company-table-container">
            <img :src="require(`@/assets/CompanyPage.svg`)">
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.companies-container {
    padding: 20px 15px;
}

.company-header-container {
    font-family: 'Inter';
    color: $paleGray1;
    font-size: 12px;
}

.company-table-container {
    font-family: 'Inter';
    img {
        width: 100%;
        height: 100%;
    }
}
</style>
