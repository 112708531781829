import { defineStore } from "pinia";
//import { reactive, ref } from "vue";

export const useBudgetDataStore = defineStore("BudgetDataStore", {
    id: "budgetDataStore",
    state: () => ({
      aggregatedBudgetChartData: [], // stores the rolled up budget grid data for reusability purposes
      isBudgetDataLoaded: false
    }),
    getters: {
      getAggregatedBudgetChartData: (state) => {
        return state.aggregatedBudgetChartData
      },
      getIsBudgetDataLoaded: (state) => {
        return state.dataPresent
      },
    },
    actions: {
        setAggregatedBudgetChartData(aggregatedBudgetChartData) {
            this.aggregatedBudgetChartData = aggregatedBudgetChartData
        },
        setIsBudgetDataLoaded(isBudgetDataLoaded) {
            this.dataPresent = isBudgetDataLoaded
        },
      
    }
})