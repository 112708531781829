<template>
  <div v-if="isDataLoaded && !isNoDataFetch" class="compsearch-container">
    <div class="comp-search-details-modal" :class="{ 'open': isLeftOpen }">
      <SideBar @resize-sidebar="openLeftModal" />
    </div>

    <div class="main-content">
      <div class="breadcrumbs">CompSearch | <strong>Search Details</strong> |
        <span v-for="menuItem in filteredMenuItems()" :key="menuItem.id">
          {{ menuItem.name }}
        </span>
      </div>
      <div class="page-title">
        <div>Search Details</div>
        <div><button class="primary-action-button" @click="openReportPageInNewTab">Generate Report</button></div>
      </div>
      <div>
        <div class="sub-menu">
          <div v-for="item in subMenuItems" :key="item.id"
            :class="{ 'sub-menu-selected': item.id === subMenuSelectedItemId }" @click="subMenuSelectItem(item.id)">
            {{ item.name }}
          </div>
        </div>

        <div v-if="!withSelectedProject" class="no-selected-projects">No generated charts to show, please select a comp
          set.
        </div>
        <div v-else-if="withSelectedProject && isNoDataFetch" class="no-selected-projects">An error occurred. No data
          has been
          fetched.</div>

        <div v-else>
          <div v-if="subMenuSelectedItemId == 1">
            <BudgetDetails></BudgetDetails>
          </div>

          <div v-if="subMenuSelectedItemId == 2">
            <ScheduleDetails></ScheduleDetails>
          </div>

          <div v-if="subMenuSelectedItemId == 3">
            <CompanyDetails></CompanyDetails>
          </div>
        </div>
      </div>

    </div>
    <div>
      <SideModal />
    </div>
  </div>

</template>
<script setup>
/* eslint-disable */
import { axiosQuery, currencyFormat, currencyFormatRoundedDecimal, kMillionsFormat, kMillionsWithDecimalsFormat, percentFormat, standardizeLabelsLength } from "@/assets/js/utils";
import { useAuthStore } from "@/stores/auth_store";
import { useCompSetStore } from "@/stores/comp_set_store";
import { useBudgetDataStore } from "@/stores/budget_data_store";
import { useErrorStore } from "@/stores/error_store";
import { computed, onMounted, reactive, ref, watch } from "vue";
import SideBar from "@/components/comp-search-details/SideBar.vue"
import SideModal from "@/components/comp-search-details/SideModal.vue"
import { doughnutMiddleTextPlugin, standardDoughnutChartJson, standardStackedBarChartJson } from "@/assets/js/chart_config";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useRoute, useRouter } from 'vue-router';
import BudgetDetails from "@/components/comp-search-details/BudgetDetails.vue"
import ScheduleDetails from "@/components/comp-search-details/ScheduleDetails.vue"
import CompanyDetails from "@/components/comp-search-details/CompanyDetails.vue"

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors,
  ArcElement,
  doughnutMiddleTextPlugin(),
  ChartDataLabels
);
let isDataLoaded = computed(() => {
  return isHCSCCostPerGSFDataLoaded.value
})
const budgetDataStore = useBudgetDataStore()
let isNoDataFetch = computed(() => {
  return !budgetDataStore.getIsBudgetDataLoaded
})
const compSetStore = useCompSetStore()
let compSetCount = ref(Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]).length)
let withSelectedProject = ref(true)

const labelMaxLength = 15;
let doughnutViewMode = ref('total') // this toggles between total view and per GSF
let doughnutSummaryValue = computed(() => {
  return doughnutViewMode.value === 'total' ? kMillionsWithDecimalsFormat(totalRevisedBudget.value) : currencyFormatRoundedDecimal(avgRevisedBudgetPerGSF.value)
})
let doughnutLabel = computed(() => {
  return doughnutViewMode.value === 'total' ? 'Total Project Costs' : 'Avg. $/GSF'
})
/*
 * Doughnut Chart
*/
let totalRevisedBudget = computed(() => {
  return lcTotalRevisedBudget.value + hcTotalRevisedBudget.value +
    miscFinancingTotalRevisedBudget.value + contingenciesTotalRevisedBudget.value
})
let avgCostsPerGSF = ref(0)
let avgRevisedBudgetPerGSF = computed(() => {
  return avgCostsPerGSF.value
});
let doughnutChartData = ref([])
const doughnutData = computed(() => {
  return {
    labels: ["Acquisition", "Hard", "Soft", "Misc/Finance", "Contingencies"],
    datasets: [
      {
        backgroundColor: [
          "#52596D", // $darkGray1
          "#4A5FD5", // $brightBlue1
          "#4A8FD0", // $mediumBlue1
          "#8A92A9", // $mediumGray1
          "#d3d3d3" // assigning to light gray for now
        ],
        data: doughnutChartData.value
      },
    ],
  };
});
let doughnutChartJsonConfig = standardDoughnutChartJson({
  doughnutTitle: "Project Costs (actuals only)"
})
const doughnutOptions = computed(() => {
  let json = doughnutChartJsonConfig
  json.cutout = 70
  json.plugins.legend.display = false
  json.plugins.doughnutMiddleTextPlugin.middleTextLabel = doughnutLabel.value;
  json.plugins.doughnutMiddleTextPlugin.middleTextValue = doughnutSummaryValue.value;
  json.plugins.doughnutMiddleTextPlugin.fontSize = 14;
  json.plugins.tooltip.callbacks = {
    label: function (context) {
      let actual = 0;
      if (context.label?.includes("Hard")) {
        actual = currencyFormat(hcTotalRevisedBudget.value)
      } else if (context.label?.includes("Soft")) {
        actual = currencyFormat(scTotalRevisedBudget.value)
      } else if (context.label?.includes("Acquisition")) {
        actual = currencyFormat(lcTotalRevisedBudget.value)
      } else if (context.label?.includes("Misc")) {
        actual = currencyFormat(miscFinancingTotalRevisedBudget.value)
      } else if (context.label?.includes("Contingencies")) {
        actual = currencyFormat(contingenciesTotalRevisedBudget.value)
      }
      return actual;
    },
    footer: function (context) {
      let variancePerc = 0;
      let varianceVal = 0;
      if (context[0].label?.includes("Hard")) {
        varianceVal = kMillionsFormat(hcTotalVariance.value);
        variancePerc = percentFormat(hcVariancePercent.value);
      } else if (context[0].label?.includes("Soft")) {
        varianceVal = kMillionsFormat(scTotalVariance.value);
        variancePerc = percentFormat(scVariancePercent.value);
      } else if (context[0].label?.includes("Acquisition")) {
        varianceVal = kMillionsFormat(lcTotalVariance.value);
        variancePerc = percentFormat(lcVariancePercent.value);
      } else if (context[0].label?.includes("Misc")) {
        varianceVal = kMillionsFormat(miscFinancingTotalVariance.value);
        variancePerc = percentFormat(miscFinancingVariancePercent.value);
      } else if (context[0].label?.includes("Contingencies")) {
        varianceVal = kMillionsFormat(contingenciesTotalVariance.value);
        variancePerc = percentFormat(contingenciesVariancePercent.value);
      }
      return `Variance: ${varianceVal} (${variancePerc})`
    },
  }
  json.responsive = true;
  return json
});

/***
 Stacked Bar Charts
***/

// Hard Costs Summary
const hardCostsSummaryStackedBarData = computed(() => {
  // Do not show a negative variance on the chart as the chart looks really funky
  // adjusted sov - original sov (delta value)
  let changeInBudget = hcTotalVariance.value
  if (changeInBudget < 0) {
    changeInBudget = 0
  }
  return {
    labels: ["Total"],
    datasets: [
      {
        label: "Original Budget",
        backgroundColor: "#4A5FD5", // $brightBlue1
        data: [hcTotalOriginalBudget.value], // original sov
      },
      {
        label: "Budget Variance",
        backgroundColor: "#84CADE", // $lightBlue1, css var doesn't work here
        data: [changeInBudget],
      },
    ],
  };
});
// Keep unique for hard/soft as we edit it without cloning
// (default cloning will mess up callbacks)
let hardSummaryJsonConfig = standardStackedBarChartJson({
  barPercentage: 1,
  xTicksFontSize: 12,
  xTicksFont: 'Inter-Bold',
  yMaxTicksLimit: 10,
})
const hardCostsSummaryStackedBarOptions = computed(() => {
  let json = hardSummaryJsonConfig
  json.plugins.tooltip = {}
  json.plugins.tooltip.callbacks = {
    label: function (context) {
      const label = context.dataset.label;
      const value = context.parsed.y;
      let multilineTooltip = [label + ":"];
      multilineTooltip.push(currencyFormat(value));
      return multilineTooltip;
    },
    footer: function (context) {
      const index = context[0].dataIndex;
      return (
        "Variance: " +
        percentFormat(
          (hcTotalRevisedBudget.value - hcTotalOriginalBudget.value) /
          hcTotalOriginalBudget.value
        )
      )
    }
  }
  json.plugins.datalabels = {
    color: 'black',
    font: {
      size: 12
    },
    anchor: 'end',
    align: 'top',
    formatter: function (val, ctx) {
      // Get only values for Budget Variance
      if (ctx.datasetIndex === 1) {
        return `${Math.round(parseFloat((
          (hcTotalRevisedBudget.value - hcTotalOriginalBudget.value) /
          hcTotalOriginalBudget.value
        )).toFixed(2) * 100)}%`
      } else {
        return ''
      }
    }
  }
  return json
})
// Hard Costs Top Offenders
const hardCostsStackedBarData = computed(() => {
  return {
    labels: standardizeLabelsLength(hcStackedBarLabels.value),
    datasets: [
      {
        label: "Original Budget",
        backgroundColor: "#4A5FD5", // $brightBlue1
        data: hcOriginalBudgetArr.value, // original sov
      },
      {
        label: "Budget Variance",
        backgroundColor: "#84CADE", // $lightBlue1
        data: hcBudgetVarianceArr.value, // adjusted sov - original sov (delta value)
      },
    ],
  };
});
// Keep unique for hard/soft as we edit it without cloning
// (default cloning will mess up callbacks)
let hardStackedBarJsonConfig = standardStackedBarChartJson({
  barPercentage: 0.8,
  xTicksFontSize: 12,
  xTicksFont: 'Inter',
  labelForcedLength: labelMaxLength,
  yMinTicksLimit: 7,
  yMaxTicksLimit: 10,
  yStepSize: 1000000
})
const hardCostsStackedBarOptions = computed(() => {
  let json = hardStackedBarJsonConfig
  json.plugins.tooltip = {}
  json.plugins.tooltip.callbacks = {
    title: function (context) {
      // return the full length version of the labels
      return hcStackedBarLabels.value[context[0].dataIndex]
    },
    label: function (context) {
      const label = context.dataset.label;
      const value = context.parsed.y;
      return label + ": " + currencyFormat(value);
    },
    footer: function (context) {
      const index = context[0].dataIndex;
      let variance = (hcRevisedBudgetArr.value[index] - hcOriginalBudgetArr.value[index]) / hcOriginalBudgetArr.value[index]
      return (
        "Variance: " + percentFormat(variance)
      );
    },
  }
  json.plugins.datalabels = {
    color: 'black',
    font: {
      size: 12
    },
    anchor: 'end',
    align: 'top',
    formatter: function (val, ctx) {
      // Get only values for Budget Variance
      if (ctx.datasetIndex === 1) {
        const totalDatasetSum = ctx.chart.data.datasets[1].data.reduce((a, b) => (a + b), 0);
        if (totalDatasetSum != 0) {
          const percentage = val * 100 / totalDatasetSum
          const roundedPercentage = Math.round(percentage * 100) / 100
          return `${roundedPercentage.toFixed(0)}%`
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
  return json
})

// Soft Costs
// Soft Costs Summary
const softCostsSummaryStackedBarData = computed(() => {
  // Do not show a negative variance on the chart as the chart looks really funky
  // adjusted sov - original sov (delta value)
  let changeInBudget = scTotalVariance.value
  if (changeInBudget < 0) {
    changeInBudget = 0
  }
  return {
    labels: ["Total"],
    datasets: [
      {
        label: "Original Budget",
        backgroundColor: "#4A8FD0", // $mediumBlue1
        data: [scTotalOriginalBudget.value], // original sov
      },
      {
        label: "Budget Variance",
        backgroundColor: "#84CADE", // $lightBlue1
        data: [changeInBudget],
      },
    ],
  };
});
// Keep unique for hard/soft as we edit it without cloning
// (default cloning will mess up callbacks)
let softSummaryJsonConfig = standardStackedBarChartJson({
  barPercentage: 1,
  xTicksFontSize: 12,
  xTicksFont: 'Inter-Bold',
  yMaxTicksLimit: 10,
})
const softCostsSummaryStackedBarOptions = computed(() => {
  let json = softSummaryJsonConfig
  json.plugins.tooltip = {}
  json.plugins.tooltip.callbacks = {
    label: function (context) {
      const label = context.dataset.label;
      const value = context.parsed.y;
      let multilineTooltip = [label + ":"];
      multilineTooltip.push(currencyFormat(value));
      return multilineTooltip;
    },
    footer: function (context) {
      const index = context[0].dataIndex;
      return (
        "Variance: " +
        percentFormat(scVariancePercent.value
        )
      );
    },
  }
  json.plugins.datalabels = {
    color: 'black',
    font: {
      size: 12
    },
    anchor: 'end',
    align: 'top',
    formatter: function (val, ctx) {
      // Get only values for Budget Variance
      if (ctx.datasetIndex === 1) {
        return `${Math.round(parseFloat(scVariancePercent.value).toFixed(2) * 100)}%`
      } else {
        return ''
      }
    }
  }
  return json
})
// Soft Costs Top Offenders
const softCostsStackedBarData = computed(() => {
  return {
    labels: standardizeLabelsLength(scStackedBarLabels.value),
    datasets: [
      {
        label: "Original Budget",
        backgroundColor: "#4A8FD0", // soft costs color based on figma
        data: scOriginalBudgetArr.value, // original sov
      },
      {
        label: "Budget Variance",
        backgroundColor: "#84C9DE", // NOTE : color variable doesn't work here
        data: scBudgetVarianceArr.value, // adjusted sov - original sov (delta value)
      },
    ],
  };
});
// Keep unique for hard/soft as we edit it without cloning
// (default cloning will mess up callbacks)
let softStackedBarJsonConfig = standardStackedBarChartJson({
  barPercentage: 0.8,
  xTicksFontSize: 12,
  xTicksFont: 'Inter',
  labelForcedLength: labelMaxLength,
  yMinTicksLimit: 7,
  yMaxTicksLimit: 10,
  yStepSize: 1000000
})
const softCostsStackedBarOptions = computed(() => {
  let json = softStackedBarJsonConfig
  json.plugins.tooltip = {}
  json.plugins.tooltip.callbacks = {
    title: function (context) {
      // return the full length version of the labels
      return scStackedBarLabels.value[context[0].dataIndex]
    },
    label: function (context) {
      const label = context.dataset.label;
      const value = context.parsed.y;
      return label + ": " + currencyFormat(value);
    },
    footer: function (context) {
      const index = context[0].dataIndex;
      let variance = (scRevisedBudgetArr.value[index] - scOriginalBudgetArr.value[index]) / scOriginalBudgetArr.value[index]
      return (
        "Variance: " + percentFormat(variance)
      );
    },
  }
  json.plugins.datalabels = {
    color: 'black',
    font: {
      size: 12
    },
    anchor: 'end',
    align: 'top',
    formatter: function (val, ctx) {
      // Get only values for Budget Variance
      if (ctx.datasetIndex === 1) {
        const totalDatasetSum = ctx.chart.data.datasets[1].data.reduce((a, b) => (a + b), 0);
        if (totalDatasetSum != 0) {
          const percentage = val * 100 / totalDatasetSum
          const roundedPercentage = Math.round(percentage * 100) / 100
          return `${roundedPercentage.toFixed(0)}%`
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
  return json
});

const selectedProjects = ref(compSetStore.selectedProjects);
const escalationQuery = ref(compSetStore.getEscalation ? `&escalation=${compSetStore.getEscalation}` : '')

compSetStore.$subscribe(syncProjects)
const currentProjects = ref(compSetStore.getIncludedProjects)
let avgTotalGSF = ref(compSetStore.getAvgTotalGSF)
function syncProjects() {
  escalationQuery.value = compSetStore.getEscalation ? `&escalation=${compSetStore.getEscalation}` : ''
  const storedProjects = compSetStore.getIncludedProjects
  compSetCount.value = Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]).length
  if (compSetCount.value === 0) {
    withSelectedProject.value = false
  } else {
    withSelectedProject.value = true
  }
  currentProjects.value = storedProjects

  avgTotalGSF = ref(compSetStore.getAvgTotalGSF) //always set on any action to ensure data is there for the donut
  //resetChartData()
  updateData()
}

const errorStore = useErrorStore();
let hcTotalOriginalBudget = ref(0);
let hcTotalRevisedBudget = ref(0);
let scTotalOriginalBudget = ref(0);
let scTotalRevisedBudget = ref(0);
let miscFinancingTotalOriginalBudget = ref(0);
let miscFinancingTotalRevisedBudget = ref(0);
let lcTotalOriginalBudget = ref(0);
let lcTotalRevisedBudget = ref(0);
let contingenciesTotalOriginalBudget = ref(0);
let contingenciesTotalRevisedBudget = ref(0);

let lcTotalVariance = ref(0);
let hcTotalVariance = ref(0);
let scTotalVariance = ref(0);
let miscFinancingTotalVariance = ref(0);
let contingenciesTotalVariance = ref(0);
let lcVariancePercent = ref(0);
let hcVariancePercent = ref(0);
let scVariancePercent = ref(0);
let miscFinancingVariancePercent = ref(0);
let contingenciesVariancePercent = ref(0);

let hcStackedBarLabels = ref([]);
let hcOriginalBudgetArr = ref([]);
let hcRevisedBudgetArr = ref([]);
let hcBudgetVarianceArr = ref([]);
let hcBudgetIncPercArr = ref([]);

let scStackedBarLabels = ref([]);
let scOriginalBudgetArr = ref([]);
let scRevisedBudgetArr = ref([]);
let scBudgetVarianceArr = ref([]);
let scBudgetIncPercArr = ref([]);
let doughnutChartPercentage = ref([]);

budgetDataStore.$subscribe(setChartData);
function setChartData() {
  let chartData = budgetDataStore.getAggregatedBudgetChartData;
  // These group ids are constants
  // These should only change if we renamed the parent terms
  const ACQUISITION_GROUP_ID = "row-group-0-ACQUISITION COSTS"
  const HC_GROUP_ID = "row-group-0-HARD COSTS"
  const SC_GROUP_ID = "row-group-0-SOFT COSTS"
  const FINANCING_GROUP_ID = "row-group-0-FINANCING COSTS"
  const CONTINGENCIES_GROUP_ID = "row-group-0-CONTINGENCIES-1-Owner Contingencies"

  // build doughnut data
  // check first if chart data is non null
  if (chartData) {
    let acquisitionGroupData = chartData.find(row => row.id === ACQUISITION_GROUP_ID)
    let hcGroupData = chartData.find(row => row.id === HC_GROUP_ID)
    let scGroupData = chartData.find(row => row.id === SC_GROUP_ID)
    let financingGroupData = chartData.find(row => row.id === FINANCING_GROUP_ID)
    let contingenciesGroupData = chartData.find(row => row.id === CONTINGENCIES_GROUP_ID)

    // TODO : Will the aggregated data ever be NaN / Null
    // TODO : As part of refactoring of charts, this function can be moved as its own module
    lcTotalRevisedBudget.value = acquisitionGroupData ? acquisitionGroupData['aggData']['current_budget'] : 0
    hcTotalRevisedBudget.value = hcGroupData ? hcGroupData['aggData']['current_budget'] : 0
    scTotalRevisedBudget.value = scGroupData ? scGroupData['aggData']['current_budget'] : 0
    miscFinancingTotalRevisedBudget.value = financingGroupData ? financingGroupData['aggData']['current_budget'] : 0
    contingenciesTotalRevisedBudget.value = contingenciesGroupData ? contingenciesGroupData['aggData']['current_budget'] : 0

    lcTotalOriginalBudget.value = acquisitionGroupData ? acquisitionGroupData['aggData']['original_budget'] : 0
    hcTotalOriginalBudget.value = hcGroupData ? hcGroupData['aggData']['original_budget'] : 0
    scTotalOriginalBudget.value = scGroupData ? scGroupData['aggData']['original_budget'] : 0
    miscFinancingTotalOriginalBudget.value = financingGroupData ? financingGroupData['aggData']['original_budget'] : 0
    contingenciesTotalOriginalBudget.value = contingenciesGroupData ? contingenciesGroupData['aggData']['original_budget'] : 0

    lcTotalVariance.value = acquisitionGroupData ? acquisitionGroupData['aggData']['total_adjustment'] : 0
    hcTotalVariance.value = hcGroupData ? hcGroupData['aggData']['total_adjustments'] : 0
    scTotalVariance.value = scGroupData ? scGroupData['aggData']['total_adjustments'] : 0
    miscFinancingTotalVariance.value = financingGroupData ? financingGroupData['aggData']['total_adjustments'] : 0
    contingenciesTotalVariance.value = contingenciesGroupData ? contingenciesGroupData['aggData']['total_adjustments'] : 0

    lcVariancePercent.value = acquisitionGroupData ? acquisitionGroupData['aggData']['variance'] : 0
    hcVariancePercent.value = hcGroupData ? hcGroupData['aggData']['variance'] : 0
    scVariancePercent.value = scGroupData ? scGroupData['aggData']['variance'] : 0
    miscFinancingVariancePercent.value = financingGroupData ? financingGroupData['aggData']['variance'] : 0
    contingenciesVariancePercent.value = contingenciesGroupData ? contingenciesGroupData['aggData']['variance'] : 0

    doughnutChartData.value = [
      lcTotalRevisedBudget.value,
      hcTotalRevisedBudget.value,
      scTotalRevisedBudget.value,
      miscFinancingTotalRevisedBudget.value,
      contingenciesTotalRevisedBudget.value
    ]

    const doughnutChartDataArray = [
      lcTotalRevisedBudget.value,
      hcTotalRevisedBudget.value,
      scTotalRevisedBudget.value,
      miscFinancingTotalRevisedBudget.value,
      contingenciesTotalRevisedBudget.value
    ]

    // Loop through the dougnutChartData
    const totalSumOfDoughnut = doughnutChartDataArray.reduce((a, b) => { return a + b; }).toFixed(0)
    doughnutChartPercentage.value = []
    doughnutChartDataArray.forEach(val => {
      const percentage = val * 100 / totalSumOfDoughnut;
      const roundedPercentage = Math.round(percentage * 100) / 100
      doughnutChartPercentage.value.push(`${roundedPercentage.toFixed(0)}%`);
    });


    // Create a copy of the leaf childre
    const hcGroupDataLeafChildren = hcGroupData.allLeafChildren;
    const scGroupDataLeafChildren = scGroupData.allLeafChildren;

    // Sort the values base on total-adjustment to get the top offenders in decreasing order
    const sortedHCGroupData = hcGroupDataLeafChildren.sort((a, b) => b.data.total_adjustments - a.data.total_adjustments);
    const sortedSCGroupData = scGroupDataLeafChildren.sort((a, b) => b.data.total_adjustments - a.data.total_adjustments);

    // Get the top 5 but remove the ones if they include a key that should not be there
    const top5DataHC = [];
    const top5DataSC = [];

    // Loop through the sorted array for hard cost
    for (const obj of sortedHCGroupData) {
      // Check if the key property contains the string "Trade"
      if (!obj.key.toLowerCase().includes("trade")) {
        // If not, push the object to the top5DataHC array
        top5DataHC.push(obj);
      }

      // If top5DataHC array has 5 elements, break the loop
      if (top5DataHC.length === 5) {
        break;
      }
    }

    // Loop through the sorted array for soft cost
    for (const obj of sortedSCGroupData) {
      // Check if the key property contains the string "Carrying" and "Land Lease"
      if (!obj.key.toLowerCase().includes("carrying") && !obj.key.toLowerCase().includes("land lease")) {
        // If not, push the object to the top5DataSC array
        top5DataSC.push(obj);
      }

      // If top5DataSC array has 5 elements, break the loop
      if (top5DataSC.length === 5) {
        break;
      }
    }

    // HCTopOffedners
    // build the chart data
    hcStackedBarLabels.value = [];
    hcOriginalBudgetArr.value = [];
    hcRevisedBudgetArr.value = [];
    hcBudgetVarianceArr.value = [];
    hcBudgetIncPercArr.value = [];
    top5DataHC.forEach((row) => {
      hcStackedBarLabels.value.push(row.key);
      hcOriginalBudgetArr.value.push(row.data.original_budget);
      hcRevisedBudgetArr.value.push(row.data.current_budget);
      hcBudgetVarianceArr.value.push(
        row.data.current_budget - row.data.original_budget
      );
      hcBudgetIncPercArr.value.push(row.data.total_adjustments);
    });

    // SCTopOffenders
    // build the chart data
    scStackedBarLabels.value = [];
    scOriginalBudgetArr.value = [];
    scRevisedBudgetArr.value = [];
    scBudgetVarianceArr.value = [];
    scBudgetIncPercArr.value = [];
    top5DataSC.forEach((row) => {
      scStackedBarLabels.value.push(row.key);
      scOriginalBudgetArr.value.push(row.data.original_budget);
      scRevisedBudgetArr.value.push(row.data.current_budget);
      scBudgetVarianceArr.value.push(
        row.data.current_budget - row.data.original_budget
      );
      scBudgetIncPercArr.value.push(row.data.total_adjustments);
    });
  }
}

let hcSummaryAvgOriginal = computed(() => {
  return hcAvgOriginalCostPerGSF.value
})
let hcSummaryAvgRevised = computed(() => {
  return hcAvgRevisedCostPerGSF.value
})
let scSummaryAvgOriginal = computed(() => {
  return scAvgOriginalCostPerGSF.value
})
let scSummaryAvgRevised = computed(() => {
  return scAvgRevisedCostPerGSF.value
})
let hcAvgOriginalCostPerGSF = ref(0)
let hcAvgRevisedCostPerGSF = ref(0)
let scAvgOriginalCostPerGSF = ref(0)
let scAvgRevisedCostPerGSF = ref(0)
let isHCSCCostPerGSFDataLoaded = ref(false)
function calculateHCSCAvgSummaryCostsPerGSF() {
  isHCSCCostPerGSFDataLoaded.value = false
  const selectedIds = Object.keys(selectedProjects.value).filter(id => selectedProjects.value[id]).join(',')
  let HCTotalOriginalCostPerGSF = 0
  let HCTotalRevisedCostPerGSF = 0
  let SCTotalOriginalCostPerGSF = 0
  let SCTotalRevisedCostPerGSF = 0

  if (selectedIds) {
    axiosQuery(
      `${apiBase}mvw_most_recent_by_projects/fetch_hc_sc_costs_per_project?project_ids=${selectedIds}${escalationQuery.value}`,
      authToken,
      (data) => {
        const validIds = selectedIds.split(',')
        let hardCostsData = data.hard
        let softCostsData = data.soft
        validIds.forEach((id) => {
          let projectGSF = parseFloat(compSetStore.getPerProjectGSF[id] || 0)
          HCTotalOriginalCostPerGSF += parseFloat(hardCostsData[id]['total_original'] || 0) / (projectGSF || 1)
          HCTotalRevisedCostPerGSF += parseFloat(hardCostsData[id]['total_revised'] || 0) / (projectGSF || 1)
          SCTotalOriginalCostPerGSF += parseFloat(softCostsData[id]['total_original'] || 0) / (projectGSF || 1)
          SCTotalRevisedCostPerGSF += parseFloat(softCostsData[id]['total_revised'] || 0) / (projectGSF || 1)
        })
        hcAvgOriginalCostPerGSF.value = HCTotalOriginalCostPerGSF / validIds.length
        hcAvgRevisedCostPerGSF.value = HCTotalRevisedCostPerGSF / validIds.length
        scAvgOriginalCostPerGSF.value = SCTotalOriginalCostPerGSF / validIds.length
        scAvgRevisedCostPerGSF.value = SCTotalRevisedCostPerGSF / validIds.length

      },
      errorStore
    );
  }
  isHCSCCostPerGSFDataLoaded.value = true

}

const apiBase =
  process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/";
const authStore = useAuthStore();
let authToken = reactive({});
onMounted(() => {
  authToken.value = authStore.getAuthTokenHeader
  // Enable auth to load fully before updating data (not sure if this completely makes sense)
  setTimeout(() => {
    updateData()
  }, 0)
})
function updateData() {
  calculateHCSCAvgSummaryCostsPerGSF();
}

// Sidebar Functions
let isLeftOpen = ref(true);
function openLeftModal() {
  isLeftOpen.value = !isLeftOpen.value;
}

// Tab Selection and Router changes settings
const subMenuItems = [
  { id: 1, name: 'Budget' },
  { id: 2, name: 'Schedule' },
  { id: 3, name: 'Companies' }
];

const route = useRoute();
const router = useRouter();

const subMenuSelectedItemId = ref(1);
function subMenuSelectItem(id) {
  subMenuSelectedItemId.value = id;
  // Find the selected item based on the id
  const selectedItem = subMenuItems.find(item => item.id === id);

  if (selectedItem) {
    // Construct the new path
    const newPath = `/compsearch/${selectedItem.name.toLowerCase()}`;

    // Navigate to the new path without creating a new history entry
    router.replace(newPath);
  }
}

function filteredMenuItems() {
  let filteredValue = subMenuItems.filter(item => item.id === subMenuSelectedItemId.value);
  return filteredValue
}

// Report Page
function openReportPageInNewTab() {
  const routeData = router.resolve({ path: '/reports' });
  window.open(routeData.href, '_blank');
}

watch(route, (currentRoute) => {
  // Assuming the route parameter name is 'selectedTab'
  const selectedTabValue = currentRoute.params.selectedTab;

  // Find the item in `subMenuItems` that matches the `selectedTabValue`
  const selectedItem = subMenuItems.find(item => item.name.toLocaleLowerCase() === selectedTabValue);

  // If found, update `subMenuSelectedItemId` with the found item's id
  if (selectedItem) {
    subMenuSelectedItemId.value = selectedItem.id;
  }
}, { immediate: true }); // `immediate: true` ensures this runs on initial load as well
</script>
<style scoped lang="scss">
@import "./src/assets/main.scss";

.compsearch-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 70px;
  font-family: 'Inter';
  position: relative;
}

.main-content {
  flex: 1;
  width: 310px;
  height: 100vh;
  margin-left: 10px;
  transition: width 0.3s ease;
}

.avg-summary-container {
  display: flex;
  flex-direction: row;
  text-align: right;
}

.avg-subtitle {
  margin: 0 5px 0 20px;
}

.summary-value {
  font-weight: bold;
}

.avg-value-original {
  color: #4A5FD5;
}

.avg-value-revised {
  color: #84CADE;

}

.top-offenders-subtitle {
  visibility: hidden;
}

.no-selected-projects {
  padding: 10px;
}

/** Sidebar Styles - Start */
.comp-search-details-modal {
  overflow: hidden;
  width: 25px;
}

.comp-search-details-modal.open {
  width: 310px;
}

/** Sidebar Styles - End */

.breadcrumbs {
  font-size: 14px;
  color: #8C8D92;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  color: $navyBlue;
  padding: 20px 20px 0 0 ;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .primary-action-button {
    background-color: $mediumBlue2;
    color: white;
    border: 0;
    border-radius: 4px;
    margin: 8px;
    cursor: pointer;
    padding: 10px;
    font-size: 12px;
  }
}

// Sub Menu Style - Start
.sub-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $mediumGray1;
  font-size: 16px;
  font-weight: lighter;

  div {
    cursor: pointer;
    padding: 10px 0px;
    margin-right: 50px;
  }
}

.sub-menu-selected {
  font-weight: bold;
  border-bottom: 3px solid $brightBlue1;
  color: $brightBlue1;
}

// Sub Menu Style - End

.summary-stacked-bar {
  padding: 0 10px;
}
</style>
