<template>
  <!-- Original / Baseline Documents -->
  <div class="upload-document-title "> Original / Baseline Documents </div>
  <div class="tile">
    <UploadBaselineDocument ref="baselineDocuments"></UploadBaselineDocument>
  </div>
  <div class="document-action-button">
    <div class="action-name">Add a new Baseline</div>
    <div class="cssCircle plusSign">
      &#43;
    </div>
  </div>

  <!-- Monthly Draw Documents -->
  <div class="upload-document-title "> Monthly Draw Documents </div>

  <div class="tile">
    <UploadDrawDocument ref="drawDocuments"></UploadDrawDocument>
  </div>
  <div class="document-action-button">
    <div class="action-name">Add a new Draw</div>
    <div class="cssCircle plusSign">
      &#43;
    </div>
  </div>
</template>

<script setup>
import { defineExpose, ref } from 'vue';
import UploadDrawDocument from '@/shared/UploadDrawDocument.vue';
import UploadBaselineDocument from '@/shared/UploadBaselineDocument.vue';

const baselineDocuments = ref(null);
const drawDocuments = ref(null);

defineExpose({
  baselineDocuments,
  drawDocuments
})
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.tile {
  background-color: $white1;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
}

.upload-document-title {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
  color: $navyBlue;
}

/** Document Action Button Style */
.document-action-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  ;

  .action-name {
    font-size: 14px;
    color: $brightBlue1;
  }

  .cssCircle {
    margin-left: 5px;
  }
}

/** Custom Plus Sign inside a circle */
.cssCircle {
  margin-left: 5px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  border-radius: 999px;

  width: 20px;
  height: 20px;
  padding: 0px;

  background: $white1;
  border: 1px solid $navyBlue;
  color: $navyBlue;
  text-align: center;
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  transition: background 0.2s linear;
  transition: color 0.2s linear;

  font: 20px Arial, sans-serif
}

.cssCircle:hover {
  background: $mediumBlue2;
  cursor: pointer;
}

.plusSign {
  line-height: 1em;
}

.plusSign:hover {
  color: $white1;
  border: 1px solid $white1;
}
</style>