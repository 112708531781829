<template>
  <div class="footer dark-blue-shade">
    <div class="footer-text">
      <div class="text">
        ©DDA {{year}}. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed} from "vue"

const year = computed(() => {
  return new Date().getFullYear()
})

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";
.footer {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  justify-content: center;
}
.footer .text {
  padding-left: 10px;
  color: black;
  font-size: 1em;
}
.footer-text {
  display: flex;
  justify-content: center;
}
</style>
