// src/stores/AuthStore.js (Pinia)
import { defineStore } from "pinia"
import axios from "axios"

// For Heroku: https://medium.com/@fishpercolator/deploying-your-nuxt-rails-api-app-to-production-with-heroku-54efd09eec22
//
// From https://medium.com/@charliepython/pinia-firebase-v9-and-axios-with-vue-3-composition-6261626da931
// const axiosPrivate = axios.create({
//   baseURL: import.meta.env.VITE_ROTULU_API_import.meta.env.VITE_API_BASE,
//   withCredentials: true,
// })
export const PREFIX = 'iP4BLqnibqamRTI_'

export const useAuthStore = defineStore("AuthStore", {
  id: 'auth',
  state: () => ({
    auth_token: null,
    refresh_token: null,
    user: {
      id: null,
      first_name: null,
      middle_initial: null,
      last_name: null,
      role: null,
      version: null,
      simulated_role: null,
      username: null,
      email: null,
      google_secret: null
    },
    is_refreshing_token: false,
    allow_mfa_setup: false,
    login_status: null,
    login_message: null
  }),
  getters: {
    getAuthToken: (state) => {
      return state.auth_token || localStorage.getItem(`${PREFIX}auth_token`)
    },
    getAuthTokenHeader: (state) => {
      let token = state.auth_token || localStorage.getItem(`${PREFIX}auth_token`)
      if (token && token.indexOf('Bearer') !== 0) {
        token = `Bearer ${token}`
      }
      return {
        headers: {
          authorization: token
        },
      }
    },
    getRefreshToken: (state) => {
      return state.refresh_token || localStorage.getItem(`${PREFIX}refresh_token`)
    },
    getRefreshTokenParam: (state) => {
      return { refresh_token: state.refresh_token || localStorage.getItem(`${PREFIX}refresh_token`) }
    },
    getUserInitials: (state) => {
      let first, middle, last
      if (state.user.first_name) {
        first = state.user?.first_name?.substring(0, 1) || ''
        middle = state.user?.middle_initial?.substring(0, 1) || ''
        last = state.user?.last_name?.substring(0, 1) || ''
      } else if (localStorage.getItem(`${PREFIX}auth_token_user`)) {
        // auth_token_user: user id, first name, middle initial, last name, role, simulated role
        const userInfo = localStorage.getItem(`${PREFIX}auth_token_user`).split('|')
        first = userInfo[1]?.substring(0, 1) || ''
        middle = userInfo[2]?.substring(0, 1) || ''
        last = userInfo[3]?.substring(0, 1) || ''
      }
      return first + middle + last
    },
    getUserID(state) {
      if (state.user?.id) {
        return state.user?.id
      } else if (localStorage.getItem(`${PREFIX}auth_token_user`)) {
        // auth_token_user: user id, first name, middle initial, last name, role, simulated role
        const userInfo = localStorage.getItem(`${PREFIX}auth_token_user`).split('|')
        return userInfo[0]
      }

      return null
    },
    getUserEmail(state) {
      return state.user?.email
    },
    isLoggedIn(state) {
      const auth_token = state.getAuthToken
      const loggedOut = !auth_token || auth_token === 'null' || auth_token === 'undefined'
      return !loggedOut
    },
    allowMFASetup(state) {
      return state.allow_mfa_setup
    },
    getGoogleSecret(state) {
      return state.user?.google_secret
    },
    isVersionCurrent(state) {
      console.log(`${process.env.VUE_APP_ENV_VERSION} vs. ${state.user?.version}`)
      // Needed for development purposes
      if (!process.env.VUE_APP_ENV_VERSION || !state.user?.version) { return true }

      return process.env.VUE_APP_ENV_VERSION === state.user?.version
    },
    getSimulatedRole(state) {
      // This function can only run with proper permissions to simulate a role
      if (!state.isFinanceAdminOrAdminSkipSimulation) {
        return null
      }

      // Check by role
      if (state.user?.simulated_role) {
        return state.user.simulated_role
      } else if (localStorage.getItem(`${PREFIX}auth_token_user`)) {
        // auth_token_user: user id, first name, middle initial, last name, role, simulated role
        const userInfo = localStorage.getItem(`${PREFIX}auth_token_user`).split('|')
        if (userInfo[5]) { return userInfo[5] }
      }

      return null
    },
    isPermitted(state) {
      // This is the only way to allow a parameter to be passed in.
      // See https://pinia.vuejs.org/core-concepts/getters.html
      return (allowedRoles, is_skip_simulation = false) => {
        // Ensure we are logged in
        if (!state.isLoggedIn) { return false }

        // Return false if simulated role exists and does not match
        // (simulated_role is never enough to return "true". The real role also needs to allow this.)
        if (!is_skip_simulation && state.user?.simulated_role) {
          if (!allowedRoles.includes(state.user.simulated_role)) { return false }
        } else if (!is_skip_simulation && localStorage.getItem(`${PREFIX}auth_token_user`)) {
          // auth_token_user: user id, first name, middle initial, last name, role, simulated role
          const userInfo = localStorage.getItem(`${PREFIX}auth_token_user`).split('|')
          if (userInfo[5] && !allowedRoles.includes(userInfo[5])) { return false }
        }

        // Check by role
        if (state.user?.role) {
          if (allowedRoles.includes(state.user.role)) { return true }
        } else if (localStorage.getItem(`${PREFIX}auth_token_user`)) {
          // auth_token_user: user id, first name, middle initial, last name, role, simulated role
          const userInfo = localStorage.getItem(`${PREFIX}auth_token_user`).split('|')
          if (allowedRoles.includes(userInfo[4])) { return true }
        }

        return false
      }
    },
    isAdmin(state) {
      const allowedRoles = ['DeveloperAdmin-311', 'SuperAdmin-1450', 'Admin']
      return state.isPermitted(allowedRoles)
    },
    isDeveloperAdmin(state) {
      const allowedRoles = ['DeveloperAdmin-311']
      return state.isPermitted(allowedRoles)
    },
    // Need this for simulating users on and off
    isFinanceAdminOrAdminSkipSimulation(state) {
      const allowedRoles = ['DeveloperAdmin-311', 'SuperAdmin-1450', 'Admin', 'FinanceAdmin']
      return state.isPermitted(allowedRoles, true)
    },
    // Need this for simulating users on and off
    isSuperAdminOrFinanceAdminSkipSimulation(state) {
      const allowedRoles = ['DeveloperAdmin-311', 'SuperAdmin-1450', 'FinanceAdmin']
      return state.isPermitted(allowedRoles, true)
    },
    // Need this for simulating users on and off
    isSuperAdminSkipSimulation(state) {
      const allowedRoles = ['DeveloperAdmin-311', 'SuperAdmin-1450']
      return state.isPermitted(allowedRoles, true)
    },
    // Need this for simulating users on and off
    isSuperAdminOrFinanceAdmin(state) {
      const allowedRoles = ['DeveloperAdmin-311', 'SuperAdmin-1450', 'FinanceAdmin']
      return state.isPermitted(allowedRoles)
    },
    // This approach is hackable by another user role, but that is not the concern. It 
    isFinanceOrSuperAdmin(state) {
      const allowedRoles = ['DeveloperAdmin-311', 'SuperAdmin-1450', 'Finance', 'FinanceAdmin']
      return state.isPermitted(allowedRoles)
    },
    // This approach is hackable by another user role, but that is not the concern. It
    isFinanceAdminOrAdmin(state) {
      const allowedRoles = ['DeveloperAdmin-311', 'SuperAdmin-1450', 'Admin', 'FinanceAdmin']
      return state.isPermitted(allowedRoles)
    },
    // This is whether we should show easy links to payments info. It is still accessible from the URL for others.
    isFinanceOrAdmin(state) {
      const allowedRoles = ['SuperAdmin-1450', 'DeveloperAdmin-311', 'Admin', 'Finance', 'FinanceAdmin']
      return state.isPermitted(allowedRoles)
    },
    isFinanceAdminOrSales(state) {
      // Shipping is the lowest permissions, so fetch via opposite logic, but do ensure we are logged in!
      const allowedRoles = ['Sales', 'SuperAdmin-1450', 'DeveloperAdmin-311', 'Admin', 'Finance', 'FinanceAdmin']
      return state.isLoggedIn && state.isPermitted(allowedRoles)
    },
    isShipping(state) {
      // Shipping is the lowest permissions, so fetch via opposite logic, but do ensure we are logged in!
      const allowedRoles = ['Sales', 'SuperAdmin-1450', 'DeveloperAdmin-311', 'Admin', 'Finance', 'FinanceAdmin']
      return state.isLoggedIn && !state.isPermitted(allowedRoles)
    },
    // This is whether we should show easy links to payments info. It is still accessible from the URL for others.
    canEmailOrderConfirmation(state) {
      const allowedRoles = ['SuperAdmin-1450', 'DeveloperAdmin-311', 'Admin', 'Sales', 'Finance', 'FinanceAdmin']
      return state.isPermitted(allowedRoles)
    },
    // This is not super secure in that someone could hack to see this information if they are already logged in.
    canModifyPayments(state) {
      // Check by role
      const allowedRoles = ['DeveloperAdmin-311', 'SuperAdmin-1450', 'FinanceAdmin']
      return state.isPermitted(allowedRoles)
    }
  },
  actions: {
    async loginUser(payload) {
      try {
        const rootBase = process.env.VUE_APP_ENV_ROOT_BASE
        this.is_refreshing_token = true
        let response = await axios.post(`${rootBase}users/sign_in`, payload)
            .catch((error) => {
              this.is_refreshing_token = false
              this.reject(error)
              return Promise.reject(error)
            })
        this.is_refreshing_token = false
        // Check if MFA has been setup for this user
        this.allow_mfa_setup = response.data.message === 'Google authenticator ready to be setup'
        if (response.data.status === 401) {
          this.login_status = 401
          this.login_message = response.data.message
        }
        this.setUserInfo(response) // TODO need await here?
      } catch (error) {
        this.is_refreshing_token = false
        this.reject(error)
      }
    },
    async logoutUser() {
      const config = {
        params: {
          refresh_token: this.refresh_token || localStorage.getItem(`${PREFIX}refresh_token`)
        }
      }
      // Do not want to authorize
      delete axios.defaults.headers.common["Authorization"]
      this.resetUserInfo()
      try {
        // logging out errors out a lot, so ensure we have deleted tokens
        const rootBase = process.env.VUE_APP_ENV_ROOT_BASE
        await axios.delete(`${rootBase}users/sign_out`, config)
            .catch((error) => {
              this.resetUserInfo()
              return Promise.reject(error)
            })
      } catch (error) {
        this.reject(error)
      }
    },
    async loginUserWithToken(payload) {
      // console.log('loginUserWithToken...' + payload)
      const config = {
        refresh_token: payload,
      }
      try {
        const apiBase = process.env.VUE_APP_ENV_ROOT_BASE
        // try to avoid refreshing token multiple times in a row while awaiting the response to return
        if (!this.is_refreshing_token) {
          this.is_refreshing_token = true
          let response = await axios.post(`${apiBase}tokens/refresh`, config)
              .catch((error) => {
                this.is_refreshing_token = false
                this.reject(error)
                return Promise.reject(error)
              })
          this.setUserInfoFromToken(response) // TODO need await here?
        }
      } catch (error) {
        this.is_refreshing_token = false
        // Clear local storage if it is not working for login
        this.reject(error)
      }
    },
    // NOTE that this only simulates the lower role on the frontend.
    // No backend code is changed for security purposes / ease of use.
    setSimulatedRole(simulated_role) {
      const unallowedSimulations = ['DeveloperAdmin-311', 'SuperAdmin-1450']
      if (!unallowedSimulations.includes(simulated_role) && this.isFinanceAdminOrAdminSkipSimulation) {
        if (this.user) {
          this.user.simulated_role = simulated_role
        }

        let userInfo = localStorage.getItem(`${PREFIX}auth_token_user`)
        if (userInfo) {
          // auth_token_user: user id, first name, middle initial, last name, role, simulated role
          [this.user.id, this.user.first_name, this.user.middle_initial, this.user.last_name, this.user.role, this.user.simulated_role, this.user.version] = userInfo.split('|')
        }
        this.user.simulated_role = simulated_role
        // auth_token_user: user id, first name, middle initial, last name, role, simulated role
        localStorage.setItem(`${PREFIX}auth_token_user`, (this.user.id || '') + '|' + (this.user.first_name || '') + '|' + (this.user.middle_initial || '') + '|' + (this.user.last_name || '') + '|' + (this.user.role || '') + '|' + (this.user.simulated_role || '') + '|' + (this.user.version || ''))
      }
    },
    setUserInfo(response) {
      this.user = response.data.user || {}
      this.user.id = response.data.user?.id || response.data.id
      this.user.first_name = response.data.user?.first_name || response.data.first_name
      this.user.middle_initial = response.data.user?.middle_initial || response.data.middle_initial
      this.user.last_name = response.data.user?.last_name || response.data.last_name
      this.user.role = response.data.user?.role || response.data.role
      this.user.version = response.data.user?.version || response.data.version
      this.user.simulated_role = null
      this.user.google_secret = response.data.google_secret
      this.user.email = response.data.email
      this.auth_token = response.data.access_token
      this.refresh_token = response.data.refresh_token
      if (response.data.access_token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + response.data.access_token.replace(/^Bearer/, '')
      }
      localStorage.setItem(`${PREFIX}auth_token`, response.data.access_token)
      localStorage.setItem(`${PREFIX}refresh_token`, response.data.refresh_token)
      // auth_token_user: user id, first name, middle initial, last name, role, simulated role
      localStorage.setItem(`${PREFIX}auth_token_user`, (this.user.id || '') + '|' + (this.user.first_name || '') + '|' + (this.user.middle_initial || '') + '|' + (this.user.last_name || '') + '|' + (this.user.role || '') + '|' + (this.user.simulated_role || '') + '|' + (this.user.version || ''))
    },
    setUserInfoFromToken(response) {
      this.auth_token = response.data.access_token
      this.refresh_token = response.data.refresh_token
      if (response.data.access_token) {
        axios.defaults.headers.common["Authorization"] = 'Bearer ' + response.data.access_token.replace(/^Bearer/, '')
      }
      localStorage.setItem(`${PREFIX}auth_token`, response.data.access_token)
      localStorage.setItem(`${PREFIX}refresh_token`, response.data.refresh_token)
      // Refresh user data from localStorage since that has not changed since logging in
      this.user = {}
      let userInfo = localStorage.getItem(`${PREFIX}auth_token_user`)
      if (userInfo) {
        // auth_token_user: user id, first name, middle initial, last name, role, simulated role
        [this.user.id, this.user.first_name, this.user.middle_initial, this.user.last_name, this.user.role, this.user.simulated_role, this.user.version] = userInfo.split('|')
      }
    },
    resetUserInfo() {
      localStorage.removeItem(`${PREFIX}auth_token`)
      localStorage.removeItem(`${PREFIX}auth_token_user`)
      localStorage.removeItem(`${PREFIX}refresh_token`)
      delete axios.defaults.headers.common["Authorization"]
      // console.log('resetUserInfo')
      this.user = {
        id: null,
        first_name: null,
        middle_initial: null,
        last_name: null,
        role: null,
        version: null,
        simulated_role: null,
        username: null,
        email: null,
      }
      this.auth_token = null
      this.refresh_token = null
      this.is_refreshing_token = false

      return null
    },
    reject() {
      this.resetUserInfo()
    },
  },
})
