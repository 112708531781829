<template>
  <div class="header-container">
    <div class="logo">
      <svg width="81" height="24" viewBox="0 0 81 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 2C0 0.895431 0.895431 0 2 0V0C3.10457 0 4 0.895431 4 2V21.1233C4 22.2279 3.10457 23.1233 2 23.1233V23.1233C0.895431 23.1233 0 22.2279 0 21.1233V2Z" fill="#7196A0"/>
        <path d="M7.59485 13.5616C7.59485 12.4571 8.49028 11.5616 9.59485 11.5616V11.5616C10.6994 11.5616 11.5948 12.4571 11.5948 13.5616V21.1233C11.5948 22.2279 10.6994 23.1233 9.59485 23.1233V23.1233C8.49028 23.1233 7.59485 22.2279 7.59485 21.1233V13.5616Z" fill="#4E6BE3"/>
        <path d="M15.1896 8.28912C15.1896 7.18456 16.085 6.28912 17.1896 6.28912V6.28912C18.2941 6.28912 19.1896 7.18455 19.1896 8.28912V21.1233C19.1896 22.2279 18.2941 23.1233 17.1896 23.1233V23.1233C16.085 23.1233 15.1896 22.2279 15.1896 21.1233V8.28912Z" fill="#46D8D5"/>
        <path d="M67.555 22.4622H63.4154L69.723 4.19067H74.7013L80.9999 22.4622H76.8603L72.2835 8.36599H72.1408L67.555 22.4622ZM67.2963 15.2803H77.0744V18.2958H67.2963V15.2803Z" fill="white"/>
        <path d="M52.8813 22.4622H46.4042V4.19067H52.9348C54.7727 4.19067 56.3548 4.55646 57.6811 5.28803C59.0075 6.01366 60.0275 7.05749 60.7412 8.41952C61.4609 9.78156 61.8207 11.4112 61.8207 13.3086C61.8207 15.2119 61.4609 16.8475 60.7412 18.2155C60.0275 19.5834 59.0015 20.6332 57.6633 21.3648C56.331 22.0964 54.737 22.4622 52.8813 22.4622ZM50.2672 19.1522H52.7207C53.8627 19.1522 54.8232 18.95 55.6024 18.5456C56.3875 18.1352 56.9763 17.5017 57.3689 16.6453C57.7673 15.7828 57.9666 14.6706 57.9666 13.3086C57.9666 11.9584 57.7673 10.8551 57.3689 9.99865C56.9763 9.14217 56.3904 8.51171 55.6113 8.10727C54.8321 7.70282 53.8716 7.5006 52.7296 7.5006H50.2672V19.1522Z" fill="white"/>
        <path d="M33.8495 22.4622H27.3724V4.19067H33.9031C35.7409 4.19067 37.323 4.55646 38.6494 5.28803C39.9757 6.01366 40.9958 7.05749 41.7095 8.41952C42.4292 9.78156 42.789 11.4112 42.789 13.3086C42.789 15.2119 42.4292 16.8475 41.7095 18.2155C40.9958 19.5834 39.9698 20.6332 38.6315 21.3648C37.2992 22.0964 35.7052 22.4622 33.8495 22.4622ZM31.2355 19.1522H33.6889C34.8309 19.1522 35.7915 18.95 36.5706 18.5456C37.3557 18.1352 37.9446 17.5017 38.3371 16.6453C38.7356 15.7828 38.9349 14.6706 38.9349 13.3086C38.9349 11.9584 38.7356 10.8551 38.3371 9.99865C37.9446 9.14217 37.3587 8.51171 36.5796 8.10727C35.8004 7.70282 34.8398 7.5006 33.6979 7.5006H31.2355V19.1522Z" fill="white"/>
      </svg>
    </div>
    <ul class="main-menu" data-test-id="main-menu">
        <li v-for="(menuItem, index) in menuItems" v-bind:key="menuItem.label">
            <span :class="{ 'active' : activeIndex === index}" @click="reroute(menuItem.route, index)">{{ menuItem.label }}</span>
        </li>
    </ul>
    <div class="logout-container" data-test-id="header-container-options">
      <span class="admin-link" :class="{ 'active' : route.path.startsWith('/admin')}" @click="reroute('admin', null)">Admin</span> |
      <span @click="logout();$emit('logout-user')">Logout</span>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineEmits } from "vue"
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from "@/stores/auth_store"
let breadcrumb = ref("Comp Search | Comp Search Details") // assumed landing page = "Comp Search"
let pageTitle = ref("Comp Search Details")
let headers = ref([breadcrumb.value, pageTitle.value])
const emit = defineEmits(['setHeaders'])
const menuItems = [
    { 
      label: 'Comp Search', 
      route:'/',
      breadcrumb: 'Comp Search',
      title: 'Comp Search',
      children: [
        { 
          label: 'Comp Search Details - Budget', 
          route:'/compsearch/budget',
          breadcrumb: 'Comp Search | Search Details | Budget',
          title: 'Budget'
        },
        { 
          label: 'Comp Search Details - Schedule', 
          route:'/compsearch/schedule',
          breadcrumb: 'Comp Search | Search Details | Schedule',
          title: 'Schedule'
        },
        { 
          label: 'Comp Search Details - Companies', 
          route:'/compsearch/companies',
          breadcrumb: 'Comp Search | Search Details | Companies',
          title: 'Companies'
        }
      ]
    },
    { 
      label: 'My Data', 
      route:'/mydata',
      breadcrumb: 'My Data',
      title: 'My Data',
      children: [
        { 
          label: 'Project Summary', 
          route:'/mydata/project-summary',
          breadcrumb: 'My Data | Project Summary',
          title: 'Project Summary'
        }
      ]
    },
    { 
      label: 'New Project',
      route: '/project',
      breadcrumb: 'New Project',
      title: 'New Project'
    }
  ]
const route = useRoute()
const activeIndex = computed(() => {
  // Find the index of the first menu item where the route matches the current route path
  const activeIdx = menuItems.findIndex((menuItem) => {
    if (route.path === menuItem.route) {
      return true;
    }
    if (menuItem.children) {
      return menuItem.children.some(child => route.path.includes(child.route));
    }
    return false;
  });

  // Return the found index or default to -1 if no match is found
  return activeIdx !== -1 ? activeIdx : -1;
});

const authStore = useAuthStore()
function logout() {
  authStore.logoutUser()
}
const router = useRouter()
function reroute (path, index) {
  if (index || index === 0) {
    setHeaders(index)
  }
  
  // Ensure the path is absolute
  if (!path.startsWith('/')) {
    path = '/' + path
  }
  
  router.push({ path: path })
}
function setHeaders (index) {
  breadcrumb.value = ""
  pageTitle.value = ""
  breadcrumb.value = menuItems[index].breadcrumb
  // Add submenu item if there's a route match (e.g if we're on a submenu page)
  if (route.matched?.length > 0) {
    breadcrumb.value = breadcrumb.value + " | " + route.matched[0].name
  }
  pageTitle.value = menuItems[index].title
  headers.value = [breadcrumb.value, pageTitle.value]
  emit('setHeaders', headers)
}
// function getBreadcrumb() {
//   return breadcrumb.value
// }
// function getPageTitle() {
//   return pageTitle.value
// }
</script>
<style lang="scss">
@import "./src/assets/main.scss";
.header-container {
  display: flex;
}
.main-menu {
  display: inline-flex;
  margin-top: 15px;
  margin-left: 80px;
}
.main-menu li {
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  color: $white1;
  padding-left: 50px;
}
.logo {
  display: inline-flex;
  margin-top: 12px;
  margin-left: 30px;
}
.logout-container {
  cursor: pointer;
  color: white;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 16px;
}
.active {
  color: $brightTeal1;
}
</style>