<template>
  <div v-if="isDataLoaded" class="individual-page-container">
    <!-- Sidebar-->
    <div class="individual-page-sidebar bak-white-1" :class="isSidebarOpen ? 'open' : 'closed'">
      <ExpandCollapse name="sidebar" :is-open-initially="true" :is-vertical-center="false" open-state="left"
        closed-state="right-zero" :show-double-chevron="true"
        @toggle-expand-collapse="toggleSidebar(); onResizeSidebar()" :show-circle="true" />
      <div :class="!isSidebarOpen ? 'hidden' : ''">
        <div class="project-name">
          <div><strong>{{ fullAddress }}</strong></div>
          <div class="menu-container">
            <button @click="toggleMenu" class="menu-button">⋮</button>
            <div v-if="isMenuVisible" class="menu-content">
              <ul>
                <li>Option 1</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Sidebar Menu -->
        <div>
          <div class="sidebar-menu">
            <div v-for="item in sidebarMenuItems" :key="item.id"
              :class="{ 'sidebar-menu-selected': item.id === sidebarSelectedItemId }"
              @click="sidebarSelectItem(item.id)">
              {{ item.text }}
            </div>
          </div>
        </div>
        <div ref="propertyImageContainer" v-if="sidebarSelectedItemId == 1">
          <div class="project-img">
            <img v-if="hasLoaded && !hasPropertyImage" :src="require(`../../assets/building_assets/property1.jpg`)">
          </div>
        </div>
        <div v-if="sidebarSelectedItemId == 2">
          <div class="map-container">
            <div v-if="!isProjectLocationValid">Project has invalid location values.</div>
            <div id="individualPageMap"></div>
          </div>
        </div>
        <div class="project-details-wrapper">
            <div class="project-details">
              <div class="project-img-summary">
                <div class="project-img-summary-item">
                  <label>Project Address</label>
                  <span>{{ fullAddress }}</span>
                </div>
                <div class="project-img-summary-item">
                  <label>City</label><span>{{ getDisplayValue(projectInformation.city) }}</span>
                </div>
                <div class="project-img-summary-item">
                  <label>State</label><span>{{ getDisplayValue(projectInformation.state) }}</span>
                </div>
                <div class="project-img-summary-item">
                  <label>Zip Code</label><span>{{ getDisplayValue(projectInformation.zipcode) }}</span>
                </div>
                <div class="project-img-summary-item construction-type-container">
                  <label>Construction Type:</label><span>{{ projectInformation.project_construction_types_s ? projectInformation.project_construction_types_s.join('; ') : 'N/A' }}</span>
                </div>

                <div class="project-img-summary-item">
                  <label>Former Use Type:</label><span>{{ getFormerUseType() }}</span>
                </div>
              </div>
              <!-- Building Details -->
              <div class="building-details-container">
                <div class="accordion-panel-header">
                  <label for="buildingDetails"><strong>Building Details</strong></label>
                  <ExpandCollapse name="buildingDetails" :is-open-initially="true" :is-vertical-center="false"
                    open-state="down" closed-state="up" :show-double-chevron="false" :show-circle="false"
                    :chevron-fill="'#4A5FD5'" @toggle-expand-collapse="expandCollapseController('buildingDetails');" />
                </div>
                <div v-if="isBuildingDetailsOpen">
                  <div>
                    <table class="building-details-table">
                      <tr>
                        <th class="left-align">Use Type(s)</th>
                        <th>GSF</th>
                        <th>Units</th>
                      </tr>
                      <tr v-for="(row, index) in useTypeTableData" :key="index">
                        <td class="left-align use-types" :class="row.building_use_type_id_s == 'Total' ? 'total' : ''">
                          {{ row.codeable_id_s ? numberFormat(row.codeable_id_s, 0) : 'N/A'}}
                        </td>
                        <td>{{ row.total_gsf ? numberFormat(row.total_gsf, 0) : 'N/A' }}</td>
                        <td>{{ row.num_units ? numberFormat(row.num_units, 0) : 'N/A' }}</td>
                      </tr>
                      <tr>
                        <td class="left-align use-types">BOH/Other</td>
                        <td>{{ bohOtherGSF() }} </td>
                        <td></td>
                      </tr>
                      <tr class="totals">
                        <td class="left-align">Total</td>
                        <td>{{ totalGsf() }}</td>
                        <td>{{ totalUnits() }}</td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                  <div class="building-details-summary">
                    <div class="building-details-summary-item">
                      <label>Building Height Classification:</label><span> {{ classifyBuilding(projectInformation.total_height) }} </span>
                    </div>
                    <div class="building-details-summary-item">
                      <label>Buildings Height (Feet)</label><span>{{ projectInformation.total_height }}</span>
                    </div>
                    <div class="building-details-summary-item">
                      <label>Building Height (Stories)</label><span>{{ projectInformation.num_floors }}</span>
                    </div>
                  </div>
                  <div class="building-details-summary">
                    <div class="building-details-summary-item amenities-container">
                      <label>Amenities:</label><span> {{ projectInformation.amenities_s ? projectInformation.amenities_s.join('; ') : 'N/A' }} </span>
                    </div>
                  </div>
                  <div class="building-details-summary">
                    <div class="building-details-summary-item">
                      <label>Affordable Component:</label><span> {{ totalAffordableComponent() ? totalAffordableComponent() : 'N/A' }}</span>
                    </div>
                    <div class="building-details-summary-item">
                      <label>Historic/Landmarks:</label><span>N/A</span>
                    </div>
                    <div class="building-details-summary-item">
                      <label>Sustainability:</label><span>{{ projectInformation.sustainability_type_id_s ? projectInformation.sustainability_type_id_s : 'N/A' }}</span>
                    </div>
                    <div class="building-details-summary-item">
                      <label>Brand/Flag:</label><span>{{ getAllBrandNames() }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Construction Details -->
              <div class="construction-details-container">
                <div class="accordion-panel-header">
                  <label for="buildingDetails"><strong>Construction Details</strong></label>
                  <ExpandCollapse name="buildingDetails" :is-open-initially="true" :is-vertical-center="false"
                    open-state="down" closed-state="up" :show-double-chevron="false" :show-circle="false"
                    :chevron-fill="'#4A5FD5'"
                    @toggle-expand-collapse="expandCollapseController('constructionDetails');" />
                </div>
                <div v-if="isConstructionDetailsOpen">
                  <div class="construction-details-summary">
                    <div class="construction-details-summary-item construction-details-summary-item-header">
                      <label>Construction Contract:</label><span>{{  getProjectConstructionContract() }}</span>
                    </div>
                    <div class="construction-details-summary-item">
                      <label>Year Construction Started</label><span>{{ projectInformation.actual_start_date ? new Date(projectInformation.actual_start_date).getFullYear() : 'N/A' }}</span>
                    </div>
                    <div class="construction-details-summary-item">
                      <label>Year Construction Completed</label><span> {{ projectInformation.actual_end_date ? new Date(projectInformation.actual_end_date).getFullYear() : 'N/A' }}</span>
                    </div>
                    <div class="construction-details-summary-item">
                      <label>Construction Duration</label><span>{{ projectInformation.project_length_months ? parseFloat(projectInformation.project_length_months).toFixed(0) : 'N/A' }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

    </div>
    <!-- Content -->
    <div class="individal-page-content" :class="!largeSidebar ? 'large' : 'small'">
      <div class="breadcrumbs">MyData | <strong>{{ fullAddress }}</strong> |
        <span v-for="menuItem in filteredMenuItems()" :key="menuItem.id">
          {{ menuItem.name }}
        </span>
      </div>
    
      <div class="individual-page-title">Property Summary: {{ fullAddress }} </div>
      <div class="individual-page-sub-menu">
        <div v-for="item in subMenuItems" :key="item.id"
          :class="{ 'individual-page-sub-menu-selected': item.id === subMenuSelectedItemId }"
          @click="subMenuSelectItem(item.id)">
          {{ item.name }}
        </div>
      </div>

      <!-- Budget Summary Content -->
      <div v-if="subMenuSelectedItemId == 1">
        <div>
          <div class="budget-summary-header-container">
            <div class="budget-summary-header-title">Budget Summary</div>
            <div class="custom-select label-text-spacing">
              <select class="right-div" id="budget-type">
                <option value="viewByTotal">View By Total</option>
                <option value="viewByGSF">View By ?</option>
              </select>
            </div>
          </div>
          <div class="budget-summary-content">
            <!-- Total Project Costs -->
            <div class="tile total-project-costs">
              <!-- Doughnut Chart -->
              <div>
                <div class="chart-main-title">Total Project Costs</div>
                <div class="chart-title">Actual Costs</div>
                <div class="doughnut-container">
                  <div>
                    <Doughnut :height=250 :width=225 :data="doughnutData" :options="doughnutOptions" />
                  </div>
                  <div class="doughnut-chart-legend">
                    <div class="acquisition-costs-legend">Acquisition Costs
                      <div class="detail-container">
                        <div>{{ doughnutChartPercentage[0] }}</div>
                        <div class="vertical-divider"></div>
                        <div>{{
    kMillionsWithDecimalsFormat(lcTotalRevisedBudget) }}</div>
                      </div>
                    </div>
                    <div class="hard-costs-legend">Hard Costs
                      <div class="detail-container">
                        <div>{{ doughnutChartPercentage[1] }}</div>
                        <div class="vertical-divider"></div>
                        <div>{{
    kMillionsWithDecimalsFormat(hcTotalRevisedBudget) }}</div>
                      </div>
                    </div>
                    <div class="soft-costs-legend">Soft Costs
                      <div class="detail-container">
                        <div>{{ doughnutChartPercentage[2] }}</div>
                        <div class="vertical-divider"></div>
                        <div>{{
    kMillionsWithDecimalsFormat(scTotalRevisedBudget) }}</div>
                      </div>
                    </div>
                    <div class="financing-and-misc-costs-legend">Financing & Misc. Costs
                      <div class="detail-container">
                        <div>{{ doughnutChartPercentage[3] }}</div>
                        <div class="vertical-divider"></div>
                        <div>{{
    kMillionsWithDecimalsFormat(miscFinancingTotalRevisedBudget)
  }}</div>
                      </div>
                    </div>
                    <div class="contingencies-legend">Contingencies
                      <div class="detail-container">
                        <div>{{ doughnutChartPercentage[4] }}</div>
                        <div class="vertical-divider"></div>
                        <div>{{
    kMillionsWithDecimalsFormat(contingenciesTotalRevisedBudget)
  }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Bar Chart -->
              <div>
                <div class="chart-main-title">&nbsp;</div>
                <div class="chart-title">Costs to Date</div>
                <div class="cost-to-date-bar-chart-container">
                  <div>
                    <Bar :height="250" :width="200" :data="costsToDateStackedBarData"
                      :options="costsToDateStackedBarOptions" />
                  </div>
                  <div class="cost-to-date-legend">
                    <div class="cost-to-date-current-budget-legend">
                      <div>{{ kMillionsWithDecimalsFormat(cdTotalCurrentBudget) }}</div>
                      <div> Current Budget </div>
                    </div>
                    <div class="cost-to-date-billed-to-date-legend">
                      <div> {{ kMillionsWithDecimalsFormat(cdTotalBilledToDate) }}</div>
                      <div> Billed to Date</div>
                      <div>{{ cdTotalCurrentBudget > 0 ? percentFormat(cdTotalBilledToDate / cdTotalCurrentBudget) : 0 }} </div>
                    </div>
                    <div class="cost-to-date-original-budget-legend">
                      <div>{{ kMillionsWithDecimalsFormat(cdTotalOriginalBudget) }}</div>
                      <div> Original Budget </div>
                      <div>{{ cdTotalCurrentBudget > 0 ? percentFormat(cdTotalOriginalBudget / cdTotalCurrentBudget) : 0 }} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Budget Variance Summary -->
            <div class="tile budget-variance-summary">
              <!-- Doughnut Chart -->
              <div>
                <div class="chart-main-title">Budget Variance Summary</div>
                <div class="chart-title">
                  <div>Hard Costs</div>
                  <div class="hc-legend">
                    <div class="hc-budget-legend">Budget</div>
                    <div class="hc-actual-legend">Actual</div>
                  </div>
                </div>
                <div class="budget-variance-charts">
                  <div class="chart summary-stacked-bar">
                    <Bar :height="248" :width="120" :data="hardCostsSummaryStackedBarData"
                      :options="hardCostsSummaryStackedBarOptions" />
                  </div>
                  <div class="chart hc-top-offender-stacked-bar">
                    <div>
                      <Bar :height="320" :width="300" :data="hardCostsStackedBarData"
                        :options="hardCostsStackedBarOptions" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Bar Chart -->
              <div>
                <div class="chart-main-title">&nbsp;</div>
                  <div class="chart-title">
                    <div>Soft Costs</div>
                    <div class="sc-legend">
                      <div class="sc-budget-legend">Budget</div>
                      <div class="sc-actual-legend">Actual</div>
                    </div>
                  </div>
                  <div class="budget-variance-charts">
                    <div class="chart summary-stacked-bar">
                      <Bar :height="248" :width="120" :data="softCostsSummaryStackedBarData"
                        :options="softCostsSummaryStackedBarOptions" />
                    </div>
                    <div class="chart sc-top-offenders-stacked-bar">
                      <div>
                        <Bar :height="320" :width="300" :data="softCostsStackedBarData"
                          :options="softCostsStackedBarOptions" />
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Table Grid -->
        <div>
          <div class="budget-tracking-header-container">
            <div class="budget-tracking-header-title">Budget Tracking</div>
            <!-- TODO: Remove this for now as it is not functional yet -->
            <!--  <div class="budget-tracking-subtitle">View in Original Format</div>
            <div class="toggle-container" :class="{ 'original-format': isOriginalFormat }" @click="toggleActive">
              <div class="circle" :class="{ 'move-right': isOriginalFormat }"></div>
            </div>
            <div class="budget-tracking-subtitle">View in DDA Categories</div> -->
          </div>
          <div class="grid-container" id="budgetViewGrid">
            <ag-grid
                :row-height="30"
                :row-data="leafParentData"
                :columnDefs="columnDefs"
                :treeData="true"
                :auto-group-column-def="autoGroupColumnDef"
                :key="`${leafParentDataCtr};`"
                @setChartData="setChartData"
                :pinnedBottomRowData="pinnedBottomRowData"
                :groupDefaultExpanded="2"
              />
          </div>
        </div>
      </div>

      <!-- Schedule Summary Content -->
      <div v-if="subMenuSelectedItemId == 2">
        <ProjectScheduleDetails />
      </div>

      <div v-if="subMenuSelectedItemId == 3">
        <ProjectCompanyDetails />
      </div>

      <div v-if="subMenuSelectedItemId == 4">In-progress</div>
    </div>
  </div>
</template>

<script setup>
/* eslint no-undef: 0 */
import { computed, onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from "@/stores/auth_store";
import { useErrorStore } from "@/stores/error_store";
import { cleanupHierarchyNames } from "@/data/data";
import { axiosQuery, currencyFormat, kMillionsFormat, kMillionsWithDecimalsFormat, numberFormat, percentFormat, standardizeLabelsLength, median } from "@/assets/js/utils";
import AgGrid from "../../components/AgGrid.vue";
import ExpandCollapse from "../helpers/ExpandCollapse.vue";
import { Bar, Doughnut } from "vue-chartjs";
import {
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Colors,
  Filler,
  Legend,
  Title,
  Tooltip,
  Chart as ChartJS,
} from "chart.js";
import { doughnutMiddleTextPlugin, standardDoughnutChartJson, standardStackedBarChartJson } from "@/assets/js/chart_config";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ProjectScheduleDetails from './ProjectScheduleDetails.vue';
import ProjectCompanyDetails from './ProjectCompanyDetails.vue';
import { CODES, BUILDING_CLASIFICATION } from "@/types/Constants";

// General Settings
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors,
  ArcElement,
  LineElement,
  PointElement,
  Filler,
  doughnutMiddleTextPlugin(),
  ChartDataLabels
);

// TODO: Related to the toggle view in original format
// let isOriginalFormat = ref(true);

// function toggleActive() {
//   isOriginalFormat.value = !isOriginalFormat.value
// }

// Menu Settings
const isMenuVisible = ref(false);
const sidebarSelectedItemId = ref(1);
const subMenuSelectedItemId = ref(1);
const sidebarMenuItems = [
  { id: 1, text: 'Images' },
  { id: 2, text: 'Map' }
];
const subMenuItems = [
  { id: 1, name: 'Budget' },
  { id: 2, name: 'Schedule' },
  { id: 3, name: 'Companies' }
];

// Use Type Table Data
let useTypeTableData = ref([])

// Map Settings
const isGoogleMapsMounted = ref(false);
const isProjectLocationValid = ref(false);
let map;
let circleCenterLocation = {};

function loadGoogleMaps() {
  if (typeof google !== 'undefined') {
    // Check if latitude and longitude are valid
    const latitude = parseFloat(projectInformation.value.latitude);
    const longitude = parseFloat(projectInformation.value.longitude);

    if (isNaN(latitude) || isNaN(longitude)) {
      console.log('Invalid latitude or longitude');
      isProjectLocationValid.value = false;
      return;
    }

    isProjectLocationValid.value = true;

    // See https://developers.google.com/maps/documentation/javascript/advanced-markers/graphic-markers
    const parser = new DOMParser()
    const pinSvgString =
      '<svg xmlns="http://www.w3.org/2000/svg" width="5.6444445mm" height="9.847393mm" viewBox="0 0 20 34.892337" version="1.1" >\n' +
      '  <g transform="translate(-814.59595,-274.38623)">\n' +
      '    <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">\n' +
      '      <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="display:inline;opacity:1;fill:#4A5FD5;fill-opacity:1;stroke:#3b73a8;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>\n' +
      '      <circle r="3.0355" cy="288.25278" cx="823.03064" style="display:inline;opacity:1;fill:#fff;fill-opacity:1;stroke-width:0"/>\n' +
      '    </g>\n' +
      '  </g>\n' +
      '</svg>\n';
    circleCenterLocation = { lat: latitude, lng: longitude };
    const mapOptions = {
      center: circleCenterLocation,
      zoom: 14,
      mapId: 'CompSearchMap'
    };

    map = new google.maps.Map(document.getElementById('individualPageMap'), mapOptions);
    // Create the marker
    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: circleCenterLocation,
      map: map,
      content: parser.parseFromString(
        pinSvgString,
        "image/svg+xml",
      ).documentElement
    });

    // TODO Dynamically require the image and get its URL
    const imageUrl = require('../../assets/building_assets/property1.jpg');

    const infoWindow = new google.maps.InfoWindow({
      content: `<div style="background: #3b73a8; color: #fff; font-size: 12px; display: flex; flex-direction: column; align-items: center; height: 100%; padding: 5px; width: 180px">
                <div><img style="width:100px" src="${imageUrl}"></div>
                <div style="padding-left: 5px;">
                  <div style="white-space:nowrap;"><strong>${projectInformation.value.address_line_1}</strong></div>
                  <div style="display: flex; align-items: center;">
                    <label style="width: 100%;">Construction Type:</label>
                    <div style="white-space:nowrap;">${projectInformation.value.project_construction_types_s ? projectInformation.value.project_construction_types_s[0] : '-'}</div>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <label style="width: 100%;">Total GSF:</label>
                    <div style="white-space:nowrap;">${projectInformation.value.total_gsf ? numberFormat(projectInformation.value.total_gsf, 0) : '-'}</div>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <label style="width: 100%;">Unit Count:</label>
                    <div style="white-space:nowrap;">${projectInformation.value.num_units ? numberFormat(projectInformation.value.num_units, 0) : '-'}</div>
                  </div>
                </div>
              </div>`,
    });

    marker.addListener('click', () => {
      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: true,
      });
    });
    isGoogleMapsMounted.value = true;
  } else {
    errorStore.setError('Google Map Error', 'Unknown error opening Google Maps', 'error');
  }
}

// Doughnut Chart Settings
// General Variables
let totalRevisedBudget = computed(() => {
  return lcTotalRevisedBudget.value + hcTotalRevisedBudget.value +
    miscFinancingTotalRevisedBudget.value + scTotalRevisedBudget.value + contingenciesTotalRevisedBudget.value
})

// Middle Text
let doughnutLabel = computed(() => {
  return 'Total Project Costs'
})
// Middle Value
let doughnutSummaryValue = computed(() => {
  return kMillionsWithDecimalsFormat(totalRevisedBudget.value)
})

let doughnutChartJsonConfig = standardDoughnutChartJson({
  doughnutTitle: "Actual Costs",
})
let doughnutChartPercentage = ref([]);
let doughnutChartData = ref([])
const doughnutData = computed(() => {
  return {
    labels: ["Acquisition Costs", "Hard Costs", "Soft Cost ", "Finance/Misc Costs", "Contingencies Costs"],
    datasets: [
      {
        backgroundColor: [
          "#52596D", // $darkGray1
          "#4A5FD5", // $brightBlue1
          "#4A8FD0", // $mediumBlue1
          "#8A92A9", // $mediumGray1
          "#d3d3d3", // assigning to light gray for now
        ],
        data: doughnutChartData.value
      },
    ],
  };
});

const doughnutOptions = computed(() => {
  let json = doughnutChartJsonConfig
  json.cutout = 70
  json.plugins.legend.display = false
  json.plugins.doughnutMiddleTextPlugin.middleTextLabel = doughnutLabel.value;
  json.plugins.doughnutMiddleTextPlugin.middleTextValue = doughnutSummaryValue.value;
  json.plugins.doughnutMiddleTextPlugin.fontSize = 14;
  json.plugins.tooltip.callbacks = {
    label: function (context) {
      let actual = 0;
      if (context.label?.includes("Acquisition")) {
        actual = currencyFormat(lcTotalRevisedBudget.value)
      } else if (context.label?.includes("Hard")) {
        actual = currencyFormat(hcTotalRevisedBudget.value)
      } else if (context.label?.includes("Soft")) {
        actual = currencyFormat(scTotalRevisedBudget.value)
      } else if (context.label?.includes("Misc")) {
        actual = currencyFormat(miscFinancingTotalRevisedBudget.value)
      } else if (context.label?.includes("Contingencies")) {
        actual = currencyFormat(contingenciesTotalRevisedBudget.value)
      }
      return actual;
    },
    footer: function (context) {
      let variancePerc = 0;
      let varianceVal = 0;
      if (context[0].label?.includes("Acquisition")) {
        varianceVal = kMillionsFormat(lcTotalVariance.value);
        variancePerc = percentFormat(lcVariancePercent.value);
      } else if (context[0].label?.includes("Hard")) {
        varianceVal = kMillionsFormat(hcTotalVariance.value);
        variancePerc = percentFormat(hcVariancePercent.value);
      } else if (context[0].label?.includes("Soft")) {
        varianceVal = kMillionsFormat(scTotalVariance.value);
        variancePerc = percentFormat(scVariancePercent.value);
      } else if (context[0].label?.includes("Misc")) {
        varianceVal = kMillionsFormat(miscFinancingTotalVariance.value);
        variancePerc = percentFormat(miscFinancingVariancePercent.value);
      } else if (context[0].label?.includes("Contingencies")) {
        varianceVal = kMillionsFormat(contingenciesTotalVariance.value);
        variancePerc = percentFormat(contingenciesVariancePercent.value);
      }
      return `Variance: ${varianceVal} (${variancePerc})`
    },
  }
  json.responsive = true;
  return json
});

let cdTotalBilledToDate = ref();
let cdTotalCurrentBudget = ref();
let cdTotalOriginalBudget = ref();

// Costs to Date Stacked Bar Chart
const costsToDateStackedBarData = computed(() => {
  return {
    labels: ["Total"],
    datasets: [
      {
        label: "Original Budget",
        backgroundColor: "#8A92A9", // Medium Grey
        data: [cdTotalOriginalBudget.value],
      },
      {
        label: "Billed to Date",
        backgroundColor: "#4A5FD5", // $brightBlue1
        data: [cdTotalBilledToDate.value - cdTotalOriginalBudget.value],
      },
      {
        label: "Current Budget",
        backgroundColor: "#ABB3EB", // It's not in our colors scss but it's called Biloba Flower
        data: [cdTotalCurrentBudget.value - cdTotalBilledToDate.value],
      }
    ],
  };
});

let costsToDateJsonConfig = standardStackedBarChartJson({
  barPercentage: 1,
  xTicksFontSize: 12,
  xTicksFont: 'Inter-Bold',
  yMaxTicksLimit: 10,
})

const costsToDateStackedBarOptions = computed(() => {
  let json = costsToDateJsonConfig
  json.plugins.tooltip = {}
  json.plugins.tooltip.callbacks = {
    label: function (context) {
      return context.dataset.label + ' : ' + kMillionsWithDecimalsFormat(context.dataset.data[0])
    }
  }
  json.barThickness = 50

  return json
})

/**
 * Stacked Bar Chart Settings
 */

// Hard Costs Summary
const hardCostsSummaryStackedBarData = computed(() => {
  // Do not show a negative variance on the chart as the chart looks really funky
  // adjusted sov - original sov (delta value)
  let changeInBudget = hcTotalVariance.value
  if (changeInBudget < 0) {
    changeInBudget = 0
  }
  return {
    labels: ["Total"],
    datasets: [
      {
        label: "Original Budget",
        backgroundColor: "#4A5FD5", // $brightBlue1
        data: [hcTotalOriginalBudget.value], // original sov
      },
      {
        label: "Budget Variance",
        backgroundColor: "#84CADE", // $lightBlue1, css var doesn't work here
        data: [changeInBudget],
      },
    ],
  };
});
// Keep unique for hard/soft as we edit it without cloning
// (default cloning will mess up callbacks)
let hardSummaryJsonConfig = standardStackedBarChartJson({
  barPercentage: 0.8,
  xTicksFontSize: 12,
  xTicksFont: 'Inter-Bold',
  yMaxTicksLimit: 10,
})
const hardCostsSummaryStackedBarOptions = computed(() => {
  let json = hardSummaryJsonConfig
  json.plugins.tooltip = {}
  json.plugins.tooltip.callbacks = {
    label: function (context) {
      const label = context.dataset.label;
      const value = context.parsed.y;
      let multilineTooltip = [label + ":"];
      multilineTooltip.push(currencyFormat(value));
      return multilineTooltip;
    },
    footer: function () {
      return (
        "Variance: " +
        percentFormat(
          (hcTotalRevisedBudget.value - hcTotalOriginalBudget.value) /
          hcTotalOriginalBudget.value
        )
      )
    }
  }
  json.plugins.datalabels = {
    color: 'black',
    font: {
      size: 12
    },
    anchor: 'end',
    align: 'top',
    formatter: function (val, ctx) {
      // Get only values for Budget Variance
      if (ctx.datasetIndex === 1) {
        return `${Math.round(parseFloat((
          (hcTotalRevisedBudget.value - hcTotalOriginalBudget.value) /
          hcTotalOriginalBudget.value
        )).toFixed(2) * 100)}%`
      } else {
        return ''
      }
    }
  }
  return json
})


const labelMaxLength = 15;
let hcStackedBarLabels = ref();
// Hard Costs Stacked Bar Chart
const hardCostsStackedBarData = computed(() => {
  return {
    labels: standardizeLabelsLength(hcStackedBarLabels.value),
    datasets: [
      {
        label: "Budget",
        backgroundColor: "#4A5FD5", // $brightBlue1
        data: hcOriginalBudgetArr.value
      },
      {
        label: "Variance",
        backgroundColor: "#84CADE", // $lightBlue1
        data: hcBudgetVarianceArr.value
      },
    ],
  };
});
// Keep unique for hard/soft as we edit it without cloning
// (default cloning will mess up callbacks)
let hardStackedBarJsonConfig = standardStackedBarChartJson({
  barPercentage: 0.8,
  xTicksFontSize: 12,
  xTicksFont: 'Inter',
  labelForcedLength: labelMaxLength,
  yMinTicksLimit: 7,
  yMaxTicksLimit: 10,
  yStepSize: 1000000
})
const hardCostsStackedBarOptions = computed(() => {
  let json = hardStackedBarJsonConfig;
  json.plugins.tooltip = {
    callbacks: {
      title: function (context) {
        return hcStackedBarLabels.value[context[0].dataIndex];
      },
      label: function (context) {
        const label = context.dataset.label;
        const value = context.parsed.y;
        return label + ": " + currencyFormat(value);
      },
      footer: function (context) {
        const index = context[0].dataIndex
        const variance = (hcRevisedBudgetArr.value[index] - hcOriginalBudgetArr.value[index]) / hcOriginalBudgetArr.value[index]
        const varianceText = context[0].datasetIndex === 0 ? 'Variance: ' : ''
        return varianceText + percentFormat(variance)
      },
    },
  };
  json.plugins.datalabels = {
    color: 'black',
    font: {
      size: 12,
    },
    anchor: 'end',
    align: 'top',
    formatter: function (val, ctx) {
      if (ctx.datasetIndex === 1) {
        const totalDatasetSum = ctx.chart.data.datasets[1].data.reduce((a, b) => a + b, 0);
        if (totalDatasetSum != 0) {
          const percentage = (val * 100) / totalDatasetSum;
          const roundedPercentage = Math.round(percentage * 100) / 100;
          return `${roundedPercentage.toFixed(0)}%`;
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
  };
  return json;
});

// Soft Costs
// Soft Costs Summary
const softCostsSummaryStackedBarData = computed(() => {
  // Do not show a negative variance on the chart as the chart looks really funky
  // adjusted sov - original sov (delta value)
  let changeInBudget = scTotalVariance.value
  if (changeInBudget < 0) {
    changeInBudget = 0
  }
  return {
    labels: ["Total"],
    datasets: [
      {
        label: "Original Budget",
        backgroundColor: "#4A8FD0", // $mediumBlue1
        data: [scTotalOriginalBudget.value], // original sov
      },
      {
        label: "Budget Variance",
        backgroundColor: "#84CADE", // $lightBlue1
        data: [changeInBudget],
      },
    ],
  };
});
// Keep unique for hard/soft as we edit it without cloning
// (default cloning will mess up callbacks)
let softSummaryJsonConfig = standardStackedBarChartJson({
  barPercentage: 0.8,
  xTicksFontSize: 12,
  xTicksFont: 'Inter-Bold',
  yMaxTicksLimit: 10,
})
const softCostsSummaryStackedBarOptions = computed(() => {
  let json = softSummaryJsonConfig
  json.plugins.tooltip = {}
  json.plugins.tooltip.callbacks = {
    label: function (context) {
      const label = context.dataset.label;
      const value = context.parsed.y;
      let multilineTooltip = [label + ":"];
      multilineTooltip.push(currencyFormat(value));
      return multilineTooltip;
    },
    footer: function () {
      return (
        "Variance: " +
        percentFormat(scVariancePercent.value
        )
      );
    },
  }
  json.plugins.datalabels = {
    color: 'black',
    font: {
      size: 12
    },
    anchor: 'end',
    align: 'top',
    formatter: function (val, ctx) {
      // Get only values for Budget Variance
      if (ctx.datasetIndex === 1) {
        return `${Math.round(parseFloat(scVariancePercent.value).toFixed(2) * 100)}%`
      } else {
        return ''
      }
    }
  }
  return json
})

let scStackedBarLabels = ref([]);
const softCostsStackedBarData = computed(() => {
  return {
    labels: standardizeLabelsLength(scStackedBarLabels.value),
    datasets: [
      {
        label: "Budget",
        backgroundColor: "#4A8FD0", // $mediumBlue1
        data: scOriginalBudgetArr.value
      },
      {
        label: "Variance",
        backgroundColor: "#84C9DE", // NOTE : color variable doesn't work here
        data: scBudgetVarianceArr.value
      },
    ],
  };
});
// Keep unique for hard/soft as we edit it without cloning
// (default cloning will mess up callbacks)
let softStackedBarJsonConfig = standardStackedBarChartJson({
  barPercentage: 0.8,
  xTicksFontSize: 12,
  xTicksFont: 'Inter',
  labelForcedLength: labelMaxLength,
  yMinTicksLimit: 7,
  yMaxTicksLimit: 10,
  yStepSize: 1000000
})
const softCostsStackedBarOptions = computed(() => {
  let json = softStackedBarJsonConfig
  json.plugins.tooltip = {}
  json.plugins.tooltip.callbacks = {
    title: function (context) {
      // return the full length version of the labels
      return scStackedBarLabels.value[context[0].dataIndex]
    },
    label: function (context) {
      const label = context.dataset.label;
      const value = context.parsed.y;
      return label + ": " + currencyFormat(value);
    },
    footer: function (context) {
      const index = context[0].dataIndex
      let variance = (scRevisedBudgetArr.value[index] - scOriginalBudgetArr.value[index]) / scOriginalBudgetArr.value[index]
      const varianceText = context[0].datasetIndex === 0 ? 'Variance: ' : ''
      return varianceText + percentFormat(variance)
    },
  }
  json.plugins.datalabels = {
    color: 'black',
    font: {
      size: 12
    },
    anchor: 'end',
    align: 'top',
    formatter: function (val, ctx) {
      // Get only values for Budget Variance
      if (ctx.datasetIndex === 1) {
        const totalDatasetSum = ctx.chart.data.datasets[1].data.reduce((a, b) => (a + b), 0);
        if (totalDatasetSum != 0) {
          const percentage = val * 100 / totalDatasetSum
          const roundedPercentage = Math.round(percentage * 100) / 100
          return `${roundedPercentage.toFixed(0)}%`
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
  return json
});

// Functions
function toggleMenu() {
  isMenuVisible.value = !isMenuVisible.value;
}

function sidebarSelectItem(id) {
  sidebarSelectedItemId.value = id;
  if (sidebarSelectedItemId.value === 1) {
    fetchImage()
  } else if (sidebarSelectedItemId.value === 2) {
    setTimeout(() => {
      loadGoogleMaps();
    }, 300);
  }
}

function subMenuSelectItem(id) {
  subMenuSelectedItemId.value = id;
}

function filteredMenuItems() {
  return subMenuItems.filter(item => item.id === subMenuSelectedItemId.value);
}

// Toggle Values
let isBuildingDetailsOpen = ref(true);
let isConstructionDetailsOpen = ref(true);

function expandCollapseController(element) {
  switch (element) {
    case 'buildingDetails':
      isBuildingDetailsOpen.value = !isBuildingDetailsOpen.value
      break;
    case 'constructionDetails':
      isConstructionDetailsOpen.value = !isConstructionDetailsOpen.value
      break;
    default:
      break;
  }
}

// Ag-Grid Settings

// Need to ask what endpoint should I be getting these values to indicate the fields as well
// NOTE: if adding more sumAggregate or other aggregate functions, please adjust leafParentData accordingly
const sumAggregateCols = ['original_budget', 'total_adjustments', 'current_budget', 'total_spent', 'total_completed_to_date']
const autoGroupColumnDef = {
  headerName: 'Description of Work',
  minWidth: 350,
  pinned: 'left', // freeze/pin itemHierarchy to the left so it can be seen when scrolling (esp. for Schedule page)
  cellRendererParams: {
    suppressCount: true,
  },
  valueGetter: params => {
    if (params.node.rowPinned === 'bottom') {
      return 'Total';
    }
    return params.node.key; // or any other default value
  }
}
const columnDefs = [
  {
    headerName: 'Scopes of Work',
    headerClass: 'center-align-header',
    children: [
      {
        headerName: 'Company',
        field: 'company',
        cellStyle: { color: '#999', backgroundColor: '#f0f0f0' },
        headerClass: 'disabled-header',
        sortable: false,
        filter: false,
        editable: false,
        resizable: false,
      }
    ],
  },
  {
    headerName: 'Budget Tracking',
    headerClass: 'center-align-header',
    children: [
      {
        headerName: "Original Budget",
        field: "original_budget",
        headerClass: 'header-custom-lilac',
        aggFunc: sumAggregate,
        editable: false,
        valueParser: "Number(newValue)",
        valueFormatter: params => `${currencyFormat(params.value)}`,
        type: 'rightAligned'
      },
      {
        headerName: "Prior Adjustments",
        field: "total_adjustments",
        headerClass: 'header-custom-lilac',
        aggFunc: sumAggregate,
        editable: false,
        valueParser: "Number(newValue)",
        valueFormatter: params => `${currencyFormat(params.value)}`,
        type: 'rightAligned'
      },
      {
        headerName: "Current Budget",
        field: "current_budget",
        headerClass: 'header-custom-lilac',
        aggFunc: sumAggregate,
        editable: false,
        valueParser: "Number(newValue)",
        valueFormatter: params => `${currencyFormat(params.value)}`,
        type: 'rightAligned'
      },
      {
        headerName: "Variance",
        field: "variance",
        headerClass: 'header-custom-lightblue',
        aggFunc: varianceAggregate,
        editable: false,
        valueParser: "Number(newValue)",
        valueFormatter: params => `${percentFormat(params.value)}`,
        type: 'rightAligned'
      },
    ],
  },
  {
    headerName: 'Expenditure Tracking',
    headerClass: 'center-align-header',
    children: [
      { headerName: 'Prior Billing', field: 'col6' },
      {
        headerName: "Total Billing to Date",
        field: "total_completed_to_date",
        aggFunc: sumAggregate,
        editable: false,
        valueParser: "Number(newValue)",
        valueFormatter: params => `${currencyFormat(params.value)}`,
        type: 'rightAligned'
      },
      {
        headerName: "% Complete",
        field: "percent_complete",
        aggFunc: pctCompleteAggregate,
        editable: false,
        valueParser: "Number(newValue)",
        valueFormatter: params => `${percentFormat(params.value)}`,
        type: 'rightAligned'
      },
    ],
  },
];


let leafParentData = ref([]);
let leafParentDataCtr = ref(0)
let pinnedBottomRowData = ref([])

function calculateGrandTotal() {
  // Check if leafRowData is empty or not defined
  if (!leafRowData.value || leafRowData.value.length === 0) return;

  // Initialize the grand total object
  const grandTotal = initializeGrandTotal();

  // Calculate sums and collect values for variance and percent complete
  const { varianceValues, percentCompleteValues } = calculateSums(grandTotal);

  // Calculate the median for variance and percent complete
  const medianVariance = median(varianceValues);
  const medianPercentComplete = median(percentCompleteValues);

  // Update the grand total with median values
  grandTotal["variance"] = medianVariance;
  grandTotal["percent_complete"] = medianPercentComplete;

  // TODO: remove this once we know the values that  causing the median not to get it properly
  grandTotal["variance"] = null;
  grandTotal["percent_complete"] = null;

  // Set the grand total row data
  pinnedBottomRowData = [grandTotal];
}

function initializeGrandTotal() {
  // Initialize an empty object to hold the grand total values
  const grandTotal = {};

  // Iterate over each column definition in the columnDefs array
  columnDefs.forEach((colDef) => {
    // Check if the column definition has children (sub-columns)
    if (colDef.children) {
      // Iterate over each child column definition
      colDef.children.forEach((child) => {
        // Check if the child column definition has a field property
        if (child.field) {
          // Initialize the grand total for this field to null
          // This sets up a placeholder for the total value of this field
          grandTotal[child.field] = null;
        }
      });
    }
  });

  // Return the initialized grand total object
  return grandTotal;
}

function calculateSums(grandTotal) {
  const varianceValues = [];
  const percentCompleteValues = [];

  // Iterate over each row in leafRowData
  leafRowData.value.forEach((row) => {
    columnDefs.forEach((colDef) => {
      if (colDef.children) {
        colDef.children.forEach((child) => {
          if (child.field && !isNaN(parseFloat(row[child.field]))) {
            // Sum the values for each field
            grandTotal[child.field] += parseFloat(row[child.field]);
            // Collect values for variance and percent complete
            if (child.field === "variance") {
              varianceValues.push(parseFloat(row[child.field]));
            }
            if (child.field === "percent_complete") {
              percentCompleteValues.push(parseFloat(row[child.field]));
            }
          }
        });
      }
    });
  });

  return { varianceValues, percentCompleteValues };
}

// API Calls
const apiBase =
  process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/";
const authStore = useAuthStore();
const errorStore = useErrorStore();
let authToken = reactive({});

let isNoDataFetch = ref(false);
let isGridDataLoaded = ref(false);
let isHCSCCostPerGSFDataLoaded = ref(false);

let branchGridData = {}
let leafParentNodesToData = {}

let isDataLoaded = computed(() => {
  return isGridDataLoaded.value && isHCSCCostPerGSFDataLoaded.value
})

// Checking for project information
let projectInformation = ref()

async function fetchProjectInformation() {
  return new Promise((resolve) => {
    axiosQuery(
      `${apiBase}projects/${selectedId.value}`,
      authToken,
      (data) => {
        projectInformation.value = data
        useTypeTableData.value = data.project_building_uses
        resolve(projectInformation.value.id)
      },
      errorStore,
      'Error fetching project_id' + selectedId.value
    );
  })
}

const fullAddress = computed(() => {
  const address1 = projectInformation.value.address_line_1 || '';
  const address2 = projectInformation.value.address_line_2 || '';
  const fullAddress = `${address1} ${address2}`.trim();
  return fullAddress || 'Address not available';
});

function getDisplayValue(projectInfo) {
  return projectInfo ? projectInfo : 'N/A';
}

function totalGsf() {
  return numberFormat(useTypeTableData.value.reduce((acc, row) => acc + (row.total_gsf || 0), 0));
}

function totalUnits() {
  return useTypeTableData.value.reduce((acc, row) => acc + (row.num_units || 0), 0);
}

function bohOtherGSF() { 
  const computedbohOtherGsf = totalGsf() - projectInformation.value.subtotal_parking_gsf;
  return computedbohOtherGsf > 0 ? numberFormat(computedbohOtherGsf) : 'N/A';
}

function totalAffordableComponent() {
  return useTypeTableData.value.reduce((acc, row) => acc + (row.affordable_comp_gsf || 0), 0);
}

function getProjectConstructionContract() {
  const projConstructionTypes = projectInformation.value.project_construction_types_full || [];
  // Find 'ContractType' from construction types objecs
  const contractTypeObject = projConstructionTypes.find(type => type.codeable_type === CODES.CONTRACT_TYPE);
  return contractTypeObject ? contractTypeObject.codeable_id_s : 'N/A';
}

function getFormerUseType() {
  const projConstructionTypes = projectInformation.value?.project_construction_types_full;
  // Find 'ConstructionType' from construction types objecs
  const constructionTypeObject = projConstructionTypes.find(type => type.codeable_type === CODES.CONSTRUCTION_TYPE);
  return constructionTypeObject?.former_asset_category_id_s ? constructionTypeObject.former_asset_category_id_s : 'N/A';
}

function getAllBrandNames() {
  const brandNames = useTypeTableData.value
    .filter(row => row.brand_type_id_s !== null)
    .map(row => row.brand_type_id_s);

  return brandNames.length > 0 ? brandNames.join('; ') : 'N/A';
}

function classifyBuilding(height) {
  if (height >= 0 && height <= 70) {
    return BUILDING_CLASIFICATION.LOW_RISE;
  } else if (height >= 71 && height <= 280) {
    return BUILDING_CLASIFICATION.MID_RISE;
  } else if (height >= 281 && height <= 983) {
    return BUILDING_CLASIFICATION.HIGH_RISE;
  } else if (height >= 984 && height <= 1967) {
    return BUILDING_CLASIFICATION.SUPER_TALL;
  } else if (height >= 1968) {
    return BUILDING_CLASIFICATION.MEGA_TALL;
  } else {
    return 'N/A';
  }
}

const leafRowData = ref([]);

// fetch GridData first
function fetchGridData() {
  if (selectedId.value) {
    axiosQuery(
      `${apiBase}mvw_most_recent_by_projects/leaves_sum_grouped_by_term?project_ids=${selectedId.value}`,
      authToken,
      (data) => {
        if (data.length === 0) {
          isNoDataFetch.value = true
        } else {
          branchGridData = {}
          leafParentNodesToData = {}
          let leafParentDataTemp = []
          data?.forEach((row) => {
            const aRow = {
              dda_term_id: row.dda_term_id,
              hiddenHierarchy: cleanupHierarchyNames(row.itemHierarchy), // TODO Need to retain this hiddenHierarchy?
              itemHierarchy: cleanupHierarchyNames(row.itemHierarchy),
              original_budget: row.original_budget,
              total_adjustments:
                parseFloat(row.current_budget || 0) -
                parseFloat(row.original_budget || 0),
              current_budget: row.current_budget || 0,
              variance: ((row.current_budget || 0) - (row.original_budget || 0)) / (row.original_budget || 1),
              draw_summary: row.current_request,
              total_spent:
                parseFloat(row.previously_funded || 0) +
                parseFloat(row.current_request || 0),
              percent_complete: row.total_completed_to_date_pct_avg,
              previously_funded: row.previously_funded,
              total_completed_to_date: row.total_completed_to_date,
            }
            // Add this row everywhere in the hierarchy
            aRow.hiddenHierarchy.forEach((term, idx) => {
              branchGridData[term] ||= []
              branchGridData[term].push(aRow)
              // Add leaf parent data -- ie the node just above a leaf node (2nd to the bottom)
              if (aRow.hiddenHierarchy.length - 2 === idx) {
                leafParentNodesToData[aRow.hiddenHierarchy[idx]] ||= []
                leafParentNodesToData[aRow.hiddenHierarchy[idx]].push(aRow)
              }
            })

            leafRowData.value.push(aRow);
          })

          // Do the summation and other aggregate math on the leaf parent nodes as AG Grid will not call this automatically
          Object.keys(leafParentNodesToData).forEach(leafParentTerm => {
            // Remove the leaf node as we don't show these.
            // Ensure we clone the objects so we don't destroy the hierarchy for other queries
            let aRow = { ...leafParentNodesToData[leafParentTerm][0] }
            aRow.hiddenHierarchy = [...aRow.hiddenHierarchy]
            aRow.hiddenHierarchy.pop()
            aRow.itemHierarchy = [...aRow.itemHierarchy]
            aRow.itemHierarchy.pop()
            // Calculate subtotals at the non-leaf node level
            sumAggregateCols.forEach(colId => {
              aRow[colId] = sumAggregate({
                rowNode: { key: leafParentTerm },
                column: { colId: colId }
              })
            })
            aRow.variance = varianceAggregate({
              rowNode: { key: leafParentTerm },
              column: { colId: 'variance' }
            })
            aRow.percent_complete = pctCompleteAggregate({
              rowNode: { key: leafParentTerm },
              column: { colId: 'percent_complete' }
            })

            leafParentDataTemp.push(aRow)
          })
          leafParentData.value = leafParentDataTemp
          leafParentDataCtr.value++
          isNoDataFetch.value = false
          calculateGrandTotal();
        }
      },
      errorStore
    );
  } else {
    isNoDataFetch.value = true
  }
  isGridDataLoaded.value = true
}

// Calculate HC/SC Avg Summary Costs Per GSF
let hcAvgOriginalCostPerGSF = ref(0)
let hcAvgRevisedCostPerGSF = ref(0)
let scAvgOriginalCostPerGSF = ref(0)
let scAvgRevisedCostPerGSF = ref(0)
function calculateHCSCAvgSummaryCostsPerGSF() {
  isHCSCCostPerGSFDataLoaded.value = false
  let HCTotalOriginalCostPerGSF = 0
  let HCTotalRevisedCostPerGSF = 0
  let SCTotalOriginalCostPerGSF = 0
  let SCTotalRevisedCostPerGSF = 0

  if (selectedId.value) {
    axiosQuery(
      `${apiBase}mvw_most_recent_by_projects/fetch_hc_sc_costs_per_project?project_ids=${selectedId.value}`,
      authToken,
      (data) => {
        const validIds = selectedId.value
        let hardCostsData = data.hard
        let softCostsData = data.soft

        let projectGSF = parseFloat(projectInformation.value.total_gsf || 0)
        HCTotalOriginalCostPerGSF += parseFloat(hardCostsData[selectedId.value]['total_original'] || 0) / (projectGSF || 1)
        HCTotalRevisedCostPerGSF += parseFloat(hardCostsData[selectedId.value]['total_revised'] || 0) / (projectGSF || 1)
        SCTotalOriginalCostPerGSF += parseFloat(softCostsData[selectedId.value]['total_original'] || 0) / (projectGSF || 1)
        SCTotalRevisedCostPerGSF += parseFloat(softCostsData[selectedId.value]['total_revised'] || 0) / (projectGSF || 1)

        hcAvgOriginalCostPerGSF.value = HCTotalOriginalCostPerGSF / validIds.length
        hcAvgRevisedCostPerGSF.value = HCTotalRevisedCostPerGSF / validIds.length
        scAvgOriginalCostPerGSF.value = SCTotalOriginalCostPerGSF / validIds.length
        scAvgRevisedCostPerGSF.value = SCTotalRevisedCostPerGSF / validIds.length

      },
      errorStore
    );
  }
  isHCSCCostPerGSFDataLoaded.value = true
}

let hcOriginalBudgetArr = ref([]);
let hcRevisedBudgetArr = ref([]);
let hcBudgetVarianceArr = ref([]);
let hcBudgetIncPercArr = ref([]);

let scOriginalBudgetArr = ref([]);
let scRevisedBudgetArr = ref([]);
let scBudgetVarianceArr = ref([]);
let scBudgetIncPercArr = ref([]);

let hcTotalOriginalBudget = ref(0);
let hcTotalRevisedBudget = ref(0);
let scTotalOriginalBudget = ref(0);
let scTotalRevisedBudget = ref(0);
let miscFinancingTotalOriginalBudget = ref(0);
let miscFinancingTotalRevisedBudget = ref(0);
let lcTotalOriginalBudget = ref(0);
let lcTotalRevisedBudget = ref(0);
let contingenciesTotalOriginalBudget = ref(0);
let contingenciesTotalRevisedBudget = ref(0);

let lcTotalVariance = ref(0);
let hcTotalVariance = ref(0);
let scTotalVariance = ref(0);
let miscFinancingTotalVariance = ref(0);
let contingenciesTotalVariance = ref(0);
let lcVariancePercent = ref(0);
let hcVariancePercent = ref(0);
let scVariancePercent = ref(0);
let miscFinancingVariancePercent = ref(0);
let contingenciesVariancePercent = ref(0);

function setChartData(event) {
  let chartData = event;

  // These group ids are constants
  // These should only change if we renamed the parent terms
  const ACQUISITION_GROUP_ID = "row-group-0-ACQUISITION COSTS"
  const HC_GROUP_ID = "row-group-0-HARD COSTS"
  const SC_GROUP_ID = "row-group-0-SOFT COSTS"
  const FINANCING_GROUP_ID = "row-group-0-FINANCING COSTS"
  const CONTINGENCIES_GROUP_ID = "row-group-0-CONTINGENCIES-1-Owner Contingencies"

  // build doughnut data
  let acquisitionGroupData = chartData.find(row => row.id === ACQUISITION_GROUP_ID)
  let hcGroupData = chartData.find(row => row.id === HC_GROUP_ID)
  let scGroupData = chartData.find(row => row.id === SC_GROUP_ID)
  let financingGroupData = chartData.find(row => row.id === FINANCING_GROUP_ID)
  let contingenciesGroupData = chartData.find(row => row.id === CONTINGENCIES_GROUP_ID)

  // TODO : Will the aggregated data ever be NaN / Null
  lcTotalRevisedBudget.value = acquisitionGroupData ? acquisitionGroupData['aggData']['current_budget'] : 0
  hcTotalRevisedBudget.value = hcGroupData ? hcGroupData['aggData']['current_budget'] : 0
  scTotalRevisedBudget.value = scGroupData ? scGroupData['aggData']['current_budget'] : 0
  miscFinancingTotalRevisedBudget.value = financingGroupData ? financingGroupData['aggData']['current_budget'] : 0
  contingenciesTotalRevisedBudget.value = contingenciesGroupData ? contingenciesGroupData['aggData']['current_budget'] : 0

  lcTotalOriginalBudget.value = acquisitionGroupData ? acquisitionGroupData['aggData']['original_budget'] : 0
  hcTotalOriginalBudget.value = hcGroupData ? hcGroupData['aggData']['original_budget'] : 0
  scTotalOriginalBudget.value = scGroupData ? scGroupData['aggData']['original_budget'] : 0
  miscFinancingTotalOriginalBudget.value = financingGroupData ? financingGroupData['aggData']['original_budget'] : 0
  contingenciesTotalOriginalBudget.value = contingenciesGroupData ? contingenciesGroupData['aggData']['original_budget'] : 0

  lcTotalVariance.value = acquisitionGroupData ? acquisitionGroupData['aggData']['total_adjustment'] : 0
  hcTotalVariance.value = hcGroupData ? hcGroupData['aggData']['total_adjustments'] : 0
  scTotalVariance.value = scGroupData ? scGroupData['aggData']['total_adjustments'] : 0
  miscFinancingTotalVariance.value = financingGroupData ? financingGroupData['aggData']['total_adjustments'] : 0
  contingenciesTotalVariance.value = contingenciesGroupData ? contingenciesGroupData['aggData']['total_adjustments'] : 0

  lcVariancePercent.value = acquisitionGroupData ? acquisitionGroupData['aggData']['variance'] : 0
  hcVariancePercent.value = hcGroupData ? hcGroupData['aggData']['variance'] : 0
  scVariancePercent.value = scGroupData ? scGroupData['aggData']['variance'] : 0
  miscFinancingVariancePercent.value = financingGroupData ? financingGroupData['aggData']['variance'] : 0
  contingenciesVariancePercent.value = contingenciesGroupData ? contingenciesGroupData['aggData']['variance'] : 0

  doughnutChartData.value = [
    lcTotalRevisedBudget.value,
    hcTotalRevisedBudget.value,
    scTotalRevisedBudget.value,
    miscFinancingTotalRevisedBudget.value,
    contingenciesTotalRevisedBudget.value
  ]

  const doughnutChartDataArray = [
    lcTotalRevisedBudget.value,
    hcTotalRevisedBudget.value,
    scTotalRevisedBudget.value,
    miscFinancingTotalRevisedBudget.value,
    contingenciesTotalRevisedBudget.value
  ]

  // Loop through the dougnutChartData
  const totalSumOfDoughnut = doughnutChartDataArray.reduce((a, b) => { return a + b; }).toFixed(0)
  doughnutChartPercentage.value = []
  doughnutChartDataArray.forEach(val => {
    const percentage = val * 100 / totalSumOfDoughnut;
    const roundedPercentage = Math.round(percentage * 100) / 100
    doughnutChartPercentage.value.push(`${roundedPercentage.toFixed(0)}%`);
  });

  // Get values for costs to date
  cdTotalCurrentBudget.value = (lcTotalRevisedBudget.value || 0) + (hcTotalRevisedBudget.value || 0) + (scTotalRevisedBudget.value || 0) + (miscFinancingTotalRevisedBudget.value || 0) + (contingenciesTotalRevisedBudget.value || 0)
  cdTotalOriginalBudget.value = (lcTotalOriginalBudget.value || 0) + (hcTotalOriginalBudget.value || 0) + (scTotalOriginalBudget.value || 0) + (miscFinancingTotalOriginalBudget.value || 0) + (contingenciesTotalOriginalBudget.value || 0)
  cdTotalBilledToDate.value = (acquisitionGroupData && acquisitionGroupData['aggData'] ? acquisitionGroupData['aggData']['total_completed_to_date'] : 0) +
    (hcGroupData && hcGroupData['aggData'] ? hcGroupData['aggData']['total_completed_to_date'] : 0) +
    (scGroupData && scGroupData['aggData'] ? scGroupData['aggData']['total_completed_to_date'] : 0) +
    (financingGroupData && financingGroupData['aggData'] ? financingGroupData['aggData']['total_completed_to_date'] : 0) +
    (contingenciesGroupData && contingenciesGroupData['aggData'] ? contingenciesGroupData['aggData']['total_completed_to_date'] : 0);

  // Create a copy of the leaf children
  const hcGroupDataLeafChildren = hcGroupData.allLeafChildren;
  const scGroupDataLeafChildren = scGroupData.allLeafChildren;

  // Sort the values base on total-adjustment to get the top offenders in decreasing order
  const sortedHCGroupData = hcGroupDataLeafChildren.sort((a, b) => b.data.total_adjustments - a.data.total_adjustments);
  const sortedSCGroupData = scGroupDataLeafChildren.sort((a, b) => b.data.total_adjustments - a.data.total_adjustments);

  // Get the top 5 but remove the ones if they include a key that should not be there
  const top5DataHC = [];
  const top5DataSC = [];

  // Loop through the sorted array for hard cost
  for (const obj of sortedHCGroupData) {
    // Check if the key property contains the string "Trade"
    if (!obj.key.toLowerCase().includes("trade")) {
      // If not, push the object to the top5DataHC array
      top5DataHC.push(obj);
    }

    // If top5DataHC array has 5 elements, break the loop
    if (top5DataHC.length === 5) {
      break;
    }
  }

  // Loop through the sorted array for soft cost
  for (const obj of sortedSCGroupData) {
    // Check if the key property contains the string "Carrying" and "Land Lease"
    if (!obj.key.toLowerCase().includes("carrying") && !obj.key.toLowerCase().includes("land lease")) {
      // If not, push the object to the top5DataSC array
      top5DataSC.push(obj);
    }

    // If top5DataSC array has 5 elements, break the loop
    if (top5DataSC.length === 5) {
      break;
    }
  }

  // HCTopOffedners
  // build the chart data
  hcStackedBarLabels.value = [];
  hcOriginalBudgetArr.value = [];
  hcRevisedBudgetArr.value = [];
  hcBudgetVarianceArr.value = [];
  hcBudgetIncPercArr.value = [];
  top5DataHC.forEach((row) => {
    hcStackedBarLabels.value.push(row.key);
    hcOriginalBudgetArr.value.push(row.data.original_budget);
    hcRevisedBudgetArr.value.push(row.data.current_budget);
    hcBudgetVarianceArr.value.push(
      row.data.current_budget - row.data.original_budget
    );
    hcBudgetIncPercArr.value.push(row.data.total_adjustments);
  });

  // SCTopOffenders
  // build the chart data
  scStackedBarLabels.value = [];
  scOriginalBudgetArr.value = [];
  scRevisedBudgetArr.value = [];
  scBudgetVarianceArr.value = [];
  scBudgetIncPercArr.value = [];
  top5DataSC.forEach((row) => {
    scStackedBarLabels.value.push(row.key);
    scOriginalBudgetArr.value.push(row.data.original_budget);
    scRevisedBudgetArr.value.push(row.data.current_budget);
    scBudgetVarianceArr.value.push(
      row.data.current_budget - row.data.original_budget
    );
    scBudgetIncPercArr.value.push(row.data.total_adjustments);
  });
}

// Save the sum aggregates so they can be used to calculate variance
let sumAggregates = {}
function sumAggregate(params) {
  // Go through the full sum of each child item, then divide by # of projects
  let otherTotal = 0.0;
  (branchGridData[params.rowNode.key] || []).forEach(value => otherTotal += parseFloat(value[params.column.colId] || 0.0))
  // As opposed to the Budget Page, we do *not* divide by the number of projects in the compSet, because this is a single item

  sumAggregates[params.column.colId] ||= {}
  sumAggregates[params.column.colId][params.rowNode.key] = otherTotal

  return otherTotal
}

// Uses the sum aggregates so they can be used to calculate variance
// NOTE: this only works because the sum aggregate columns are calculated first!
function varianceAggregate(params) {
  const originalBudget = sumAggregates['original_budget'][params.rowNode.key]
  const currentBudget = sumAggregates['current_budget'][params.rowNode.key]

  return ((currentBudget || 0.0) - (originalBudget || 0.0)) / (parseFloat(originalBudget) || 1.0)
}

// Uses the sum aggregates so they can be used to calculate % complete
// NOTE: this only works because the sum aggregate columns are calculated first!
function pctCompleteAggregate(params) {
  console.log('sum aggregates', sumAggregates)
  const currentBudget = sumAggregates['current_budget'][params.rowNode.key]
  const totalCompletedToDate = sumAggregates['total_completed_to_date'][params.rowNode.key]

  return (totalCompletedToDate || 0.0) / (parseFloat(currentBudget) || 1.0)
}

let isSidebarOpen = ref(true)
function toggleSidebar() {
  isSidebarOpen.value = !isSidebarOpen.value
}

let largeSidebar = ref(false)
function onResizeSidebar() {
  largeSidebar.value = !largeSidebar.value
}

const selectedId = ref(null);
const route = useRoute();

const propertyImageContainer = ref()
const hasPropertyImage = ref(null)
async function fetchImage() {
  return new Promise((resolve) => {
    axiosQuery(
        `${apiBase}projects/${selectedId.value}/images`,
        authToken,
        (data) => {
          if (data && !data.error_message) {
            const reader = new FileReader()
            reader.onload = () => {
              const img = document.createElement('img')
              // NOTE that png and webp work with this spec as well
              img.src = `data:image/jpg;base64,` + data
              img.style = "width: 66%; height: auto;"
              propertyImageContainer.value.children[0].appendChild(img)

              hasPropertyImage.value = true
              resolve(selectedId.value)
            }
            reader.readAsDataURL(new Blob([data], {type: `data:image/jpg;base64`}))
          } else {
            hasPropertyImage.value = false
            resolve(selectedId.value)
          }
          resolve(selectedId.value)
        },
        errorStore,
        'Error fetching project_id image' + selectedId.value
    );
  })
}

const hasLoaded = ref(false)
onMounted(async () => {
  authToken.value = authStore.getAuthTokenHeader;
  selectedId.value = route.params.selectedId;
  await fetchProjectInformation();
  await fetchGridData();
  await calculateHCSCAvgSummaryCostsPerGSF();
  await fetchImage()
  // Give images a little time to load
  setTimeout(() => {
    hasLoaded.value = true
  }, 500)
});

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.individual-page-container {
  display: flex;
  margin-top: 53px;
  font-family: 'Inter';
  height: 100vh;

  .project-name {
    font-size: 18px;
    color: $navyBlue;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.breadcrumbs {
  font-size: 14px;
  color: #8C8D92;
}

.project-details-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.project-details {
  overflow-y: auto;
  overflow-x: hidden;
  height: 50%;
  padding-bottom: 32rem;
  padding-right: 0.75rem;
}

.project-details::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: $white1;
}

.project-details::-webkit-scrollbar-thumb {
  background: $lightGray1;
}

.individual-page-sidebar {
  position: fixed;
  display: flex;
  height: 100vh;
  top: 50px;
  left: 0;
  background: $white1;
  box-shadow: 6px 0 18px rgba(0, 0, 0, 0.06);
  background-color: $white1;
  padding: 20px 20px 20px 30px;
  // font-weight: bold;
}

.individual-page-sidebar.open {
  width: 400px;
  transition: width 0.5s ease;
}

.individual-page-sidebar.closed {
  width: 20px;
  padding: 0;
  transition: width 0.5s ease;
}

.hidden {
  display: none;
}

.individal-page-content {
  padding: 25px 30px;
  width: 100%;
}

.individal-page-content.small {
  margin-left: 10px;
}

.individal-page-content.large {
  margin-left: 430px;
}

.individual-page-title {
  font-size: 24px;
  font-weight: bold;
  color: $navyBlue;
  padding: 20px 0;
}

.project-img {
  margin: 10px 0px 10px 0px;
  border: .5px solid $lightGray1;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  width: 399px;
  height: 350px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.project-img-summary {
  padding-bottom: 15px;
  border-bottom: 2px solid $lightGray1;

  .project-img-summary-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3px;
    font-size: 14px;

    span {
      color: $navyBlue;
      text-align: right;
    }
  }
}

.tile {
  background-color: $white1;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 8px;
  display: inline-block;
  width: calc(33.333% - 20px);
  box-sizing: border-box;
  padding: 20px;
}

// 3 Dotted Menu Style - Start
.menu-container {
  position: relative;
  display: inline-block;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.menu-content {
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-content ul li {
  padding: 12px 16px;
  cursor: pointer;
}

.menu-content ul li:hover {
  background-color: #f1f1f1;
}

// 3 Dotted Menu Style - End

// Sidebar Menu Style - Start
.sidebar-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $mediumGray1;
  font-size: 12px;
  font-weight: lighter;

  div {
    cursor: pointer;
    padding: 5px 5px 5px 0px;
    margin-right: 10px;
  }
}

.sidebar-menu-selected {
  font-weight: bold;
  border-bottom: 3px solid $brightBlue1;
  color: $brightBlue1;
}

// Sidebar Menu Style - End

// Accordion Panel Style - Start
.accordion-panel-header {
  display: flex;
  align-items: center;
  position: relative;
  width: 97%;
  height: 27px;
  margin-top: 5px;
  margin-bottom: 10px;

  label {
    font-size: 16px;
  }
}

// Accordion Panel Style - End

// Custom Select Style - Start
.custom-select {
  position: relative;
  font-family: inherit;
  display: flex;
  align-items: center;
  font-size: 12px;

  select {
    border: 0;
    color: $brightBlue1;
  }

  select:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }
}

// Custom Select Style - End

// Building Details Style - Start
.building-details-container {
  padding: 15px 0;
  border-bottom: 2px solid $lightGray1;
}

.building-details-table {
  width: 100%;
  text-align: right;
  color: $navyBlue;
  font-size: 14px;

  .left-align {
    text-align: left;
  }

  .use-types {
    padding-left: 10px;
    color: #000;

  }

  .total {
    padding: 0;
    color: #000;
  }
}

.building-details-summary {
  padding: 10px 0;

  .building-details-summary-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3px;
    font-size: 14px;

    span {
      color: $navyBlue;
    }
  }
}

.amenities-container {
  display: flex;
  flex-direction: column;
}

// Building Details Style - End

// Construction Details Style - Start
.construction-details-container {
  padding: 15px 0;
  border-bottom: 2px solid $lightGray1;
}

.construction-details-summary {
  padding: 10px 0;

  .construction-details-summary-item {
    display: grid;
    grid-template-columns: 55% 45%;
    justify-content: space-between;
    padding-bottom: 3px;
    font-size: 14px;

    span {
      color: $navyBlue;
      text-align: right;
    }
  }
}

.construction-details-summary-item-header {
  padding-bottom: 20px !important;
}

.construction-type-container {
  padding-top: 20px;
}

// Construction Details Style - End

// Individual Page Menu Style - Start
.individual-page-sub-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $mediumGray1;
  font-size: 16px;
  font-weight: lighter;

  div {
    cursor: pointer;
    padding: 10px 0px;
    margin-right: 50px;
  }
}

.individual-page-sub-menu-selected {
  font-weight: bold;
  border-bottom: 3px solid $brightBlue1;
  color: $brightBlue1;
}

// Indvidual Page Menu Style - End

// Budget Summary Style - Start
.budget-summary-header-container {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .budget-summary-header-title {
    font-size: 22px;
    font-weight: bolder;
    color: $navyBlue;
    margin-right: 20px;
  }
}

.budget-summary-content {
  display: flex;
  flex-direction: row;
}

.chart-main-title {
  font-size: 18px;
  font-weight: bolder;
}

.chart-title {
  margin: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .hc-legend {
    display: flex;
    justify-content: flex-start;
    align-self: center;

    .hc-budget-legend {
      border-left: 5px solid $brightBlue1;
      padding-left: 5px;
      margin-left: 5px;
    }

    .hc-actual-legend {
      border-left: 5px solid $lightBlue1;
      padding-left: 5px;
      margin-left: 5px;
    }
  }

  .sc-legend {
    display: flex;
    justify-content: flex-start;
    align-self: center;

    .sc-budget-legend {
      border-left: 5px solid $mediumBlue1;
      padding-left: 5px;
      margin-left: 5px;
    }

    .sc-actual-legend {
      border-left: 5px solid #84C9DE;
      padding-left: 5px;
      margin-left: 5px;
    }
  }
}

// Budget Summary Style - End

// Doughnut Chart Styles -- Start
.doughnut-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  // Doughnut Legend
  .doughnut-chart-legend {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5%;

    .acquisition-costs-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: $darkGray1
      }

      .vertical-divider {
        border-left: 5px solid $darkGray1;
        margin: 0 10px;
      }
    }

    .hard-costs-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: $brightBlue1;
      }

      .vertical-divider {
        border-left: 5px solid $brightBlue1;
        margin: 0 10px;
      }
    }

    .soft-costs-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: $mediumBlue1;
      }

      .vertical-divider {
        border-left: 5px solid $mediumBlue1;
        margin: 0 10px;
      }
    }

    .financing-and-misc-costs-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: $mediumGray1;
      }

      .vertical-divider {
        border-left: 5px solid $mediumGray1;
        margin: 0 10px;
      }
    }

    .contingencies-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: $mediumGray1;
      }

      .vertical-divider {
        border-left: 5px solid #d3d3d3;
        margin: 0 10px;
      }
    }
  }
}

.doughnut-summary-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Creates 2 columns */
  gap: 10px;
  /* Optional: Adds space between grid items */

  .doughnut-summary-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
}

// Doughnut Chart Styles -- End

// Total Project Costs Style - Start
.total-project-costs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

// Total Project Costs Style - End

// Cost to Date Style - Start
.cost-to-date-bar-chart-container {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cost-to-date-legend {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  font-size: 14px;

  .cost-to-date-current-budget-legend {
    div:nth-child(1),
    div:nth-child(3) {
      color: #ABB3EB;
    }
  }

  .cost-to-date-original-budget-legend {
    div:nth-child(1),
    div:nth-child(3) {
      color: #8A92A9;
    }
  }

  .cost-to-date-billed-to-date-legend {
    div:nth-child(1),
    div:nth-child(3) {
      color: $brightBlue1;
    }
  }
}

// Cost to Date Style - End

// Budget Variance Summary Style - Start
.budget-variance-summary {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  div:first-child {
    padding-right: 1.25rem;
  }
}

.budget-variance-charts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.summary-stacked-bar {
  padding-right: 1rem;
}

// Budget Variance Summary Style - End


// Budget Tracking Style - Start
.budget-tracking-header-container {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .budget-tracking-header-title {
    font-size: 22px;
    font-weight: bolder;
    color: $navyBlue;
    margin-right: 20px;
  }
}

.budget-tracking-subtitle {
  font-size: 14px;
  margin: 0px 10px;
  font-weight: bold;
}


// Budget Tracking Style - End

// Toogle Switch - Start
.toggle-container {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2.5px;
  left: 3px;
  transition: transform 0.3s ease;
}

.move-right {
  transform: translateX(18px);
}

.toggle-container:hover {
  background-color: #bbb;
}

/* Add a new class for the original-format state */
.toggle-container.original-format {
  background-color: #4A5FD5;
}

// Toggle Switch - End


// AG Grid Styles - Start
.disabled-header {
  color: #999 !important;
  background-color: #f0f0f0 !important;
  cursor: not-allowed;
  pointer-events: none;
}

// AG Grid Styles - End

// Map Styles - Start
.map-container {
  width: 100%;
  display: block;
  margin: 10px 0px 10px 0px;

  #individualPageMap {
    height: 350px;
  }
}

// Map Styles - End

.totals {
  font-weight: bold;
  color: $navyBlue;
}
</style>