<template>
  <div class="new-project-view-container">
    <div class="new-project-header-container">
      <div class="breadcrumb-container">New Project&nbsp;|&nbsp; <strong> Project Information</strong></div>
      <div class="new-project-main-title">New Project</div>
      <div>Tell us about the project, upload documents, and let us do the rest.</div>
      <div>Upload more documents for an existing project instead? <router-link to="/upload-documents">Upload Documents Here</router-link></div>
    </div>

    <!-- Project Information -->
    <div>
      <div class="divider">
        <div class="accordion-panel-header">
          <label for="projectInformation"><strong>Project Information</strong></label>
          <ExpandCollapse name="projectInformation" :is-open-initially="isProjectInformationOpen"
            :is-vertical-center="false" open-state="down" closed-state="up" :show-double-chevron="false"
            :show-circle="false" :chevron-fill="'#4A5FD5'"
            @toggle-expand-collapse="expandCollapseController('projectInformation');" />
        </div>
      </div>
      <div v-if="isProjectInformationOpen">
        <ProjectInformation ref="projectInformationRef"></ProjectInformation>
      </div>
    </div>

    <!-- Team Information -->
    <div>
      <div class="divider">
        <div class="accordion-panel-header">
          <label for="teamInformation"><strong>Team Information</strong></label>
          <ExpandCollapse name="teamInformation" :is-open-initially="isTeamInformationOpen" :is-vertical-center="false"
            open-state="down" closed-state="up" :show-double-chevron="false" :show-circle="false"
            :chevron-fill="'#4A5FD5'" @toggle-expand-collapse="expandCollapseController('teamInformation');" />
        </div>
      </div>
      <div v-if="isTeamInformationOpen">
        <ProjectTeamInformation ref="projectTeamInformationRef"></ProjectTeamInformation>
      </div>
    </div>

    <!-- Upload Documents -->
    <div>
      <div class="divider">
        <div class="accordion-panel-header">
          <label for="uploadDocuments"><strong>Upload Documents</strong></label>
          <ExpandCollapse name="uploadDocuments" :is-open-initially="isUploadDocumentsOpen" :is-vertical-center="false"
            open-state="down" closed-state="up" :show-double-chevron="false" :show-circle="false"
            :chevron-fill="'#4A5FD5'" @toggle-expand-collapse="expandCollapseController('uploadDocuments');" />
        </div>
      </div>
      <div v-if="isUploadDocumentsOpen">
        <ProjectDocuments ref="projectDocuments"></ProjectDocuments>
      </div>
    </div>

    <div class="project-action-button">
      <button @click="createNewProject">Complete Project</button>
    </div>

    <error-message class="error-message" />
    <success-message class="success-message" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import ExpandCollapse from "@/components/helpers/ExpandCollapse.vue";
import ProjectInformation from "@/components/add-project/ProjectInformation.vue";
import ProjectTeamInformation from "@/components/add-project/ProjectTeamInformation.vue";
import ProjectDocuments from "@/components/add-project/ProjectDocuments.vue";
import { useErrorStore } from "@/stores/error_store";
import { useSuccessStore } from "@/stores/success_store";
import { axiosPostQuery } from "@/assets/js/utils";
import ErrorMessage from "@/components/helpers/ErrorMessage.vue"
import SuccessMessage from "@/components/helpers/SuccessMessage.vue"

// API settings
const apiBase = process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/"
const apiKey = process.env.VUE_APP_ENV_API_KEY // Uncomment once upload can be done
let authToken = reactive({});
const errorStore = useErrorStore();
const successStore = useSuccessStore();
const isProjectInformationOpen = ref(true);
const isTeamInformationOpen = ref(false);
const isUploadDocumentsOpen = ref(false);

const expandCollapseController = (element) => {
  switch (element) {
    case 'projectInformation':
      isProjectInformationOpen.value = !isProjectInformationOpen.value
      break;
    case 'teamInformation':
      isTeamInformationOpen.value = !isTeamInformationOpen.value
      break;
    case 'uploadDocuments':
      isUploadDocumentsOpen.value = !isUploadDocumentsOpen.value
      break;
    default:
      break;
  }
}

const projectInformationRef = ref(null);
const projectTeamInformationRef = ref(null);
const projectDocuments = ref(null);

function createNewProject() {
  const projectInformationFormData = projectInformationRef.value.form;
  const projectTeamInformationFormData = projectTeamInformationRef.value.form; 
  const projectBaselineDocumentsFormData = projectDocuments.value.baselineDocuments;
  const projectDrawDocumentsFormData = projectDocuments.value.drawDocuments;

  // Project Information
  const projectValidData = {
    project: {
      general_info: {
        image_url: null,
        name: projectInformationFormData.buildingName,
        status: projectInformationFormData.constructionStatus,
        entity_name: projectInformationFormData.buildingName, // Keep it as it is for now
        building_name: projectInformationFormData.buildingName, // Keep it as it is for now
        address_line_1: projectInformationFormData.streetAddress,
        address_line_2: null, // keep it null for now
        city: projectInformationFormData.city,
        state: projectInformationFormData.state,
        zipcode: projectInformationFormData.zip,
        finish_level: null, // keep it null for now
        notes: projectInformationFormData.notes,
        total_gsf: Number(projectInformationFormData.totalSquareFeetPerUse),
        total_num_units: Number(projectInformationFormData.totalNumberOfUnits),
        subtotal_building_gsf: projectInformationFormData.subTotalBuildingGSF,
        subtotal_num_units: projectInformationFormData.subTotalBuildingNumUnits,
        subtotal_parking_gsf: projectInformationFormData.subTotalParkingGSF,
        subtotal_num_spaces: projectInformationFormData.subTotalParkingNumSpaces,
      },
      construction_info: {
        construction_types: buildNewConstructionTypes(projectInformationFormData.constructionTypes, projectInformationFormData.constructionTypeAdaptiveReuse),
        construction_contract_type_id: projectInformationFormData.constructionContractType,
        num_floors: projectInformationFormData.buildingHeightStories,
        total_height: projectInformationFormData.buildingHeightFeet,
        is_historic_has_landmark: projectInformationFormData.historicLandmarksComponent === 'true',
        sustainability_type_id: projectInformationFormData.sustainabilityType,
        is_modular: projectInformationFormData.modularBuild === 'true',
      },
      building_use_info: {
        new_uses: buildNewUsesWithSubCategories(projectInformationFormData),
        parking_types: buildNewParkingTypes(projectInformationFormData)
      },
      amenities_info: {
        amenities: projectInformationFormData.amenities
      },
      team_info: {
        investor_sponsor_info: {
          investors: buildInvestors(projectTeamInformationFormData)
        },
        consultant_info: {
          consultants: buildConsultants(projectTeamInformationFormData)
       },
      }
    }
  }

  axiosPostQuery(
    `${apiBase}/projects`,
    projectValidData,
    authToken,
    (data) => {
      uploadedBaselineDocument(data.id, projectBaselineDocumentsFormData);
      uploadedDrawDocument(data.id, projectDrawDocumentsFormData);
      successStore.setSuccess('Project Creation', 'The project has been created successfully.', 'success', false).then(() => {
        // After the promise resolves, check clearedStatus
        if (successStore.clearedStatus === 'confirm') {
          window.location.reload();
        }
      });
    },
    () => {
      errorStore.setError('Project Creation', 'An error occurred. The project creation was unsuccessful.', 'error', false)
    } 
  );
}

// Function to build a structured list of new uses with their subcategories from a form
function buildNewUsesWithSubCategories(form) {
  // Retrieve updated use categories from a reference object
  const newUseCategoriesWithSubCategories = projectInformationRef.value.updatedUseCategories;
  // Initialize an empty array to hold the result
  const result = [];

  // Helper function to add a category or subcategory to the result array
  const addCategoryResult = (category, subCategory = null) => {
    // Base information structure for each category/subcategory
    const baseInfo = {
      asset_category_id: parseInt(category.id), // Convert category ID to integer
      sub_category_id: subCategory?.id ? parseInt(subCategory.id) : null, // Convert subcategory ID to integer, if present
      total_gsf: 0, // Default gross square footage
      num_units: 0, // Default number of units
      brand_type_id: null, // Default brand type ID
      affordable_comp_gsf: null, // Default affordable component GSF
      affordable_comp_num_units: null // Default affordable component number of units
    };

    // If a subcategory is provided, update the base information with specific values
    if (subCategory) {
      // Check if a brand type ID exists for the subcategory
      if (form.subCategoriesBrandType[subCategory.id]?.id) {
        // Update base information with subcategory-specific values, excluding brand name
        Object.assign(baseInfo, {
          total_gsf: form.subCategoriesSquareFeetPerUse[subCategory.id] || 0,
          num_units: form.subCategoriesNumberOfUnits[subCategory.id] || 0,
          brand_type_id: form.subCategoriesBrandType[subCategory.id]?.id || null,
          affordable_comp_gsf: form.subCategoriesAffordableCompGSF[subCategory.id] || null,
          affordable_comp_num_units: form.subCategoriesAffordableCompNumUnits[subCategory.id] || null
        });
      } else {
        // Update base information with subcategory-specific values, including brand name
        Object.assign(baseInfo, {
          total_gsf: form.subCategoriesSquareFeetPerUse[subCategory.id] || 0,
          num_units: form.subCategoriesNumberOfUnits[subCategory.id] || 0,
          brand_type_id: form.subCategoriesBrandType[subCategory.id]?.id || null,
          brand_name: form.subCategoriesBrandType[subCategory.id]?.code || null,
          affordable_comp_gsf: form.subCategoriesAffordableCompGSF[subCategory.id] || null,
          affordable_comp_num_units: form.subCategoriesAffordableCompNumUnits[subCategory.id] || null
        });
      }
    } else {
      // If no subcategory is provided, update the base information with category-specific values
      Object.assign(baseInfo, {
        total_gsf: form.newUseCategoriesSquareFeetPerUse[category.id] || 0,
        num_units: form.newUseCategoriesNumberOfUnits[category.id] || 0
      });
    }

    // Add the updated base information to the result array
    result.push(baseInfo);
  };

  // Iterate over each category ID and its enabled status from the form
  Object.entries(form.newUseCategoryIds).forEach(([categoryId, isEnabled]) => {
    // Skip if the category is not enabled
    if (!isEnabled) return;

    // Find the category object by its ID
    const category = newUseCategoriesWithSubCategories.find(cat => cat.id.toString() === categoryId);
    // Skip if the category was not found
    if (!category) return;

    // Check if the category has subcategories
    if (category.subCategories && category.subCategories.length > 0) {
      // Iterate over each subcategory ID and its enabled status
      Object.entries(form.subCategoryIds).forEach(([subCategoryId, isSubEnabled]) => {
        // Skip if the subcategory is not enabled
        if (!isSubEnabled) return;

        // Find the subcategory object by its ID within the current category
        const subCategory = category.subCategories.find(sub => sub.id.toString() === subCategoryId);
        // Skip if the subcategory was not found
        if (!subCategory) return;

        // Add the category and subcategory to the result
        addCategoryResult(category, subCategory);
      });
    } else {
      // If there are no subcategories, add just the category to the result
      addCategoryResult(category);
    }
  });

  // Return the structured list of new uses with their subcategories
  return result;
}

// Function to build an array of parking types objects based on form input
function buildNewParkingTypes(form) {
  // Initialize an empty array to hold the result
  const result = [];

  // Iterate over each parking type ID and its enabled status from the form
  Object.entries(form.newParkingTypeIds).forEach(([parking_type_id, isEnabled]) => {
    // Check if the parking type is enabled
    if (isEnabled) {
      // Retrieve the total gross square footage for the parking type, defaulting to 0 if not specified
      const total_gsf = form.parkingTypesSquareFeetPerUse[parking_type_id] || 0;
      // Retrieve the number of spaces for the parking type, defaulting to 0 if not specified
      const num_spaces = form.parkingTypesNumberOfSpaces[parking_type_id] || 0;

      // Add a new object to the result array with the parking type ID, total GSF, and number of spaces
      result.push({
        parking_type_id: parseInt(parking_type_id), // Convert the parking type ID to an integer
        total_gsf, // Total gross square footage for this parking type
        num_spaces, // Number of parking spaces for this parking type
      });
    }
  });

  // Return the array of parking types objects
  return result;
}

function buildNewConstructionTypes(constructionTypes, constructionTypeAdaptiveReuse) {
  // Check if constructionTypes is an object and doesn't have any values
  if (typeof constructionTypes !== 'object' || constructionTypes === null || Object.keys(constructionTypes).length === 0) {
    return [];
  }

  const newConstructionTypes = Object.values(constructionTypes).map(constructionType => {
    // Initialize the object with construction_type and former_asset_category_id as null
    let newConstructionType = {
      construction_type_id: constructionType.id,
      former_asset_category_id: null
    };

    // If the constructionType is Adaptive Reuse
    if (constructionType.code == 'Adaptive Reuse') {
      newConstructionType.former_asset_category_id = constructionTypeAdaptiveReuse;
    }

    return newConstructionType;
  });

  return newConstructionTypes;
}

function buildInvestors(form) {
  let investors = []; // Initialize an empty array to hold investor objects

  // Define an array of keys for primary investor fields in the form
  const primaryInvestors = ['primaryLender', 'primaryInvestor', 'primaryDeveloper'];

  // Iterate over primary investor fields and add non-empty objects to the investors array
  primaryInvestors.forEach(investorField => {
    const investor = form[investorField]; // Get the investor object from the form
    if (investor && Object.keys(investor).length > 0) { // Check if the object is not empty
      investors.push(investor); // Add the investor object to the investors array
    }
  });

  // Define an array of keys for fields that may contain multiple investors
  const fields = [
    'additionalLenders', 'additionalInvestors',
    'coDevelopers'
  ];

  // Iterate over fields that may contain multiple investors
  fields.forEach(field => {
    if (form[field] && Array.isArray(form[field])) { // Check if the field exists and is an array
      form[field].forEach(investor => { // Iterate over each investor in the field
        if (Object.keys(investor).length > 0) { // Check if the investor object is not empty
          investors.push(investor); // Add the investor object to the investors array
        }
      });
    }
  });

  return investors; // Return the array of investors
}

// Function to build an array of consultant objects from a form
function buildConsultants(form) {
  // Define an array of consultant field names expected in the form
  const fields = [
    'ownersRepresentative', 'additionalProjectOrConstructionManager',
    'architectOfRecord', 'designArchitect', 'civil', 'structural',
    'mechanical', 'electrical', 'plumbing', 'fireProtection',
    'interiorDesign', 'landscapeArchitect', 'geotechnical', 'environmental'
  ];
  
  // Initialize an empty array to hold consultant objects
  let consultants = [];
  
  // Iterate over each field name defined in the fields array
  fields.forEach(field => {
    // Check if the current field exists in the form
    if (form[field]) {
      // Assuming form[field] is an object structured as { name: ..., consultant_type_id: ... }
      // Directly push the object from the form into the consultants array
      consultants.push(form[field]);
    }
  });
  
  // Return the array of consultant objects
  return consultants;
}

// For Project Upload 
async function uploadedBaselineDocument (projectId, data) {
  // Iterate in each container
  // Developer Budget Files first
  uploadDocuments(projectId, data.localForm.developerBudget.values, 'DeveloperBudget', data.localForm.date, null);
  // General Contractor Budget Files
  uploadDocuments(projectId, data.localForm.generalContractorBudget.values, 'GeneralContractorBudget', data.localForm.date, null);
  // General Contractor Schedule Files
  uploadDocuments(projectId, data.localForm.generalContractorSchedule.values, 'GeneralContractorSchedule', data.localForm.date, null);
  // Executed Construction Contracts
  uploadDocuments(projectId, data.localForm.executedConstructionContracts.values, 'ConstructionContracts', data.localForm.date, null);
}

async function uploadFile(file, document) {
  try {
    let keys = Object.keys(document);
    let formData = new FormData();
    formData.append('document[file]', file);
    keys.forEach((key) => formData.append(`document[${key}]`, document[key]));

    await axiosPostQuery(
      `${apiBase}/documents?api_key=${apiKey}`,
      formData,
      {
        authToken,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      },
      () => {
        console.log(`Document created`)
      },
      () => {
        errorStore.setError('Document Upload', 'An error occurred. The document upload was unsuccessful.', 'error', false)
      }
    );

  } catch (error) {
    console.error('Error:', error);
  }
}

async function uploadedDrawDocument (projectId, data) {
  // Iterate in each container
  // Borrower Requisition Letter
  uploadDocuments(projectId, data.localForm.borrowerRequisitionLetter.values, 'BorrowerRequisitionLetters', data.localForm.periodThroughDate, data.localForm.drawNumber);
  // Developer Draw Tracker
  uploadDocuments(projectId, data.localForm.developerDrawTracker.values, 'SponsorDrawTracker', data.localForm.periodThroughDate, data.localForm.drawNumber);
  // Full Payment Application
  uploadDocuments(projectId, data.localForm.fullPaymentApplication.values, 'FullPaymentApplication', data.localForm.periodThroughDate, data.localForm.drawNumber);
  // Soft Cost Invoices
  uploadDocuments(projectId, data.localForm.softCostInvoices.values, 'SoftCostInvoices', data.localForm.periodThroughDate, data.localForm.drawNumber);
}

async function uploadDocuments (projectId, documentContainerArray, documentContainerName, date, drawNumber) {
  const validDocuments = documentContainerArray?.length ? [...documentContainerArray] : []
  const convertedDate = formattedDate(date);
  const convertedDrawNumber = drawNumber ? '_' + drawNumber : '';
  for (const file of validDocuments) {
    const validFile = getFilenameAndExtension(file.name);
    let document = {
      filepath: validFile[0] + '_' + documentContainerName + '_' + convertedDate + `${convertedDrawNumber}` + validFile[1],
      status: 'in_progress',
      project_id: projectId,
      period_through: convertedDate,
      draw_number: drawNumber
    };

    await uploadFile(file, document);
  }
}

const getFilenameAndExtension = (filename) => {
  const fileNameWithExtension = filename;

  const lastDotIndex = fileNameWithExtension.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    const fileName = fileNameWithExtension.substring(0, lastDotIndex);
    const fileExtension = fileNameWithExtension.substring(lastDotIndex);
    return [fileName, fileExtension]
  } else {
    return null
  }
}

function formattedDate(date) {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(dateObj.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
</script>

<style scoped lang="scss">
@import "@/assets/main.scss";

.new-project-view-container {
  margin-top: 60px;
  padding: 15px;
  font-family: 'Inter';

  .new-project-header-container {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 2px solid lightGray;
  }

  .new-project-main-title {
    font-size: 24px;
    font-weight: bolder;
    color: $navyBlue;
    margin-bottom: 15px;
  }

  .breadcrumb-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px;
    color: #8C8D92;
  }
}

/* Container Accordion -- Start */
.accordion-panel-header {
  display: flex;
  align-items: center;
  position: relative;
  width: 99%;
  margin: 15px 0;
  color: $navyBlue;
  font-weight: bolder;

  label {
    font-size: 20px;
  }
}

/* Container Accordion -- End */

.divider {
  border-bottom: 2px solid lightgray;
}

.project-action-button {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;

  button {
    background-color: $lightGray1;
    border: 1px solid $paleBlue1;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
  }
}
</style>