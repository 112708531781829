const costs = getProjectCosts()
const sideModalColumnDefs = getSideModalAgGridColumnDefs()
const sideModalSampleData = getSampleSideModalData()
const hcSamplePlannedDuration = getHCSamplePlannedDuration()
const hcSampleActualDuration = getHCSampleActualDuration()
const sampleCoordinates = getSampleCoordinates()
const structuralTypesData = getStructuralTypes()
const windowTypesData = getWindowTypes()
const facadeTypesData = getFacadeTypes()
const constructionTypesForNewProjectsData = getConstructionTypesForNewProjects()
const baselineDocumentsData = getBaselineDocuments()
const drawDocumentsData = getDrawDocuments()
const baselineAtTypesData = getBaselineAtTypes()

export { costs, sideModalColumnDefs, sideModalSampleData, hcSamplePlannedDuration, hcSampleActualDuration, sampleCoordinates, structuralTypesData, windowTypesData, facadeTypesData, constructionTypesForNewProjectsData, baselineDocumentsData, drawDocumentsData, baselineAtTypesData }

function getProjectCosts() {
  return [100000, 300000, 200000, 50000]
}

// Cleanup the DDA Terms to display nicely
export function cleanupHierarchyNames(names) {
  if (typeof (names) === 'object') {
    names.forEach((name, i) => {
      names[i] = name.replace(/^Equity /i, '').replace(/^Debt /i, '').replace(/Subtotal/i, '')
    })
  } else {
    names = names.replace(/^Equity /i, '').replace(/^Debt /i, '').replace(/Subtotal/i, '')
  }

  return names
}

function getSideModalAgGridColumnDefs() {
  return [
    {
      headerName: "Input Values",
      field: "input_value",
      headerClass: 'header-custom-input',
      editable: true,
      valueParser: "Number(newValue)"
    },
    {
      headerName: "Variance ($)",
      field: "variance_monetary",
      headerClass: 'header-custom-modvariance',
      editable: true,
      valueParser: "Number(newValue)"
    },
    {
      headerName: "Variance (%)",
      field: "variance_perc",
      headerClass: 'header-custom-modvariance',
      editable: true,
      valueParser: "Number(newValue)"
    },
  ]
}

function getSampleSideModalData() {
  return [
    {
      input_value: 18000000,
      variance_monetary: 56000,
      variance_perc: 3.1
    },
    {
      input_value: 16500000,
      variance_monetary: 45000,
      variance_perc: 2.8
    },
    {
      input_value: 15000000,
      variance_monetary: 11000,
      variance_perc: 0.3
    },
  ]
}

function getHCSamplePlannedDuration() {
  return [23, 19, 21]
}

function getHCSampleActualDuration() {
  return [26, 21, 23]
}

function getSampleCoordinates() {
  return { lat: 40.75533, lng: -73.999568 }
}

function getStructuralTypes() {
  return [
    { id: 1, name: 'Wood' },
    { id: 2, name: 'Concrete' },
    { id: 3, name: 'Steel' }
  ]
}

function getWindowTypes() {
  return [
    { id: 1, name: 'Punch' },
    { id: 2, name: 'Window System' },
    { id: 3, name: 'Curtain Wall' }
  ]
}

function getFacadeTypes() {
  return [
    { id: 1, name: 'EIFS' },
    { id: 2, name: 'Stucco' },
    { id: 3, name: 'Masonry' },
    { id: 4, name: 'Glass Fiber Reinforced Concrete' },
    { id: 5, name: 'Precast' },
    { id: 5, name: 'Metal Panel' },
  ]
}

function getConstructionTypesForNewProjects() {
  return [
    { id: 1, name: 'Ground Up' },
    { id: 2, name: 'Renovation' },
    { id: 3, name: 'Adaptive Reuse' },
    { id: 4, name: 'Interior Fit Out' },
    { id: 5, name: 'Horizontal Work' },
  ]
}

function getBaselineDocuments() {
  return [
    {
      id: 1,
      drawNumber: 'Loan Close',
      periodThrough: '12/15/21',
      fileName: '',
      fileSize: '',
      hierarchy: 1
    },
    {
      id: 10,
      drawNumber: 'Developer Budget',
      periodThrough: '',
      fileName: '',
      fileSize: '',
      hierarchy: 2,
    },
    {
      id: 101,
      drawNumber: 'Project Start',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 11,
      drawNumber: 'General Contractor Budget',
      periodThrough: '',
      fileName: '',
      fileSize: '',
      hierarchy: 2,
    },
    {
      id: 111,
      drawNumber: 'Project Start',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 12,
      drawNumber: 'General Contractor Schedule',
      periodThrough: '',
      fileName: '',
      fileSize: '',
      hierarchy: 2,
    },
    {
      id: 121,
      drawNumber: 'General Contractor Schedule',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 13,
      drawNumber: 'Construction Contracts',
      periodThrough: '',
      fileName: '',
      fileSize: '',
      hierarchy: 2,
    },
    {
      id: 131,
      drawNumber: 'Subcontractor Lien Waivers',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 132,
      drawNumber: 'Subcontractor Lien Waivers',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 133,
      drawNumber: 'Subcontractor Lien Waivers',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 134,
      drawNumber: 'Current Change Orders',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 14,
      drawNumber: 'Project Rendering/Image',
      periodThrough: '',
      fileName: '',
      fileSize: '',
      hierarchy: 2,
    },
    {
      id: 141,
      drawNumber: 'Draw 2',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 2,
      drawNumber: 'GMP Amendment',
      periodThrough: '03/22/22',
      fileName: '',
      fileSize: '',
      hierarchy: 1,
    },
    {
      id: 3,
      drawNumber: 'Loan Rebalancing',
      periodThrough: '09/12/23',
      fileName: '',
      fileSize: '',
      hierarchy: 1,
    }
  ]
}

function getDrawDocuments() {
  return [
    {
      id: 1,
      drawNumber: 'Draw 1',
      periodThrough: '01/31/22',
      fileName: '',
      fileSize: '',
      hierarchy: 1
    },
    {
      id: 10,
      drawNumber: 'Borrower Requisition Letters',
      periodThrough: '',
      fileName: '',
      fileSize: '',
      hierarchy: 2,
    },
    {
      id: 101,
      drawNumber: 'Project Start',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 102,
      drawNumber: 'Project Start',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 11,
      drawNumber: 'Sponso Draw Tracker',
      periodThrough: '',
      fileName: '',
      fileSize: '',
      hierarchy: 2,
    },
    {
      id: 111,
      drawNumber: 'Project Start',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 12,
      drawNumber: 'Full Payment Application',
      periodThrough: '',
      fileName: '',
      fileSize: '',
      hierarchy: 2,
    },
    {
      id: 121,
      drawNumber: 'GC G702 & G703',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 122,
      drawNumber: 'Current Change Orders',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 123,
      drawNumber: 'Subcontractor Lien Waivers',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 124,
      drawNumber: 'Current Change Orders',
      periodThrough: '',
      fileName: '7 Wall Street - GC Schedule of Values - Draft 2.pdf',
      fileSize: '15.3 MB',
      hierarchy: 3,
    },
    {
      id: 14,
      drawNumber: 'Soft Cost Invoices',
      periodThrough: '',
      fileName: '',
      fileSize: '',
      hierarchy: 2,
    },
    {
      id: 2,
      drawNumber: 'Draw 2',
      periodThrough: '02/28/22',
      fileName: '',
      fileSize: '',
      hierarchy: 1,
    },
    {
      id: 3,
      drawNumber: 'Draw 3',
      periodThrough: '02/28/22',
      fileName: '',
      fileSize: '',
      hierarchy: 1,
    },
    {
      id: 4,
      drawNumber: 'Draw 4',
      periodThrough: '04/30/22',
      fileName: '',
      fileSize: '',
      hierarchy: 1,
    }
  ]
}

function getBaselineAtTypes() {
  return [
    {
      id: 1,
      name: 'Underwriting / Pre-Development'
    },
    {
      id: 2,
      name: 'Initial Bid (Design Documents)'
    },
    {
      id: 3,
      name: 'GMP (Construction Documents)'
    },
    {
      id: 4,
      name: 'Loan Closing'
    },
    {
      id: 5,
      name: 'Loan Restructuring / Recapitalization'
    },
    {
      id: 6,
      name: 'Substantial Completion'
    }
  ]
}