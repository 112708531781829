<template>
  <div v-if="title" class="toggle-button cell-container-title bak-light-blue-2"
    @click="toggleExpandCollapse(); $emit('toggle-expand-collapse')">
    <button>{{ props.title }}</button>
    <div :class="[isExpandCollapseOpen ? openState : closedState, isVerticalCenter ? 'vertical-center' : 'vertical-lower']"
      class="expand-collapse" :data-test-id="`expand-collapse-${props.name}`">
      <svg v-if="showIcon" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle v-if="showCircle" r="15.5" transform="matrix(-1 0 0 1 16 16)" fill="white" stroke="#CED4DA" />
        <g transform="translate(12, 10)" fill="none">
          <path :class="props.name" clip-rule="evenodd"
            d="M5.74895 0.863702C5.41421 0.51223 4.8715 0.51223 4.53677 0.863702L0.251051 5.3637C-0.083684 5.71517 -0.083684 6.28502 0.251051 6.63649L4.53677 11.1365C4.8715 11.488 5.41421 11.488 5.74895 11.1365C6.08368 10.785 6.08368 10.2152 5.74895 9.8637L2.06933 6.0001L5.74895 2.13649C6.08368 1.78502 6.08368 1.21517 5.74895 0.863702Z" />
        </g>
      </svg>
    </div>
  </div>
  <div v-else
    :class="[isExpandCollapseOpen ? openState : closedState, isVerticalCenter ? 'vertical-center' : 'vertical-lower']"
    class="expand-collapse" @click="toggleExpandCollapse(); $emit('toggle-expand-collapse')"
    :data-test-id="`expand-collapse-${props.name}`">
    <svg v-if="showIcon && !showDoubleChevron" width="32" height="32" viewBox="0 0 32 32" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle v-if="showCircle" r="15.5" transform="matrix(-1 0 0 1 16 16)" fill="white" stroke="#CED4DA" />
      <g transform="translate(12, 10)" fill="none">
        <path :class="props.name" :fill="chevronFill" fill-rule="evenodd" clip-rule="evenodd"
          d="M5.74895 0.863702C5.41421 0.51223 4.8715 0.51223 4.53677 0.863702L0.251051 5.3637C-0.083684 5.71517 -0.083684 6.28502 0.251051 6.63649L4.53677 11.1365C4.8715 11.488 5.41421 11.488 5.74895 11.1365C6.08368 10.785 6.08368 10.2152 5.74895 9.8637L2.06933 6.0001L5.74895 2.13649C6.08368 1.78502 6.08368 1.21517 5.74895 0.863702Z" />
      </g>
    </svg>
    <svg v-else-if="showIcon && showDoubleChevron && isVerticalChevron" width="32" height="32" viewBox="0 0 32 32"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle v-if="showCircle" r="15.5" transform="matrix(-1 0 0 1 16 16)" fill="white" stroke="#CED4DA" />
      <g transform="translate(10, 10)" fill="none">
        <path :class="props.name" :fill="chevronFill" fill-rule="evenodd" clip-rule="evenodd"
          d="M5.74895 0.863702C5.41421 0.51223 4.8715 0.51223 4.53677 0.863702L0.251051 5.3637C-0.083684 5.71517 -0.083684 6.28502 0.251051 6.63649L4.53677 11.1365C4.8715 11.488 5.41421 11.488 5.74895 11.1365C6.08368 10.785 6.08368 10.2152 5.74895 9.8637L2.06933 6.0001L5.74895 2.13649C6.08368 1.78502 6.08368 1.21517 5.74895 0.863702Z" />
      </g>
      <g transform="translate(14, 10)" fill="none">
        <path :class="props.name" :fill="chevronFill" fill-rule="evenodd" clip-rule="evenodd"
          d="M5.74895 0.863702C5.41421 0.51223 4.8715 0.51223 4.53677 0.863702L0.251051 5.3637C-0.083684 5.71517 -0.083684 6.28502 0.251051 6.63649L4.53677 11.1365C4.8715 11.488 5.41421 11.488 5.74895 11.1365C6.08368 10.785 6.08368 10.2152 5.74895 9.8637L2.06933 6.0001L5.74895 2.13649C6.08368 1.78502 6.08368 1.21517 5.74895 0.863702Z" />
      </g>
    </svg>
    <svg v-else-if="showIcon && showDoubleChevron && !isVerticalChevron" width="32" height="32" viewBox="0 0 32 32"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle v-if="showCircle" r="15.5" transform="matrix(-1 0 0 1 16 16)" fill="white" stroke="#CED4DA" />
      <g transform="translate(12, 8)" fill="none">
        <path :class="props.name" :fill="chevronFill" fill-rule="evenodd" clip-rule="evenodd"
          d="M5.74895 0.863702C5.41421 0.51223 4.8715 0.51223 4.53677 0.863702L0.251051 5.3637C-0.083684 5.71517 -0.083684 6.28502 0.251051 6.63649L4.53677 11.1365C4.8715 11.488 5.41421 11.488 5.74895 11.1365C6.08368 10.785 6.08368 10.2152 5.74895 9.8637L2.06933 6.0001L5.74895 2.13649C6.08368 1.78502 6.08368 1.21517 5.74895 0.863702Z" />
      </g>
      <g transform="translate(12, 12)" fill="none">
        <path :class="props.name" :fill="chevronFill" fill-rule="evenodd" clip-rule="evenodd"
          d="M5.74895 0.863702C5.41421 0.51223 4.8715 0.51223 4.53677 0.863702L0.251051 5.3637C-0.083684 5.71517 -0.083684 6.28502 0.251051 6.63649L4.53677 11.1365C4.8715 11.488 5.41421 11.488 5.74895 11.1365C6.08368 10.785 6.08368 10.2152 5.74895 9.8637L2.06933 6.0001L5.74895 2.13649C6.08368 1.78502 6.08368 1.21517 5.74895 0.863702Z" />
      </g>
    </svg>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'

const props = defineProps({
  title: String,
  name: {
    type: String,
    default: ''
  },
  isOpenInitially: Boolean,
  openState: String,
  closedState: String,
  isVerticalCenter: Boolean,
  showIcon: {
    type: Boolean,
    default: true
  },
  showDoubleChevron: Boolean,
  chevronFill: {
    type: String,
    default: null
  },
  showCircle: Boolean
})

defineEmits(['toggle-expand-collapse'])

let isExpandCollapseOpen = ref(props.isOpenInitially)
function toggleExpandCollapse() {
  isExpandCollapseOpen.value = !isExpandCollapseOpen.value
}
function isVerticalChevron() {
  const currentChevron = isExpandCollapseOpen.value ? props.openState : props.closedState
  return (currentChevron === 'up' || currentChevron === 'down')
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";
.toggle-button {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 0;
}

button:not(.action-button) {
  font-family: 'Inter', 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 1.0em;
  font-weight: bold;
  background-color: transparent;
  border-color: transparent;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
}

.expand-collapse {
  cursor: pointer;
  z-index: 1; // Keep this above other divs
}

.vertical-center {
  top: -4px;
}

.vertical-lower {
  top: 12px;
}

.left,
.right-left,
.right-zero,
.right,
.down,
.up {
  position: absolute;
}

.left {
  right: -16px;
}

.right-left {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  left: -16px;
}

.right-zero {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  left: 0px;
}

.right {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  right: -16px;
}

.sidebar, .compsearch-sidemodal {
  fill: $mediumBlue2;
}

.sidemodal {
  fill: #F77B01;
}

.down {
  transform: matrix(0, -1, 1, 0, 0, 0);
  right: -18px;
  top: -6px;
}

.up {
  transform: matrix(0, 1, -1, 0, 0, 0);
  right: -14px;
  top: -6px;
}</style>