<template>
  <div class="grid-container" id="budgetViewGrid">
    <ag-grid
      :row-height="25"
      :row-data="leafParentData"
      :columnDefs="columnDefs"
      :treeData="true"
      :key="`${leafParentDataCtr};${escalationQuery}`"
      @setChartData="setChartData"
      :pinnedBottomRowData="pinnedBottomRowData"
      :groupDefaultExpanded="2"
    />
  </div>
</template>

<script setup>
import { axiosQuery } from "@/assets/js/utils";
import { defineProps, reactive, ref, onMounted } from "vue";
import { useBudgetDataStore } from "@/stores/budget_data_store";
import { useAuthStore } from "@/stores/auth_store";
import { useErrorStore } from "@/stores/error_store";
import { useCompSetStore } from "@/stores/comp_set_store";
import AgGrid from "../../components/AgGrid.vue";
import { cleanupHierarchyNames } from "@/data/data";
import { median } from "@/assets/js/utils";
// Define props
const props = defineProps({
  shouldCalculateGrandTotal: {
    type: Boolean,
    default: true,
  },
});

const apiBase =
  process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/";
const authStore = useAuthStore();
const errorStore = useErrorStore();
let authToken = reactive({});
const budgetDataStore = useBudgetDataStore();
const compSetStore = useCompSetStore();

let leafParentData = ref([]);
let branchGridData = ref({});
let leafParentNodesToData = {};
let leafParentDataCtr = ref(0);
const sumAggregateCols = [
  "original_budget",
  "total_adjustments",
  "current_budget",
  "total_spent",
];
const columnDefs = [
  {
    headerName: "Original Budget",
    field: "original_budget",
    headerClass: "header-custom-lilac",
    aggFunc: sumAggregate,
    editable: false,
    valueParser: "Number(newValue)",
    minWidth: 1000,
    type: 'rightAligned'
  },
  {
    headerName: "Total Adjustments",
    field: "total_adjustments",
    headerClass: "header-custom-lilac",
    aggFunc: sumAggregate,
    editable: false,
    valueParser: "Number(newValue)",
    type: 'rightAligned'
  },
  {
    headerName: "Actual Budget",
    field: "current_budget",
    headerClass: "header-custom-lilac",
    aggFunc: sumAggregate,
    editable: false,
    valueParser: "Number(newValue)",
    type: 'rightAligned'
  },
  {
    headerName: "Variance",
    field: "variance",
    headerClass: "header-custom-lightblue",
    aggFunc: varianceAggregate,
    editable: false,
    valueParser: "Number(newValue)",
    type: 'rightAligned'
  },
  {
    headerName: "Total Spent",
    field: "total_spent",
    headerClass: "header-custom",
    aggFunc: sumAggregate,
    editable: false,
    valueParser: "Number(newValue)",
    type: 'rightAligned'
  },
  {
    headerName: "% Complete",
    field: "percent_complete",
    headerClass: "header-custom",
    aggFunc: pctCompleteAggregate,
    editable: false,
    valueParser: "Number(newValue)",
    type: 'rightAligned'
  },
  // For debugging....not a long-term addition!
  // {
  //   headerName: "Term Id",
  //   field: "dda_term_id",
  //   editable: false,
  //   valueParser: "Number(newValue)"
  // },
];
onMounted(() => {
  authToken.value = authStore.getAuthTokenHeader;
  fetchGridData();
});

let leafRowData = ref([]);
// Update grid data on any update on compset store
compSetStore.$subscribe(fetchGridData);
function fetchGridData() {
  let selectedProjects = compSetStore.getSelectedProjects
  let escalationQuery = compSetStore.getEscalation
    ? `&escalation=${compSetStore.getEscalation}`
    : "";
  const selectedIds = Object.keys(selectedProjects)
    .filter((id) => selectedProjects[id])
    .join(",");
  if (selectedIds) {
    axiosQuery(
      `${apiBase}mvw_most_recent_by_projects/leaves_sum_grouped_by_term?project_ids=${selectedIds}${escalationQuery}`,
      authToken,
      (data) => {
        if (data.length === 0) {
          budgetDataStore.setIsBudgetDataLoaded(false);
        } else {
          leafRowData.value = [];
          branchGridData = {};
          leafParentNodesToData = {};
          let leafParentDataTemp = [];
          data?.forEach((row) => {
            const aRow = {
              dda_term_id: row.dda_term_id,
              hiddenHierarchy: cleanupHierarchyNames(row.itemHierarchy), // TODO Need to retain this hiddenHierarchy?
              itemHierarchy: cleanupHierarchyNames(row.itemHierarchy),
              original_budget: row.original_budget,
              total_adjustments:
                parseFloat(row.current_budget || 0) -
                parseFloat(row.original_budget || 0),
              current_budget: row.current_budget || 0,
              variance:
                ((row.current_budget || 0) - (row.original_budget || 0)) /
                (row.original_budget || 1),
              draw_summary: row.current_request,
              total_spent:
                parseFloat(row.previously_funded || 0) +
                parseFloat(row.current_request || 0),
              percent_complete: row.total_completed_to_date_pct_avg,
            };
            // Add this row everywhere in the hierarchy
            aRow.hiddenHierarchy.forEach((term, idx) => {
              branchGridData[term] ||= [];
              branchGridData[term].push(aRow);
              // Add leaf parent data -- ie the node just above a leaf node (2nd to the bottom)
              if (aRow.hiddenHierarchy.length - 2 === idx) {
                leafParentNodesToData[aRow.hiddenHierarchy[idx]] ||= [];
                leafParentNodesToData[aRow.hiddenHierarchy[idx]].push(aRow);
              }
            });
            leafRowData.value.push(aRow);
          });
          // Do the summation and other aggregate math on the leaf parent nodes as AG Grid will not call this automatically
          Object.keys(leafParentNodesToData).forEach((leafParentTerm) => {
            // Remove the leaf node as we don't show these.
            // Ensure we clone the objects so we don't destroy the hierarchy for other queries
            let aRow = { ...leafParentNodesToData[leafParentTerm][0] };
            aRow.hiddenHierarchy = [...aRow.hiddenHierarchy];
            aRow.hiddenHierarchy.pop();
            aRow.itemHierarchy = [...aRow.itemHierarchy];
            aRow.itemHierarchy.pop();
            // Calculate subtotals at the non-leaf node level
            sumAggregateCols.forEach((colId) => {
              aRow[colId] = sumAggregate({
                rowNode: { key: leafParentTerm },
                column: { colId: colId },
              });
            });
            aRow.variance = varianceAggregate({
              rowNode: { key: leafParentTerm },
              column: { colId: "variance" },
            });
            aRow.percent_complete = pctCompleteAggregate({
              rowNode: { key: leafParentTerm },
              column: { colId: "percent_complete" },
            });
            leafParentDataTemp.push(aRow);
          });
          leafParentData.value = leafParentDataTemp;
          leafParentDataCtr.value++;
          budgetDataStore.setIsBudgetDataLoaded(true);

        }
        if (props.shouldCalculateGrandTotal) {
            calculateGrandTotal();
        }
      },
      errorStore
    );
  } 
}

// // Save the sum aggregates so they can be used to calculate variance
let sumAggregates = {};
function sumAggregate(params) {
  let selectedProjects = compSetStore.getSelectedProjects
  // Go through the full sum of each child item, then divide by # of projects
  let otherTotal = 0.0;
  (branchGridData[params.rowNode.key] || []).forEach(
    (value) => (otherTotal += parseFloat(value[params.column.colId] || 0.0))
  );
  otherTotal =
    otherTotal /
    (Object.keys(selectedProjects).filter(
      (id) => selectedProjects[id]
    ).length || 1);
  sumAggregates[params.column.colId] ||= {};
  sumAggregates[params.column.colId][params.rowNode.key] = otherTotal;
  return otherTotal;
}
// Uses the sum aggregates so they can be used to calculate variance
// NOTE: this only works because the sum aggregate columns are calculated first!
function varianceAggregate(params) {
  const originalBudget = sumAggregates["original_budget"][params.rowNode.key];
  const currentBudget = sumAggregates["current_budget"][params.rowNode.key];
  return (
    ((currentBudget || 0.0) - (originalBudget || 0.0)) /
    (parseFloat(originalBudget) || 1.0)
  );
}
// Uses the sum aggregates so they can be used to calculate % complete
// NOTE: this only works because the sum aggregate columns are calculated first!
function pctCompleteAggregate(params) {
  const currentBudget = sumAggregates["current_budget"][params.rowNode.key];
  const totalSpent = sumAggregates["total_spent"][params.rowNode.key];
  return (totalSpent || 0.0) / (parseFloat(currentBudget) || 1.0);
}
function setChartData(event) {
  let chartData = event;
  budgetDataStore.setAggregatedBudgetChartData(chartData);
}

//const leafRowData = ref([]);
let pinnedBottomRowData = ref([])
function calculateGrandTotal() {
  // Check if leafRowData is empty or not defined
 if (!leafRowData.value || leafRowData.value.length === 0) return;

  // TODO -- may cause less confusion to initialize grandtotal at the same time as sums
  // Initialize the grand total object
  const grandTotal = initializeGrandTotal();
  // Calculate sums and collect values for variance and percent complete
  const { varianceValues, percentCompleteValues } = calculateSums(grandTotal);

  // Calculate the median for variance and percent complete
  const medianVariance = median(varianceValues);
  const medianPercentComplete = median(percentCompleteValues);

  // Update the grand total with median values
  grandTotal["variance"] = medianVariance;
  grandTotal["percent_complete"] = medianPercentComplete;

  // todo: for now keep the  values null
  grandTotal["variance"] = null;
  grandTotal["percent_complete"] = null;

  // Set the grand total row data
  pinnedBottomRowData = [grandTotal];
}

function initializeGrandTotal() {
  const grandTotal = {};
  // Iterate over column definitions to initialize grand total fields
  columnDefs.forEach((colDef) => {
    if (colDef.field) {
      grandTotal[colDef.field] = null; // Initialize to null
    }
  });
  return grandTotal;
}

function calculateSums(grandTotal) {
  const varianceValues = [];
  const percentCompleteValues = [];

  // Iterate over each row in leafRowData
  leafRowData.value.forEach((row) => {
    columnDefs.forEach((colDef) => {
      if (colDef.field && !isNaN(parseFloat(row[colDef.field]))) {
        // Sum the values for each field
        grandTotal[colDef.field] += parseFloat(row[colDef.field]);
        // Collect values for variance and percent complete
        if (colDef.field === "variance") {
          varianceValues.push(parseFloat(row[colDef.field]));
        }
        if (colDef.field === "percent_complete") {
          percentCompleteValues.push(parseFloat(row[colDef.field]));
        }
      }
    });
  });
  // sums should be sum aggregates
  let selectedProjects = compSetStore.getSelectedProjects
  columnDefs.forEach((colDef) => {
    grandTotal[colDef.field] = grandTotal[colDef.field] / (Object.keys(selectedProjects).filter(
      (id) => selectedProjects[id]
    ).length || 1)
  })
  return { varianceValues, percentCompleteValues };
}
</script>
