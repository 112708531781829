<template>
  <ag-grid-vue v-if="isDataReady"
    style="height: 600px"
    class="ag-theme-alpine"
    tooltipShowDelay="250"
    :rowHeight="props.rowHeight"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :autoGroupColumnDef="autoGroupColumnDef"
    :column-options="columnOptions"
    :rowData="props.rowData || rowData.value"
    :treeData="props.treeData"
    :groupDefaultExpanded="-1"
    :getDataPath="getDataPath"
    :modules="[ServerSideRowModelModule, RowGroupingModule]"
    :autoSizeStrategy="autoSizeStrategy"
    @filter-changed="onFilterChanged"
    @grid-ready="onGridReady"
    @grid-size-changed="onGridSizeChanged"
    colResizeDefault='shift'
    :key="isDataReady"
    :isFlatData="props.isFlatData"
  >
  </ag-grid-vue>
</template>

<script setup>
import { onMounted, reactive, ref, defineProps, defineEmits, onUpdated } from "vue"
import { defaultColumnNameParsing } from "@/assets/js/ag_grid_field_helper"
import { useErrorStore } from "@/stores/error_store"
import axios from "axios"
import { AgGridVue } from "ag-grid-vue3"  // the AG Grid Vue Component
import 'ag-grid-enterprise'
import 'ag-grid-community'
import "ag-grid-community/styles/ag-grid.css" // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css" // Optional theme CSS
import 'ag-grid-autocomplete-editor/dist/main.css'
import { LicenseManager, RowGroupingModule, ServerSideRowModelModule } from 'ag-grid-enterprise'

LicenseManager.setLicenseKey(process.env.VUE_APP_ENV_AG_LICENSE);

const emit = defineEmits(['setChartData'])
const props = defineProps({
  rowHeight: Number,
  columns: Array,
  dropdownColumns: Array,
  overrideColumnNames: Array, // ability to override the column name
  rowClassRules: {
    type: Object,
    default: null
  },
  responseKey: String,
  autocompleteDataType: String,
  foreignKeys: Object,
  url: String,
  params: Object,
  authToken: Object,
  title: String,
  editable: Boolean,
  editableColumns: Array,
  parent: String,
  rowData: Array,
  treeData: Boolean,
  columnDefs: Array,
  isFlatData: Boolean,
  overrideWidth: {
    type: Boolean,
    default: false
  },
  fullScroll: Boolean,
  isAutoHeight: {
    type: Boolean,
    default: false
  },
  // Allows us to turn off going to another page when cell is clicked
  isUseCellClicked: {
    type: Boolean,
    default: true
  },
  isOpenNewTab: {
    type: Boolean,
    default: false
  },
  columnOptions: {
    type: Object,
    default: null
  },
  defaultSort: {
    type: Array,
    default: null
  },
  showFilters: {
    type: Boolean,
    default: true
  },
  topTotalColRows: {
    type: Array,
    default: null
  },
  baseRoute: {
    type: String,
    default: null
  },
  routeQueryKey: {
    type: String,
    default: null
  },
  hasCheckboxColumn: {
    type: Boolean,
    default: false
  },
  customColumn: {
    type: String,
    default: null
  },
  customColRedirectPath: {
    type: String,
    default: null
  },
  customColQueryKey: {
    type: String,
    default: null
  },
  customQuery: {
    type: String,
    default: null
  },
  // css styles for a given column
  cellStyles: {
    type: Object,
    default: null
  },
  breakColumns: {
    type: Array,
    default: null
  },
  wrapColumns: {
    type: Array,
    default: null
  },
  listenForFetchedData: {
    type: Boolean,
    default: false
  }
})

// AG Grid other config

// Use math to stretch columns if needed
const overrideWidthMin = 150
let width = ref('')
if (props.overrideWidth && props.columns?.length > 10) {
  width.value = `width:${overrideWidthMin * props.columns.length}px;`
} else if (props.overrideWidth && props.columnDefs?.length > 10) {
  width.value = `width:${overrideWidthMin * props.columnDefs.length}px;`
}


const autoSizeStrategy = (props.overrideWidth || props.fullScroll) ? null : { type: 'fitGridWidth' }

const gridApi = ref(null) // Optional - for accessing Grid's API
const columnApi = ref(null) // Optional - for accessing Grid's API

// Obtain API from grid's onGridReady event
const onGridReady = (params) => {
  gridApi.value = params.api
  columnApi.value = params.columnApi

  // Only autosize if we have not overridden autosize options
  if (autoSizeStrategy) {
    gridApi.value.autoSizeAllColumns()
  }

  // emit chart data every time grid has been updated
  getAllRows()
}

const onGridSizeChanged = (params) => {
  if (autoSizeStrategy) {
    let columnCount = params.columnApi.api.columnModel.gridColumns.length
    width = params.clientWidth / columnCount
    params.api.sizeColumnsToFit();
  }
};

const defaultColDef = ref({
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true
})
const autoGroupColumnDef = ref(null);
autoGroupColumnDef.value = {
  headerName: 'Item',
  minWidth: 300,
  pinned: 'left', // freeze/pin itemHierarchy to the left so it can be seen when scrolling (esp. for Schedule page)
  cellRendererParams: {
    suppressCount: true,
  },
  valueGetter: params => {
  if (params.node.rowPinned === 'bottom') {
      return 'Total';
    }
    return params.node.key; // or any other default value
  },
}

if(props.isFlatData) {
  autoGroupColumnDef.value = {
        headerName: 'Item',
        minWidth: 150,
        pinned: 'left', // freeze/pin itemHierarchy to the left so it can be seen when scrolling (esp. for Schedule page)
        cellRendererParams: {
          suppressCount: true,
        },
        // If the isFlatData is true, then we want to show the itemHierarchy as the first column without the tree format
        cellRenderer: (params) => {
          return params.value
        }
      }
}
const getDataPath = ref(null)
getDataPath.value = (data) => {
  return data.itemHierarchy;
};

const isDataReady = ref(!!props.rowData?.length)
const rowData = reactive(props.rowData || []) // Set rowData to Array of Objects, one Object per Row

// Each Column Definition results in one Column.
let fields = []

if (props.hasCheckboxColumn) {
  fields.push({
    colId: 'checkbox',
    maxWidth: 50,
    sortable: false,
    filter: false,
    checkboxSelection: true,
    suppressMenu: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true
  })
}

props.columnDefs?.forEach((columnDef, idx) => {
  const columnName = columnDef.field
  let formattedColumnName = columnDef.headerName
  if (!formattedColumnName) {
    formattedColumnName = columnName.replaceAll('_', ' ')
    formattedColumnName = formattedColumnName.replace(' id s', '') // remove id_s foreign keys for column name
    formattedColumnName = formattedColumnName.charAt(0).toUpperCase() + formattedColumnName.slice(1)
  }

  if (props.overrideColumnNames && props.overrideColumnNames[idx]) {
    formattedColumnName = props.overrideColumnNames[idx]
  }

  let isColumnEditable = props.editableColumns?.length ? props.editableColumns.includes(columnName) : props.editable
  let extraOpts = {
    editable: isColumnEditable,
    columnOptions: props.columnOptions ? props.columnOptions[columnName] : null,
    boldFirstLine: props.breakColumns ? true : false,
    wrapText: props.wrapColumns?.includes(formattedColumnName),
    autoHeight: props.wrapColumns?.includes(formattedColumnName),
    filter: props.showFilters,
    flex: 1,
    cellStyle: props.cellStyles && props.cellStyles[columnName] ? props.cellStyles[columnName] : null
  }
  if (props.overrideWidth && !extraOpts.minWidth) {
    extraOpts.minWidth = overrideWidthMin
  }
  // Idea from https://stackoverflow.com/a/74057195/2611078
  if (props.breakColumns?.includes(columnName)) {
    // Allow us to build upon the other attributes for the cell style
    if (!extraOpts.cellStyle) extraOpts.cellStyle = {}
    extraOpts.cellStyle['white-space'] = 'pre'
    extraOpts.valueGetter = ({ data }) => {
      if (data[columnName]) {
        return String(data[columnName]).split(';')?.join('\n')
      } else {
        return data[columnName]
      }
    }
  }
  const field = defaultColumnNameParsing(columnName, formattedColumnName, props.autocompleteDataType, idx, extraOpts)
  fields.push({...field, ...columnDef})
})
const columnDefs = reactive(fields)

// load data from sever
// placeholder codes
const apiBase = process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/"
const errorStore = useErrorStore()
onMounted(() => {
  if (props.url) {
    fetchData(props.url)
  }
})

function getAllRows() {
  emit('setChartData', gridApi.value.rowModel.rowsToDisplay);
}
// function fetchData(url, isResetCounter = false) {
function fetchData(url) {
  // if (isResetCounter) {
  //   gridReadyCounter--
  // }
  if (props.url) {
    try {
      axios.get(`${apiBase}${url}`, props.authToken)
          .then(result => {
            // if (props.foreignKeys) {
            //   Object.keys(props.foreignKeys).forEach(foreignKey => {
            //     foreignKeyData[foreignKey] = result.data[foreignKey]
            //   })
            // }
            let data = result?.data
            // // add a warning if data has reached search results threshold
            // if (searchStore.searchValue) {
            //   let type = searchStore.searchKey === 'company_name' ? 'customers' : 'orders'
            //   if (data[`${type}`]?.length === MAX_SEARCH_RESULTS) {
            //     errorStore.setError('Result set too big', `Result set too big, only showing first ${MAX_SEARCH_RESULTS} rows`, "warning")
            //   }
            // }
            if (props.responseKey && result?.data) {
              data = result.data[props.responseKey]
            }

            // if (!props.isAutoHeight || data?.length > autoHeightThreshold) {
            //   height.value = defaultHeight
            //   isAutoHeight.value = false
            // } else {
            //   height.value = 'height: unset;'
            //   isAutoHeight.value = true
              // gridApi onReady usually has already run, so reset here
              gridApi.value?.setDomLayout('autoHeight')
            // }
            return data
          })
          .then((remoteRowData) => {
            rowData.value = remoteRowData
            isDataReady.value = true
            // TODO I am not sure how efficient Vue3 is with emitting events if there is no event listener.
            //      Given this is a lot of data in certain cases, I am only going to emit if we ask for the data.
            //      I asked about this on stackoverflow: https://stackoverflow.com/questions/75960034/does-vue-emit-an-event-even-if-there-is-no-listener
            // if (props.listenForFetchedData) {
            //   emit('setFetchedData', remoteRowData)
            // }
            // for switching off the data loader gif. Not that grid needs to be ready *and* data needs to be fetched
            // gridReadyCounter++
            totalRowCalculations()
            // heightCalculations()
            // emit('setIsDataReady', gridReadyCounter > 1)
          })
          .catch(error => {
            errorStore.setError("General Error Viewing Table", error?.message)
          })
    } catch (error) {
      errorStore.setError("General Error Viewing Table", error.message)
    }
  }
  else {
    // rowData.value = props.sourceRowData
    // // for switching off the data loader gif. Not that grid needs to be ready *and* data needs to be fetched
    // gridReadyCounter++
    totalRowCalculations()
    // heightCalculations()
    // emit('setIsDataReady', gridReadyCounter > 1)
  }

  // // populate dropdowns as needed
  // if (props.autocompleteDataType === 'itemables' || props.autocompleteDataType === 'items') {
  //   fetchDataOptions(props.autocompleteDataType)
  // }
}

// placeholder function for populating AG Grid
// function fetchData(url, isResetCounter = false) {
// }

// TODO Julie: not working right now -- not sure why...
function totalRowCalculations() {
  // function totalRowCalculations( _filteredData = null) {
  // const dataToUse = filteredData ? filteredData : rowData.value
  // if (props.topTotalColRows && dataToUse) {
  //   let values = {}
  //   props.topTotalColRows.forEach(col => {
  //     const val = (dataToUse.map(item => item[col] ? parseFloat(item[col]) : 0.0).reduce((a, b) => {
  //       return a + b
  //     }, 0))
  //     if (!isNaN(val)) {
  //       values[col] = numberFormat(val)
  //     }
  //   })
  //   if (rowData.value) {
  //     // Potentially a bit of a hack, but should work with how we represent our data
  //     values['id'] = filteredData && filteredData.length !== rowData.value.length ? 'Subtotals' : 'Totals'
  //   }
  //
  //   // gridApi.value.setPinnedTopRowData(props.topTotalColCallback(rowData.value))
  //   gridApi.value?.setPinnedTopRowData([values])
  // }
}
// function onSelectionChanged() {
//   emit('onSelectionChanged', gridApi.value.getSelectedRows(), gridApi.value.getRenderedNodes())
// }
function onFilterChanged(event) {
  // // TODO I am not sure how efficient Vue3 is with emitting events if there is no event listener.
  // //      Given this is a lot of data in certain cases, I am only going to emit if we ask for the data.
  // //      I asked about this on stackoverflow: https://stackoverflow.com/questions/75960034/does-vue-emit-an-event-even-if-there-is-no-listener
  // if (props.listenForFetchedData) {
  //   emit('setFetchedData', event.api.rowModel.rowsToDisplay.map(display => display.data), true)
  // }
  totalRowCalculations(event.api.rowModel.rowsToDisplay.map(display => display.data))
  getAllRows()
}
// This needs to be called by any external filter whenever the values selected externally change
// function onExternalFilterChanged() {
//   gridApi.value?.onFilterChanged()
// }
// function onCellValueChanged(event, rowsToDisplayParam, nodeParam) {
//   const rowsToDisplay = event ? event.api.rowModel.rowsToDisplay : rowsToDisplayParam
//   const node = event ? event.node : nodeParam
//   emit('onCellValueChanged', rowsToDisplay.map(display => display.data), node)
//
//   totalRowCalculations(rowsToDisplay.map(display => display.data))
// }
onUpdated(() => {
  gridApi.value?.onFilterChanged()
})


</script>

<style lang="scss">
@import "./src/assets/main.scss";
// NOTE that AgGrid.vue variables do not seem to accept color variables :(
.ag-theme-alpine.ag-custom {
  --ag-row-hover-color: #B9EBAB; // $brightGreen1 -- was #EBF4FF;
  --ag-selected-row-background-color: #D3DEE8;
  --ag-range-selection-border-color: #3490FC;
  --ag-alpine-active-color: #3490FC;
  // override default border colors for more contrast
  --ag-border-color: #C3C3C5;
  --ag-row-border-color: #C3C3C5;
  // see https://www.ag-grid.com/react-data-grid/global-style-customisation-compactness/
  --ag-grid-size: 3px;
  --ag-list-item-height: 20px;
  --ag-line-height: 20px;
  --ag-row-height: 20px;
}

.ag-theme-alpine {
  --ag-header-height: 30px;
}

</style>