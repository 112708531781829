<template>
  <div class="header-bar-container">
    <MainMenu @logout-user="$emit('logout-user')" @setHeaders="setHeaders"/>
    <error-message class="error-message" />
  </div>
</template>
<script setup>
import { defineEmits, ref } from "vue"
import ErrorMessage from "../helpers/ErrorMessage.vue"
import MainMenu from './MainMenu.vue'

const emit = defineEmits(['setHeaders'])
let headers = ref([])
function setHeaders(event) {
  headers.value = event.value
  emit('setHeaders', headers)
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";
.header-bar-container {
  background-color: #152742;
}
</style>
