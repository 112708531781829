<template>
  <div class="admin-container">
    <div v-if="isCurrentlyAdmin" class="all-tiles" data-test-id="admins-view">
      <div class="tile">
        <div v-if="isFinanceAdminOrAdmin" class="tile-header">
          <div class="tile-header-text">Simulate another user:</div>
          <button class="action-button" @click="toggleSimulateUser('Sales')" data-test-id="admins-simulated-roles-sales">Sales</button>
          <button class="action-button" @click="toggleSimulateUser('Finance')" data-test-id="admins-simulated-roles-finance">Finance</button>
          <button v-if="isSuperAdminFinanceAdmin" class="action-button" @click="toggleSimulateUser('Admin')" data-test-id="admins-simulated-roles-admin">Admin</button>
          <button v-if="isSuperAdmin" class="action-button" @click="toggleSimulateUser('FinanceAdmin')" data-test-id="admins-simulated-roles-finance-admin">Finance Admin</button>
          <button class="action-button" @click="toggleSimulateUser(null)" data-test-id="admins-simulated-roles-clear">Clear Simulation</button>
        </div>
      </div>

      <!-- admin/misc section -->
      <div class="toggle-button admin">
        <button @click="toggleShow('codes')" data-test-id="admins-codes-button">Toggle Codes</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('codes')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('codes') > -1">
        <ag-grid title="Codes" :url="urls['codes']" :foreign-keys="foreignKeys['codes']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['codes']" :top-total-col-rows="topTotalColRows['codes']" />
      </div>

      <div class="toggle-button admin">
        <button @click="toggleShow('dda_terms_proper')" data-test-id="admins-dda_terms_proper-button">Toggle DDA Terms PROPER</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('dda_terms_proper')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('dda_terms_proper') > -1">
        <ag-grid title="DDA Terms PROPER" :url="urls['dda_terms_proper']" :foreign-keys="foreignKeys['dda_terms_proper']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['dda_terms_proper']" :top-total-col-rows="topTotalColRows['dda_terms_proper']"
                 :tree-data="true" />
      </div>

      <div class="toggle-button admin">
        <button @click="toggleShow('dda_terms')" data-test-id="admins-dda_terms-button">Toggle DDA Terms ALL MAPPINGS</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('dda_terms')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('dda_terms') > -1">
        <ag-grid title="DDA Terms ALL MAPPINGS" :url="urls['dda_terms']" :foreign-keys="foreignKeys['dda_terms']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['dda_terms']" :top-total-col-rows="topTotalColRows['dda_terms']"
                 :tree-data="true" />
      </div>

      <div class="toggle-button admin">
        <button @click="toggleShow('amenities')" data-test-id="admins-amenities-button">Toggle Amenities</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('amenities')" :show-circle="true" />
      </div>
      <div class="tile" v-if="shown.indexOf('amenities') > -1">
        <ag-grid title="Amenities" :url="urls['amenities']" :foreign-keys="foreignKeys['amenities']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['amenities']" :top-total-col-rows="topTotalColRows['amenities']"
                 :tree-data="false" />
      </div>

      <div class="toggle-button admin">
        <button @click="toggleShow('building_uses')" data-test-id="admins-building_uses-button">Toggle Building Use</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('building_uses')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('building_uses') > -1">
        <ag-grid title="Building Uses" :url="urls['building_uses']" :foreign-keys="foreignKeys['building_uses']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['building_uses']" :top-total-col-rows="topTotalColRows['building_uses']"
                 :tree-data="false" />
      </div>

      <div class="toggle-button admin">
        <button @click="toggleShow('project_construction_types')" data-test-id="admins-project_construction_types-button">Toggle Project Construction Types</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('project_construction_types')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('project_construction_types') > -1">
        <ag-grid title="Project Construction Types" :url="urls['project_construction_types']" :foreign-keys="foreignKeys['project_construction_types']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['project_construction_types']" :top-total-col-rows="topTotalColRows['project_construction_types']"
                 :tree-data="false" />
      </div>

<!--      <div class="toggle-button admin">-->
<!--        <button @click="toggleShow('users')" data-test-id="admins-users-button">Toggle Users</button>-->
<!--        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('users')" />-->
<!--      </div>-->
<!--      <div class="tile" v-if="shown.indexOf('users') > -1">-->
<!--        <ag-grid title="Users" :url="urls['users']" :foreign-keys="foreignKeys['users']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['users']" :top-total-col-rows="topTotalColRows['users']" />-->
<!--      </div>-->

      <!-- People / Management section -->
      <div class="toggle-button management">
        <button  @click="toggleShow('contractors')" data-test-id="admins-contractors-button">Toggle Contractors</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('contractors')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('contractors') > -1">
        <ag-grid title="Contractors" :url="urls['contractors']" :foreign-keys="foreignKeys['contractors']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['contractors']" :top-total-col-rows="topTotalColRows['contractors']" />
      </div>

      <div class="toggle-button management">
        <button  @click="toggleShow('investor_sponsors')" data-test-id="admins-investor_sponsors-button">Toggle Investor Sponsors</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('investor_sponsors')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('investor_sponsors') > -1">
        <ag-grid title="Investor Sponsors" :url="urls['investor_sponsors']" :foreign-keys="foreignKeys['investor_sponsors']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['investor_sponsors']" :top-total-col-rows="topTotalColRows['investor_sponsors']" />
      </div>

      <div class="toggle-button management">
        <button  @click="toggleShow('owner_allocations')" data-test-id="admins-owner_allocations-button">Toggle Owner Allocations</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('owner_allocations')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('owner_allocations') > -1">
        <ag-grid title="Owner Allocations" :url="urls['owner_allocations']" :foreign-keys="foreignKeys['owner_allocations']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['owner_allocations']" :top-total-col-rows="topTotalColRows['owner_allocations']" />
      </div>

      <div class="toggle-button management">
        <button  @click="toggleShow('consultants')" data-test-id="admins-consultants-button">Toggle Consultants</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('consultants')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('consultants') > -1">
        <ag-grid title="Consultants" :url="urls['consultants']" :foreign-keys="foreignKeys['consultants']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['consultants']" :top-total-col-rows="topTotalColRows['consultants']" />
      </div>

      <div class="toggle-button management">
        <button  @click="toggleShow('consultant_projects')" data-test-id="admins-consultant-projects-button">Toggle Consultant Projects</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('consultant_projects')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('consultant_projects') > -1">
        <ag-grid title="Consultant Projects" :url="urls['consultant_projects']" :foreign-keys="foreignKeys['consultant_projects']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['consultant_projects']" :top-total-col-rows="topTotalColRows['consultant_projects']" />
      </div>

      <!-- Projects section -->
      <div class="toggle-button projects">
        <button  @click="toggleShow('documents')" data-test-id="admins-documents-button">Toggle Documents</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('documents')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('documents') > -1">
        <ag-grid title="Documents" :url="urls['documents']" :foreign-keys="foreignKeys['documents']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['documents']" :top-total-col-rows="topTotalColRows['documents']" />
      </div>

      <div class="toggle-button projects">
        <button  @click="toggleShow('projects')" data-test-id="admins-projects-button">Toggle Projects</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('projects')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('projects') > -1">
        <ag-grid title="Projects" :url="urls['projects']" :foreign-keys="foreignKeys['projects']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['projects']" :top-total-col-rows="topTotalColRows['projects']" />
      </div>

      <div class="toggle-button projects">
        <button @click="toggleShow('project_phases')" data-test-id="admins-project_phases-button">Toggle Project Phases</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('project_phases')" :show-circle="true" />
      </div>
      <div class="tile" v-if="shown.indexOf('project_phases') > -1">
        <ag-grid title="Project Phases" :url="urls['project_phases']" :foreign-keys="foreignKeys['project_phases']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['project_phases']" :top-total-col-rows="topTotalColRows['project_phases']" />
      </div>

      <div class="toggle-button projects">
        <button @click="toggleShow('project_buildings')" data-test-id="admins-project_buildings-button">Toggle Project Buildings</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('project_buildings')" :show-circle="true" />
      </div>
      <div class="tile" v-if="shown.indexOf('project_buildings') > -1">
        <ag-grid title="Project Buildings" :url="urls['project_buildings']" :foreign-keys="foreignKeys['project_buildings']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['project_buildings']" :top-total-col-rows="topTotalColRows['project_buildings']" />
      </div>

      <div class="toggle-button projects">
        <button @click="toggleShow('project_jobs')" data-test-id="admins-project_jobs-button">Toggle Project Jobs</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('project_jobs')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('project_jobs') > -1">
        <ag-grid title="Project Jobs" :url="urls['project_jobs']" :foreign-keys="foreignKeys['project_jobs']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['project_jobs']" :top-total-col-rows="topTotalColRows['project_jobs']" />
      </div>

      <div class="toggle-button projects">
        <button @click="toggleShow('project_schedules')" data-test-id="admins-project_schedules-button">Toggle Project Schedules</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('project_schedules')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('project_schedules') > -1">
        <ag-grid title="Project Schedules" :url="urls['project_schedules']" :foreign-keys="foreignKeys['project_schedules']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['project_schedules']" :top-total-col-rows="topTotalColRows['project_schedules']" />
      </div>

      <div class="toggle-button projects">
        <button @click="toggleShow('scope_of_works')" data-test-id="admins-scope_of_works-button">Toggle Scope of Works</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('scope_of_works')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('scope_of_works') > -1">
        <ag-grid title="Scope of Work" :url="urls['scope_of_works']" :foreign-keys="foreignKeys['scope_of_works']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['scope_of_works']" :top-total-col-rows="topTotalColRows['scope_of_works']" />
      </div>

      <!-- Math section -->
      <div class="toggle-button math">
        <button @click="toggleShow('draws')" data-test-id="admins-draws-button">Toggle Draws</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('draws')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('draws') > -1">
        <ag-grid title="Draws" :url="urls['draws']" :foreign-keys="foreignKeys['draws']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['draws']" :top-total-col-rows="topTotalColRows['draws']"
                 :tree-data="false" />
      </div>

      <div class="toggle-button math">
        <button @click="toggleShow('draw_details')" data-test-id="admins-draw_details-button">Toggle Draw Details</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('draw_details')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('draw_details') > -1">
        <ag-grid title="Draw Details" :url="urls['draw_details']" :foreign-keys="foreignKeys['draw_details']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['draw_details']" :top-total-col-rows="topTotalColRows['draw_details']"
                 :tree-data="false" />
      </div>

      <div class="toggle-button math">
        <button @click="toggleShow('general_contractor_g702s')" data-test-id="admins-general_contractor_g702s-button">Toggle GC702</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('general_contractor_g702s')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('general_contractor_g702s') > -1">
        <ag-grid title="GC702" :url="urls['general_contractor_g702s']" :foreign-keys="foreignKeys['general_contractor_g702s']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['general_contractor_g702s']"
                 :tree-data="false" />
      </div>

      <div class="toggle-button math">
        <button @click="toggleShow('general_contractor_g703s')" data-test-id="admins-general_contractor_g703s-button">Toggle GC703</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('general_contractor_g703s')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('general_contractor_g703s') > -1">
        <ag-grid title="GC703" :url="urls['general_contractor_g703s']" :foreign-keys="foreignKeys['general_contractor_g703s']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['general_contractor_g703s']"
                 :tree-data="false" />
      </div>

      <!-- Derived section -->
      <div class="toggle-button derived">
        <button @click="toggleShow('mvw_most_recent_by_projectables')" data-test-id="admins-mvw_most_recent_by_projectables-button">Toggle Most Recent By Projectables</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('mvw_most_recent_by_projectables')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('mvw_most_recent_by_projectables') > -1">
        <ag-grid title="Most Recent By Projectables" :url="urls['mvw_most_recent_by_projectables']" :foreign-keys="foreignKeys['mvw_most_recent_by_projectables']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['mvw_most_recent_by_projectables']" :top-total-col-rows="topTotalColRows['mvw_most_recent_by_projectables']" />
      </div>

      <div class="toggle-button derived">
        <button @click="toggleShow('mvw_most_recent_by_projects')" data-test-id="admins-mvw_most_recent_by_projects-button">Toggle Most Recent By Projects</button>
        <ExpandCollapse :chevron-fill="chevronFillColor" :show-double-chevron="true" :is-open-initially="false" :is-vertical-center="true" open-state="down" closed-state="right" @toggle-expand-collapse="toggleShow('mvw_most_recent_by_projects')" :show-circle="true"/>
      </div>
      <div class="tile" v-if="shown.indexOf('mvw_most_recent_by_projects') > -1">
        <ag-grid title="Most Recent By Projects" :url="urls['mvw_most_recent_by_projects']" :foreign-keys="foreignKeys['mvw_most_recent_by_projects']" :auth-token="authToken" :full-scroll="true" :override-width="true" :columnDefs="columnDefs['mvw_most_recent_by_projects']" :top-total-col-rows="topTotalColRows['mvw_most_recent_by_projects']" />
      </div>
    </div>
    <div v-else-if="isFinanceAdminOrAdmin">
      <div class="tile">
        <div v-if="isFinanceAdminOrAdmin" class="tile-header">
          <div class="tile-header-text">Simulate another user:</div>
          <button class="action-button" @click="toggleSimulateUser('Sales')" data-test-id="admins-simulated-roles-sales">Sales</button>
          <button class="action-button" @click="toggleSimulateUser('Finance')" data-test-id="admins-simulated-roles-finance">Finance</button>
          <button v-if="isSuperAdminFinanceAdmin" class="action-button" @click="toggleSimulateUser('Admin')" data-test-id="admins-simulated-roles-admin">Admin</button>
          <button v-if="isSuperAdmin" class="action-button" @click="toggleSimulateUser('FinanceAdmin')" data-test-id="admins-simulated-roles-finance-admin">Finance Admin</button>
          <button class="action-button" @click="toggleSimulateUser(null)" data-test-id="admins-simulated-roles-clear">Clear Simulation</button>
        </div>
      </div>
    </div>
    <div v-else>
      <div data-test-id="admin-no-permission">You do not have permissions to view this page.</div>
    </div>
  </div>
</template>

<script setup>
import {computed, reactive, onMounted, ref} from 'vue'
import { useAuthStore } from "@/stores/auth_store"
import AgGrid from "../components/AgGrid.vue"
import ExpandCollapse from "../components/helpers/ExpandCollapse.vue"
// import { useErrorStore } from "@/stores/error_store"
// const errorStore = useErrorStore()
// import axios from "axios"
// import {useRouter} from "vue-router"

const chevronFillColor = '#6ED0CD'

// TODO neater way to do this?
let authToken = reactive({})
const authStore = useAuthStore()
let isDeveloperAdmin = ref(authStore.isDeveloperAdmin)
let isFinanceAdminOrAdmin = ref(authStore.isFinanceAdminOrAdminSkipSimulation)
let isSuperAdminFinanceAdmin = ref(authStore.isSuperAdminOrFinanceAdminSkipSimulation)
let isSuperAdmin = ref(authStore.isSuperAdminSkipSimulation)
let isCurrentlyAdmin = ref(authStore.isFinanceAdminOrAdmin)
authStore.$subscribe(checkUserRole)
function checkUserRole() {
  isDeveloperAdmin.value = authStore.isDeveloperAdmin
  isFinanceAdminOrAdmin.value = authStore.isFinanceAdminOrAdminSkipSimulation
  isSuperAdminFinanceAdmin.value = authStore.isSuperAdminOrFinanceAdminSkipSimulation
  isSuperAdmin.value = authStore.isSuperAdminSkipSimulation
  isCurrentlyAdmin.value = authStore.isFinanceAdminOrAdmin
}

onMounted(() => {
  authToken.value = authStore.getRefreshTokenParam
})

const foreignKeys = reactive({ })
const showColumns = reactive({
  // Admin
  // Hide for now for some security
  // users: ['id', 'first_name', 'middle_initial', 'last_name', 'title'],
  codes: ['id', 'type', 'code', 'description'],
  dda_terms_proper: ['itemHierarchy', 'is_leaf_node', 'is_total', 'is_proper_noun', 'id', 'term', 'primary_dda_term_id', 'dda_term_category_id', 'ml_accuracy_rate'],
  dda_terms: ['itemHierarchy', 'id', 'term', 'primary_dda_term_id', 'is_total', 'is_proper_noun', 'dda_term_category_id', 'ml_accuracy_rate'],
  amenities: ['id', 'project_id', 'name', 'description'],
  building_uses: ['id', 'project_building_id', 'project_building_id_s', 'codeable_id', 'codeable_id_s', 'codeable_type','asset_category_id','asset_category_id_s','total_gsf', 'num_units', 'num_spaces', 'affordable_program'],
  project_construction_types: ['id', 'project_id', 'codeable_id', 'codeable_type', 'former_asset_category_id'],

  // Management
  contractors: ['id', 'name', 'is_self_performed', 'address_line_1', 'state', 'zipcode'],
  investor_sponsors: ['id', 'name', 'is_self_performed', 'address_line_1', 'state', 'zipcode', 'created_at', 'updated_at'],
  owner_allocations: ['id', 'projectable_id', 'projectable_type', 'investor_sponsor_id', 'investment_role_id', 'investment_role_id_s', 'is_primary', 'position_1', 'legal_entity_name', 'partnership', 'closing_date', 'website', 'loan_address_line_1', 'loan_state', 'loan_zip', 'created_at', 'updated_at'],
  consultants: ['id', 'name', 'address_line_1', 'state', 'zipcode', 'created_at', 'updated_at'],
  consultant_projects: ['id', 'consultant_id', 'project_id', 'consultant_type_id', 'consultant_type_id_s', 'created_at', 'updated_at'],

  
  // Projects
  documents: ['id','filepath','status','period_through','draw_number','project_id','error','created_at','updated_at'],
  projects: ['id','status', 'is_released','name','entity_name','address_line_1','address_line_2','city','state','zipcode','num_floors','total_height','initial_gsf','total_gsf','total_num_units','image_url','finish_level_id','total_original_budget','actual_start_date','actual_end_date','project_length_months','is_historic_has_landmark','sustainability_component','is_modular','notes','latitude','longitude','created_by_id','updated_by_id','deleted_by_id','deleted_at','created_at','updated_at'],
  project_phases: ['id','project_id','name','initial_square_feet','final_square_feet','description'],
  project_buildings: ['id','project_id','name','initial_square_feet','final_square_feet','description'],
  project_jobs: ['id','project_id','name','initial_square_feet','final_square_feet','description'],
  project_schedules: ['id','project_id','contractor_id','scope_of_work_id','submitting_entity','projected_start','projected_end','actual_start','actual_end','project_duration','submitted_term','lock_dda_term_assignment', 'dda_term_id','dda_term_name', 'itemHierarchy'],
  scope_of_works: ['id','project_id','projectable_id','projectable_type','contractor_id','primary_scope_of_work_id','dda_term_id','dda_term_name','description','projected_cost','final_cost','projected_start','projected_end','projected_project_months','actual_start','actual_end','actual_project_months','ml_accuracy_rate','lock_dda_term_assignment','itemHierarchy'],

  // Math
  draws: ['id','projectable_name','projectable_id','projectable_type','document_id','draw_number','date_submitted','period_through','created_at','updated_at'],
  draw_details: ['id','draw_id','projectable_name','projectable_id','projectable_type','document_id','document_filepath','draw_number','date_submitted','period_through','dda_term_id','dda_term_name','description','original_budget','previous_adjustments','current_adjustments','total_adjustments','current_budget','previously_funded','current_request','total_completed_to_date','total_completed_to_date_pct','balance_to_complete','previous_retainage_held','current_retainage_held','total_retainage_held','budget_increase','created_at','updated_at','lock_dda_term_assignment','is_leaf_node','itemHierarchy','created_at','updated_at'],
  general_contractor_g702s: ['id','projectable_name','projectable_id','projectable_type','contractor_id','contractor_id_s','investor_sponsor_ids','investor_sponsors_s','document_id','document_filepath','scope_of_work_id','draw_detail_id','scope_detail','application_number','period_through','image_url','original_contract_sum','net_change_by_change_order','contract_sum_to_date','total_completed_and_stored_to_date','total_earned_less_retainage','less_previous_certificates_for_payment','current_payment_due','balance_to_finish_including_retainage','retainage','created_at','updated_at'],
  general_contractor_g703s: ['id','projectable_name','general_contractor_g702_id','projectable_id','projectable_type','contractor_id_s','document_id','document_filepath','scope_detail','application_number','period_through','contractor_id','dda_term_id','dda_term_name','draw_detail_id','description','original_budget','all_budget_transfers','previous_adjustments','current_adjustments','total_adjustments','current_budget','previously_funded','current_request','materials_presently_stored','total_completed_to_date','total_completed_to_date_pct','balance_to_complete','previous_retainage_held','total_retainage_held','current_retainage_held','budget_increase','created_at','updated_at','lock_dda_term_assignment','is_leaf_node','itemHierarchy','created_at','updated_at'],
  mvw_most_recent_by_projectables: ['id','project_id','projectable_type','projectable_id','dda_term_id','dda_term_name','scope_detail','application_numbers','details_application_number','g703_application_number','detailed_ids','details_detailed_ids','g703_detailed_ids','detailed_descriptions','details_detailed_descriptions','g703_detailed_descriptions','original_budget','all_budget_transfers','previous_adjustments','current_adjustments','total_adjustments','total_cost','current_budget','budget_increase','previously_funded','current_request','materials_presently_stored','total_completed_to_date','total_completed_to_date_pct_avg','balance_to_complete','previous_retainage_held','total_retainage_held','current_retainage_held','query_type','details_summary_id','g703_summary_id','contractor_id','summary_draw_detail_id','details_detailed_draw_detail_id','g703_detailed_draw_detail_id','min_created_at','min_created_by_id','max_updated_at','max_updated_by_id','max_deleted_at','max_deleted_by_id','is_leaf_node','itemHierarchy'],
  mvw_most_recent_by_projects: ['id','project_id','dda_term_id','dda_term_name','scope_detail','application_numbers','details_application_number','g703_application_number','detailed_ids','details_detailed_ids','g703_detailed_ids','detailed_descriptions','details_detailed_descriptions','g703_detailed_descriptions','original_budget','all_budget_transfers','previous_adjustments','current_adjustments','total_adjustments','total_cost','current_budget','budget_increase','pct_of_total_original_budget','previously_funded','current_request','materials_presently_stored','total_completed_to_date','total_completed_to_date_pct_avg','balance_to_complete','previous_retainage_held','total_retainage_held','current_retainage_held','query_types','details_summary_ids','g703_summary_ids','contractor_ids','summary_draw_detail_ids','details_detailed_draw_detail_ids','g703_detailed_draw_detail_ids','min_created_at','min_created_by_id','max_updated_at','max_updated_by_id','max_deleted_at','max_deleted_by_id','is_leaf_node','itemHierarchy'],
})
// Need to add all cols to the select list so we get foreign keys
const urls = reactive({ })
const apiKey = process.env.VUE_APP_ENV_API_KEY
Object.keys(showColumns).forEach(col => {
  if (col === 'dda_terms_proper') {
    urls[col] = 'dda_terms?is_proper=true&select=' + showColumns[col].join(',')
  } else if (col === 'documents') {
    urls[col] = `documents?api_key=${apiKey}`
  } else if (col === 'general_contractor_g703s' || col === 'draw_details') {
    urls[col] = col + '?optional_dda_term_id=1&select=' + showColumns[col].join(',')
  } else {
    urls[col] = col + '?select=' + showColumns[col].join(',')  
  }
})

const topTotalColRows = reactive({})
Object.keys(showColumns).forEach(table => {
  topTotalColRows[table] = []
  showColumns[table].forEach(col => {
    if (!col.match(/_id$/i) && !col.match(/_name$/i) && !col.match(/_at$/i) && !col.match(/_date/i) &&
        !['id', 'name', 'term', 'description'].includes(col)) {
      topTotalColRows[table].push(col)
    }
  })
})

const columnDefs = computed(() => {
  let columnDefinitions = {}
  Object.keys(showColumns).forEach(table => {
    columnDefinitions[table] = []
    showColumns[table].forEach(columnName => {
      let opts = {
        headerName: columnName,
        field: columnName,
        editable: false,
        filter: true,
        floatingFilter: true,
        tooltipField: columnName
      }
      // freeze/pin itemHierarchy to the left so it can be seen when scrolling (esp. for Schedule page)
      if (columnName === 'id' || columnName === 'dda_term_id' || columnName === 'dda_term_name' ||
          columnName === 'description' ||
          columnName === 'project_name' || columnName === 'projectable_name' || columnName === 'project_id' ||
          columnName === 'projectable_id') {
        opts.pinned = 'left'
      }
      columnDefinitions[table].push(opts)
    })
  })

  return columnDefinitions
})

const shown = reactive([])
function toggleShow (type) {
  let index = shown.indexOf(type)
  if (index > -1) {
    delete shown[index]
  } else {
    shown.push(type)
  }
}

function toggleSimulateUser(role) {
  if (isFinanceAdminOrAdmin.value) {
    authStore.setSimulatedRole(role)
  }
}

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.admin-container {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.toggle-button {
  font-family: 'Inter', 'Avenir', Helvetica, Arial, sans-serif;
  position: relative;
  cursor: pointer;
  background-color: $brightBlue1;
  border: 8px solid $brightBlue1;
  border-radius: 4px;
  margin: 8px;
  padding-left: 12px;
  width: 250px;
}
button {
  font-weight: bold;
  color: black;
  background-color: transparent;
  border-color: transparent;
  border-width: 0;
  border-radius: 0;
}

// colors for admin sections
.admin {
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}
.projects {
  background-color: #d1e6c9;
  border-color: #d1e6c9;
}
.math {
  background-color: #94b3f1;
  border-color: #94b3f1;
}
.derived {
  background-color: #a494cc;
  border-color: 	#a494cc;
}
.management {
  background-color: #ebb852;
  border-color: 	#ebb852;
}
</style>
