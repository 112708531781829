<template>
  <div :class="isSidebarOpen ? 'open' : 'closed'" class="side-bar bak-white-1" data-test-id="sidebar">
    <ExpandCollapse name="sidebar" :is-open-initially="true" :is-vertical-center="false" open-state="left"
      closed-state="right-zero" :show-double-chevron="true"
      @toggle-expand-collapse="toggleSidebar(); $emit('resize-sidebar')" :show-circle="true" />
    <div class="side-bar-content" :class="!isSidebarOpen ? 'hidden' : ''">
      <div>
        <div class="side-bar-title">Comp Set Averages</div>
        <div class="side-bar-item summary-data">
          <div class="compset-data-summary">
            <span class="label">Use Type(s)</span>
            <span class="summary-value">{{ useTypes?.length > 0 ? useTypes.join('; ') : '-' }}</span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Gross Sq Ft</span>
            <span class="summary-value">{{ projectSizeValue ? numberFormat(projectSizeValue, 0) : 0 }}</span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Unit Count</span>
            <span class="summary-value">{{ unitsValue ? numberFormat(unitsValue, 0) : 0 }} </span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Parking Count</span>
            <span class="summary-value">{{ parkingCountValue ? numberFormat(parkingCountValue, 0) : 0 }} </span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Stories</span>
            <span class="summary-value">{{ storiesValue ? numberFormat(storiesValue, 0) : 0 }} </span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Construction Type</span>
            <span class="summary-value">{{ constructionTypes?.length > 0 ? constructionTypes.join('; ') : '-' }}</span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Construction Duration (mo)</span>
            <span class="summary-value">{{ durationMonthsValue ? numberFormat(durationMonthsValue, 0) : 0 }} </span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Year Completed (range)</span>
            <span class="summary-value">{{ yearCompletedRange }} </span>
            
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div :class="!isSidebarOpen ? 'hidden' : ''" class="escalation">
        <div class="side-bar-title-items">Apply Escalation</div>
        <div class="escalation-options">
          <div class="escalation-option cpi">
            <span class="escalation-label font-pale-blue-1">CPI (YoY)</span>
            <span class="escalation-checkbox">
              <label class="custom-checkbox">
                <input type="checkbox" style="display: none;" id="cpi" :value="null">
                <span class="checkmark"></span>
              </label>
            </span>
          </div>
          <div class="escalation-option or">
            <span class="escalation-label">or</span>
          </div>
          <div class="escalation-option custom">
            <span class="escalation-label">Custom (YoY)</span>
            <span class="escalation-input">
              <input type="number" step="0.01" min="0.01" max="100.0" id="escalation" :value="escalation"
                @input="updateEscalation($event)" v-on:keyup.enter="updateEscalation($event)" />%
            </span>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div :class="!isSidebarOpen ? 'hidden' : ''">
        <div class="compset-headers">
          <div class="side-bar-title-items">Active Comp Set</div>
          <div class="side-bar-title-items compset-count">{{ compSetCount }}</div>
        </div>
        <div class="header-instructions">View aggregate data from your selected compset. You may add or remove projects
          to view the change in results.</div>
        <div class="side-bar-item comp-set-assets">
          <ul v-if="currentProjects?.length">
            <li class="asset-images-options" v-for="(project, index) in currentProjects" :key="index">
              <div class="property-address">
                <strong>{{ project.address_line_1 }}</strong>
                <label class="custom-checkbox">
                  <input type="checkbox" style="display: none;" :id="project.id" :value="project.value"
                    @change="toggleSelected(project.id)" :checked="isProjectSelected(project.id)">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="active-compset-details-container">
                <!-- todo : if there is no image url just have a default value? -->
                <div class="property-thumbnail"><img
                    :src="project.image ? require(project.image_url) : require(`../../assets/building_assets/property1.jpg`)">
                </div>
                <div class="property-info">
                  <div class="property-details">
                    <div class="property-building-info">
                      <div class="property-info-item">
                        <div class="property-info-label">Use Type(s)</div>
                        <div class="property-info-value">{{ project.building_use_types_abv?.length > 0 ? project.building_use_types_abv.join('; ') : '-' }}
                        </div>
                      </div>
                      <div class="property-info-item">
                        <div class="property-info-label">Gross Sq Ft</div>
                        <div>{{ project.total_gsf ? numberFormat(project.total_gsf, 0) : '-' }}
                        </div>
                      </div>
                      <div class="property-info-item">
                        <div class="property-info-label">Unit Count</div>
                        <div>{{ project.total_num_units ? numberFormat(project.total_num_units, 0) : '-' }}</div>
                      </div>
                      <div class="property-info-item">
                        <div class="property-info-label">Parking Count</div>
                        <div>{{ project.parking_count ? numberFormat(project.parking_count, 0) : '-' }}</div>
                      </div>
                      <div class="property-info-item">
                        <div class="property-info-label">Stories</div>
                        <div>{{ project.num_floors ? numberFormat(project.num_floors, 0) : '-' }}</div>
                      </div>
                      <div class="property-info-item">
                        <div class="property-info-label">Construction Type</div>
                        <div class="property-info-value">{{ project.project_construction_types_abv?.length > 0 ? project.project_construction_types_abv.join('; ') : '-'
                          }}</div>
                      </div>
                      <div class="property-info-item">
                        <div class="property-info-label">Year Completed</div>
                        <div>{{ project.actual_end_date ? extractDate(project.actual_end_date) : '-' }}</div>
                      </div>
                      <div class="property-info-item">
                        <div class="property-info-label">Const. Duration (mo)</div>
                        <div>{{ project.project_length_months ? parseFloat(project.project_length_months).toFixed(0) : '-' }}
                        </div>
                      </div>
                      <div class="property-info-item">
                        <div class="property-info-label">Finish Level</div>
                        <div>{{ project.finish_level ? project.finish_level.code : '-' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="index + 1 != currentProjects.length ? 'divider-comp-set' : ''">
                <div></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"
import ExpandCollapse from "../helpers/ExpandCollapse.vue"
import { useCompSetStore } from "@/stores/comp_set_store";
import { numberFormat } from "@/assets/js/utils";

let isSidebarOpen = ref(true)
function toggleSidebar() {
  isSidebarOpen.value = !isSidebarOpen.value
}
const compSetStore = useCompSetStore()
const currentProjects = ref(compSetStore.getIncludedProjects)
const escalation = ref(compSetStore.getEscalation)

let useTypes = ref(null)
let projectSizeValue = ref(0);
let unitsValue = ref(0);
let storiesValue = ref(0);
let parkingCountValue = ref(0);
let durationMonthsValue = ref(0);
let constructionTypes = ref(null);
let yearCompletedRange = ref(null);

let perProjectGSF = ref({})
let compSetCount = "(" + Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]).length + ")"

// load data from sever
// placeholder codes
onMounted(() => {
  // Enable auth to load fully before updating data (not sure if this completely makes sense)
  initializeProjects()
})

compSetStore.$subscribe(syncProjects);
async function syncProjects() {
  const storedProjects = compSetStore.getIncludedProjects
  compSetCount = "(" + Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]).length + ")"
  // See if project array has actually changed, as we only want to query the backend if there was a real change.
  if (JSON.stringify(storedProjects) !== JSON.stringify(currentProjects.value)) {
    const transformedData = currentProjects.value.reduce((acc, item) => {
      acc[item.id] = compSetStore.isProjectSelected(item.id);
      return acc;
    }, {});

    compSetStore.selectedProjects = transformedData;

    await adjustAverages()
  }
}

function initializeProjects() {
  const includedProjects = compSetStore.getIncludedProjects;
  for (let index = 0; index < includedProjects.length; index++) {
    const projectId = includedProjects[index].toString();
    if (Object.prototype.hasOwnProperty.call(compSetStore.selectedProjects, projectId)) {
      compSetStore.selectedProjects[projectId] = true;
    }
  }
  adjustAverages();
}

function toggleSelected(projectId) {
  // Toggle the selection status of the project in compSetStore
  compSetStore.toggleProjectSelection(projectId);
  // Adjust the comp set average values on toggle
  adjustAverages();
  // Set the number of active comp set
  compSetCount = "(" + Object.keys(compSetStore.selectedProjects).filter(id => compSetStore.selectedProjects[id]).length + ")";
}

function isProjectSelected(projectId) {
  return compSetStore.isProjectSelected(projectId);
}

// Adjust the Comp Set Averages
function adjustAverages() {
  const newUseTypes = [];
  const newTotalGSF = [];
  const newNumUnits = [];
  const newNumFlrs = [];
  const newParkingCount = [];
  const newDurationMonths = [];
  const newConstructionTypes = [];
  const newYearCompletedRange = [];

  if (compSetStore.getIncludedProjects.length > 0) {
    for (const project of currentProjects.value) {
      // Check if the project is selected
      if (compSetStore.selectedProjects[project.id]) {
        newUseTypes.push(...project.building_use_types_abv);
        newTotalGSF.push(project.total_gsf);
        newNumUnits.push(project.total_num_units);
        newNumFlrs.push(project.num_floors);
        newParkingCount.push(project.parking_count);
        newDurationMonths.push(project.project_length_months !== null ? parseFloat(project.project_length_months).toFixed(0) : 0);
        newConstructionTypes.push(...project.project_construction_types_abv);
        newYearCompletedRange.push(project.actual_end_date)
        // update perProjectGSF for doughnut chart
        perProjectGSF.value[project.id] = project.total_gsf
      }
    }
    useTypes.value = removeDuplicates(newUseTypes);
    projectSizeValue.value = getAverage(newTotalGSF);
    unitsValue.value = getAverage(newNumUnits);
    storiesValue.value = getAverage(newNumFlrs);
    parkingCountValue.value = getAverage(newParkingCount);
    durationMonthsValue.value = getAverage(newDurationMonths.map(Number));
    constructionTypes.value = removeDuplicates(newConstructionTypes);
    yearCompletedRange.value = getYearRange(newYearCompletedRange);
  } else {
    useTypes.value = null;
    // Reset to zero if none is selected
    projectSizeValue.value = 0;
    unitsValue.value = 0;
    storiesValue.value = 0;
    parkingCountValue.value = 0;
    durationMonthsValue.value = 0;
    constructionTypes.value = null;
    yearCompletedRange.value = null;
  }
  compSetStore.setAvgTotalGSF(projectSizeValue.value)
  compSetStore.setPerProjectGSF(perProjectGSF.value)
}

// Remove duplicates in a string array
function removeDuplicates(array) {
  return array.filter((item, index) => array.indexOf(item) === index);
}

function getYearRange(array) {
  // Assuming newYearCompletedRange is an array of string dates in the format "yyyy-mm-dd"
  const years = array.map(date => date.split('-')[0]); // Extract the year part from each date
  const uniqueYears = [...new Set(years)]; // Remove duplicates

  if(uniqueYears.length === 0) {
    return '-'; // Return a dash if there are no unique years
  } 
  // Check if there's only one unique year
  else if (uniqueYears.length === 1) {
    return `${uniqueYears[0]}`; // Return an array with that year
  } else {
    const minYear = Math.min(...uniqueYears); // Find the minimum year
    const maxYear = Math.max(...uniqueYears); // Find the maximum year
    return `${minYear.toString() +  ` - ` + maxYear.toString()}`; // Convert to string array
  }
}

// Calculate the average of two or more numbers inside an array
function getAverage(numbers) {
  // Calculate the sum of all the numbers
  const sum = numbers.reduce((acc, number) => acc + number, 0);
  // Calculate the average
  const average = sum / numbers.length;
  return average;
}

function updateEscalation($event) {
  const newEscalation = $event.target.value
  if (!newEscalation || (newEscalation > 0 && newEscalation <= 100)) {
    // Maintain the escalation input, and also update the comp set store
    escalation.value = newEscalation
    compSetStore.setEscalation(escalation.value)
  }
}

const extractDate = (dateStr) => {
  if (dateStr) {
    const dateParts = dateStr.split('-');
    return dateParts[0];
  } else {
    return '';
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.side-bar {
  position: fixed;
  display: flex;
  height: 100vh;
  top: 50px;
  background: $white1;
  box-shadow: 6px 0 18px rgba(0, 0, 0, 0.06);
  z-index: 5;
}

.side-bar-title {
  font-size: 16px;
  font-family: 'Inter';
  font-weight: bold;
  color: $navyBlue;
  padding-top: 10px;
}

.side-bar-title-items {
  font-size: 14px;
  font-family: 'Inter';
  font-weight: bold;
  color: $navyBlue;
  padding: 10px 0;
}

.side-bar.open {
  width: 300px;
  transition: width 0.5s ease;
}

.side-bar.closed {
  width: 0;
  transition: width 0.5s ease;
}

.side-bar-header {
  padding-top: 17px;
  font-family: 'Raleway', 'Avenir', Helvetica, Arial, sans-serif;
  font-weight: 550;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.25em;
  text-align: left;
  margin-left: 24px;
}

.hidden {
  display: none;
}

.side-bar-content {
  padding: 15px;
  overflow-y: scroll;
  margin-bottom: 50px;
}

.side-bar-content::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: $white1;
}

.side-bar-content::-webkit-scrollbar-thumb {
  background: $lightGray1;
}

.divider {
  border: 1px solid lightgray;
}

.side-bar-item {
  display: flex;
  flex-direction: column;
}

.header-instructions {
  color: #A7A9AD;
  font-size: 12px;
  margin-bottom: 15px;
}

.summary-data,
.escalation {
  margin: 20px 0;
}

.escalation-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .escalation-label {
    font-size: 12px;
    padding: 10px;
  }

  .or {
    justify-content: center;
    padding-left: 15px;
  }

  .custom {
    .escalation-input {
      display: flex;
      justify-content: center;

      input[type=text] {
        color: $brightBlue1;
        background-color: $lightGray1;
        border: 1px solid $lightGray1;
        border-radius: 5px;
        padding: 5px;
        height: 10px;
        width: 30%;
      }

      input[type=text]:focus {
        border-color: $brightBlue1;
        outline: $brightBlue1;
      }
    }
  }
}

.escalation-option {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.custom-text {
  width: 40px;
  margin: 5px 5px 5px 10px;
  height: 15px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.custom-text::placeholder {
  color: #6ED0CD;
  font-size: 12px;
}

.cpi input {
  width: 30px;
  height: 15px;
  margin: 5px 5px 5px 10px;
  border-radius: 2%;
}

span {
  font-size: 12px;
}

span.summary-value {
  color: $navyBlue;
  float: right;
}

span.label {
  color: #494C56;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.property-detail {
  flex: 4;

  span {
    vertical-align: top;
    font-size: 12px;
  }
}

.property-header {
  display: flex;
  font-size: 12px;
}

.name {
  font-weight: bold;
  margin-right: 5px;
}

.property-summary {
  margin-top: 10px;
  margin-right: 30px;
}

.property-summary-value {
  float: right;
  color: #6ED0CD;
}

h4 {
  display: flex;
  font-size: 16px;
}

h5,
h4 {
  color: #00005c;
}

.escalation-label {
  font-size: 12px;
}

.compset-headers {
  display: flex;
  flex-direction: row;
}

.compset-count {
  color: lightgray;
  margin-left: 5px;
}

.property-address {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0px;
  display: flex;
  justify-content: space-between;
  width: 93%;
  color: $navyBlue;
}

.active-compset-details-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 5px;
  color: $navyBlue;

  .property-thumbnail {
    display: flex;
    justify-content: center;
    border: .5px solid $lightGray1;
    border-radius: 2px;
    margin-bottom: 10px;

    img {
      width: 80px;
      height: 80px;
    }
  }

  .property-info {
    padding-left: 5px;
    width: 100%;

    .property-details {
      display: flex;
      flex-direction: row;

      .property-building-info,
      .property-construction-info {
        font-size: 11px;
        width: 100%;
      }

      .property-building-label,
      .property-construction-label {
        font-size: 13px;
        font-weight: bolder;
        padding-bottom: 5px;
      }

      .property-info-item {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding: 2px 0px;
        width: 100%;


        .property-info-value {
          text-align: right;
        }
      }
    }
  }
}

.divider-comp-set {
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    border: 1px solid lightgray;
    width: 50%;
    margin: 15px 15px 25px 15px;
  }
}

.compset-data-summary {
  display: flex;
  padding: 2px 0;

  .label {
    width: 100%;
  }

  .summary-value {
    width: 100%;
    text-align: right;
  }
}

.comp-set-assets {
  height: 50vh;
  ul {
    overflow-y: scroll;
    padding-right: 0.75rem;
  }
    ul::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: $white1;
  }

  ul::-webkit-scrollbar-thumb {
    background: $lightGray1;
  }
}
</style>