<template>
  <form class="project-information-form-container">
    <!-- Left Information-->
    <div>
      <!-- General Information -->
      <div class="information-main-title">General Information</div>
      <div class="tile">
        <div class="information-subtitle">Project Image</div>
        <div class="custom-text-input">
          <label class="left-div">Upload Image</label>
          <div class="right-div">
            <div class="file-upload-container">
              <input type="file" id="file-upload" class="file-input" @change="handleFileChange" />
              <label for="file-upload" class="file-upload-label">{{ fileLabel }}</label>
            </div>
          </div>
        </div>
        <div class="information-subtitle">Location & Status</div>
        <div class="custom-text-input">
          <label class="left-div">Building Name</label>
          <div class="right-div">
            <input type="text" v-model="form.buildingName" placeholder="--" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Street Address</label>
          <div class="right-div">
            <input type="text" v-model="form.streetAddress" placeholder="--" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">City</label>
          <div class="right-div">
            <input type="text" v-model="form.city" placeholder="--" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">State</label>
          <div class="right-div">
            <input type="text" v-model="form.state" placeholder="--" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div">Zip</label>
          <div class="right-div">
            <input type="text" @input="form.zip = $event.target.value.replace(/\D/g, '')" id="zip" v-model="form.zip"
              placeholder="--" />
          </div>
        </div>
        <div class="custom-select">
          <label class="left-div">Construction Status</label>
          <select class="right-div" id="constructionStatus" v-model="form.constructionStatus">
            <option value="" disabled hidden>Active/Complete</option>
            <option value="A">Active</option>
            <option value="C">Complete</option>
          </select>
        </div>
      </div>
      <!-- Construction Information -->
      <div class="information-main-title">Construction Information</div>
      <!-- Construction Type -->
      <div class="tile construction-type-container">
        <div>
          <div class="information-subtitle">Construction Type <span>(select all that apply)</span></div>
        </div>
        <ul>
          <li v-for="(constructionType, index) in constructionTypes" :key="index">
            <label class="custom-checkbox">
              <span :for="`constructionType-${constructionType.id}`" class="custom-checkbox-label"> {{
                constructionType.code }}</span>
              <input type="checkbox" style="display: none;" :id="`constructionType-${constructionType.id}`"
                v-model="selectedConstructionTypeIds[constructionType.id]" @click="toggleCheck(constructionType)"
                @change="updateConstructionTypes">
              <span class="checkmark"></span>
            </label>
            <div class="custom-select" v-if="constructionType.isChecked && constructionType.code == 'Adaptive Reuse'"
              style="padding: 0">
              <select class="right-div" id="constructionTypeAdaptiveReuse" v-model="form.constructionTypeAdaptiveReuse">
                <option value="" disabled hidden>Select Former Asset Category</option>
                <option v-for="(formerAssetCategory, indexFormerAsset) in formerAssetCategories" :key="indexFormerAsset"
                  :value="formerAssetCategory.id">
                  {{ formerAssetCategory.code }}</option>
              </select>
            </div>
          </li>
        </ul>
      </div>
      <!-- Construction Contract Type -->
      <div class="tile">
        <div class="information-subtitle">Construction Contract Type</div>
        <div class="custom-select">
          <label class="left-div">Enter Type</label>
          <select class="right-div" id="constructionContractType" v-model="form.constructionContractType">
            <option value="" disabled hidden>Select Construction Contract Type</option>
            <option v-for="(contractType, index) in contractTypes" :key="index" :value="contractType.id">{{
                contractType.code }}</option>
          </select>
        </div>
      </div>
      <!-- Building Characteristics -->
      <div class="tile">
        <div class="information-subtitle"> Building Characteristics</div>
        <div class="custom-text-input">
          <label class="left-div"> Building Height (Stories)</label>
          <div class="right-div">
            <input type="text" v-model="form.buildingHeightStories"
              @input="form.buildingHeightStories = $event.target.value.replace(/\D/g, '')"
              placeholder="Enter Total # of Above Grade Stories" />
          </div>
        </div>
        <div class="custom-text-input">
          <label class="left-div"> Building Height (Feet)</label>
          <div class="right-div">
            <input type="text" v-model="form.buildingHeightFeet"
              @input="form.buildingHeightFeet = $event.target.value.replace(/\D/g, '')"
              placeholder="Enter Highest Point in Feet" />
          </div>
        </div>
        <div class="custom-select">
          <label class="left-div">Historic/Landmarks Component?</label>
          <select class="right-div" id="historicLandmarksComponent" v-model="form.historicLandmarksComponent">
            <option value="" disabled hidden>Y/N</option>
            <option value="true">Y</option>
            <option value="false">N</option>
          </select>
        </div>
        <div class="custom-select">
          <label class="left-div">Sustainability Component</label>
          <select class="right-div" id="sustainabilityType" v-model="form.sustainabilityType">
            <option value="" disabled hidden>Select Sustainability Type</option>
            <option v-for="(sustainabilityType, index) in sustainabilityTypes" :key="index"
              :value="sustainabilityType.id">{{
                sustainabilityType.code }}</option>
          </select>
        </div>
        <div class="custom-select">
          <label class="left-div">Modular Build?</label>
          <select class="right-div" id="modularBuild" v-model="form.modularBuild">
            <option value="" disabled hidden>Y/N</option>
            <option value="true">Y</option>
            <option value="false">N</option>
          </select>
        </div>
      </div>
    </div>
    <!-- Right Information-->
    <div>
      <!-- Building Use and Square Footage -->
      <div class="information-main-title">Building Use and Square Footage</div>
      <div class="tile">
        <!-- New Use Category Type -->
        <table class="project-detail-table">
          <tr class="project-detail-table-headers">
            <td class="project-detail-table-header-desc">
              <div>New Use Category Type<span>(Select All That Apply)</span></div>
            </td>
            <td class="project-detail-table-sub-header square-feet-per-use-input"></td>
            <td class="project-detail-table-sub-header num-of-units-input"></td>
          </tr>
          <!-- Main categories-->
          <template v-for="newUseCategory in updatedUseCategories" :key="newUseCategory.id">
            <tr>
              <td class="new-use-category-checkbox-container category-checkbox-header ">
                <label class="custom-checkbox">
                  <span :for="newUseCategories[newUseCategory.id]" class="custom-checkbox-label"> {{ newUseCategory.code
                    }}</span>
                  <input type="checkbox" style="display: none;" :id="newUseCategory.id" :value="newUseCategory.id"
                    v-model="form.newUseCategoryIds[newUseCategory.id]" @click="toggleCheck(newUseCategory)">
                  <span class="checkmark"></span>
                </label>
                <div v-if="newUseCategory.isChecked && newUseCategory.subCategories">(Select All That Apply)</div>
              </td>
              <td>
                <div class="square-feet-per-use-header" v-if="newUseCategory.isChecked && newUseCategory.subCategories">
                  Gross Square Feet
                </div>
              </td>
              <td>
                <div class="num-of-units-header" v-if="newUseCategory.isChecked && newUseCategory.subCategories">
                  # Units
                </div>
              </td>
            </tr>
            <!-- Sub-categories -->
            <template v-if="newUseCategory.isChecked">
              <template v-for="subCategory in newUseCategory.subCategories" :key="subCategory.id">
                <tr>
                  <td class="sub-category-indent">
                    <label class="custom-checkbox">
                      <span :for="subCategory.code" class="custom-checkbox-label">{{ subCategory.code }}</span>
                      <input type="checkbox" style="display: none;" :id="subCategory.id" :value="subCategory.id"
                        v-model="form.subCategoryIds[subCategory.id]" @click="toggleCheck(subCategory)">
                      <span class="checkmark"></span>
                    </label>
                  </td>
                  <td v-if="subCategory.isChecked">
                    <div class="checkbox-text-input">
                      <input type="text"
                        @input="form.subCategoriesSquareFeetPerUse[subCategory.id] = $event.target.value.replace(/\D/g, '')"
                        :id="subCategory.id" v-model="form.subCategoriesSquareFeetPerUse[subCategory.id]"
                        placeholder="ex. 5000" />
                    </div>
                  </td>
                  <td v-if="subCategory.isChecked">
                    <div class="checkbox-text-input">
                      <input type="text"
                        @input="form.subCategoriesNumberOfUnits[subCategory.id] = $event.target.value.replace(/\D/g, '')"
                        :id="subCategory.id" v-model="form.subCategoriesNumberOfUnits[subCategory.id]"
                        placeholder="ex. 10" />
                    </div>
                  </td>
                </tr>
                <template
                  v-if="(subCategory.isChecked && subCategory.code == BUILDING_USE_TYPES_RESIDENTIAL.RENTAL_APARTMENT) || (subCategory.isChecked && subCategory.code == BUILDING_USE_TYPES_RESIDENTIAL.CONDOMINIUM)">
                  <tr>
                    <td class="brand-type-container">Affordable Component</td>
                    <td>
                      <div class="checkbox-text-input">
                        <input type="text"
                          @input="form.subCategoriesAffordableCompGSF[subCategory.id] = $event.target.value.replace(/\D/g, '')"
                          :id="subCategory.id" v-model="form.subCategoriesAffordableCompGSF[subCategory.id]"
                          placeholder="ex. 5000" />
                      </div>
                    </td>
                    <td>
                      <div class="checkbox-text-input">
                        <input type="text"
                          @input="form.subCategoriesAffordableCompNumUnits[subCategory.id] = $event.target.value.replace(/\D/g, '')"
                          :id="subCategory.id" v-model="form.subCategoriesAffordableCompNumUnits[subCategory.id]"
                          placeholder="ex. 10" />
                      </div>
                    </td>
                  </tr>
                </template>
                <template
                  v-if="(subCategory.isChecked && newUseCategory.code == ASSET_CATEGORY_TYPES.HOSPITALITY) || (subCategory.isChecked && subCategory.code == BUILDING_USE_TYPES_RESIDENTIAL.CO_LIVING)">
                  <tr>
                    <td class="brand-type-container">Brand Name/Flag</td>
                    <td>
                      <auto-complete-search-box :items="brandTypes" :display-key="'code'" :placeholder="'ex. Four Seasons Residences'"
                        @item-selected="updateBrandType($event, subCategory.id)" />
                    </td>
                  </tr>
                </template>
                <template
                  v-if="(subCategory.isChecked && subCategory.code == BUILDING_USE_TYPES_RESIDENTIAL.CONDOMINIUM)">
                  <tr>
                    <td class="brand-type-container">Branded Residences</td>
                    <td>
                      <auto-complete-search-box :items="brandTypes" :display-key="'code'" :placeholder="'ex. Four Seasons Residences'"
                        @item-selected="updateBrandType($event, subCategory.id)" />
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </template>
        </table>

        <!-- Parking Type-->
        <table class="project-detail-table ">
          <tr class="project-detail-table-headers">
            <td class="project-detail-table-header-desc">
              <div>Parking Type<span>(Select All That Apply)</span></div>
            </td>
            <td class="project-detail-table-sub-header square-feet-per-use-input">Gross Square Feet</td>
            <td class="project-detail-table-sub-header num-of-units-input"># Spaces</td>
          </tr>
          <tr v-for="(parkingType, index) in parkingTypes" :key="parkingType.id">
            <td class="parking-type-checkbox-container">
              <label class="custom-checkbox">
                <span :for="parkingTypes[index]" class="custom-checkbox-label"> {{ parkingType.code
                  }}</span>
                <input type="checkbox" style="display: none;" :id="parkingType.id" :value="parkingType.id"
                  v-model="form.newParkingTypeIds[parkingType.id]" @click="toggleCheck(parkingType)">
                <span class="checkmark"></span>
              </label>
            </td>
            <td>
              <div v-if="parkingType.isChecked && parkingType.code != PARKING_TYPES.AUTOMATED"
                class="checkbox-text-input">
                <input type="text"
                  @input="form.parkingTypesSquareFeetPerUse[parkingType.id] = $event.target.value.replace(/\D/g, '')"
                  :id="parkingType.name" v-model="form.parkingTypesSquareFeetPerUse[parkingType.id]"
                  placeholder="ex. 5000" />
              </div>
            </td>
            <td>
              <div v-if="parkingType.isChecked && parkingType.code != PARKING_TYPES.AUTOMATED"
                class="checkbox-text-input">
                <input type="text" min="0"
                  @input="form.parkingTypesNumberOfSpaces[parkingType.id] = $event.target.value.replace(/\D/g, '')"
                  :id="parkingType.name" v-model="form.parkingTypesNumberOfSpaces[parkingType.id]"
                  placeholder="ex. 10" />
              </div>
            </td>

          </tr>
        </table>

        <div class="divider"></div>

        <!-- SubTotal-->
        <table class="project-detail-table">
          <tr class="parking-subtotal project-detail-table-headers ">
            <td class="project-detail-table-sub-total-header-desc">
              <div>Sub Total Parking</div>
            </td>
            <td class="project-detail-table-sub-header square-feet-per-use-input">
              {{ subTotalParkingSquareFeetPerUse ? subTotalParkingSquareFeetPerUse : '--' }}
            </td>
            <td class="project-detail-table-sub-header num-of-units-input">
              {{ subTotalParkingNumberOfSpaces ? subTotalParkingNumberOfSpaces : '--' }}
            </td>
          </tr>
          <tr class="project-detail-table-headers">
            <td class="project-detail-table-sub-total-header-desc">
              <div>Sub Total Building</div>
            </td>
            <td class="project-detail-table-sub-header square-feet-per-use-input">
              {{ subTotalBuildingSquareFeetPerUse ? subTotalBuildingSquareFeetPerUse : '--' }}
            </td>
            <td class="project-detail-table-sub-header num-of-units-input">
              {{ subTotalBuildingNumberOfUnits ? subTotalBuildingNumberOfUnits : '--' }}
            </td>
          </tr>
          <tr class="project-detail-table-headers">
            <td class="project-detail-table-sub-total-header-desc">
              <div>BOH, Mechanical, Other GSF</div>
            </td>
            <td class="project-detail-table-sub-header square-feet-per-use-input">
              {{ subTotalBuildingSquareFeetPerUse && form.totalSquareFeetPerUse ? subTotalOtherSquareFeetPerUse :
                '--' }}
            </td>
            <td class="project-detail-table-sub-header num-of-units-input"></td>
          </tr>
          <tr class="project-detail-table-headers">
            <td class="project-detail-table-header-desc">
              <div>Total GSF <span>(If different from the Subtotal above)</span></div>
            </td>
            <td class="project-detail-table-sub-header square-feet-per-use-input">
              <div class="checkbox-text-input">
                <input type="text" @input="form.totalSquareFeetPerUse = $event.target.value.replace(/\D/g, '')"
                  id="totalSquareFeetPerUse" v-model="form.totalSquareFeetPerUse" placeholder="ex. 5000" />
              </div>
            </td>
            <td class="project-detail-table-sub-header num-of-units-input">
              {{ subTotalBuildingNumberOfUnits ? subTotalBuildingNumberOfUnits : '--' }}
            </td>
          </tr>
        </table>

      </div>

      <!-- Amenities -->
      <div class="tile">
        <div class="information-subtitle">Amenities</div>
        <div class="custom-select">
          <label class="left-div">Amenities</label>
          <div class="right-div">
            <auto-complete-search-box :items="amenityTypes" :display-key="'code'" @item-selected="handleUpdate" :placeholder="'ex. Pool'"/>
          </div>
        </div>
        <!-- Chips Display -->
        <div class="chips-container">
          <div class="chip" v-for="(amenity, index) in form.amenities" :key="amenity.amenity_id">
            {{ amenity.name }}
            <span class="chip-remove" @click="removeAmenity(index)">x</span>
          </div>
        </div>
      </div>

      <!-- Notes -->
      <div class="tile">
        <div class="information-main-title">Notes:</div>
        <div class="notes-container">
          <textarea class="full-width-textarea" v-model="form.notes"
            placeholder="Add Additional Project Notes Here:&#10;&#10;Any information not captured above, part of master development plan?&#10;&#10;Includes other constructions components: marina, boat slip, railway, etc?"></textarea>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { computed, defineExpose, onMounted, reactive, ref, watch } from 'vue';
import { useDropdownStore } from "@/stores/dropdown_store";
import AutoCompleteSearchBox from './AutoCompleteSearchBox.vue';
import { ASSET_CATEGORY_TYPES, BUILDING_USE_TYPES_RESIDENTIAL, PARKING_TYPES } from "@/types/Constants";

const dropdownStore = useDropdownStore();

let fileLabel = ref('...');

const form = ref({
  buildingName: '',
  streetAddress: '',
  city: '',
  state: '',
  zip: '',
  constructionStatus: '',
  constructionTypes: {},
  constructionTypeAdaptiveReuse: '',
  constructionContractType: '',
  buildingHeightStories: null,
  buildingHeightFeet: null,
  historicLandmarksComponent: '',
  sustainabilityType: '',
  modularBuild: '',
  newUseCategoryIds: {},
  subCategoryIds: {},
  subCategoriesSquareFeetPerUse: {},
  subCategoriesNumberOfUnits: {},
  subCategoriesAffordableCompGSF: {},
  subCategoriesAffordableCompNumUnits: {},
  subCategoriesBrandType: {},
  newParkingTypeIds: {},
  parkingTypesSquareFeetPerUse: {},
  parkingTypesNumberOfSpaces: {},
  subTotalBuildingGSF: null,
  subTotalBuildingNumUnits: null,
  subTotalParkingGSF: null,
  subTotalParkingNumSpaces: null,
  totalSquareFeetPerUse: null,
  totalNumberOfUnits: null,
  amenities: [],
  notes: ''
});

const constructionTypes = computed(() => dropdownStore.constructionTypes);

const brandTypes = computed(() => dropdownStore.brandTypes);

const newUseCategories = computed(() => dropdownStore.assetCategoryTypes);

const subCategoriesForResidential = computed(() => dropdownStore.buildingUseTypeForResidential);

const subCategoriesForRetail = computed(() => dropdownStore.buildingUseTypeForRetail);

const subCategoriesForCommercial = computed(() => dropdownStore.buildingUseTypeForCommercial);

const subCategoriesForIndustrial = computed(() => dropdownStore.buildingUseTypeForIndustrial);

const subCategoriesForHospitality = computed(() => dropdownStore.buildingUseTypeForHospitality);

const subCategoriesForCivicAndCultural = computed(() => dropdownStore.buildingUseTypeForCivicAndCultural);

const updatedUseCategories = computed(() => {
  return newUseCategories.value.map(category => {
    switch (category.code) {
      case ASSET_CATEGORY_TYPES.RESIDENTIAL:
        return reactive({ ...category, subCategories: subCategoriesForResidential.value });
      case ASSET_CATEGORY_TYPES.COMMERCIAL:
        return reactive({ ...category, subCategories: subCategoriesForCommercial.value });
      case ASSET_CATEGORY_TYPES.INDUSTRIAL:
        return reactive({ ...category, subCategories: subCategoriesForIndustrial.value });
      case ASSET_CATEGORY_TYPES.HOSPITALITY:
        return reactive({ ...category, subCategories: subCategoriesForHospitality.value });
      case ASSET_CATEGORY_TYPES.RETAIL:
        return reactive({ ...category, subCategories: subCategoriesForRetail.value });
      case ASSET_CATEGORY_TYPES.CIVIC_AND_CULTURAL:
        return reactive({ ...category, subCategories: subCategoriesForCivicAndCultural.value });
      default:
        return category;
    }
  });
});

const parkingTypes = computed(() => dropdownStore.parkingTypes);

const contractTypes = computed(() => dropdownStore.contractTypes);

const formerAssetCategories = computed(() => dropdownStore.formerAssetCategories);

const sustainabilityTypes = computed(() => dropdownStore.sustainabilityTypes);

const amenityTypes = computed(() => dropdownStore.amenityTypes);

// Construction Types Functionality
let selectedConstructionTypeIds = ref({});

// Function to update construction types
function updateConstructionTypes() {
  // Directly filter and map `constructionTypes.value` based on the truthy values in `selectedConstructionTypeIds.value`
  const { value: selectedIds } = selectedConstructionTypeIds;
  const { value: types } = constructionTypes;
  const selectedTypes = types.filter(({ id }) => selectedIds[id.toString()]);

  // Update `form.constructionTypes` with the selected types
  form.value.constructionTypes = selectedTypes;
}

function handleFileChange(event) {
  const file = event.target.files[0];
  if (file) {
    // Update label text with the file name
    fileLabel.value = file.name;
  } else {
    // Reset to default if no file is selected
    fileLabel.value = '...';
  }
}

const toggleCheck = (newUseCategory) => {
  return newUseCategory.isChecked = !newUseCategory.isChecked;
};

defineExpose({
  form,
  updatedUseCategories
})

onMounted(() => {
  dropdownStore.getContractTypes();
  dropdownStore.getFormerAssetCategories();
  dropdownStore.getConstructionTypes();
  dropdownStore.getParkingTypes();
  dropdownStore.getAssetCategoryTypes();
  dropdownStore.getBuildingUseTypes();
  dropdownStore.getSustainabilityTypes();
  dropdownStore.getAmenityTypes();
  dropdownStore.getBrandTypes();
});

// Computed property to calculate the subtotal of building square feet
const subTotalBuildingSquareFeetPerUse = computed(() => {
  const squareFeetValuesForSubCategories = Object.values(form.value.subCategoriesSquareFeetPerUse);
  return squareFeetValuesForSubCategories.reduce((acc, currentValue) => acc + parseFloat(currentValue), 0);
});

// Computed property to calculate the subtotal of building num of units
const subTotalBuildingNumberOfUnits = computed(() => {
  const numberOfUnitsForSubCategories = Object.values(form.value.subCategoriesNumberOfUnits);
  return numberOfUnitsForSubCategories.reduce((acc, currentValue) => acc + parseFloat(currentValue), 0);
});

// Computed property to calculate the subtotal of parking square feet
const subTotalParkingSquareFeetPerUse = computed(() => {
  const squareFeetValues = Object.values(form.value.parkingTypesSquareFeetPerUse);
  return squareFeetValues.reduce((acc, currentValue) => acc + parseFloat(currentValue), 0);
});

// Computed property to calculate the subtotal of parking num of spaces
const subTotalParkingNumberOfSpaces = computed(() => {
  const numberOfUnits = Object.values(form.value.parkingTypesNumberOfSpaces);
  return numberOfUnits.reduce((acc, currentValue) => acc + parseFloat(currentValue), 0)
});

// Computed property to calculate the difference between building and parking square feet
const subTotalOtherSquareFeetPerUse = computed(() => {
  const buildingSquareFeet = subTotalBuildingSquareFeetPerUse.value;
  const parkingSquareFeet = subTotalParkingSquareFeetPerUse.value;
  const totalGSF = form.value.totalSquareFeetPerUse;
  return totalGSF - (buildingSquareFeet + parkingSquareFeet);
});

// Watcher for the subtotals and totals changes
watch([subTotalBuildingSquareFeetPerUse, subTotalBuildingNumberOfUnits, subTotalParkingSquareFeetPerUse, subTotalParkingNumberOfSpaces], ([subTotalBuildingGSF, subTotalBuildingUnits, subTotalParkingGSF, subTotalParkingSpaces]) => {
  form.value.subTotalBuildingGSF = subTotalBuildingGSF;
  form.value.subTotalBuildingNumUnits = subTotalBuildingUnits;
  form.value.subTotalParkingGSF = subTotalParkingGSF;
  form.value.subTotalParkingNumSpaces = subTotalParkingSpaces;
  form.value.totalNumberOfUnits = subTotalBuildingUnits;
});

function updateBrandType(value, subCategoryId) {
  form.value.subCategoriesBrandType[subCategoryId] = value;

}

// Amenities Functionality
function removeAmenity(index) {
  form.value.amenities.splice(index, 1);
}

function handleUpdate(newValue) {
  const amenityToAdd = amenityTypes.value.find(amenity => amenity.id === newValue.id);
  if (amenityToAdd && !form.value.amenities.some(a => a.amenity_id === newValue.id)) {
    form.value.amenities.push({
      amenity_type_id: amenityToAdd.id,
      name: amenityToAdd.code
    });
  } else {
    form.value.amenities.push({
      amenity_type_id: null,
      name: newValue.code.trim()
    });
  }
}

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.project-information-form-container {
  display: flex;
  gap: 25px;
}

.information-main-title {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
  color: $navyBlue;
}

.information-subtitle {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0;
  color: $navyBlue;

  span {
    font-size: 12px;
    font-weight: normal;
  }
}

.tile {
  background-color: $white1;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
  width: 700px;
}

.custom-text-input {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 5px 0px;
  width: 100%;

  .left-div {
    width: 40%;
  }

  .right-div {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  input[type=text] {
    color: $brightBlue1;
    background-color: $lightGray1;
    border: 1px solid $lightGray1;
    border-radius: 5px;
    padding: 5px;
    height: 10px;
    width: 100%;
  }

  input[type=text]:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }
}

.left-div .right-div {
  flex: 1;
}

.right-div {
  p {
    margin: 5px;
  }
}

/** Custom Dropdown  */
.custom-select {
  width: 100%;
  position: relative;
  font-family: inherit;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 5px 0px;
  color: $navyBlue;

  .left-div {
    width: 67.5%;
  }

  .right-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  select {
    appearance: none;
    font-family: inherit;
    -webkit-appearance: none;
    padding: 5px;
    color: $brightBlue1;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    line-height: 1;
    border: 0;
    border-radius: 5px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right $lightGray1;
    background-position-x: 5px;
    text-align-last: right;
    background-size: 10px;
  }

  select:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }
}

// Style for Custom Checkbox
.new-use-category-checkbox-container,
.parking-type-checkbox-container {
  padding: 5px 0px;

  .custom-checkbox .custom-checkbox-label {
    font-size: 12px;
    font-family: 'Inter';
  }

  .custom-checkbox-sub-label {
    font-size: 8px;
  }
}


/** Custom Checkbox */
.construction-type-container {
  color: $navyBlue;

  .custom-checkbox {
    width: 69%;
  }

  .custom-checkbox .custom-checkbox-label {
    font-size: 12px;
    font-family: 'Inter';
  }

  .custom-checkbox-sub-label {
    font-size: 8px;
  }

  ul {
    position: relative;
    padding-left: 0;
    font-size: 12px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  li {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    height: 15px;
  }
}

/* Custom Table */
.project-detail-table {
  width: 100%;

  .project-detail-table-headers {
    color: #00005c;
    width: 100%;
    font-size: 14px;

    .project-detail-table-header-desc {
      display: flex;
      align-items: center;
      font-size: 14px;
      width: 100%;
      margin: 5px 0px;

      div {
        font-weight: bold;
      }

      span {
        font-weight: normal;
        padding-left: 5px;
        font-size: 12px;
      }
    }

    .project-detail-table-sub-total-header-desc {
      display: flex;
      align-items: center;
      font-size: 12px;
      width: 100%;
      margin: 5px 0px;

      span {
        font-weight: normal;
        padding-left: 5px;
        font-size: 12px;
      }
    }

    .total-proj-gross-sqr-ft {
      align-items: flex-start;
      flex-direction: column;
    }

    .project-detail-table-sub-header {
      color: $navyBlue;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .square-feet-per-use-header,
  .num-of-units-header {
    color: $navyBlue;
    font-weight: bold;
    font-size: 12px;
  }

  .square-feet-per-use-input {
    width: 30%;
  }

  .num-of-units-input,
  .num-of-stories-input {
    width: 15%;
  }

  .checkbox-text-input {
    display: flex;
    justify-content: flex-end;

    input[type=text] {
      color: $brightBlue1;
      background-color: $lightGray1;
      border: 1px solid $lightGray1;
      border-radius: 5px;
      padding: 5px;
      height: 10px;
      width: 100%;
    }

    input[type=text]:focus {
      border-color: $brightBlue1;
      outline: $brightBlue1;
    }
  }
}

.divider {
  border-bottom: 2px solid lightgray;
  margin: 10px 0;
}

/** Custom Chips Style */
.chips-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.chip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white1;
  background-color: $brightBlue1;
  border: 1px solid $brightBlue1;
  border-radius: 5px;
  gap: 5px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  min-width: 20px;
}

.chip-remove {
  cursor: pointer;
  margin: 0px 5px;
  font-weight: bold;
}

/** Notes Style */
.notes-container {
  width: 100%;
}

.full-width-textarea {
  width: 100%;
  box-sizing: border-box;
  font-family: 'Inter';
  height: 100px;
}

/** Custom File Input Style */
.file-upload-container {
  display: flex;
  justify-content: flex-end;
}

.file-input {
  opacity: 0;
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  z-index: -1;
}

.file-upload-label {
  display: inline-block;
  cursor: pointer;
  background-color: $lightGray1;
  border: 1px solid $lightGray1;
  border-radius: 5px;
  padding: 5px;
  height: 10px;
  min-width: 5%;
  max-width: 100%;
  text-align: center;
  overflow: hidden;
}

/** Sub category Styles */
.sub-category-indent {
  padding: 5px 0 5px 15px;

  .custom-checkbox .custom-checkbox-label {
    font-size: 12px;
    font-family: 'Inter';
  }

  .custom-checkbox-sub-label {
    font-size: 8px;
  }
}

/** Nested Category Styles */
.brand-type-container {
  padding: 5px 0 5px 35px;
  font-size: 12px;
  font-family: 'Inter';
}

.category-checkbox-header {
  display: flex;
  align-items: center;

  div {
    font-size: 10px;
    padding-left: 5px;
    color: $navyBlue;
  }
}

.parking-subtotal {
  color: $paleGray1 !important;

  td {
    color: $paleGray1 !important;
  }
}
</style>