<template>
  <div :class="showCss" class="tooltip" :style="style">
    <div v-if="html" v-html="html"></div>
    <div v-else-if="lines && lines.length">{{ lines[0] }}</div>
    <ul v-else-if="lines && lines.length > 1">
      <span v-for="(line, idx) in (lines || []).slice(1)" :key="idx">
        <div>
          <li>{{ line }}</li>
        </div>
      </span>
    </ul>
  </div>
</template>

<script setup>
import { computed, toRefs, defineProps } from "vue"

const props = defineProps({
  isShow: Boolean,
  top: String,
  right: String,
  bottom: String,
  left: String,
  lines: Array,
  html: String
})
let { isShow } = toRefs(props)

const showCss = computed(() => {
  return isShow.value ? 'show' : ''
})
const style = computed(() => {
  let str = ''
  if (props.top) {
    str += `top:${props.top};`
  }
  if (props.right) {
    str += `right:${props.right};`
  }
  if (props.bottom) {
    str += `bottom:${props.bottom};`
  }
  if (props.left) {
    str += `left:${props.left};`
  }

  return str
})

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.tooltip {
  display: none;
  transition-timing-function: ease-in;
  /* This timing applies on the way OUT */
  transition: 0.2s;
  /* Quick on the way out */
}

.tooltip.show {
  display: block;
  position: absolute;
  font: $specialFont;
  font-size: 11px;
  background-color: $darkBlue1;
  border: 10px solid $darkBlue1;
  color: white;
  border-radius: 10px;
  z-index: 10000;
  transition-timing-function: ease-out;
  /* This timing applies on the way IN */
  transition: 0.25s;
  /* A litttttle slower on the way in */
  width: 158px;
  height: 54px;
}
</style>
