<template>
  <div :class="isSidebarOpen ? 'open' : 'closed'" class="side-bar-comp-search bak-white-1"
    data-test-id="sidebar-comp-search">
    <ExpandCollapse name="sidebar" :is-open-initially="true" :is-vertical-center="false" open-state="left"
      closed-state="right-zero" :show-double-chevron="true"
      @toggle-expand-collapse="toggleSidebar(); $emit('resize-sidebar-comp-search')" :show-circle="true" />
    <div class="comp-search" :class="!isSidebarOpen ? 'hidden' : ''">
      <form @submit.prevent="submitForm(); $emit('comp-search-submit')">
        <!-- Header-->
        <div class="header-search">
          <div>
            <div class="comp-search-title left-div">Search</div>
          </div>
          <div>
            <button class="right-div secondary-action-button">My Searches</button>
          </div>
        </div>
        <div class="location-container">
          <div v-if="$route.name === 'Home' ? true : false">
            <input id="autocomplete" class="location-search-box" v-model="selectedPlaceName"
              placeholder="Search by location or by building" style="font-size: medium" />
          </div>
        </div>
        <div class="divider"></div>

        <!-- Building Details-->
        <div class="building-details-container">
          <div class="accordion-panel-header">
            <label for="buildingDetails"><strong>Building Details</strong></label>
            <ExpandCollapse name="buildingDetails" :is-open-initially="true" :is-vertical-center="false"
              open-state="down" closed-state="up" :show-double-chevron="false" :show-circle="false"
              :chevron-fill="'#4A5FD5'"
              @toggle-expand-collapse="expandCollapseController('buildingDetails');" />
          </div>
          <div v-if="isBuildingDetailsOpen">
            <!-- Asset Type -->
            <div class="asset-type-container">
              <div class="header-details-with-desc">
                <div>Asset Type</div>
                <p>(Select All That Apply)</p>
              </div>
              <ul>
                <li class="asset-type-input-container" v-for="(assetType, index) in assetCategoryTypes" :key="index">
                  <label class="custom-checkbox">
                    <span :for="assetType" class="custom-checkbox-label">{{ assetType.code }}</span>
                    <input type="checkbox" style="display: none;" :id="assetType.id"
                      v-model="form.assetTypes" :value="assetType.id">
                    <span class="checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
            <!-- Parking Type -->
            <div class="parking-type-container">
              <div class="header-details-with-desc">
                <div>Parking Type</div>
                <p>(Select All That Apply)</p>
              </div>
              <ul>
                <li class="parking-type-input-container" v-for="(parkingType, index) in parkingTypes"
                  :key="index">
                  <div class="width-100">
                    <label class="custom-checkbox">
                      <span :for="parkingTypes" class="custom-checkbox-label"> {{
    parkingType.code }}</span>
                      <input type="checkbox" style="display: none"
                        :id="'parkingType_' + parkingType.id" v-model="form.parkingTypes"
                        :value="parkingType.id"
                        @click="parkingType.isChecked = !parkingType.isChecked">
                      <span class="checkmark"></span>
                    </label>

                  </div>
                  <!-- todo: hide as of the moment since it's not functional-->
                  <!-- <div class="checkbox-text-input">
                    <input type="number" min="0"
                      @input="form.parkingTypes[index].valid || (value = '');"
                      :id="parkingType.name" v-model="form.parkingTypes[index]"
                      :disabled="!parkingType.isChecked" placeholder="--" />
                  </div> -->

                </li>
              </ul>
            </div>
            <!-- Construction Completion Date -->
            <div class="construction-completion-date-container">
              <div class="header-details">
                Construction Completion Date
              </div>
              <div class="construction-completion-date-input-container label-text-spacing">
                <label class="left-div width-100">Year Completed</label>
                <div class="right-div">
                  <input type="number" min="0" id="yearBuildFrom" v-model="form.yearBuild[0]" />
                  <p>to</p>
                  <input type="number" min="0" id="yearBuildTo" v-model="form.yearBuild[1]" />
                </div>
              </div>
            </div>
            <!-- Sizing -->
            <div class="sizing-container">
              <div class="header-details">
                Sizing
              </div>
              <div class="sizing-input-container label-text-spacing">
                <label class="left-div width-100">Square Feet</label>
                <div class="right-div">
                  <input type="number" min="0" id="squareFeetFrom" v-model="form.squareFeet[0]" />
                  <p>to</p>
                  <input type="number" min="0" id="squareFeetTo" v-model="form.squareFeet[1]" />
                </div>
              </div>
              <div class="units-input-container label-text-spacing">
                <label class="left-div width-100">Number of Units</label>
                <div class="right-div">
                  <input type="number" min="0" id="numberOfUnitsFrom"
                    v-model="form.numberOfUnits[0]" />
                  <p>to</p>
                  <input type="number" min="0" id="numberOfUnitsTo" v-model="form.numberOfUnits[1]" />
                </div>
              </div>
              <div class="floors-input-container label-text-spacing">
                <label class="left-div width-100">Number of Floors</label>
                <div class="right-div">
                  <input type="number" min="0" id="numberOfFloorsFrom"
                    v-model="form.numberOfFloors[0]" />
                  <p>to</p>
                  <input type="number" min="0" id="numberOfFloorsTo"
                    v-model="form.numberOfFloors[1]" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>

        <!-- Construction Details -->
        <div class="construction-details-container">
          <div class="accordion-panel-header">
            <label for="constructionDetails"><strong>Construction Details</strong></label>
            <ExpandCollapse name="constructionDetails" :is-open-initially="true" :is-vertical-center="false"
              open-state="down" closed-state="up" :show-double-chevron="false" :show-circle="false"
              :chevron-fill="'#4A5FD5'"
              @toggle-expand-collapse="expandCollapseController('constructionDetails');" />
          </div>
          <div v-if="isConstructionDetailsOpen">
            <!-- Construction Type -->
            <div class="construction-type-container">
              <div class="header-details-with-desc">
                <div>Construction Type</div>
                <p>(Select All That Apply)</p>
              </div>
              <ul>
                <li class="construction-type-input-container" v-for="(constructionType, index) in constructionTypes" :key="index">
                  <label class="custom-checkbox">
                    <span :for="constructionType" class="custom-checkbox-label"> {{
    constructionType.code
  }}</span>
                    <input type="checkbox" style="display: none;" :id="constructionType.id"
                      v-model="form.constructionTypes" :value="constructionType.id">
                    <span class="checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
            <!-- Project Buyout Date -->
            <!-- <div class="project-buyout-date-container">
              <div class="header-details">Project Buyout Date</div>
              <div class="project-buyout-date-input-container label-text-spacing">
                <label class="left-div width-100">Year Pricing Locked In</label>
                <div class="right-div">
                  <input type="number" min="0"
                    id="yearPricingLockedInFrom" v-model="form.yearPricingLockedIn[0]" />
                  <p>to</p>
                  <input type="number" min="0"
                    id="yearPricingLockedInTo" v-model="form.yearPricingLockedIn[1]" />
                </div>
              </div>
            </div> -->
            <!-- Scopes of Work -->
            <div class="scope-of-work-container">
              <div class="header-details">
                Scopes of Work
              </div>
              <div class="custom-select label-text-spacing">
                <label class="left-div width-100" for="demolition">Demolition</label>
                <select class="right-div" id="demolition" v-model="form.demolition" disabled>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div class="custom-select label-text-spacing">
                <label class="left-div width-100" for="abatement">Abatement</label>
                <select class="right-div" id="abatement" v-model="form.abatement" disabled>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div class="custom-select label-text-spacing">
                <label class="left-div width-100" for="foundationType">Foundation Type</label>
                <select class="right-div" id="foundationType" v-model="form.foundationType" disabled>
                  <option value="1">Slab on Grade</option>
                  <option value="2">Cellar</option>
                  <option value="3">Deep</option>
                </select>
              </div>
              <!-- Just put multiple="true" to bring back multiple select in dropdown-->
              <div class="custom-select label-text-spacing"
                tooltip="Use ctrl or cmd to do multiple selection">
                <label class="left-div width-100" for="structuralType">Structural Type</label>
                <select class="right-div" id="structuralType" v-model="form.structuralType" disabled>
                  <option v-for="(structuralType, index) in structuralTypes" :key="index">{{
    structuralType.name }}</option>
                </select>
              </div>
              <div class="custom-select label-text-spacing">
                <label class="left-div width-100" for="windowType">Window Type</label>
                <select class="right-div" id="windowType" v-model="form.windowType" disabled>
                  <option v-for="(windowType, index) in windowTypes" :key="index">{{ windowType.name
                    }}
                  </option>
                </select>
              </div>

              <div class="custom-select label-text-spacing">
                <label class="left-div width-100" for="facadeType">Facade Type</label>
                <select class="right-div" id="facadeType" v-model="form.facadeType" disabled>
                  <option v-for="(facadeType, index) in facadeTypes" :key="index">{{ facadeType.name
                    }}
                  </option>
                </select>
              </div>
              <div class="custom-select label-text-spacing">
                <label class="left-div width-100" for="interiorFinishLevel">Interior Finish
                  Level</label>
                <select class="right-div" id="interiorFinishLevel" v-model="form.interiorFinishLevel"
                  disabled>
                  <option v-for="(finishLevelType, index) in finishLevelTypes"
                    :value="finishLevelType.id" :key="index">{{ finishLevelType.code
                    }}</option>
                </select>
              </div>
            </div>

            <div class="divider"></div>

            <!-- Escalation Panel -->
            <div class="escalation">
              <div class="header-details">Apply Escalation</div>
              <div class="escalation-options">
                <div class="escalation-option cpi">
                  <span class="escalation-label font-pale-blue-1">CPI (YoY)</span>
                  <span class="escalation-checkbox">
                    <label class="custom-checkbox">
                      <input type="checkbox" style="display: none;" id="cpi" :value="null">
                      <span class="checkmark"></span>
                    </label>
                  </span>
                </div>
                <div class="escalation-option or">
                  <span class="escalation-label">or</span>
                </div>
                <div class="escalation-option custom">
                  <span class="escalation-label">Custom (YoY)</span>
                  <span class="escalation-input">
                    <input type="number" step="0.01" min="0.01" max="100.0" id="escalation"
                           v-model="form.escalation"
                           @change="updateEscalation" />%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>

        <!-- Action Buttons -->
        <div class="action-btn-container">
          <div class="left-div">
            <button class="secondary-action-button">Clear Filters</button>
            <button class="secondary-action-button">Save Filters</button>
          </div>
          <div class="right-div">
            <button class="primary-action-button comp-search-button" type="submit">Search Comp Set</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { facadeTypesData, structuralTypesData, windowTypesData } from "@/data/data";
import { onMounted, computed, reactive, ref } from 'vue';
import ExpandCollapse from "../helpers/ExpandCollapse.vue";
import { useCompSetStore } from "@/stores/comp_set_store";
import { useDropdownStore } from "@/stores/dropdown_store";
import { useErrorStore } from "@/stores/error_store";
import { axiosQuery } from "@/assets/js/utils";
import qs from 'qs'; // qs is a querystring parsing library
const dropdownStore = useDropdownStore();
const compSetStore = useCompSetStore()

const form = reactive({
  location: '',
  assetTypes: [],
  parkingTypes: [],
  yearBuild: [null, null],
  squareFeet: [null, null],
  numberOfUnits: [null, null],
  numberOfFloors: [null, null],
  constructionTypes: [],
  yearPricingLockedIn: [null, null],
  demolition: '',
  abatement: '',
  foundationType: '',
  structuralType: '',
  windowType: '',
  facadeType: '',
  interiorFinishLevel: '',
  escalation: compSetStore.getEscalation
});

const structuralTypes = ref(structuralTypesData);

const windowTypes = ref(windowTypesData);

const facadeTypes = ref(facadeTypesData);

// Toggle Values
let isBuildingDetailsOpen = ref(true);
let isConstructionDetailsOpen = ref(true);

const expandCollapseController = (element) => {
  switch (element) {
    case 'buildingDetails':
      isBuildingDetailsOpen.value = !isBuildingDetailsOpen.value
      break;
    case 'constructionDetails':
      isConstructionDetailsOpen.value = !isConstructionDetailsOpen.value
      break;
    default:
      break;
  }
}

let isSidebarOpen = ref(true)
const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value
}

const errorStore = useErrorStore();
const apiBase = process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/"
let authToken = reactive({});

// Once comp set is selected, allow escalation to be dynamic
function updateEscalation() {
  const newEscalation = form.escalation
  if (!newEscalation || (newEscalation > 0 && newEscalation < 2)) {
    compSetStore.setEscalation(form.escalation)
  }
}

const submitForm = () => {
  compSetStore.setEscalation(form.escalation)
  fetchActiveCompSet()
}

const fetchActiveCompSet = () => {
  let validFilterProjectOptions = {
    asset_category_id: form.assetTypes,
    num_floors: form.numberOfFloors,
    num_units: form.numberOfUnits,
    actual_end_date: form.yearBuild,
    total_gsf: form.squareFeet,
    parking_type: form.parkingTypes,
    construction_type: form.constructionTypes,
  };

  // Remove null values from the options
  validFilterProjectOptions = Object.entries(validFilterProjectOptions).reduce((acc, [key, value]) => {
    if (value.some(v => v !== null && v !== undefined && v !== '')) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const queryString = qs.stringify(validFilterProjectOptions, { arrayFormat: 'brackets' });
  compSetStore.setCompQuery(queryString)
  const compSetAndMapQuery = compSetStore.getQuery

  axiosQuery(
    `${apiBase}projects/released_projects?${compSetAndMapQuery}`,
    authToken,
    (data) => {
      compSetStore.setAllProjects(data);
      compSetStore.setIncludedProjects(data);

      // Set all projects as selected
      const selectedProjects = Object.fromEntries(data.map(project => [project.id, true]));
      compSetStore.setSelectedProjects(selectedProjects);

    },
    errorStore
  );
}

const finishLevelTypes = computed(() => dropdownStore.finishLevelTypes);
const assetCategoryTypes = computed(() => dropdownStore.assetCategoryTypes);
const constructionTypes = computed(() => dropdownStore.constructionTypes);
const parkingTypes = computed(() => dropdownStore.parkingTypes);

const selectedPlace = ref(null);
const selectedPlaceName = ref('');
const initializeAutocomplete = () => {
  /* global google */
  const input = document.getElementById('autocomplete')
  if (typeof google === 'undefined') {
    setTimeout(initializeAutocomplete, 1000);
  } else if (!input) {
    // If the input is not defined for some reason (quick page navigation?) do not try again too frequently
    setTimeout(initializeAutocomplete, 2000);
  } else {
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.setFields(['geometry', 'formatted_address', 'name']);

    autocomplete.addListener('place_changed', () => {
      let place = autocomplete.getPlace();

      // If the user does not select a place from the dropdown, get the first place from the dropdown
      if (!place.geometry) {
        const autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({ input: input.value, types: ['(cities)'] }, (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
            const placesService = new google.maps.places.PlacesService(input);
            placesService.getDetails({ placeId: predictions[0].place_id }, (placeResult, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                selectedPlace.value = placeResult;
                selectedPlaceName.value = placeResult.formatted_address; // Update the input value
                compSetStore.setLocation(selectedPlace.value);
              }
            });
          }
        });
      } else {
        selectedPlace.value = place;
        selectedPlaceName.value = place.formatted_address; // Update the input value
        compSetStore.setLocation(selectedPlace.value);
      }
    });
  }
};

onMounted(() => {
  dropdownStore.getInteriorFinishLevelTypes();
  dropdownStore.getAssetCategoryTypes();
  dropdownStore.getConstructionTypes();
  dropdownStore.getParkingTypes();
  initializeAutocomplete();
})

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

// Sidebar
.side-bar-comp-search {
  position: fixed;
  display: flex;
  height: 100vh;
  top: 50px;
  background: $white1;
  box-shadow: 6px 0 18px rgba(0, 0, 0, 0.06);
  z-index: 5;
}

.comp-search-title {
  font-size: 16px;
  font-family: 'Inter';
  font-weight: bold;
  color: $navyBlue;
  padding: 10px 0 20px 0;
}

.side-bar-comp-search.open {
  width: 310px;
  transition: width 0.5s ease;
}

.side-bar-comp-search.closed {
  width: 0;
  transition: width 0.5s ease;
}

.hidden {
  display: none;
}

.comp-search {
  padding: 10px;
  font-family: 'Inter';
  overflow-y: scroll;
}

.comp-search::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: $white1;
}

.comp-search::-webkit-scrollbar-thumb {
  background: $lightGray1;
}

h4 {
  color: $navyBlue;
}

.header-details-with-desc {
  color: $navyBlue;
  display: flex;
  align-items: center;

  div {
    font-size: 14px;
    font-weight: bold;
    padding-right: 0.5rem;
  }

  p {
    font-size: 10px;
  }
}

.header-details {
  color: $navyBlue;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 0;
}

.header-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left-div .right-div {
  flex: 1;
}

.right-div {
  p {
    margin: 5px;
  }
}

.divider {
  border: 1px solid lightgray;
}

.location-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  position: relative;

  label {
    margin-bottom: 5px;
    color: $navyBlue;
    font-size: 14px;
    font-weight: bold;
  }

  input[type=text] {
    color: $brightBlue1;
    background-color: $lightGray1;
    border: 1px solid $lightGray1;
    border-radius: 5px;
    padding: 5px;
    height: 10px;
    width: 85%
  }

  input[type=text]:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }

  .location-search-box {
    color: $brightBlue1;
    background-color: $lightGray1;
    border: 1px solid $lightGray1;
    border-radius: 5px;
    padding: 5px;
    height: 10px;
    width: 85%
  }
}

.building-details-container,
.construction-details-container,
.scope-of-work-container {
  padding: 15px 0px;

  label {
    margin-bottom: 5px;
    color: $navyBlue;
    font-size: 12px;
  }
}

.asset-type-container {
  ul {
    position: relative;
    padding-left: 0;
    font-size: 12px;
    column-count: 2;
    list-style: none;
  }
}

.parking-type-container {
  .parking-type-input-container {
    display: flex;
    margin-bottom: 3px;

    .checkbox-text-input {
      display: flex;
      justify-content: flex-end;

      input[type=number] {
        color: $brightBlue1;
        background-color: $lightGray1;
        border: 1px solid $lightGray1;
        border-radius: 5px;
        padding: 5px;
        height: 10px;
        width: 30%;
      }
    }
  }

  ul {
    padding-left: 0;
  }
}

.construction-completion-date-container,
.sizing-container,
.project-buyout-date-container {
  margin-bottom: 10px;
}

.construction-completion-date-input-container,
.sizing-input-container,
.units-input-container,
.floors-input-container,
.project-buyout-date-input-container {
  display: flex;
  align-items: center;
  font-size: 12px;

  input[type=number] {
    color: $brightBlue1;
    background-color: $lightGray1;
    border: 1px solid $lightGray1;
    border-radius: 5px;
    padding: 5px;
    height: 10px;
    width: 30%;
  }

  input[type=number]:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }
}

.accordion-panel-header {
  display: flex;
  align-items: center;
  position: relative;
  width: 97%;
  height: 27px;

  label {
    font-size: 14px;
  }
}

.construction-type-container {
  ul {
    position: relative;
    padding-left: 0;
    font-size: 12px;
    column-count: 2;
    list-style: none;
  }
}

.label-text-spacing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

// Custom Dropdown
.custom-select {
  width: 100%;
  position: relative;
  font-family: inherit;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 5px;

  label {
    color: $lightGray1; // To be removed once Sope of Work is functional again
  }

  select {
    appearance: none;
    font-family: inherit;
    -webkit-appearance: none;
    padding: 5px;
    color: $brightBlue1;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    line-height: 1;
    border: 0;
    border-radius: 5px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right $lightGray1;
    background-position-x: 5px;
    text-align-last: right;
    background-size: 10px;
  }

  select:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }

  select:disabled {
    cursor: not-allowed;
  }
}

// Custom Multiple Select
.custom-multiple-select {
  width: 100%;
  position: relative;
  font-family: inherit;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 5px;


  select {
    appearance: none;
    font-family: inherit;
    -webkit-appearance: none;
    padding: 5px;
    color: $brightBlue1;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    line-height: 1;
    border: 0;
    border-radius: 5px;
    background: $lightGray1;
    background-position-x: 5px;
    text-align-last: right;
    background-size: 10px;
  }

  select:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }
}

// Action Buttons
.action-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 8vh;
}

.primary-action-button {
  background-color: $mediumBlue2;
  color: white;
  border: 0;
  border-radius: 4px;
  margin: 8px;
  cursor: pointer;
  padding: 10px;
  font-size: 12px;
}

// Uncomment this once secondary buttons are functional
// .secondary-action-button {
//   background-color: transparent;
//   color: $brightBlue1; 
//   border: 0;
//   cursor: pointer;
//   padding: 5px;
//   font-size: 12px;
// }

// .secondary-action-button:hover {
//   text-decoration: underline;
// }


// Remove these once secondary buttons are functional
.secondary-action-button {
  background-color: transparent;
  border: 0;
  padding: 5px;
  font-size: 12px;
  color: $lightGray1;
  cursor: not-allowed;
}

.secondary-action-button:hover {
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: right;
}

.custom-checkbox-label {
  padding-left: 25px;
}

.escalation {
  margin: 15px 0;
}

.escalation-checkbox {
  display: flex;
  flex-direction: row;
}

.escalation-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .escalation-label {
    font-size: 12px;
    padding: 10px;
  }

  .or {
    justify-content: center;
    padding-left: 15px;
  }

  .custom {
    .escalation-input {
      display: flex;
      justify-content: center;

      input[type=text] {
        color: $brightBlue1;
        background-color: $lightGray1;
        border: 1px solid $lightGray1;
        border-radius: 5px;
        padding: 5px;
        height: 10px;
        width: 30%;
      }

      input[type=text]:focus {
        border-color: $brightBlue1;
        outline: $brightBlue1;
      }
    }
  }
}

.escalation-option {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
</style>