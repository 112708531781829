<template>
    <div>
        <!-- Selectors -->
        <div>
            <div class="custom-text-input">
                <label class="left-div">Draw Number</label>
                <div class="right-div" :class="{ 'error-input': formError.drawNumber }">
                    <input type="number" min="0" @input="localForm.drawNumber.valid || (value = '');" id="drawNumber"
                        v-model="localForm.drawNumber" placeholder="--" />
                    <div v-if="formError.drawNumber" class="error-message">Draw Number is Required</div>
                </div>
            </div>
            <div class="custom-text-input">
                <label class="left-div width-100">Date</label>
                <div class="right-div" :class="{ 'error-input': formError.periodThroughDate }">
                    <VueDatePicker v-model="localForm.periodThroughDate" :enable-time-picker="false"
                        format="yyyy-MM-dd"></VueDatePicker>
                    <div v-if="formError.periodThroughDate" class="error-message">Period Through is Required
                    </div>
                </div>
            </div>
        </div>
        <!-- Input File Container -->
        <div class="draw-drop-file-container">
            <!-- Borrower Requisition Letter -->
            <div>
                <DropFile :header-title="'Borrower Requisition Letter'"
                    :header-sub-title="'Download Template (Optional)'" :name="'borrowerRequisitionLetter'"
                    v-on:uploaded-files="matchFileContainers($event, 'borrowerRequisitionLetter')" />
            </div>
            <!-- Developer Draw Tracker -->
            <div>
                <DropFile :header-title="'Developer Draw Tracker'" :header-sub-title="'Download Template (Optional)'"
                    :name="'developerDrawTracker'"
                    v-on:uploaded-files="matchFileContainers($event, 'developerDrawTracker')" />
            </div>
            <!-- Full Payment Application -->
            <div>
                <DropFile :header-title="'Full Payment Application'"
                    :header-sub-title="'Download Template (Optional)'" :name ="'fullPaymentApplication'"
                    v-on:uploaded-files=" matchFileContainers($event, 'fullPaymentApplication' )" />
            </div>
            <!-- Soft Cost Invoices-->
            <div>
                <DropFile :header-title=" 'Soft Cost Invoices' " :name=" 'softCostInvoices' "
                    v-on:uploaded-files="matchFileContainers($event, 'softCostInvoices')" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineExpose, defineProps, reactive } from 'vue';
import DropFile from "@/shared/DropFile.vue";

const props = defineProps({
    form: {
        type: Object, default: () => ({
            drawNumber: null,
            periodThroughDate: null,
            borrowerRequisitionLetter: [],
            developerDrawTracker: [],
            fullPaymentApplication: [],
            softCostInvoices: []
        })
    }
})

const formError = reactive({
    drawNumber: false,
    periodThroughDate: false,
});

let localForm = reactive({ ...props.form });

const matchFileContainers = (event, containerName) => {
    switch (containerName) {
        case 'borrowerRequisitionLetter':
            localForm.borrowerRequisitionLetter.values = event;
            break;
        case 'developerDrawTracker':
            localForm.developerDrawTracker.values = event;
            break;
        case 'fullPaymentApplication':
            localForm.fullPaymentApplication.values = event;
            break;
        case 'softCostInvoices':
            localForm.softCostInvoices.values = event;
            break;
        default:
            break;
    }
}

defineExpose({
    localForm
})
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

/* Style for the input drop files */
.draw-drop-file-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-top: 20px;
    overflow-y: scroll;
    height: 100%;

    div {
        padding-bottom: 10px;
    }
}

.draw-drop-file-container::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: $white1;
}

.draw-drop-file-container::-webkit-scrollbar-thumb {
    background: $lightGray1;
}

// Input CSS
.custom-text-input {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 0px;

    label {
        font-weight: 100;
    }

    .left-div {
        width: 10%;
    }

    .right-div {
        width: 20%;

        #drawNumber {
            width: 92%;
        }
    }

    input[type=number] {
        color: $brightBlue1;
        background-color: $white1;
        border: 1px solid $lightGray1;
        border-radius: 5px;
        padding: 10px;
        height: 10px;
        width: 25%;
    }

    input[type=number]:focus {
        border-color: $brightBlue1;
        outline: $brightBlue1;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    text-align: right;
}

.error-message {
    color: $red3;
    font-size: 10px;
    padding-top: 5px;
}

.error-input {
    input[type=number] {
        border: 1px solid $red3;
    }

    &.input[type=number]:focus {
        border-color: $red3;
        outline: $red3;
    }

    .dp__theme_light {
        --dp-border-color: #ea5a4f;
        --dp-border-color-hover: #ea5a4f;
        --dp-border-color-focus: #ea5a4f;
    }
}
</style>