<template>
  <div class="company-items-content">
    <div>{{ title }}</div>
    <div v-if="items.length > 0">
      <div v-for="(item, index) in items" :key="index">{{ item }}</div>
    </div>
    <div class="no-data" v-else>
      <div>N/A</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.company-items-content {
  font-size: 14px;
  margin: 10px 0;

  &>div:nth-child(2) {
    color: $brightBlue1;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
  }
}

.no-data {
  div {
    color: black;;
  }
}

</style>