<template>
    <div class="header-title">
        <div>{{ props.headerTitle }}</div>
        <div class="header-title-info">
            <svg id="SvgjsSvg1035" width="14" height="14" xmlns="http://www.w3.org/2000/svg" version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
                <defs id="circle-info"></defs>
                <g id="circle-info"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="14" height="14">
                        <path fill="none" d="M0 0h48v48H0z"></path>
                        <path
                            d="M22 34h4V22h-4v12zm2-30C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z"
                            fill="#000000" class="color000 svgShape"></path>
                    </svg></g>
            </svg>
        </div>
    </div>
    <div class="custom-file-input">
        <input type="text" v-model="fileName" readonly placeholder="Choose files to upload" />
        <label :for="'file-upload' + props.name" class="browse-button">Browse Files</label>
        <!-- <input id="file-upload" type="file" @change="handleFileInput" accept=".pdf" multiple /> -->
        <input :id="'file-upload' + props.name" type="file" ref="fileInput" @change="handleFileInput"
            accept=".pdf, .xls, .xlsx" multiple>
    </div>
    <div class="file-upload-container" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop="handleDrop">
        <div class="file-upload-placeholder" @click="$refs.fileInput.click()">
            <svg id="SvgjsSvg1017" width="50" height="50" xmlns="http://www.w3.org/2000/svg" version="1.1"
                xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs">
                <defs id="SvgjsDefs1018"></defs>
                <g id="SvgjsG1019"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="50" height="50">
                        <path
                            d="M398.1 233.2c0-1.2.2-2.4.2-3.6 0-65-51.8-117.6-115.7-117.6-46.1 0-85.7 27.4-104.3 67-8.1-4.1-17.2-6.5-26.8-6.5-29.5 0-54.1 21.9-58.8 50.5C57.3 235.2 32 269.1 32 309c0 50.2 40.1 91 89.5 91H224v-80h-48.2l80.2-83.7 80.2 83.6H288v80h110.3c45.2 0 81.7-37.5 81.7-83.4 0-45.9-36.7-83.2-81.9-83.3z"
                            fill="#8A92A9" class="color000 svgShape"></path>
                    </svg></g>
            </svg>
            <div>Drag and drop files here</div>
        </div>
        <div v-if="error" class="error-message">{{ error }}</div>
    </div>
    <div v-if="props.headerSubTitle" class="header-subtitle secondary-action-button">{{ props.headerSubTitle }}</div>
    <!-- List of uploaded files -->
    <div v-if="file && uploadedFiles.length > 0" class="file-details">
        <div v-for="(uploadedFile, index) in file" :key="index" class="progress-bar-file-name">
            {{ uploadedFile.name }}
            <div class="progress-bar">
                <div :style="{ width: progress + '%' }"></div>
            </div>
        </div>

    </div>
    <div v-if="uploadedFiles.length > 0" class="uploaded-files">
        <div class="uploaded-files-header">List of Uploaded Files in {{ props.headerTitle }}</div>
        <div class="uploaded-files-container">
            <ul>
                <li v-for="(uploadedFile, index) in uploadedFiles" :key="index">{{ uploadedFile.name }}
                    <button @click="deleteFile(index)">x</button>
                </li>

            </ul>
        </div>
    </div>

</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue';

const props = defineProps({
    headerTitle: { type: String, default: 'Uploaded files:' },
    headerSubTitle: { type: String, default: '' },
    name: { type: String, default: '' }
});

const file = ref(null);
const progress = ref(0);
const error = ref('');
const uploadedFiles = ref([]);
const fileName = ref('');
// Define custom emits
const emits = defineEmits(['uploadedFiles']);

function handleFileInput(event) {
    const uploadedFilesArray = Array.from(event.target.files);
    const validFiles = uploadedFilesArray.filter(file =>
        file.type === 'application/pdf' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    if (validFiles.length !== uploadedFilesArray.length) {
        error.value = 'Only PDF and Excel files are allowed';
    } else {
        error.value = '';
        // Check if we can check duplicates in an array
        for (const uploadedFile of uploadedFilesArray) {
            const fileAlreadyExists = uploadedFiles.value.some(existingFile => existingFile.name == uploadedFile.name);
            if (fileAlreadyExists) {
                // Handle the case where the file already exists (e.g., display a message to the user)
                error.value = 'File already exists:' + uploadedFile.name;
            } else {
                // If the file doesn't already exist, add it to the list of uploaded files
                error.value = '';
                file.value = uploadedFilesArray;
                uploadedFilesArray.forEach(addUploadedFile);

                // If the file already exists and has been pushed, find its index in the array and remove it
                if (uploadedFilesArray.length > 0) {
                    const index = uploadedFilesArray.findIndex(existingFile => existingFile.name === uploadedFile.name);
                    if (index !== -1) {
                        uploadedFilesArray.splice(index, 1);
                    }
                }


                // Get Filename
                fileName.value = uploadedFile ? uploadedFile.name : '';
            }
        }
    }
}

function handleDrop(event) {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const droppedFilesArray = Array.from(droppedFiles);
    const validFiles = droppedFilesArray.filter(file =>
        file.type === 'application/pdf' ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    if (validFiles.length !== droppedFilesArray.length) {
        error.value = 'Only PDF and excel files are allowed';
    } else {
        error.value = '';
        // Check if we can check duplicates in an array
        for (const droppedFile of droppedFilesArray) {
            const fileAlreadyExists = uploadedFiles.value.some(existingFile => existingFile.name == droppedFile.name);

            if (fileAlreadyExists) {
                // Handle the case where the file already exists (e.g., display a message to the user)
                error.value = 'File already exists:' + droppedFile.name;
            } else {
                // If the file doesn't already exist, add it to the list of uploaded files
                error.value = '';
                file.value = droppedFilesArray;
                droppedFilesArray.forEach(addUploadedFile);

                // If the file already exists and has been pushed, find its index in the array and remove it
                if (droppedFilesArray.length > 0) {
                    const index = droppedFilesArray.findIndex(existingFile => existingFile.name === droppedFile.name);
                    if (index !== -1) {
                        droppedFilesArray.splice(index, 1);
                    }
                }

                // Get Filename
                fileName.value = droppedFile ? droppedFile.name : '';
            }
        }
    }
}

function addUploadedFile(file) {
    uploadedFiles.value.push(file);
    emits('uploadedFiles', uploadedFiles.value);
}

function deleteFile(index) {
    fileName.value = null;
    uploadedFiles.value.splice(index, 1);
    emits('uploadedFiles', uploadedFiles.value);
}

// Simulate progress from 50% to 100% after 3 seconds
setTimeout(() => {
    progress.value = 100;
}, 3000);

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

/* File upload Container*/
.file-upload-container {
    border: 2px dashed $mediumGray2;
    padding: 20px;
    background: $darkWhite1;
    height: 100px;
}

.file-upload-placeholder {
    text-align: center;
}

.file-details {
    margin-top: 10px;
}

.progress-bar {
    width: 100%;
    height: 8px;
    background-color: #eee;
    margin-top: 5px;
}

.progress-bar>div {
    height: 100%;
    background-color: $mediumBlue2;
    transition: width 0.3s ease;
}

.progress-bar-file-name {
    font-size: 10px;
    font-weight: 100;
}

.error-message {
    color: red;
    margin-top: 5px;
    text-align: center;
}

.uploaded-files {
    margin-top: 10px;
    font-size: 12px;

    .uploaded-files-container {
        overflow-y: auto;
        height: 100px;
    }

    .uploaded-files-header {
        font-size: 12px;
    }

    ul {
        font-weight: 100;
        list-style-type: none;
        padding: 0;
    }

    li {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            background: none;
            border: none;
            outline: none;
            box-shadow: none;
        }
    }
}

.uploaded-files-container::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: $white1;
}

.uploaded-files-container::-webkit-scrollbar-thumb {
    background: $lightGray1;
}

/* */
.header-title {
    font-size: 12px;
    font-weight: bold;
    display: flex;

    .header-title-info {
        margin: 0px 5px;
    }
}

.header-subtitle {
    font-size: 10px;
    font-weight: 100;
}

/* Secondary Action Button */
.secondary-action-button {
    background-color: transparent;
    color: $brightBlue1;
    border: 0;
    cursor: pointer;
    padding: 5px 0px;
    font-size: 12px;
    margin-right: 15px;
}

.secondary-action-button:hover {
    text-decoration: underline;
}

/** Custom File Input */
.custom-file-input {
    position: relative;
    display: inline-block;
    margin: 10px 0px;
    width: 100%;
}

.custom-file-input input[type="file"] {
    position: absolute;
    left: -9999px;
}

.custom-file-input label.browse-button {
    background-color: $lightGray1;
    color: black;
    padding: 5.5px 17px 5.5px 18px;
    -webkit-appearance: none;
    cursor: pointer;
    font-size: 12px;
    border: 1px solid $mediumGray2;
}

.custom-file-input input[type="text"] {
    padding: 5px;
    border: 1px solid $mediumGray2;
    width: 63.5%;
}
</style>
