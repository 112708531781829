// src/stores/AuthStore.js (Pinia)
import { defineStore } from "pinia"

// The CompSetStore tracks the projects that a user has selected for comparison at the given time.
export const useCompSetStore = defineStore("CompSetStore", {
  id: 'compSetStore',
  state: () => ({
    // sorted array of project ids to be used.
    includedProjects: [],
    allProjects: [],
    compQuery: null,
    mapQuery: null,
    mapExpandedQuery: null,
    location: null,
    avgTotalGSF: null, // for reuse on chart
    perProjectGSF: {},
    showMarkers: null,
    selectedProjects: {},
    escalation: null
  }),
  getters: {
    getAllProjects: (state) => {
      return state.allProjects
    },
    getIncludedProjects: (state) => {
      return state.includedProjects
    },
    getSelectedProjects: (state) => {
      return state.selectedProjects
    },
    // Map has to show everything that fits compsearch and the map as a whole
    getQueryMapExpanded: (state) => {
      const ampersand = state.compQuery && state.mapExpandedQuery ? '&' : ''
      return `${state.compQuery || ''}${ampersand}${state.mapExpandedQuery || ''}`
    },
    // CompSearch needs to query only what is in the encircled data
    getMapQuery: (state) => {
      return state.mapQuery
    },
    // CompSearch needs to query only what is in the encircled data
    getQuery: (state) => {
      const ampersand = state.compQuery && state.mapQuery ? '&' : ''
      return `${state.compQuery || ''}${ampersand}${state.mapQuery || ''}`
    },
    getLocation: (state) => {
      return state.location
    },
    getAvgTotalGSF: (state) => {
      return state.avgTotalGSF
    },
    getPerProjectGSF: (state) => {
      return state.perProjectGSF
    },
    getEscalation: (state) => {
      if (!state.escalation) { return state.escalation}

      return parseFloat(String(state.escalation)).toFixed(2)
    },
    getShowMarkers: (state) => {
      return state.showMarkers
    }
  },
  actions: {
    setCompQuery(compQuery) {
      this.compQuery = compQuery
    },
    setMapQuery(mapQuery) {
      this.mapQuery = mapQuery
    },
    // wider boundary map
    setMapExpandedQuery(mapExpandedQuery) {
      this.mapExpandedQuery = mapExpandedQuery
    },
    setAllProjects(projects) {
      this.allProjects = projects
    },
    setIncludedProjects(projects) {
      this.includedProjects = projects
    },
    toggleProjectId(id) {
      // Maintain array of which projects are selected
      const idxInProjects = this.includedProjects.findIndex((obj) => obj.id === id)
      if (idxInProjects > -1) {
        this.includedProjects.splice(idxInProjects, 1)
      } else {
        this.includedProjects.push(id)
      }

      if (this.allProjects.indexOf(id) < 0) {
        this.allProjects.push(id)
      }

      // Maintain sorting here as it is used elsewhere to determine if the selected projects have changed
      this.includedProjects = this.includedProjects.sort()
      this.allProjects = this.allProjects.sort()
    },
    setLocation(value) {
      this.location = value
    },
    setAvgTotalGSF(avgTotalGSF) {
      this.avgTotalGSF = avgTotalGSF
    },
    setPerProjectGSF(perProjectGSF) {
      this.perProjectGSF = perProjectGSF
    },
    setEscalation(escalation) {
      this.escalation = escalation
    },
    setShowMarkers(value) {
      this.showMarkers = value
    },
    setSelectedProjects(value) {
      this.selectedProjects = value;
    },
    toggleProjectSelection(projectId) {
      this.selectedProjects[projectId] = !this.selectedProjects[projectId];
    },
    isProjectSelected(projectId) {
      return !!this.selectedProjects[projectId];
    }
  },
})
