// From https://blog.ag-grid.com/valueformatters-part-2/
// DATE COMPARATOR FOR SORTING
import {
  percentFormat,
  dateFormat,
  formatDecimals,
  timeFormat,
  currencyFormat
} from "./utils"
import moment from 'moment' // for Date functions
import { utils, writeFile } from "xlsx"

function renameRowData(agGridFields, rowData, excludeFieldNames = []) {
  const agColNameToHeader = {}
  let columnHeaders = []
  // get rid of non-null column names
  agGridFields.filter(n => n && excludeFieldNames.indexOf(n.field) < 0).forEach(field => {
    // Skip checkbox columns, etc.
    if (field.headerName) {
      agColNameToHeader[field.field] = field.headerName
      columnHeaders.push(field.headerName)
    }
  })

  if (!rowData || !rowData.length) { return rowData }
  let newRowData = []

  rowData.forEach(row => {
    let newRow = {}
    Object.keys(row).forEach(columnName => {
      // We sometimes add extra data for row dragging -- no need to have that now
      if (agColNameToHeader[columnName]) {
        newRow[agColNameToHeader[columnName]] = row[columnName]
      }
    })
    newRowData.push(newRow)
  })

  return [newRowData, columnHeaders]
}
export function formatColsForExcelExport(rowData, agGridFields) {
  let fields = {}
  agGridFields.map(field => { if (field !== 'checkbox' && field !== 'button') fields[field.field] = field })
  let newRowData = []
  rowData.forEach(row => {
    Object.keys(row).forEach(columnName => {
      let field = fields[columnName]
      if (row[columnName] && field && field.cellRenderer) {
        const cleansedValue = row[columnName] ? String(row[columnName]).replaceAll(',', '').replace('$', '').replaceAll(/<\/?b>/g, '') : row[columnName]
        row[columnName] = field.cellRenderer({ value: cleansedValue })
        row[columnName] = row[columnName] ? String(row[columnName]).replaceAll(',', '').replace('$', '').replaceAll(/<\/?b>/g, '') : row[columnName]
        // Ensure Export to Excel keeps any numeric fields as numeric!
        // NOTE that we need to include a negative sign '-' and a period '.' in this
        if (row[columnName].match(/^-?[0-9.]+$/)) {
          row[columnName] = parseFloat(row[columnName])
        }
      }

      // Get all break columns and wrap into rows
      // https://stackoverflow.com/a/29980321/2611078
      // TODO: This works to make it separate rows, but then formatting in Excel doesn't show the rows, so it doesn't really help
      if (row[columnName] && field && field.valueGetter) {
        const cleansedValue = row[columnName] ? String(row[columnName]).replaceAll(';', "\r\n") : row[columnName]
        row[columnName] = cleansedValue
      }
    })
    newRowData.push(row)
  })

  return newRowData
}
export function exportToExcel(filename, rowData, agGridFields = [], excludeFieldNames = []) {
  rowData = formatColsForExcelExport(rowData, agGridFields)
  let columns;
  [rowData, columns] = renameRowData(agGridFields, rowData, excludeFieldNames)
  const listWS = utils.json_to_sheet(rowData, { header: columns })
  const wb = utils.book_new() // make Workbook of Excel

  // add Worksheet to Workbook
  utils.book_append_sheet(wb, listWS, 'Sheet1') // list is the name of the worksheet

  // export Excel file
  return writeFile(wb, filename)
}
export function dateComparator(date1, date2) {
  // This null logic means that blank dates will show first when doing order DESC
  // This is needed for the invoice_date sort, but could be customized if we need it to differ for other dates
  if (date1 == null && date2 == null) {
    return 0
  } else if (date1 == null) {
    return 1
  } else if (date2 == null) {
    return -1
  }

  // date comparison https://stackoverflow.com/a/493018/2611078
  let date1Number = new Date(date1).getTime()
  let date2Number = new Date(date2).getTime()

  if (date1Number === date2Number) {
    return 0
  } else if (date1Number < date2Number) {
    return -1
  } else {
    return 1
  }
}

// NUMBER COMPARATOR FOR SORTING
export function numberComparator(num1, num2) {
  num1 = (num1 || num1 === 0) ? parseFloat(num1) : null
  num2 = (num2 || num2 === 0) ? parseFloat(num2) : null

  // This null logic means that blank numbers will show first when doing order ASC
  if (num1 == null && num2 == null) {
    return 0
  } else if (num1 == null) {
    return -1
  } else if (num2 == null) {
    return 1
  }

  if (num1 === num2) {
    return 0
  } else if (num1 < num2) {
    return -1
  } else {
    return 1
  }
}

export function defaultColumnNameParsing(columnName, formattedColumnName, dataType, idx, opts) {
  // This happens with grouped columns
  if (!columnName) {
    return
  }
  const minWidth = opts.minWidth ? opts.minWidth : null

  // if data is ofDropDown type and table is editable, set additional properties
  // TODO : make this more dynamic
  let cellRenderer = null
  if (opts.boldFirstLine) {
    cellRenderer = (params) => {
      if (params.value !== 0 && !params.value) {
        return ''
      }
      let boldFirstLine = "<b>" + params.value
      boldFirstLine = boldFirstLine.replace(/\n/, "</b>\n")
      return boldFirstLine
    }
  }

  if (opts.columnOptions && !opts.columnOptions['isShowTime']) {
    // isShowTime needs to go through the date options...
    let columnOpts = opts.columnOptions
    if (!columnOpts.cellRenderer) { columnOpts.cellRenderer = cellRenderer }

    if (!opts.columnOptions.field) {
      columnOpts.field = columnName
    }
    if (!opts.columnOptions.headerName) {
      columnOpts.headerName = formattedColumnName
    }
    columnOpts.hide = opts.hide
    return columnOpts
  } else if (columnName === 'date' || columnName === 'Date' ||
      ['last_payment', 'date_submitted', 'period_start', 'period_through', 'projected_start', 'projected_end'].includes(columnName) ||
      ['actual_start_date', 'actual_end_date', 'created_at', 'updated_at', 'closing_date', 'last_sign_in_at'].includes(columnName)) {
    const isShowTime = columnName.includes('updated_at') || (opts.columnOptions && opts.columnOptions['isShowTime'])
    return {
      field: columnName,
      headerName: formattedColumnName,
      hide: opts.hide,
      minWidth,
      maxWidth: isShowTime ? '150' : '125',
      cellRenderer: (data) => {
        let newValue = dateFormat(data.value, false)
        if (isShowTime) {
          newValue += ' ' + timeFormat(data.value, false, true)
        }
        if (data?.value && cellRenderer) {
          newValue = "<b>" + data.value
          newValue = newValue.replace(/\n/, "</b>\n")
        }
        return newValue
      },
      comparator: dateComparator,
      filter: opts.filter ? 'agDateColumnFilter' : false,
      filterParams: {
        defaultOption: 'greaterThan',
        debounceMs: 500,
        suppressAndOrCondition: true,
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          if (cellValue == null) {
            return 0
          }

          // convert  date to midnight before comparison
          let cellTime = moment(cellValue).startOf('day').toDate()

          // date comparison https://stackoverflow.com/a/493018/2611078
          if (typeof(filterLocalDateAtMidnight) === 'string') {
            filterLocalDateAtMidnight = new Date(filterLocalDateAtMidnight)
          }
          if (cellTime.getTime() < filterLocalDateAtMidnight.getTime()) {
            return -1
          } else if (cellTime.getTime() > filterLocalDateAtMidnight.getTime()) {
            return 1
          } else {
            return 0
          }
        },
      },
    }
  } else if (!opts.valueGetter &&
      (['original_budget','total_adjustments','current_budget','previous_retainage_held','total_retainage_held','current_retainage_held','materials_presently_stored','balance_to_complete','original_budget'].includes(columnName) ||
       ['all_budget_transfers','previous_adjustments','current_adjustments','total_adjustments','total_cost','current_budget','budget_increase','previously_funded','current_request','total_completed_to_date','total_completed_to_date_pct','total_completed_to_date_pct_avg'].includes(columnName) ||
       ['original_contract_sum','net_change_by_change_order','contract_sum_to_date','total_completed_and_stored_to_date','total_earned_less_retainage','less_previous_certificates_for_payment','current_payment_due','balance_to_finish_including_retainage','retainage'].includes(columnName) ||
       ['total_original_budget','pct_of_total_original_budget','percent_complete','variance','budget_increase','total_spent','project_length_months','num_units'].includes(columnName) ||
       ['projected_project_months', 'actual_project_months'].includes(columnName) ||
       columnName.match(/gsf/))) {
    return {
      field: columnName,
      headerName: formattedColumnName,
      hide: opts.hide,
      // See https://github.com/ag-grid/ag-grid/issues/1334
      // cellClass: 'ag-right-aligned-cell',
      type: 'rightAligned',
      minWidth,
      editable: opts.editable,
      wrapText: opts.wrapText,
      cellStyle: opts.cellStyle,
      autoHeight: opts.autoHeight,
      maxWidth: '140', // fits up to 99 million in bold for total row
      // floatingFilter: false, // Confusing with most numbers
      filter: opts.filter ? 'agNumberColumnFilter' : false,
      filterParams: {
        defaultOption: 'greaterThanOrEqual'
      },
      cellRenderer: params => {
        if (params.value !== 0 && !params.value) {
          return ''
        }
        const isPercent = ['percent_complete','variance','budget_increase'].includes(columnName) || columnName.match(/_pct(_)?/i) || columnName.match(/^pct_/i)
        const isSimpleNumber = columnName.match(/gsf/) || ['project_length_months','num_units', 'projected_project_months', 'actual_project_months'].includes(columnName)
        let newValue = null
        if (isSimpleNumber) {
          newValue = formatDecimals(params.value)
        } else {
          newValue = isPercent ? percentFormat(params.value) : currencyFormat(params.value, true)
        }
        if (cellRenderer) {
          newValue = '<b>' + newValue
          newValue = newValue.replace(/\n/, "</b>\n")
        }
        return newValue
      },
      comparator: numberComparator,
    }
  } else if (columnName === 'id' || columnName === 'Id' || columnName === 'Cust id' || columnName === 'contractor_id' ||
      columnName === 'Parent id' ||
      columnName === 'Quantity' || columnName === 'quantity' || columnName === 'qty' || columnName === 'shipping_status') {
    return {
      field: columnName,
      headerName: formattedColumnName,
      hide: opts.hide,
      // See https://github.com/ag-grid/ag-grid/issues/1334
      // cellClass: 'ag-right-aligned-cell',
      type: 'rightAligned',
      minWidth,
      wrapText: opts.wrapText,
      valueGetter: opts.valueGetter,
      cellStyle: opts.cellStyle,
      autoHeight: opts.autoHeight,
      // Use string filter, since would be using for search
      comparator: numberComparator,
      maxWidth: '100',
    }
  } else {
    let field = {
      field: columnName,
      headerName: formattedColumnName,
      hide: opts.hide,
      minWidth,
      cellRenderer: cellRenderer,
      wrapText: opts.wrapText,
      valueGetter: opts.valueGetter,
      cellStyle: opts.cellStyle,
      autoHeight: opts.autoHeight,
    }

    // Width settings
    if (columnName.match(/description/i) || columnName.match(/comment/i)) {
      field.wrapText = true
      field.filterParams = {
        defaultOption: 'contains'
      }
    }
    return field
  }
}
