import { defineStore } from "pinia"

export const useSuccessStore = defineStore("SuccessStore", {
  id: 'success',
  state: () => ({
    header: null,
    message: null,
    successLevel: 'success',
    hasConfirm: false,
    clearedStatus: null,
    promiseResolver: null
  }),
  getters: {
    getHeader: (state) => {
      return state.header
    },
    getMessage: (state) => {
      return state.message
    },
    getSuccessLevel: (state) => {
      return state.successLevel
    },
    getConfirmation: (state) => {
      return state.hasConfirm
    },
    getClearedStatus: (state) => {
      return state.clearedStatus
    },
  },
  actions: {
    setSuccess (header, message, successLevel = 'success', hasConfirm = false, overrideCurrentSuccess = false) {
      // Do not override a current message if we already have one.
      if (!overrideCurrentSuccess && (this.message && !this.clearedStatus)) {
        return
      }
      this.header = header
      this.message = message
      const successLevels = ['success', 'notice', 'warning', 'critical']
      if (successLevels.includes(successLevel)) {
        this.successLevel = successLevel
      } else {
        this.successLevel = 'success'
      }
      this.hasConfirm = hasConfirm
      this.clearedStatus = null // reset cleared status when setting a new success
      return new Promise((resolve) => { // Return a new promise
        this.promiseResolver = resolve; // Store the resolver
      });
    },
    cancelSuccess() {
      this.header = null
      this.message = null
      this.successLevel = 3
      this.hasConfirm = false
      this.clearedStatus = 'cancel'
      if (this.promiseResolver) {
        this.promiseResolver();
        this.promiseResolver = null;
      }
    },
    confirmSuccess() {
      this.header = null
      this.message = null
      this.successLevel = 3
      this.hasConfirm = false
      this.clearedStatus = 'confirm'
      if (this.promiseResolver) {
        this.promiseResolver();
        this.promiseResolver = null;
      }
    },
  },
})
