<template>
  <OverlaySpinner v-if="isLoading" />
  <div class="my-data-view-container">
    <div class="breadcrumb-container">My Data</div>
    <div class="dashboard-header">
      <div class="section-title-container">Dashboard</div>
    </div>
    <!-- Map and Charts -->
    <div class="map-charts-container">
      <div class="map-container tile">
        <div id="myDataMap" style="height: 100%"></div>
      </div>
      <div class="doughnut-chart-container tile" v-if="isDoughnutChartDataLoaded">
        <div class="chart-title">Projects By Asset Class</div>
        <div class="doughnut-container">
          <div>
            <Doughnut :height=250 :width=225 :data="doughnutData" :options="doughnutOptions" />
          </div>
          <div class="doughnut-chart-legend">
            <div class="residential-legend">Residential
              <div class="detail-container">
                <div>{{ doughnutChartPercentage[0] }}</div>
                <div class="vertical-divider"></div>
                <div>{{
        kMillionsWithDecimalsFormat(residentialTotalRevisedBudget) }}</div>
              </div>
            </div>
            <div class="office-legend">Commercial
              <div class="detail-container">
                <div>{{ doughnutChartPercentage[1] }}</div>
                <div class="vertical-divider"></div>
                <div>{{
        kMillionsWithDecimalsFormat(commercialTotalRevisedBudget) }}</div>
              </div>
            </div>
            <div class="industrial-legend">Industrial
              <div class="detail-container">
                <div>{{ doughnutChartPercentage[2] }}</div>
                <div class="vertical-divider"></div>
                <div>{{
        kMillionsWithDecimalsFormat(industrialTotalRevisedBudget) }}</div>
              </div>
            </div>
            <div class="hospitality-legend">Hospitality
              <div class="detail-container">
                <div>{{ doughnutChartPercentage[3] }}</div>
                <div class="vertical-divider"></div>
                <div>{{
        kMillionsWithDecimalsFormat(hospitalityTotalRevisedBudget)
      }}</div>
              </div>
            </div>
            <div class="retail-legend">Retail
              <div class="detail-container">
                <div>{{ doughnutChartPercentage[4] }}</div>
                <div class="vertical-divider"></div>
                <div>{{
        kMillionsWithDecimalsFormat(retailTotalRevisedBudget)
      }}</div>
              </div>
            </div>
            <div class="civic-cultural-legend">Civic & Cultural
              <div class="detail-container">
                <div>{{ doughnutChartPercentage[5] }}</div>
                <div class="vertical-divider"></div>
                <div>{{
        kMillionsWithDecimalsFormat(civicCulturalTotalRevisedBudget)
      }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="doughnut-summary-container">
          <div class="doughnut-summary-item">Total Projects:<strong>{{ totalProjects }}</strong></div>
          <div class="doughnut-summary-item">Total GSF:<strong>{{ totalGSF }}</strong></div>
          <div class="doughnut-summary-item">Total States:<strong>{{ totalStates }}</strong></div>
          <div class="doughnut-summary-item">Total Clients:<strong>{{ totalClients }}</strong></div>
        </div>
      </div>
      <div class="bar-line-container tile" v-if="isDataLoaded">
        <!--Budget Variance Bar Chart -->
        <div style="grid-area: bar1;">
          <div class="chart-title">Budget Variance</div>
          <Bar :height="bvChartHeight" :width="400" :data="budgetVarianceStackedBarData"
            :options="budgetVarianceStackedBarOptions" />
        </div>
        <!-- Schedule Variance Bar Chart -->
        <div style="grid-area: bar2;">
          <div class="chart-title">Schedule Variance</div>
          <Bar :height="svChartHeight" :width="400" :data="scheduleVarianceStackedBarData"
            :options="scheduleVarianceStackedBarOptions" />
        </div>
        <!-- Project Starts Line Chart-->
        <div style="grid-area: line;">
          <div class="chart-title">Project Starts</div>
          <Line :height="150" :width="800" :data="projectStartsLineChartData"
            :options="projectStartsLineChartOptions" />
        </div>
      </div>
    </div>
    <div class="portfolio-header">
      <div class="section-title-container">Portfolio</div>
      <div class="section-subtitle-container">View Active Projects Only</div>
      <div class="toggle-container" :class="{ 'active': isActive }" @click="toggleActive">
        <div class="circle" :class="{ 'move-right': isActive }"></div>
      </div>
    </div>
    <!-- Data Table -->
    <div>
      <ag-grid style="min-height:250px" :row-height="20" :auth-token="authToken" :full-scroll="true" :override-width="true"
        :row-data="rowData" :column-defs="colDefs" :key="rowData.length" @setChartData="setChartData"/>
    </div>
  </div>
</template>

<script setup>
/* eslint no-undef: 0 */
import {computed, nextTick, onMounted, reactive, ref} from 'vue';
import { useAuthStore } from '@/stores/auth_store';
import { useErrorStore } from "@/stores/error_store";
import { axiosQuery, currencyFormat, kMillionsFormat, kMillionsWithDecimalsFormat, numberFormat, percentFormat, standardizeLabelsLength } from "@/assets/js/utils";
import AgGrid from "@/components/AgGrid.vue";
import OverlaySpinner from "@/components/helpers/OverlaySpinner.vue"
import { Bar, Doughnut, Line } from "vue-chartjs";
import {
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Colors,
  Filler,
  Legend,
  Title,
  Tooltip,
  Chart as ChartJS,
} from "chart.js";
import { doughnutMiddleTextPlugin, standardDoughnutChartJson } from "@/assets/js/chart_config";
import ChartDataLabels from 'chartjs-plugin-datalabels';

// General Settings
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors,
  ArcElement,
  LineElement,
  PointElement,
  Filler,
  doughnutMiddleTextPlugin(),
  ChartDataLabels
);
const apiBase = process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/";
let authToken = reactive({});
const authStore = useAuthStore();
const errorStore = useErrorStore();
const isDataLoaded = computed(() => {
  return isBudgetVarianceDataFetch.value && isScheduleVarianceDataFetch.value;
});
const isBudgetVarianceDataFetch = ref(false);
const isScheduleVarianceDataFetch = ref(false);
let isActive = ref(false);

function toggleActive() {
  isActive.value = !isActive.value
  
}

// Map Settings
const isGoogleMapsMounted = ref(false);
let map;
let markers = []
let infoWindows = []
let circleCenterLocation = { lat: 40.7740, lng: -73.9710 }; // New York City to start

function loadGoogleMaps() {
  if (typeof google !== 'undefined') {
    const mapOptions = {
      center: circleCenterLocation,
      zoom: 12,
      mapId: 'CompSearchMap',
      // disableDefaultUI: true, // This disables all controls
      // zoomControl: false, // Specifically disables zoom controls
      // streetViewControl: false, // Specifically disables Street View control
      // mapTypeControl: false, // Specifically disables map type control
      // scaleControl: false, // Specifically disables the scale control
      // rotateControl: false, // Specifically disables the rotate control
      // fullscreenControl: false, // Specifically disables the fullscreen control
      // gestureHandling: 'none' // This disables all map gestures
    };

    map = new google.maps.Map(document.getElementById('myDataMap'), mapOptions);

    isGoogleMapsMounted.value = true;
  } else {
    errorStore.setError('Google Map Error', 'Unknown error opening Google Maps', 'error');
  }
}

function loadMarkers(data) {
  
  // Clear the old markers, info windows, and circle
  infoWindows.forEach(infoWindow => infoWindow.close())
  markers.forEach(marker => marker.setMap(null))

  // Reset the values
  markers = [];
  infoWindows = [];

  data.forEach((result) => {
    const lat = parseFloat(result.latitude);
    const lng = parseFloat(result.longitude);

    if (isNaN(lat) || isNaN(lng)) {
      console.error('Invalid latitude or longitude:', result);
      return;
    }

    const location = { lat, lng };

    // See https://developers.google.com/maps/documentation/javascript/advanced-markers/graphic-markers
    const parser = new DOMParser()
    const pinSvgString =
      '<svg xmlns="http://www.w3.org/2000/svg" width="5.6444445mm" height="9.847393mm" viewBox="0 0 20 34.892337" version="1.1" >\n' +
      '  <g transform="translate(-814.59595,-274.38623)">\n' +
      '    <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">\n' +
      '      <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="display:inline;opacity:1;fill:#4A5FD5;fill-opacity:1;stroke:#3b73a8;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>\n' +
      '      <circle r="3.0355" cy="288.25278" cx="823.03064" style="display:inline;opacity:1;fill:#fff;fill-opacity:1;stroke-width:0"/>\n' +
      '    </g>\n' +
      '  </g>\n' +
      '</svg>\n';

    // If we click back to this page, sometimes google.maps.marker is undefined
    if (!google.maps.marker) { loadGoogleMaps() }
    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: location,
      map: map,
      content: parser.parseFromString(
        pinSvgString,
        "image/svg+xml",
      ).documentElement
    })

    // TODO Dynamically require the image and get its URL
    const imageUrl = require('../assets/building_assets/property1.jpg');

    const infoWindow = new google.maps.InfoWindow({
      content: `<div style="background: #3b73a8; color: #fff; font-size: 12px; display: flex; align-items: center; height: 100%; padding: 5px; width: 265px">
                      <div><img style="width:100px" src="${imageUrl}"></div>
                      <div style="padding-left: 5px;">
                        <div style="white-space:nowrap;"><strong>${result.address_line_1}</strong></div>
                        <div style="display: flex; align-items: center;">
                          <label style="width: 100%;">Construction Type:</label>
                          <div style="white-space:nowrap;">${result.project_construction_types_s ? result.project_construction_types_s[0] : '-'}</div>
                        </div>
                        <div style="display: flex; align-items: center;">
                          <label style="width: 100%;">Total GSF:</label>
                          <div style="white-space:nowrap;">${result.total_gsf ? numberFormat(result.total_gsf, 0) : '-'}</div>
                        </div>
                        <div style="display: flex; align-items: center;">
                          <label style="width: 100%;">Unit Count:</label>
                          <div style="white-space:nowrap;">${result.total_num_units ? numberFormat(result.total_num_units, 0) : '-'}</div>
                        </div>
                      </div>
                    </div>`,
    });

    marker.addListener('click', () => {
      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: true,
      });
    });

    markers.push(marker);
    infoWindows.push(infoWindow);
  })

  // After processing all markers, check if any were added
  if (markers.length > 0) {
    // TODO do anything here?  Shouldn't happen
  } else {
    const centerLat = mapCenter[0] || map.getCenter().lat()
    const centerLng = mapCenter[0] || map.getCenter().lng()
    const mapCenterLocation = new google.maps.LatLng(centerLat, centerLng)
    // If there are no markers, add a marker at the searched location with "No available projects here"
    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: mapCenterLocation,
      map: map,
    });

    const infoWindow = new google.maps.InfoWindow({
      content: `<h1>No available projects here</h1>`,
    });

    marker.addListener('click', () => {
      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: false,
      });
    });

    markers.push(marker);
    infoWindows.push(infoWindow);
  }
}

// Doughnut Chart Settings
//TODO: Need to update these values
// General Variables
const isDoughnutChartDataLoaded = ref(false);

let residentialTotalOriginalBudget = ref(0);
let residentialTotalRevisedBudget = ref(0);
let commercialTotalOriginalBudget = ref(0);
let commercialTotalRevisedBudget = ref(0);
let hospitalityTotalOriginalBudget = ref(0);
let hospitalityTotalRevisedBudget = ref(0);
let industrialTotalOriginalBudget = ref(0);
let industrialTotalRevisedBudget = ref(0);
let retailTotalOriginalBudget = ref(0);
let retailTotalRevisedBudget = ref(0);
let civicCulturalTotalOriginalBudget = ref(0);
let civicCulturalTotalRevisedBudget = ref(0);

let residentialTotalVariance = ref(0);
let commercialTotalVariance = ref(0);
let hospitalityTotalVariance = ref(0);
let industrialTotalVariance = ref(0);
let retailTotalVariance = ref(0);
let civicCulturalTotalVariance = ref(0);
let residentialVariancePercent = ref(0);
let industrialVariancePercent = ref(0);
let commercialVariancePercent = ref(0);
let hospitalityVariancePercent = ref(0);
let retailVariancePercent = ref(0);
let civicCulturalVariancePercent = ref(0);

let totalRevisedBudget = computed(() => {
  return residentialTotalRevisedBudget.value +
    commercialTotalRevisedBudget.value +
    industrialTotalRevisedBudget.value +
    hospitalityTotalRevisedBudget.value +
    retailTotalRevisedBudget.value +
    civicCulturalTotalRevisedBudget.value
})

let totalProjects = computed(() => {
  return activeGridData?.value.length
})

let totalGSF = computed(() => {
  return activeGridData?.value.reduce((acc, val) => {
    return acc + val.total_gsf
  }, 0).toLocaleString()
})

let totalStates = computed(() => {
  const uniqueStates = activeGridData?.value.reduce((acc, val) => {
    acc.add(val.state);
    return acc;
  }, new Set());
  return uniqueStates.size;
});

let totalClients = computed(() => {
  let lenders = []
  activeGridData?.value?.forEach(acc => {
    if (!lenders.includes(acc?.primary_project_lender)) {
      lenders.push(acc?.primary_project_lender)
    }
  })

  return lenders.length
})

// Middle Text
let doughnutLabel = computed(() => {
  return 'Total'
})
// Middle Value
let doughnutSummaryValue = computed(() => {
  return kMillionsWithDecimalsFormat(totalRevisedBudget.value)
})

let doughnutChartJsonConfig = standardDoughnutChartJson({
  doughnutTitle: "Projects By Asset Class",
})
let doughnutChartPercentage = ref([]);
let doughnutChartData = ref([])
// TODO - Syd : This should potentially be fetched as Asset Categories from Codes table
const doughnutData = computed(() => {
  return {
    labels: ["Residential", "Commercial", "Industrial", "Hospitality", "Retail", "Civic & Cultural"],
    datasets: [
      {
        backgroundColor: [
          "#52596D", // $darkGray1
          "#4A5FD5", // $brightBlue1
          "#4A8FD0", // $mediumBlue1
          "#8A92A9", // $mediumGray1
          "#d3d3d3", // assigning to light gray for now
          "#6ED0CD" // assigning to $brightTeal1 for now
        ],
        data: doughnutChartData.value
      },
    ],
  };
});

const doughnutOptions = computed(() => {
  let json = doughnutChartJsonConfig
  json.cutout = 85
  json.plugins.legend.display = false
  json.plugins.doughnutMiddleTextPlugin.middleTextLabel = doughnutLabel.value;
  json.plugins.doughnutMiddleTextPlugin.middleTextValue = doughnutSummaryValue.value;
  json.plugins.doughnutMiddleTextPlugin.fontSize = 18;
  json.plugins.tooltip.callbacks = {
    label: function (context) {
      let actual = 0;
      if (context.label?.includes("Residential")) {
        actual = currencyFormat(residentialTotalRevisedBudget.value)
      } else if (context.label?.includes("Commercial")) {
        actual = currencyFormat(commercialTotalRevisedBudget.value)
      } else if (context.label?.includes("Industrial")) {
        actual = currencyFormat(industrialTotalRevisedBudget.value)
      } else if (context.label?.includes("Hospitality")) {
        actual = currencyFormat(hospitalityTotalRevisedBudget.value)
      } else if (context.label?.includes("Retail")) {
        actual = currencyFormat(retailTotalRevisedBudget.value)
      } else if (context.label?.includes("Civic & Cultural")) {
        actual = currencyFormat(civicCulturalTotalRevisedBudget.value)
      }
      return actual;
    },
    footer: function (context) {
      let variancePerc = 0;
      let varianceVal = 0;
      if (context[0].label?.includes("Residential")) {
        varianceVal = kMillionsFormat(residentialTotalVariance.value);
        variancePerc = percentFormat(residentialVariancePercent.value);
      } else if (context[0].label?.includes("Commercial")) {
        varianceVal = kMillionsFormat(commercialTotalVariance.value);
        variancePerc = percentFormat(commercialVariancePercent.value);
      } else if (context[0].label?.includes("Industrial")) {
        varianceVal = kMillionsFormat(industrialTotalVariance.value);
        variancePerc = percentFormat(industrialVariancePercent.value);
      } else if (context[0].label?.includes("Hospitality")) {
        varianceVal = kMillionsFormat(hospitalityTotalVariance.value);
        variancePerc = percentFormat(hospitalityVariancePercent.value);
      } else if (context[0].label?.includes("Retail")) {
        varianceVal = kMillionsFormat(retailTotalVariance.value);
        variancePerc = percentFormat(retailVariancePercent.value);
      } else if (context[0].label?.includes("Civic & Cultural")) {
        varianceVal = kMillionsFormat(civicCulturalTotalVariance.value);
        variancePerc = percentFormat(civicCulturalVariancePercent.value);
      }
      return `Variance: ${varianceVal} (${variancePerc})`
    },
  }
  json.responsive = true;
  return json
});

// Budget Variance and Schedule Variance Bar Chart Settings
// General Settings
let bvStackedBarLabels = ref([]);
let bvBudgetVarianceArr = ref([]);
let svStackedBarLabels = ref([]);
let svScheduleVarianceArr = ref([]);
const bvMaxValue = ref(0); // Set the maximum value for the x-axis of budget variance
const svMaxValue = ref(0); // Set the maximum value for the x-axis of schedule variance
const bvChartHeight = ref(150);
const svChartHeight = ref(150);

const budgetVarianceStackedBarData = computed(() => {
  return {
    labels: standardizeLabelsLength(bvStackedBarLabels.value),
    datasets: [
      {
        label: 'Budget Variance',
        backgroundColor: "#84CADE", // $lightBlue1
        data: bvBudgetVarianceArr.value,
      }
    ]
  }
});

const budgetVarianceStackedBarOptions = computed(() => {
  return {
    indexAxis: 'y',
    responsive: true,
    layout: {
      padding: {
        top: 30, // Adjust this value as needed to prevent data labels from being cut off
        right: 2 * 16,
      }
    },
    scales: {
      x: {
        position: 'top',
        max: 1, // Set the maximum to the bvMaxValue
        grid: {
          drawBorder: true,
          drawOnChartArea: true,
          color: function (context) {
            if (context.tick.value === 0) {
              return '#4A5FD5'; // $brightBlue1:
            }
            return 'rgba(0, 0, 0, 0.1)'; // Black with 10% opacity for other values
          },
          lineWidth: function (context) {
            if (context.tick.value === 0) {
              return 2; // Make the 0 axis line thicker
            }
            return 1; // Default grid line width for other values
          }
        },
        ticks: {
          font: {
            size: 1.25 * 16,
          },
          callback: function(value) {
            if (!value) {
              return value
            }

            return `${Math.round(parseFloat(value).toFixed(2) * 100)}%`;
          },
          maxTicksLimit: 5 // Limit the number of ticks to 5
        },
      },
      y: {
        ticks: {
            font: {
                size: 1.25 * 16,
            }
        },
      },
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        align: function (context) {
          var value = context.dataset.data[context.dataIndex];
          return value < 0 ? 'start' : 'end';
        },
        color: '#000000', // Black
        font: {
          weight: 'normal',
          size: 1.25 * 16, // 20px font size
        },
        formatter: function (value) {
          if (!value) {
            return value
          }

          return `${Math.round(parseFloat(value).toFixed(2) * 100)}%`;
        }
      },
    }
  }
})

const scheduleVarianceStackedBarData = computed(() => {
  return {
    labels: standardizeLabelsLength(svStackedBarLabels.value),
    datasets: [
      {
        label: 'Schedule Variance',
        backgroundColor: "#84CADE", // $lightBlue1
        data: svScheduleVarianceArr.value,
      }
    ]
  }
});

const scheduleVarianceStackedBarOptions = computed(() => {
  return {
    indexAxis: 'y',
    responsive: true,
    layout: {
      padding: {
        top: 30 // Adjust this value as needed to prevent data labels from being cut off
      }
    },
    scales: {
      x: {
        position: 'top',
        max: svMaxValue.value * 1.5, // Set the maximum to the svMaxValue
        grid: {
          drawBorder: true,
          drawOnChartArea: true,
          color: function (context) {
            if (context.tick.value === 0) {
              return '#4A5FD5'; // $brightBlue1
            }
            return 'rgba(0, 0, 0, 0.1)'; // Black with 10% opacity for other values
          },
          lineWidth: function (context) {
            if (context.tick.value === 0) {
              return 2; // Make the 0 axis line thicker
            }
            return 1; // Default grid line width for other values
          }
        },
        ticks: {
            font: {
                size: 1.25 * 16,
            },
            maxTicksLimit: 5
        },
      },
      y: {
        ticks: {
            font: {
                size: 1.25 * 16,
            },
        },
      },
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        align: function (context) {
          // Get the data value for the current data point
          var value = context.dataset.data[context.dataIndex];
          // If the value is less than 0, align the label to the 'start' (left)
          // Otherwise, align it to the 'end' (right)
          return value < 0 ? 'start' : 'end';
        },
        color: '#000000', // Black text color
        font: {
          weight: 'normal',
          size: 1.25 * 16, // 20px font size
        },
        formatter: function (value) {
          return `${parseFloat(value).toFixed(1)} Mo`;
        }
      }
    }
  }
})

// Project Starts Line Chart Settings
// General Settings
let projectStartsLineLabels = ref([]);
let projectStartsLineData = ref([]);

const projectStartsLineChartData = computed(() => {
  return {
    labels: projectStartsLineLabels.value,
    datasets: [
      {
        label: 'Project Starts',
        backgroundColor: "#A0D1FF", // not in our color palette but it's name is ButterflyBlue
        data: projectStartsLineData.value,
        fill: true,
        borderColor: "#3CA1FB", // not in our color palette but it's name is ButterflyBlue
        pointRadius: 5
      }
    ]
  }
});

const projectStartsLineChartOptions = computed(() => {
  return {
    indexAxis: 'x',
    responsive: true,
    layout: {
      padding: {
        top: 30 // Adjust this value as needed to prevent data labels from being cut off
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        anchor: 'top',
        align: 'end',
        color: '#000000', // Black
        font: {
          weight: 'normal'
        }
      }
    },
    scales: {
      y: {
        type: 'linear',
        ticks: {
          stepSize: 5,
          // Use the callback function to format the tick labels
          callback: function (value) {
            return value.toFixed(0); // Converts the number to a string with no decimal places
          }
        }
      }
    }
  }
})

// AG-Grid Settings
let rowData = ref([]);
let colDefs = ref([
  {
    headerName: 'Project Name', field: 'address_line_1', resizable: false, sortable: true, filter: true, floatingFilter: true, cellRenderer: (data) => {
      const route = `/mydata/project-summary/${data.data.id}`
      return "<a href='" + route + "'>" + data.value + "</a>"
    }, cellStyle: {fontSize: '1rem'}
  },
  { headerName: 'Address', field: 'address_line_1', resizable: false, sortable: true, filter: true, floatingFilter: true, cellStyle: {fontSize: '1rem'} },
  { headerName: 'City', field: 'city', resizable: false, sortable: true, filter: true, floatingFilter: true, cellStyle: {fontSize: '1rem'} },
  { headerName: 'State', field: 'state', resizable: false, sortable: true, filter: true, floatingFilter: true, cellStyle: {fontSize: '1rem'} },
  { headerName: 'Asset Class', field: 'primary_asset_category', resizable: false, sortable: true, filter: true, floatingFilter: true, cellStyle: {fontSize: '1rem'} },
  { headerName: 'Primary Use Type', field: 'primary_building_use_type', resizable: false, sortable: true, filter: true, floatingFilter: true, cellStyle: {fontSize: '1rem'} },
  { headerName: 'Construction Type', field: 'project_construction_types_s', resizable: false, sortable: true, filter: true, floatingFilter: true, cellStyle: {fontSize: '1rem'} },
  { headerName: 'GSF', field: 'totalGSF', resizable: false, sortable: true, filter: true, floatingFilter: true, type: 'rightAligned', cellStyle: {fontSize: '1rem'} },
  { headerName: 'Actual Start', field: 'actual_start_date', resizable: false, sortable: true, filter: true, floatingFilter: true, type: 'rightAligned', cellStyle: {fontSize: '1rem'} },
  { headerName: 'Actual End', field: 'actual_end_date', resizable: false, sortable: true, filter: true, floatingFilter: true, type: 'rightAligned', cellStyle: {fontSize: '1rem'} },
  { headerName: 'Lender', field: 'primary_project_lender', resizable: false, sortable: true, filter: true, floatingFilter: true, cellStyle: {fontSize: '1rem'} },
  { headerName: 'Developer', field: 'primary_project_developer', resizable: false, sortable: true, filter: true, floatingFilter: true, cellStyle: {fontSize: '1rem'} },
  { headerName: 'General Contractor', field: 'project_general_contractor', resizable: false, sortable: true, filter: true, floatingFilter: true, cellStyle: {fontSize: '1rem'} },
  { headerName: 'Architect', field: 'project_architect', resizable: false, sortable: true, filter: true, floatingFilter: true, cellStyle: {fontSize: '1rem'} },
  { headerName: 'Planned Budget', field: 'total_original_budget', resizable: false, sortable: true, filter: true, floatingFilter: true, type: 'rightAligned', cellStyle: {fontSize: '1rem'} },
  {
    headerName: 'Budget Variance', field: 'budgetVariance', resizable: false, sortable: true, filter: true, floatingFilter: true, type: 'rightAligned', valueFormatter: params => {
      return `${percentFormat(parseFloat(params.value))}`;
    }, cellStyle: {fontSize: '1rem'}
  },
  { headerName: 'Actual Duration', field: 'project_length_months', resizable: false, sortable: true, filter: true, floatingFilter: true, type: 'rightAligned', cellStyle: {fontSize: '1rem'} },
  { headerName: 'Schedule Variance', field: 'scheduleVariance', resizable: false, sortable: true, filter: true, floatingFilter: true, type: 'rightAligned', valueFormatter: params => {
    return `${numberFormat(parseFloat(params.value))}`; 
    }, cellStyle: {fontSize: '1rem'}
  },
])

function getTotals(assetCategory, filterRowsBy) {
  // Filter rowData.value based primary asset category
  const filteredRows = activeGridData.value.filter(row =>
    row.primary_asset_category?.includes(assetCategory)
  );

  // Map the filtered rows to their total_revised values
  const totalRevisedValues = filteredRows.map(row => row[filterRowsBy] || 0);

  // Sum the values in totalRevisedValues
  const totalRevisedSum = totalRevisedValues.reduce((acc, currentValue) => acc + currentValue, 0);

  return totalRevisedSum;
}

// Call all projects
async function callAllProjects() {
  let validIds = [];
  return new Promise((resolve) => {
    axiosQuery(
      `${apiBase}/projects`,
      authToken,
      (data) => {
        rowData.value = data;

        data.forEach((row) => {
          validIds.push(row.id);
        });

        rowData.value = rowData.value.map(row => {
          // Define a helper function to safely parse date strings, returning null for invalid inputs
          const parseDateString = (dateString) => {
            const date = new Date(dateString);
            return isNaN(date.getTime()) ? null : date;
          };

          // Convert date strings to Date objects
          const actualStartDate = parseDateString(row.actual_start_date);
          const actualEndDate = parseDateString(row.actual_end_date);

          const plannedStartDate = parseDateString(row.planned_start_date);
          const plannedEndDate = parseDateString(row.planned_end_date);

          // Calculate the difference in months between actualEndDate and actualStartDate
          const actualProjectMonths = actualEndDate && actualStartDate ?
            (actualEndDate.getFullYear() - actualStartDate.getFullYear()) * 12 + actualEndDate.getMonth() - actualStartDate.getMonth() :
            0;

            // Calculate the difference in months between actualEndDate and actualStartDate
          const plannedProjectMonths = plannedEndDate && plannedStartDate ?
            (plannedEndDate.getFullYear() - plannedStartDate.getFullYear()) * 12 + plannedEndDate.getMonth() - plannedStartDate.getMonth() :
            0;

          // Calculate scheduleVariance
          const scheduleVariance = plannedProjectMonths == 0 ? 0 :
            ((actualProjectMonths - plannedProjectMonths) / plannedProjectMonths);

          // Return the updated row object with assetClass and scheduleVariance
          return {
            ...row,
            totalGSF: numberFormat(row.total_gsf),
            scheduleVariance: scheduleVariance, // Add or update scheduleVariance
          };
        });

        // Schedule Variance
        // Step 1: Filter out only the positive scheduleVariance entries
        let positiveDataSV = rowData.value.filter(row => row.scheduleVariance > 0);

        // Step 2: Sort the positive entries in descending order
        positiveDataSV = positiveDataSV.sort((a, b) => b.scheduleVariance - a.scheduleVariance);

        // Step 3: If there are less than 5 positive entries, include negative values to make up the top 5
        if (positiveDataSV.length < 5) {
          const negativeDataSV = rowData.value.filter(row => row.scheduleVariance <= 0).sort((a, b) => b.scheduleVariance - a.scheduleVariance);
          positiveDataSV = positiveDataSV.concat(negativeDataSV.slice(0, 5 - positiveDataSV.length));
        }

        // Step 4: Slice the first 5 items for the top 5 data
        const top5DataSV = positiveDataSV.slice(0, 5);

        // Resetting the arrays
        svStackedBarLabels.value = [];
        svScheduleVarianceArr.value = [];

        // Step 5: Iterate over the top 5 array to populate svStackedBarLabels and svScheduleVarianceArr
        top5DataSV.forEach((row) => {
          svStackedBarLabels.value.push(row.name);
          svScheduleVarianceArr.value.push(row.scheduleVariance);
        });

        // Step 6: Find the maximum value in the svScheduleVarianceArr
        const varianceArr = svScheduleVarianceArr.value.map(Math.abs);
        if (varianceArr.length === 0) {
          svMaxValue.value = 0; // or some default value
        } else {
          svMaxValue.value = Math.ceil(Math.max(...varianceArr));
        }

        // Step 7: Set the chart height based on the number of labels
        svChartHeight.value = svScheduleVarianceArr.value.length * 50;

        // Step 8: Set the isScheduleVarianceDataFetch flag to true
        isScheduleVarianceDataFetch.value = true;

        activeGridData.value = rowData.value
        // Call to load pins in the map
        loadMarkers(data)

        resolve(validIds)

      },
      errorStore,
      'Error', 'Error fetching projects'
    );
  })
}
let activeGridData = ref({})
function setChartData(event) {
  // get non-filtered ids
  let activeIds = event.map(row => row.data.id)
  activeGridData.value = rowData.value?.filter(row =>
    activeIds.includes(row.id)
  );
  drawDonutChart()
  drawAreaChart()
}

function drawAreaChart() {
  // Project Starts
  // Step 1 & 2: Extract years from actual_start_date and convert them
  const projectYears = activeGridData.value.map(row => new Date(row.actual_start_date).getFullYear());

  // New Step: Filter out 0 or null years
  const filteredProjectYears = projectYears.filter(year => year && year !== 1970);

  // Step 3: Count the number of projects per year, using filteredProjectYears
  const projectCountPerYear = filteredProjectYears.reduce((acc, year) => {
    acc[year] = (acc[year] || 0) + 1;
    return acc;
  }, {});

  // Step 4: Sort the years
  const sortedYears = Object.keys(projectCountPerYear).sort((a, b) => a - b);

  // Resetting the arrays
  projectStartsLineLabels.value = [];
  projectStartsLineData.value = [];

  // Step 5 & 6: Push the sorted years and their counts
  sortedYears.forEach(year => {
    projectStartsLineLabels.value.push(year);
    projectStartsLineData.value.push(projectCountPerYear[year]);
  });

}
function drawDonutChart() {
  // Get the total revised values
  residentialTotalRevisedBudget.value = getTotals('Residential', 'total_revised')
  commercialTotalRevisedBudget.value = getTotals('Commercial', 'total_revised')
  industrialTotalRevisedBudget.value = getTotals('Industrial', 'total_revised')
  hospitalityTotalRevisedBudget.value = getTotals('Hospitality', 'total_revised')
  retailTotalRevisedBudget.value = getTotals('Retail', 'total_revised')
  civicCulturalTotalRevisedBudget.value = getTotals('Civic and Cultural', 'total_revised')

  residentialTotalOriginalBudget.value = getTotals('Residential', 'total_original')
  commercialTotalOriginalBudget.value = getTotals('Commercial', 'total_original')
  industrialTotalOriginalBudget.value = getTotals('Industrial', 'total_original')
  hospitalityTotalOriginalBudget.value = getTotals('Hospitality', 'total_original')
  retailTotalOriginalBudget.value = getTotals('Retail', 'total_original')
  civicCulturalTotalOriginalBudget.value = getTotals('Civic and Cultural', 'total_original')

  doughnutChartData.value = [
  residentialTotalRevisedBudget.value,
  commercialTotalRevisedBudget.value,
  industrialTotalRevisedBudget.value,
  hospitalityTotalRevisedBudget.value,
  retailTotalRevisedBudget.value,
  civicCulturalTotalRevisedBudget.value
]

const doughnutChartDataArray = [
  residentialTotalRevisedBudget.value,
  commercialTotalRevisedBudget.value,
  industrialTotalRevisedBudget.value,
  hospitalityTotalRevisedBudget.value,
  retailTotalRevisedBudget.value,
  civicCulturalTotalRevisedBudget.value
]
  // Loop through the dougnutChartData
  const totalSumOfDoughnut = parseFloat(doughnutChartDataArray.reduce((a, b) => a + b, 0).toFixed(0));
  
  doughnutChartDataArray.forEach(val => {
    // todo: need to ask the team what should be the returned value if the sum is 0
    if (isNaN(totalSumOfDoughnut) || totalSumOfDoughnut === 0) {
      console.error('Invalid totalSumOfDoughnut:', totalSumOfDoughnut);
      return;
    }
    const percentage = val * 100 / totalSumOfDoughnut;
    const roundedPercentage = Math.round(percentage * 100) / 100;
    doughnutChartPercentage.value.push(`${roundedPercentage.toFixed(0)}%`);
  });

  isDoughnutChartDataLoaded.value = true
}

// Get Initial Data for the Donut Chart
async function getDonutChartData(selectedIds) {
  let validRows = []
  return new Promise((resolve) => {
    axiosQuery(
      `${apiBase}/mvw_most_recent_by_projects/fetch_total_costs_per_project?project_ids=${selectedIds.join(',')}`,
      authToken,
      (data) => {
        rowData.value = rowData.value.map(row => {
          // Check if the row's id is in validIds
          if (selectedIds.includes(row.id)) {
            return {
              ...row,
              total_revised: (parseFloat(data[row.id].total_revised) || 0),
              total_original: (parseFloat(data[row.id].total_original) || 0)
            };
          } else {
            // Return the row unchanged if id does not match
            return row;
          }
        });
        activeGridData.value = rowData.value
        drawDonutChart()
        validRows = rowData.value;
        resolve(validRows)
      },
      errorStore,
      'Error fetching doughnut chart data'
    );
  })
}

// Get Budget Variance
async function getBudgetVariance(selectedIds) {
  axiosQuery(
    `${apiBase}mvw_most_recent_by_projects/fetch_total_costs_per_project?project_ids=${selectedIds.join(',')}`,
    authToken,
    (data) => {
      rowData.value = rowData.value.map(row => {
        // Access the matching object in data directly using row.id as key
        const matchingData = data[String(row.id)]; // Convert row.id to string to match keys

        const budgetVariance = matchingData && matchingData.total_original > 0 && matchingData.total_revised > 0?
          ((parseFloat(matchingData.total_revised) - parseFloat(matchingData.total_original)) / parseFloat(matchingData.total_original)) :
          0;
          
        return {
          ...row,
          budgetVariance
        };
      });
      
      // Budget Variance
      // Step 1: Filter out entries with a budgetVariance of 0 and sort the remaining in descending order
      const filteredAndSorted = rowData.value
        .filter(row => row.budgetVariance !== 0)
        .sort((a, b) => b.budgetVariance - a.budgetVariance);
      
      // Step 2: Filter out only the positive budgetVariance entries
      const positiveDataBV = filteredAndSorted.filter(row => row.budgetVariance > 0);
      
      // Step 3: Sort the positive entries in descending order and slice the first 5 items for the top 5 positive data
      const top5PositiveDataBV = positiveDataBV.sort((a, b) => b.budgetVariance - a.budgetVariance).slice(0, 5);
      
      // Resetting the arrays
      bvStackedBarLabels.value = [];
      bvBudgetVarianceArr.value = [];
      
      // Step 4: Iterate over the top 5 positive array to populate bvStackedBarLabels and bvBudgetVarianceArr
      top5PositiveDataBV.forEach((row) => {
        bvStackedBarLabels.value.push(row.name);
        bvBudgetVarianceArr.value.push(row.budgetVariance);
      });
      
      // Step 5: Find the maximum value in the bvBudgetVarianceArr
      const varianceArr = bvBudgetVarianceArr.value.map(Math.abs);
      if (varianceArr.length === 0) {
        bvMaxValue.value = 0; // or some default value
      } else {
        bvMaxValue.value = Math.ceil(Math.max(...varianceArr));
      }
      
      // Step 6: Set the chart height based on the number of labels
      bvChartHeight.value = bvStackedBarLabels.value.length * 50;
      
      // Step 7: Set the isBudgetVarianceDataFetch flag to true
      isBudgetVarianceDataFetch.value = true;

      // todo: need to return contractor id and create api to add contractor name but that is still up for discussion

    },
    errorStore
  );
}

const isLoading = ref(true)

onMounted(async () => {
  // Ensure the DOM is fully updated before initializing the map
  nextTick(() => {
    // Timeout for loading Google Maps
    setTimeout(() => {
      loadGoogleMaps();
    }, 500);

    // Timeout for setting isLoading to false
    setTimeout(() => {
      isLoading.value = false;
    }, 1500); // Adjust the timeout duration as needed
  });
  authToken.value = authStore.getAuthTokenHeader;
  const selectedIds = await callAllProjects();
  await getDonutChartData(selectedIds);
  await getBudgetVariance(selectedIds);
});

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.my-data-view-container {
  margin-top: 60px;
  padding: 0 15px 15px 15px;
  font-family: 'Inter';

  .breadcrumb-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    font-size: 1rem;
    color: #8C8D92;
  }

  .map-charts-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  .map-container {
    width: 30%;
    display: block;
  }

  .dashboard-header,
  .portfolio-header {
    margin: 15px 0;
    display: flex;
    align-items: center;
  }

  .section-title-container {
    font-size: 1.5rem; // 1rem = 16px
    font-weight: 600;
    color: $navyBlue;
    display: flex;
    margin-right: 10px;
  }

  .section-subtitle-container {
    font-size: 1rem;
    margin-right: 10px;
  }
}

.tile {
  background-color: $white1;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 8px;
  display: inline-block;
  width: calc(33.333% - 20px);
  box-sizing: border-box;

  &.doughnut-chart-container {
    padding: 10px;
  }
}

.chart-title {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Inter';
}

// Doughnut Chart Styles -- Start
.doughnut-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  // Doughnut Legend
  .doughnut-chart-legend {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5%;

    .residential-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: $darkGray1
      }

      .vertical-divider {
        border-left: 5px solid $darkGray1;
        margin: 0 10px;
      }
    }

    .office-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: $brightBlue1;
      }

      .vertical-divider {
        border-left: 5px solid $brightBlue1;
        margin: 0 10px;
      }
    }

    .industrial-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: $mediumBlue1;
      }

      .vertical-divider {
        border-left: 5px solid $mediumBlue1;
        margin: 0 10px;
      }
    }

    .hospitality-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: $mediumGray1;
      }

      .vertical-divider {
        border-left: 5px solid $mediumGray1;
        margin: 0 10px;
      }
    }

    .retail-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: #d3d3d3;
      }

      .vertical-divider {
        border-left: 5px solid #d3d3d3;
        margin: 0 10px;
      }
    }

    .civic-cultural-legend {
      margin: 5px 0;

      .detail-container {
        display: flex;
        flex-direction: row;
        color: #6ED0CD;
      }

      .vertical-divider {
        border-left: 5px solid #6ED0CD;
        margin: 0 10px;
      }
    }
  }
}

.doughnut-summary-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  height: 45%;

  .doughnut-summary-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
}

// Doughnut Chart Styles -- End

// Bar Chart Styles -- Start
.bar-line-container {
  display: grid;
  /* Two columns of equal width */
  grid-template-areas:
    "bar1 bar2"
    "line line";
  /* Third div spans both columns */
  justify-content: space-evenly;
  width: 70%;
  padding: 10px 20px;
}

// Toogle Switch -- Start
.toggle-container {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2.5px;
  left: 3px;
  transition: transform 0.3s ease;
}

.move-right {
  transform: translateX(18px);
}

.toggle-container:hover {
  background-color: #bbb;
}

/* Add a new class for the active state */
.toggle-container.active {
  background-color: #4A5FD5;
}

// Toggle Switch -- End
</style>