<template>
  <div class="container">
    <div class="sm-card">
      <h1 data-test-id="login-title">Development Data Analytics</h1>
      <div class="sign-in" v-if="!setupMFA">
        <h3>Sign In</h3>
        <div class="subtitle">Configure Multifactor Authentication if you have not already.</div>
        <form @submit="onLogin($event, $emit)" class="login-form">
          <input
            class="login-form-email"
            type="text"
            v-model="loginEmail"
            placeholder="Email"
          />
          <br />
          <input
            class="login-form-password"
            type="password"
            v-model="loginPassword"
            placeholder="Password"
          />
          <br />
          <input v-if="showMFA"
            class="login-form-mfa-otp"
            type="text"
            v-model="otp"
            placeholder="OTP"
          />
          <br />
          <input type="submit" value="Login" class="login-form-submit" v-on:keyup.enter="onLogin($event, $emit)" />
        </form>
        <div v-if="showMFA">
          <br />
          <button class="secondary-action-button" @click="toggleSetupMFA">Setup Multifactor Authentication</button>
        </div>
      </div>
      <div v-if="setupMFA && !allowMfaSetup" class="setup-MFA">
        <h3>Sign In to Generate a QR Code</h3>
        <div class="subtitle">The QR Code must be used to setup Multifactor Authentication</div>
        <form @submit="onLogin($event, $emit)" class="login-form">
          <input
            class="login-form-email"
            type="text"
            v-model="loginEmail"
            placeholder="Email"
          />
          <br />
          <input
            class="login-form-password"
            type="password"
            v-model="loginPassword"
            placeholder="Password"
          />
          <br />
          <input type="submit" value="Login" class="login-form-submit" />
          <br />
        </form>
      </div>
      <div v-else-if="setupMFA && allowMfaSetup && mfaString.length" :key="mfaString" class="QR-container" >
        <h4>Scan this QR Code in your Google Authenticator App</h4>
        <vue-qrcode scale="8" :value=mfaString />
        <br />
      </div>

      <div v-if="!!setupMFA">
        <br />
        <button class="secondary-action-button" @click="toggleSetupMFA">Go back to Main Login page</button>
      </div>

      <hr />
    </div>
    <error-message class="error-message" />
  </div>
</template>

<script setup>
import { useAuthStore } from "@/stores/auth_store"
import {useErrorStore} from "@/stores/error_store"
import ErrorMessage from "../components/helpers/ErrorMessage.vue"
import {computed, ref} from "vue"
import VueQrcode from "vue-qrcode"

const authStore = useAuthStore()
const errorStore = useErrorStore()
const loginEmail = ref("")
const loginPassword = ref("")
const otp = ref("")
let setupMFA = ref(false)
let mfaString = ref("")
// NOTE that VUE_APP_ENV_FORCE_MFA can come in as a String, so test that as well
let inCypressEnvt = window.Cypress? true : false
let forceMFA = !!process.env.VUE_APP_ENV_FORCE_MFA && process.env.VUE_APP_ENV_FORCE_MFA !== 'false' && !inCypressEnvt
let inDevEnvt = process.env.NODE_ENV === 'development'
let showMFA = computed(() => {
  return !inDevEnvt || forceMFA
})
const allowMfaSetup = ref(false)
authStore.$subscribe(checkLoggedIn)
function checkLoggedIn() {
  allowMfaSetup.value = authStore.allowMFASetup
  mfaString.value = 'otpauth://totp/Dda:' + authStore.getUserEmail + '?secret=' + authStore.getGoogleSecret + '&issuer=dda'
}
async function onLogin($event, emit) {
  $event.preventDefault()
  let data = {
    user: {
      email: loginEmail.value,
      password: loginPassword.value,
      otp: otp.value
    },
  }
  await authStore.loginUser(data)
      .then(() => {
        if (data && data.user) {
           emit('logged-in-attempt')
        } else {
           console.log('error logging in')
        }
        if (authStore.login_status == 401) {
          errorStore.setError('Error Logging In', authStore.login_message, 'error', true)
        }
      })
  resetData()
}

function resetData () {
  loginEmail.value = ""
  loginPassword.value = ""
  otp.value = ""
}

function toggleSetupMFA () {
  setupMFA.value = !setupMFA.value
}

</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.container {
  display: flex;
  color: black;
  justify-content: center;
}
.subtitle {
  margin-top: -10px; // To avoid leaving too much space under the <h3> above it
  margin-bottom: 15px;
}
.sm-card {
  margin: auto;
}
.login-form-email, .login-form-password, .login-form-mfa-otp  {
  width: 240px;
  margin-bottom: 5px;
}
.secondary-action-button {
  background-color: transparent;
  color: $brightBlue1;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  margin: 5px 0 5px -5px;
}
@media (min-width: 1024px) {
  .sm-card {
    grid-column: 2 / 3; /* put this in the center panel of the screen on larger screens */
  }
}
</style>
