import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth_store';
import { useErrorStore } from "@/stores/error_store";
import { axiosQuery } from "@/assets/js/utils";
import { BUILDING_USE_TYPES_RESIDENTIAL_ARRAY, BUILDING_USE_TYPES_COMMERCIAL_ARRAY, BUILDING_USE_TYPES_INDUSTRIAL_ARRAY, BUILDING_USE_TYPES_HOSPITALITY_ARRAY, BUILDING_USE_TYPES_RETAIL_ARRAY, BUILDING_USE_TYPES_CIVIC_AND_CULTURAL_ARRAY } from '@/types/Constants'
const apiBase = process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/"

let authTokenHeader = reactive({});
let authToken = ref('');

export const useDropdownStore = defineStore('DropdownStore', {
    id: 'dropdownStore',
    state: () => ({
        assetCategoryTypes: [],
        constructionTypes: [],
        finishLevelTypes: [],
        parkingTypes: [],
        buildingUseTypes: [],
        contractTypes: [],
        buildingUseTypeForResidential: [],
        buildingUseTypeForRetail: [],
        formerAssetCategories: [],
        buildingUseTypeForCommercial: [],
        buildingUseTypeForIndustrial: [],
        buildingUseTypeForHospitality: [],
        buildingUseTypeForCivicAndCultural: [],
        sustainabilityTypes: [],
        amenityTypes: [],
        brandTypes: [],
        consultantTypes: [],
        investorSponsors: [],
        consultants: [],
    }),
    actions: {
        getAssetCategoryTypes() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=AssetCategory`,
                    authTokenHeader.value,
                    (data) => {
                        this.assetCategoryTypes = data.map(item => ({ ...item, isChecked: false }));
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Asset Category Types.', 'error', false);
                return Promise.reject(error);
            }

        },
        getConstructionTypes() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;
            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=ConstructionType`,
                    authTokenHeader.value,
                    (data) => {
                        this.constructionTypes = data.map(item => ({ ...item, isChecked: false }));
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Construction Types.', 'error', false);
                return Promise.reject(error)
            }
        },
        getInteriorFinishLevelTypes() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=FinishLevel`,
                    authTokenHeader.value,
                    (data) => {
                        this.finishLevelTypes = data;
                    },
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Finish Level Types.', 'error', false);
                return Promise.reject(error);
            }
        },
        getParkingTypes() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=ParkingType`,
                    authTokenHeader.value,
                    (data) => {
                        this.parkingTypes = data.map(item => ({ ...item, isChecked: false }));
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Parking Types.', 'error', false);
                return Promise.reject(error)
            }
        },
        getBuildingUseTypes() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=BuildingUseType`,
                    authTokenHeader.value,
                    (data) => {
                        this.buildingUseType = data.map(item => ({ ...item, isChecked: false }));

                        const forResidentialType = BUILDING_USE_TYPES_RESIDENTIAL_ARRAY;
                        const forRetailType = BUILDING_USE_TYPES_RETAIL_ARRAY;
                        const forCommercialType = BUILDING_USE_TYPES_COMMERCIAL_ARRAY;
                        const forIndustrialType = BUILDING_USE_TYPES_INDUSTRIAL_ARRAY;
                        const forHospitalityType = BUILDING_USE_TYPES_HOSPITALITY_ARRAY;
                        const forCivicAndCulturalType = BUILDING_USE_TYPES_CIVIC_AND_CULTURAL_ARRAY;

                        this.buildingUseTypeForResidential = this.buildingUseType.filter(item => forResidentialType.includes(item.code));
                        this.buildingUseTypeForRetail = this.buildingUseType.filter(item => forRetailType.includes(item.code));
                        this.buildingUseTypeForCommercial = this.buildingUseType.filter(item => forCommercialType.includes(item.code));
                        this.buildingUseTypeForIndustrial = this.buildingUseType.filter(item => forIndustrialType.includes(item.code));
                        this.buildingUseTypeForHospitality = this.buildingUseType.filter(item => forHospitalityType.includes(item.code));
                        this.buildingUseTypeForCivicAndCultural = this.buildingUseType.filter(item => forCivicAndCulturalType.includes(item.code));
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Building Use Types.', 'error', false);
                return Promise.reject(error);
            }
        },
        getContractTypes() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=ContractType`,
                    authTokenHeader.value,
                    (data) => {
                        this.contractTypes = data.sort((a, b) => a.code.localeCompare(b.code));
                    },

                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Contract Types.', 'error', false);
                return Promise.reject(error);
            }
        },
        getFormerAssetCategories() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=FormerAssetCategory`,
                    authTokenHeader.value,
                    (data) => {
                        this.formerAssetCategories = data;
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Former Asset Category Types.', 'error', false);
                return Promise.reject(error);
            }
        },
        getBrandTypes() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=BrandType`,
                    authTokenHeader.value,
                    (data) => {
                        this.brandTypes = data.sort((a, b) => a.code.localeCompare(b.code));
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Brand Types.', 'error', false);
                return Promise.reject(error);
            }
        },
        getSustainabilityTypes() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=SustainabilityType`,
                    authTokenHeader.value,
                    (data) => {
                        this.sustainabilityTypes = data.sort((a, b) => a.code.localeCompare(b.code));
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Sustainability Types.', 'error', false);
                return Promise.reject(error);
            }
        },
        getAmenityTypes() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=AmenityType`,
                    authTokenHeader.value,
                    (data) => {
                        this.amenityTypes = data.sort((a, b) => a.code.localeCompare(b.code));
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Amenity Types.', 'error', false);
                return Promise.reject(error);
            }
        },
        getConsultantTypes() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}codes?type=ConsultantType`,
                    authTokenHeader.value,
                    (data) => {
                        this.consultantTypes = data.sort((a, b) => a.code.localeCompare(b.code));
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Consultant Types.', 'error', false);
                return Promise.reject(error);
            }
        },
        getInvestorSponsors() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}investor_sponsors`,
                    authTokenHeader.value,
                    (data) => {
                        this.investorSponsors = data.sort((a, b) => a.name.localeCompare(b.name));
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Investor Sponsors.', 'error', false);
                return Promise.reject(error);
            }
        },
        getConsultants() {
            const authStore = useAuthStore();
            const errorStore = useErrorStore();
            authToken.value = authStore.getAuthToken;
            authTokenHeader.value = authStore.getAuthTokenHeader;

            if (!authToken.value) {
                return Promise.reject(new Error("Auth token is missing"));
            }

            try {
                axiosQuery(
                    `${apiBase}consultants`,
                    authTokenHeader.value,
                    (data) => {
                        this.consultants = data.sort((a, b) => a.name.localeCompare(b.name));
                    }
                );
            } catch (error) {
                errorStore.setError('Dropdown', 'An error occurred in retrieving Consultants.', 'error', false);
                return Promise.reject(error);
            }
        },
    },
});