<template>
  <div class="project-view-container">
    <div class="upload-document-header-container">
      <div class="breadcrumb-container">Upload Documents</div>
      <div class="upload-document-main-title">Upload Documents</div>
      <div>You may upload more documents here, please provide the project id.</div>
    </div>
    <!-- Step #1: Input project details -->
    <div class="divider">
      <div class="accordion-panel-header">
        <label for="projectDetails"><strong>Project Details</strong></label>
        <ExpandCollapse name="projectDetails" :is-open-initially="isProjectDetailsOpen" :is-vertical-center="false"
          open-state="down" closed-state="up" :show-double-chevron="false" :show-circle="false"
          :chevron-fill="'#4A5FD5'" @toggle-expand-collapse="expandCollapseController('projectDetails');" />
      </div>
      <!-- Project Id -->
      <div v-if="isProjectDetailsOpen" class="project-view-form">
        <div class="tile existing-project">
          <div class="project-detail-header">Existing Project</div>
          <div class="custom-text-input label-text-spacing">
            <label class="left-div width-100">Project Id</label>
            <div class="right-div">
              <input type="text" v-model="enteredProjectId" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step #2: Input Baseline Documents -->
    <div>
      <div class="divider">
        <div class="accordion-panel-header">
          <label for="uploadBaselineDocuments"><strong>Upload Baseline Documents</strong></label>
          <ExpandCollapse name="uploadBaselineDocuments" :is-open-initially="isBaselineDocumentUpload"
            :is-vertical-center="false" open-state="down" closed-state="up" :show-double-chevron="false"
            :show-circle="false" :chevron-fill="'#4A5FD5'"
            @toggle-expand-collapse="expandCollapseController('baselineDocuments');" />
        </div>
      </div>
      <div class="tile" v-if="enteredProjectId && !isBaselineDocumentUpload">
        <UploadBaselineDocument ref="projectBaselineDocuments"></UploadBaselineDocument>
        <!-- Submit Button -->
        <div class="project-action-button">
          <button @click="submitBaselineDocuments">Upload Documents</button>
        </div>
      </div>
    </div>

    <!-- Step #3: Input Draw Documents -->
    <div>
      <div class="divider">
        <div class="accordion-panel-header">
          <label for="uploadDrawDocuments"><strong>Upload Draw Documents</strong></label>
          <ExpandCollapse name="uploadDrawDocuments" :is-open-initially="isDrawDocumentUpload"
            :is-vertical-center="false" open-state="down" closed-state="up" :show-double-chevron="false"
            :show-circle="false" :chevron-fill="'#4A5FD5'"
            @toggle-expand-collapse="expandCollapseController('drawDocuments');" />
        </div>
      </div>
      <div class="tile" v-if="enteredProjectId && !isDrawDocumentUpload">
        <UploadDrawDocument ref="projectDrawDocuments"></UploadDrawDocument>
        <!-- Submit Button -->
        <div class="project-action-button">
          <button @click="submitDrawDocuments">Upload Draw Documents</button>
        </div>
      </div>
    </div>

    <!-- Step #4: List of Documents -->
    <div class="divider">
      <div class="accordion-panel-header">
        <label for="projectDocumentsList"><strong>Document List</strong></label>
        <ExpandCollapse name="projectDocumentsList" :is-open-initially="isProjectDocumentsListOpen"
          :is-vertical-center="false" open-state="down" closed-state="up" :show-double-chevron="false"
          :show-circle="false" :chevron-fill="'#4A5FD5'"
          @toggle-expand-collapse="expandCollapseController('projectDocumentsList')" />
      </div>
      <div class="tile" v-if="isProjectDocumentsListOpen">
        <ag-grid title="Documents" :url="documentsUrl" :foreign-keys="{}" :auth-token="authToken" :full-scroll="true"
          :override-width="true" :columnDefs="documentsColumnDefs" :key="documentUploadCtr" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, toRaw } from 'vue';
import { useAuthStore } from '@/stores/auth_store';
import { useErrorStore } from "@/stores/error_store";
import { useRouter } from 'vue-router'
import { axiosPostQuery } from "@/assets/js/utils";
import ExpandCollapse from "@/components/helpers/ExpandCollapse.vue";
import UploadBaselineDocument from "@/shared/UploadBaselineDocument.vue";
import UploadDrawDocument from "@/shared/UploadDrawDocument.vue";
import AgGrid from "@/components/AgGrid.vue";

/** Toogle Functions */
const isProjectDetailsOpen = ref(true);
const isBaselineDocumentUpload = ref(false);
const isDrawDocumentUpload = ref(false);
const isProjectDocumentsListOpen = ref(false);

const expandCollapseController = (element) => {
  switch (element) {
    case 'projectDetails':
      isProjectDetailsOpen.value = !isProjectDetailsOpen.value
      break;
    case 'baselineDocuments':
      isBaselineDocumentUpload.value = !isBaselineDocumentUpload.value
      break;
    case 'drawDocuments':
      isDrawDocumentUpload.value = !isDrawDocumentUpload.value
      break;
    case 'projectDocumentsList':
      isProjectDocumentsListOpen.value = !isProjectDocumentsListOpen.value
      break;
    default:
      break;
  }
}

/** API Calls for Upload */
const apiBase = process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/"
const apiKey = process.env.VUE_APP_ENV_API_KEY
let authToken = reactive({});
const authStore = useAuthStore();
const errorStore = useErrorStore();
const router = useRouter()
const enteredProjectId = ref(parseInt(router.currentRoute.value?.query?.id) || null)

// For Project Upload 
async function uploadedBaselineDocument(projectId, data) {
  // Iterate in each container
  // Developer Budget Files first
  uploadDocuments(projectId, data.developerBudget.values, 'DeveloperBudget', data.date, null);
  // General Contractor Budget Files
  uploadDocuments(projectId, data.generalContractorBudget.values, 'GeneralContractorBudget', data.date, null);
  // General Contractor Schedule Files
  uploadDocuments(projectId, data.generalContractorSchedule.values, 'GeneralContractorSchedule', data.date, null);
  // Executed Construction Contracts
  uploadDocuments(projectId, data.executedConstructionContracts.values, 'ConstructionContracts', data.date, null);
}

async function uploadFile(file, document) {
  try {
    let keys = Object.keys(document);
    let formData = new FormData();
    formData.append('document[file]', file);
    keys.forEach((key) => formData.append(`document[${key}]`, document[key]));

    await axiosPostQuery(
      `${apiBase}/documents?api_key=${apiKey}`,
      formData,
      {
        authToken,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      },
      () => {
        console.log(`Document uploaded`);
        isProjectDocumentsListOpen.value = true;
      },
      errorStore
    );

  } catch (error) {
    console.log(`Document *not* uploaded`, error);
    errorStore.setError('Document Upload', 'An error occurred. The document upload was unsuccessful.', 'error', false);
  }
}

async function uploadedDrawDocument(projectId, data) {
  // Iterate in each container
  // Borrower Requisition Letter
  uploadDocuments(projectId, data.borrowerRequisitionLetter.values, 'BorrowerRequisitionLetter', data.periodThroughDate, data.drawNumber);
  // Developer Draw Tracker
  uploadDocuments(projectId, data.developerDrawTracker.values, 'SponsorDrawTracker', data.periodThroughDate, data.drawNumber);
  // Full Payment Application
  uploadDocuments(projectId, data.fullPaymentApplication.values, 'FullPaymentApplication', data.periodThroughDate, data.drawNumber);
  // Soft Cost Invoices
  uploadDocuments(projectId, data.softCostInvoices.values, 'SoftCostInvoices', data.periodThroughDate, data.drawNumber);
}

async function uploadDocuments(projectId, documentContainerArray, documentContainerName, date, drawNumber) {
  const validDocuments = documentContainerArray?.length ? [...documentContainerArray] : []
  const convertedDate = formattedDate(date);
  const convertedDrawNumber = drawNumber ? '_' + drawNumber : '';
  for (const file of validDocuments) {
    const validFile = getFilenameAndExtension(file.name);
    let document = {
      filepath: validFile[0] + '_' + documentContainerName + '_' + convertedDate + `${convertedDrawNumber}` + validFile[1],
      status: 'in_progress',
      project_id: projectId,
      period_through: convertedDate,
      draw_number: drawNumber
    };

    await uploadFile(file, document);
  }
}

const getFilenameAndExtension = (filename) => {
  const fileNameWithExtension = filename;

  const lastDotIndex = fileNameWithExtension.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    const fileName = fileNameWithExtension.substring(0, lastDotIndex);
    const fileExtension = fileNameWithExtension.substring(lastDotIndex);
    return [fileName, fileExtension]
  } else {
    return null
  }
}

function formattedDate(date) {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(dateObj.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

let projectBaselineDocuments = ref(null);
let projectDrawDocuments = ref(null);

function submitBaselineDocuments() {
  const projectBaselineDocumentsFormData = toRaw(projectBaselineDocuments).value.localForm;
  uploadedBaselineDocument(enteredProjectId.value, toRaw(projectBaselineDocumentsFormData));
}

function submitDrawDocuments() {
  const projectDrawDocumentsFormData = toRaw(projectDrawDocuments).value.localForm;
  uploadedDrawDocument(enteredProjectId.value, toRaw(projectDrawDocumentsFormData));
}

/** Document List Functions */
const documentsUrl = computed(() => {
  return `documents?api_key=${apiKey}&project_id=${enteredProjectId.value}`
})

const documentsColumns = ['id', 'filepath', 'status', 'period_through', 'draw_number', 'project_id', 'error', 'created_at', 'updated_at']
const documentsColumnDefs = computed(() => {
  let documentsColumnDefs = []
  documentsColumns.forEach(columnName => {
    let opts = {
      headerName: columnName,
      field: columnName,
      editable: false,
      filter: true,
      floatingFilter: true,
    }
    // freeze/pin itemHierarchy to the left so it can be seen when scrolling (esp. for Schedule page)
    if (columnName === 'id' || columnName === 'dda_term_id' || columnName === 'project_id' || columnName === 'projectable_id') {
      opts.pinned = 'left'
    }
    documentsColumnDefs.push(opts)
  })
  return documentsColumnDefs
})


onMounted(() => {
  authToken.value = authStore.getAuthTokenHeader;
})


</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.project-view-container {
  margin-top: 60px;
  padding: 15px;
  font-family: 'Inter';

  .project-view-form {
    display: grid;
    grid-template-columns: auto auto;
    padding-bottom: 15px;
  }

  .upload-document-header-container {
    padding-bottom: 30px;
  }

  .upload-document-main-title {
    font-size: 24px;
    font-weight: bolder;
    color: $navyBlue;
    margin-bottom: 15px;
  }

  .breadcrumb-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px;
    color: #8C8D92;
  }
}

.tile {
  background-color: $white1;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
  height: 100%;

  .project-detail-header {
    color: $navyBlue;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .project-detail-sub-header {
    color: $navyBlue;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 0px;
  }

}

.existing-project {
  grid-column: 1 / 3; // span width of whole screen
  height: auto;
  margin-right: 20px;
}

.page-description {
  font-size: 14px;
  width: 100%;
  padding: 15px 0px;
}

.label-text-spacing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


.divider {
  border-top: 2px solid lightgray;
}

/** Container Accordion -- Start */
.accordion-panel-header {
  display: flex;
  align-items: center;
  position: relative;
  width: 99%;
  margin: 15px 0;
  color: $navyBlue;
  font-weight: bolder;

  label {
    font-size: 20px;
  }
}

// Button
.action-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.primary-action-button {
  background-color: $mediumBlue2;
  color: white;
  border: 0;
  border-radius: 4px;
  margin: 8px;
  cursor: pointer;
  padding: 10px;
  font-size: 12px;

  &.disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    opacity: 0.6;
  }
}

/** Button */
.project-action-button {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0;

  button {
    background-color: $lightGray1;
    border: 1px solid $paleBlue1;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
  }
}

.custom-text-input {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 5px 0px;
  width: 100%;

  .left-div {
    width: 40%;
  }

  .right-div {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  input[type=text] {
    color: $brightBlue1;
    background-color: $lightGray1;
    border: 1px solid $lightGray1;
    border-radius: 5px;
    padding: 5px;
    height: 10px;
    width: 100%;
  }

  input[type=text]:focus {
    border-color: $brightBlue1;
    outline: $brightBlue1;
  }
}
</style>
