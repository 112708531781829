import axios from "axios"

export async function axiosQuery(url, config, callback, errorStore, errorTitleName) {
  try {
    axios.get(url, config)
      .then(result => {
        callback(result?.data)
      })
      .catch(error => {
        if (errorStore) {
          return errorManagement(error, errorStore, errorTitleName)
        }
      })
  } catch (error) {
    if (errorStore) {
      errorStore.setError("General Error " + errorTitleName, error.message)
    }
  }
  return null
}

export async function axiosPostQuery(url, data, config, callback, errorStore, errorTitleName) {
  try {
    axios.post(url, data, config)
      .then(result => {
        callback(result?.data)
      })
      .catch(error => {
        if (errorStore) {
          return errorManagement(error, errorStore, errorTitleName)
        }
      })
  } catch (error) {
    if (errorStore) {
      errorStore.setError("General Error " + errorTitleName, error.message)
    }
  }
  return null
}

export function errorManagement(error, errorStore, errorTitleName) {
  if (error && error.response && error.response.status === 401) {
    errorStore.setError('Login Expired', errorStore.getLoggedOutMessage)
  }
  else if (error && error.response && error.response.status === 403) {
    errorStore.setError("Error " + errorTitleName, 'You do not have access to this data.')
  } else if (error.response) {
    // The client was given an error response (5xx, 4xx)
    const message = formatErrorMessage(errorTitleName, error)
    errorStore.setError("Error " + errorTitleName, message)
  } else if (error.request) {
    // The client never received a response, and the request was never left
    errorStore.setError("Error " + errorTitleName, 'Error receiving a response from the server. Error message:' + error.message)
  } else {
    // Anything else
    errorStore.setError("Error " + errorTitleName, error.message)
  }
  return Promise.reject(error)
}

export function formatErrorMessage(tableName, error) {
  let { ...errorObject } = error
  let message = ''
  if (errorObject?.response && errorObject.response.status === 404) {
    return `Server Error: ${errorObject.response.statusText}`
  } else if (errorObject?.response && errorObject.response.status === 403) {
    return 'You do not have access to this data.'
  } else if ((error?.response || errorObject) && (errorObject.status === 400 || errorObject.code === 'ERR_BAD_REQUEST')) {
    // get the proper error object as it can vary
    if (errorObject.response) {
      errorObject = errorObject.response
    } else if (error?.response) {
      errorObject = error.response
    }
    if (errorObject.data && typeof (errorObject.data[tableName]) === 'string') {
      message += errorObject.data[tableName]
    } else if (errorObject.data && errorObject.data[tableName]) {
      errorObject.data[tableName].forEach(errors => {
        if (typeof (errors) === 'string') {
          message += errors + "; "
        } else {
          Object.keys(errors).forEach((key) => {
            let error_keys = typeof (errors[key]) === 'object' ? errors[key].join(',') : String(errors[key].join(','))
            message += "*" + key + "*: " + error_keys + "; "
          })
        }
      })
    } else if (errorObject.data && typeof (errorObject.data) === 'object') {
      Object.keys(errorObject.data).forEach((key) => {
        let error_keys = typeof (errorObject.data[key]) === 'object' && errorObject.data[key] ? errorObject.data[key].join(',') : String(errorObject.data[key])
        message += "*" + key + "*: " + error_keys + "; "
      })
    } else {
      message = 'validations failed'
    }
    return message
  } else if (errorObject) {
    message = errorObject.status ? (errorObject.status + '. ') : ''
    message = message + (errorObject.errorData ? (errorObject.errorData + '. ') : '')
    message = message + (errorObject.exception ? (errorObject.exception + '. ') : '')
    return message
  } else {
    return 'Unknown Error'
  }
}

export function largeEditWarning(count) {
  if (count > 10) {
    const answer = window.confirm(
      `WARNING: You have selected ${count} items to move. Are you sure you want to do this?`
    )
    // cancel the navigation and stay on the same page
    if (!answer) return false
  }

  return true
}

export function downloadPdf(downloadFileName, resultData) {
  let pdfFile = new Blob([resultData], {
    type: "application/pdf",
    'Content-Disposition': 'attachment'
  })
  let pdfUrl = URL.createObjectURL(pdfFile)

  let anchor = document.createElement('a')
  anchor.href = pdfUrl
  anchor.target = '_blank'
  // eslint-disable-next-line no-unused-vars
  anchor.download = downloadFileName || 'file.pdf'
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export function standardizeLabelLength(label, maxLength = 15) {
  if (label && label.length > maxLength - 3) {
    return label.slice(0, maxLength) + "...";
  }
  return label
}
export function standardizeLabelsLength(labels, maxLength = 15) {
  if (!labels) { return labels }

  let newLabels = []
  labels.forEach(label => {
    if (label && label.length > maxLength - 3) {
      newLabels.push(label.slice(0, maxLength) + "...")
    } else {
      newLabels.push(label)
    }
  })
  return newLabels
}
export function percentFormat(percent) {
  if (percent === undefined || percent === null) { return '' }
  let amount = Math.round(parseFloat(percent).toFixed(2) * 100)
  amount = formatDecimals(amount) // limit accuracy / detail
  return amount + '%'
}
export function numberFormat(number, numDecimals = 2) {
  if (number === undefined || number === null) { return '' }
  let parts = number.toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  if (numDecimals !== null && parts[1]) {
    parts[1] = parts[1].slice(0, numDecimals)
  }
  return numDecimals !== 0 ? parts.join(".") : parts[0]
}
// get the tens digit after decimal
export function decimalPart(number) {
  if (number === undefined || number === null) { return '' }
  let parts = number.toString().split(".")
  if (parts[1]) {
    return parseFloat(`0.${parts[1].slice(0, 1)}`)
  }
  return 0.0
}
export function kMillionsFormat(money) {
  if (!money && money !== 0.0) {
    return ''
  } else if (Math.abs(money) >= 1000000000) {
    return "$" + (money / 100000000).toFixed(0) + "B";
  } else if (Math.abs(money) >= 1000000) {
    return "$" + (money / 1000000).toFixed(0) + "M";
  } else if (Math.abs(money) >= 1000) {
    return "$" + (money / 1000).toFixed(0) + "k";
  }

  return currencyFormat(money.toFixed(0))
}

export function kMillionsWithDecimalsFormat(money) {
  if (!money && money !== 0.0) {
    return ''
  } else if (Math.abs(money) >= 1000000000) {
    return "$" + (money / 1000000000).toFixed(1) + "B";
  } else if (Math.abs(money) >= 1000000) {
    return "$" + (money / 1000000).toFixed(1) + "M";
  } else if (Math.abs(money) >= 1000) {
    return "$" + (money / 1000).toFixed(1) + "k";
  }

  return currencyFormat(money)
}
export function currencyFormat(money, skipDecimal) {
  if (money === undefined || money === null || (!parseFloat(money) && money !== 0)) { return '' }
  let amount = parseFloat(money).toFixed(skipDecimal ? 0 : 2)
  // Add commas
  amount = amount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  return '$' + amount
}
// new version of currency format with (0.25 / 0.75) decimal rounding applied
export function currencyFormatRoundedDecimal(money) {
  if (!isNaN(parseFloat(money))) {
    let amount = formatDecimals(money)
    // Add commas
    amount = amount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    return '$' + amount
  }
  return null
}
// Remove currency formatting from money
export function removeCurrencyFormat(money) {
  if (money === undefined || money === null || money === '') { return money }
  if (!money.toString().match(/\$[0-9-,.]+/)) { return money }
  // Remove commas and $
  return parseFloat(money.toString().replace(/[$,]/g, ''))
}
// second parameter lets you choose whether or not time is shown
export function dateFormat(dateStr, includeTime) {
  if (!dateStr) { return '' }
  let formatted = null
  if (typeof (dateStr) !== 'string' || (dateStr.indexOf('T') > -1 || dateStr.indexOf('Z') > -1)) {
    formatted = new Date(dateStr)
  } else {
    // honor that this is coming as a date that needs to avoid time zone conversion
    formatted = new Date(dateStr + 'T12:00:00.000Z')
  }
  // Not sure why a comma is coming in here....
  formatted = formatted.toLocaleString().replace(',', '')
  return includeTime ? formatted : formatted.split(' ')[0]
}
// only show time, allow to choose whether show seconds
export function timeFormat(dateStr, includeSeconds, includeAmPm) {
  if (!dateStr) { return '' }
  let formatted = new Date(dateStr)
  // Not sure why a comma is coming in here....
  // Example output from formatted.toLocaleString(): 3/9/2023, 1:11:26 PM
  formatted = formatted.toLocaleString().replace(',', '')
  // get AM or PM and convert to lower case
  let ampm = formatted.split(' ')[2]?.toLowerCase()
  // Get h:mm:ss
  formatted = formatted.split(' ')[1].split(':')
  formatted = includeSeconds ? formatted.join(':') : formatted[0] + ':' + formatted[1]
  return includeAmPm ? formatted + ampm : formatted
}
// Convert a Javascript date to a SQL-friendly date
export function jsDateToSqlDate(date, addTime) {
  if (!date) { return '' }
  if (addTime) {
    throw new Error('This can introduce hard to solve bugs where dates keep getting incremented by a day!')
    // const now = new Date()
    // date = new Date(date.getUTCFullYear(), date.getUTCMonth(),
    //     date.getUTCDate(), now.getUTCHours(),
    //     now.getUTCMinutes(), now.getUTCSeconds())
    // return date.getUTCFullYear() + '-' + prependZero(date.getUTCMonth() + 1) + '-' + prependZero(date.getUTCDate()) + ' T' + prependZero(now.getUTCHours()) + ':' + prependZero(now.getUTCMinutes()) + ':' + prependZero(now.getUTCSeconds()) + 'Z'
  } else {
    return date.getUTCFullYear() + '-' + prependZero(date.getUTCMonth() + 1) + '-' + prependZero(date.getUTCDate()) + ' T' + prependZero(date.getUTCHours()) + ':' + prependZero(date.getUTCMinutes()) + ':' + prependZero(date.getUTCSeconds()) + 'Z'
  }
}
export function yyyymmddDateFormat(date) {
  if (!date) { return '' }
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}${month}${day}`
}

function prependZero(num) {
  if (num < 10) {
    return `0${num}`
  }
  return num
}

// An extra protection before sending it to the backend.
// This isn't perfect as the backend is what does the heavy lifting.
// A lot of valid characters can be used by the client.
export function sanitizeString(text) {
  if (!text) { return text }
  return String(text).replace('`', '')
    .replace('@', '')
    .replace('""', '')
    .replace(`"'`, '')
    .replace(`'"`, '')
    .replace(/DROP TABLE/i, '')
    .replace(/DELETE FROM/i, '')
    .replace(/SElECT FROM/i, '')
}

export function strToHtml(str) {
  return str.replace(/[&<>'"]/g,
    tag => ({
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      "'": '&#39;',
      '"': '&quot;'
    }[tag]))
}

export function formatDecimals(number, decimalPlace = 1) {
  if (!number && number !== 0) { return number }
  number = parseFloat(number)
  // Get first the whole number
  let decimal = number - Math.floor(number);
  // Checks the decimal numbers
  if (decimal < 0.25) {
    return Math.floor(number).toFixed(decimalPlace);
  } else if (decimal < 0.75) {
    return (Math.floor(number) + 0.5).toFixed(decimalPlace);
  } else {
    return Math.ceil(number).toFixed(decimalPlace);
  }
}
// Finds if num is in the range of num1 and num2
// From https://stackoverflow.com/a/71677332/2611078
export function inRange(num, num1, num2) {
   return Math.min(num1, num2) <= num && Math.max(num1, num2) >= num
}

// Find the median of an array of values
export function median(values) {
  if (values.length === 0) return 0;

  values.sort(function(a, b) {
    return a - b;
  });

  const half = Math.floor(values.length / 2);

  if (values.length % 2) {
    return values[half];
  } else {
    return (values[half - 1] + values[half]) / 2.0;
  }
}
