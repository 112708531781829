<template>
  <div class="svg-container">
    <img src="@/assets/report/Reports - 1.svg" alt="Report 1" class="svg-image" />
    <img src="@/assets/report/Reports - 2.svg" alt="Report 2" class="svg-image" />
    <img src="@/assets/report/Reports - 3.svg" alt="Report 3" class="svg-image" />
  </div>
</template>

<script>
export default {
  name: 'ReportPage'
}
</script>

<style scoped>
.svg-container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.svg-image {
  width: 100%;
  max-width: 60vw;
  margin-bottom: 20px;
}
</style>