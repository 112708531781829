<template>
    <div>
        <!-- Selectors -->
        <div>
            <div class="custom-select">
                <label class="left-div" for="baselineAt">Current Baseline</label>
                <select class="right-div" id="baselineAt" v-model="localForm.baselineAt">
                    <option v-for="(baselineType, index) in baselineAtTypes" :key="index">{{
                    baselineType.name }}</option>
                </select>
            </div>
            <div class="custom-text-input">
                <label class="left-div">Date</label>
                <div class="right-div" :class="{ 'error-input': formError.date }">
                    <VueDatePicker v-model="localForm.date" :enable-time-picker="false" format="yyyy-MM-dd">
                    </VueDatePicker>
                    <div v-if="formError.date" class="error-message">Date is Required
                    </div>
                </div>
            </div>
        </div>
        <!-- Input File Container -->
        <div class="baseline-drop-file-container">
            <!-- Developer Budget-->
            <div>
                <DropFile :header-title="'Developer Budget'" :header-sub-title="'Download Template (Optional)'"
                    :name="'developerBudget'" v-on:uploaded-files="matchFileContainers($event, 'developerBudget')" />
            </div>
            <!-- General Contractor Budget -->
            <div>
                <DropFile :header-title="'General Contractor Budget'" :header-sub-title="'Download Template (Optional)'"
                    :name="'generalContractorBudget'"
                    v-on:uploaded-files="matchFileContainers($event, 'generalContractorBudget')" />
            </div>
            <!-- General Contractor Schedule -->
            <div>
                <DropFile :header-title="'General Contractor Schedule'" :name="'generalContractorSchedule'"
                    v-on:uploaded-files="matchFileContainers($event, 'generalContractorSchedule')" />
            </div>
            <!-- Construction Contracts -->
            <div>
                <DropFile :header-title="'Executed Construction Contracts'" :name="'executedConstructionContracts'"
                    v-on:uploaded-files="matchFileContainers($event, 'executedConstructionContracts')" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineExpose, defineProps, reactive, ref } from 'vue';
import DropFile from "@/shared/DropFile.vue";

const props = defineProps({
    form: {
        type: Object, default: () => ({
            baselineAt: null,
            date: null,
            developerBudget: [],
            generalContractorBudget: [],
            generalContractorSchedule: [],
            executedConstructionContracts: [],
        })
    }
})

const formError = reactive({
    date: false,
});

let localForm = reactive({ ...props.form });

// todo: Ask Jam or Julie if we have codes for this one
const baselineAtTypes = ref([
    {
      id: 1,
      name: 'Acquisition Financing (Design & Planning)'
    },
    {
      id: 2,
      name: 'Bridge Financing (Construction Documents & Bidding)'
    },
    {
      id: 3,
      name: 'Construction Financing (Sub Buyout Progressing)'
    },
    {
      id: 4,
      name: 'Restructuring (Adjusting the Capital Stack)'
    },
    {
      id: 5,
      name: 'Completion Financing (Construction Nearly Complete)'
    },
    {
      id: 6,
      name: 'Permanent Financing (Construction Complete)'
    },
    {
      id: 7,
      name: 'Preconstruction (Design Development)'
    },
    {
      id: 8,
      name: 'Substantial Buyout (Construction Documents)'
    },
    {
      id: 9,
      name: 'Construction Management Agreement (Sub Buyout Started)'
    },
    {
      id: 10,
      name: 'Exceeded Budget (Adjusting the Capital Stack)'
    },
    {
      id: 11,
      name: 'Substantial Completion (Construction Nearly Complete)'
    },
    {
      id: 12,
      name: 'Final Completion & Operations (Construction Complete)'
    }
  ]);

const matchFileContainers = (event, containerName) => {
    switch (containerName) {
        case 'developerBudget':
            localForm.developerBudget.values = event;
            break;
        case 'generalContractorBudget':
            localForm.generalContractorBudget.values = event;
            break;
        case 'generalContractorSchedule':
            localForm.generalContractorSchedule.values = event;
            break;
        case 'executedConstructionContracts':
            localForm.executedConstructionContracts.values = event;
            break;
        default:
            break;
    }
}

defineExpose({
    localForm
})
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

/* Style for the input drop files */
.baseline-drop-file-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    margin-top: 20px;
    overflow-y: scroll;
    height: 100%;

    div {
        padding-bottom: 10px;
    }
}

.baseline-drop-file-container::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: $white1;
}

.baseline-drop-file-container::-webkit-scrollbar-thumb {
    background: $lightGray1;
}

// Custom Plus Sign inside a circle
.cssCircle {
    margin-left: 5px;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    border-radius: 999px;

    width: 20px;
    height: 20px;
    padding: 0px;

    background: $white1;
    border: 1px solid $navyBlue;
    color: $navyBlue;
    text-align: center;
    -webkit-transition: background 0.2s linear;
    -moz-transition: background 0.2s linear;
    -ms-transition: background 0.2s linear;
    -o-transition: background 0.2s linear;
    transition: background 0.2s linear;
    transition: color 0.2s linear;

    font: 20px Arial, sans-serif
}

.cssCircle:hover {
    background: $mediumBlue2;
    cursor: pointer;
}

.plusSign {
    line-height: 1em;
}

.plusSign:hover {
    color: $white1;
    border: 1px solid $white1;
}

// Input CSS
.custom-text-input {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 0px;

    label {
        font-weight: 100;
    }

    .left-div {
        width: 10%;
    }

    .right-div {
        width: 20%;
    }

    input[type=number] {
        color: $brightBlue1;
        background-color: $white1;
        border: 1px solid $white1;
        border-radius: 5px;
        padding: 5px;
        height: 10px;
        width: 25%;
    }

    input[type=number]:focus {
        border-color: $brightBlue1;
        outline: $brightBlue1;
    }
}


// Custom Dropdown
.custom-select {
    width: 100%;
    position: relative;
    font-family: inherit;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 0px;
    color: $navyBlue;

    .left-div {
        width: 10%;
    }

    .right-div {
        width: 20%;
        display: flex;
        justify-content: flex-start;
    }

    label {
        font-weight: 100;
    }

    select {
        appearance: none;
        font-family: inherit;
        -webkit-appearance: none;
        padding: 10px;
        color: $brightBlue1;
        cursor: pointer;
        width: 58.5%;
        font-size: 12px;
        line-height: 1;
        border: 0;
        border-radius: 5px;
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right $white1;
        background-position-x: 5px;
        text-align-last: right;
        background-size: 10px;
        border: 1px solid $lightGray1;
    }

    select:focus {
        border-color: $brightBlue1;
        outline: $brightBlue1;
    }
}

.error-message {
    color: $red3;
    font-size: 10px;
    padding-top: 5px;
}

.error-input {
    .dp__theme_light {
        --dp-border-color: #ea5a4f;
        --dp-border-color-hover: #ea5a4f;
        --dp-border-color-focus: #ea5a4f;
    }
}
</style>