<template>
  <div :class="showModal ? 'side-modal-open' : 'side-modal-closed'" class="side-modal">
    <ExpandCollapse name="sidemodal" :is-open-initially="true" :is-vertical-center="false" open-state="right-left"
      closed-state="right-left" :show-double-chevron="true" @toggle-expand-collapse="toggleModal()"
      :show-circle="true" />
    <div v-if="showModal" class="side-modal-child modal">
      <div class="side-modal-items">
        <div class="side-modal-title">Subject Property Panel</div>
        <div class="subheader">
          <div class="side-modal-items-title">Input Comparison Values</div>
        </div>
        <div class="side-modal-item input-summary-data">
          <div class="compset-data-summary">
            <span class="label">Project Name</span>
            <span class="summary-value"><input type="text" :id="project - name" placeholder="Project Name" /></span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Address</span>
            <span class="summary-value"><input type="text" :id="project - address" placeholder="Address" /></span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Gross Sq Ft</span>
            <span class="summary-value"><input type="text" :id="project - sq - ft" placeholder="Gross Sq Ft" /></span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Unit Count</span>
            <span class="summary-value"><input type="text" :id="project - unit - count"
                placeholder="Unit Count" /></span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Stories</span>
            <span class="summary-value"><input type="text" :id="project - stories" placeholder="Stories" /></span>
          </div>
          <div class="compset-data-summary">
            <span class="label">Parking Count</span>
            <span class="summary-value"><input type="text" :id="project - stories" placeholder="Parking Count" /></span>
          </div>
        </div>
      </div>
      <div v-if="showModal" class="side-modal-items2 compare-props">
        <div class="divider"></div>
        <div class="side-modal-items-title grayed-text">Compare My Properties</div>
        <div class="search-field">
          <input type="text" class="search-text-box" placeholder="Search My Data" />
        </div>
        <div class="side-modal-item upload-docs">
          <span>+ Add New Project & Upload Documents</span>
        </div>
        <div class="divider"></div>
      </div>

      <div v-if="showModal" class="side-modal-items3">
        <div class="side-modal-items-title">Comparison Property Values</div>
        <span class="grid-instructions">Input values below to compare between current compset and subject
          property.</span>
        <div class="side-modal-item side-modal-grid-container">
          <ag-grid :row-data="sideModalSampleData" :columnDefs="sideModalColumnDefs" :column-options="columnOptions"
            :treeData="false"></ag-grid>
        </div>
        <div class="action-btn-container">
          <div class="left-div">
            <button class="secondary-action-button">Clear</button>
            <button class="secondary-action-button">Save Values</button>
          </div>
          <div class="right-div">
            <button class="primary-action-button" type="submit">Run Comparison</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import ExpandCollapse from "../helpers/ExpandCollapse.vue";
import AgGrid from "../../components/AgGrid.vue";
import { sideModalColumnDefs, sideModalSampleData } from "@/data/data";
import { currencyFormatRoundedDecimal, percentFormat } from "@/assets/js/utils";


let showModal = ref(false);
const columnOptions = {
  input_value: {
    maxWidth: '120',
    cellRenderer: (params => {
      if (params.value !== 0 && !params.value) {
        return ''
      }
      return currencyFormatRoundedDecimal(params.value)
    })
  },
  variance_monetary: {
    maxWidth: '100',
    cellRenderer: (params => {
      if (params.value !== 0 && !params.value) {
        return ''
      }
      return currencyFormatRoundedDecimal(params.value)
    })
  },
  variance_perc: {
    maxWidth: '100',
    cellRenderer: (params => {
      if (params.value !== 0 && !params.value) {
        return ''
      }
      return percentFormat(params.value)
    })
  }
}
function toggleModal() {
  showModal.value = !showModal.value;
}
</script>
<style scoped lang="scss">
@import "./src/assets/main.scss";

.side-modal {
  position: fixed;
  display: flex;
  height: 100vh;
  top: 50px;
  z-index: 3; // Get above AG Grid .ag-ltr .ag-header-cell-resize
  background: $white1;
}

.side-modal-title {
  font-size: 16px;
  font-family: 'Inter';
  font-weight: bold;
  color: $navyBlue;
  padding: 20px 0 10px 0;
}

.side-modal-items-title {
  font-size: 14px;
  font-family: 'Inter';
  font-weight: bold;
  color: $navyBlue;
  padding: 10px 0;
}

.side-modal-open {
  right: 0;
  transition: right 0.3s ease;
}

.side-modal-closed {
  right: 20px;
  transition: right 0.3s ease;
}

.modal {
  display: flex;
  width: 370px;
  box-shadow: 6px 0 18px #BEBFBF;
}

.side-modal-items {
  position: absolute;
  margin: 0 30px 0 30px;

  input {
    border: 1px solid #F77B01;
    text-align: right;
    margin-left: 80px;
  }
}

.side-modal-items2 {
  position: absolute;
  top: 27%;
  margin: 0 30px 0 30px;
  font-size: 14px;

  input {
    width: 200px;
    margin: -20px 40px 10px 40px;
    border: 1px solid lightgray;
    padding: 5px 10px 5px 10px;
    box-shadow: 5px 0 5px lightgrey;
  }
}

.upload-docs {
  margin-left: 40px;
  margin-bottom: 20px;
  color: #F77B01;
}

.side-modal-items3 {
  position: absolute;
  top: 41%;
  left: 24px;
  font-size: 14px;
}

.side-modal-item {
  display: flex;
  flex-direction: column;
}

.or {
  flex: 0.5;
  width: 20px;
}

.divider {
  border: 1px solid lightgray;
}

span {
  font-size: 12px;
}

span.summary-value {
  float: right;
}

span.label {
  color: #494C56;
}

.grayed-text {
  color: #A7A9AD;
}

.side-modal-grid-container {
  margin-top: 20px;
  display: flex;
  width: 320px;
  height: 300px;
  font-size: 10px;
}

// Action Buttons
.action-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 8vh;
}

.primary-action-button {
  background-color: $brightOrange;
  color: $white1;
  border: 0;
  border-radius: 4px;
  margin: 8px;
  cursor: pointer;
  padding: 10px;
  font-size: 12px;
}

.secondary-action-button {
  background-color: transparent;
  color: $brightOrange;
  border: 0;
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
}

.secondary-action-button:hover {
  text-decoration: underline;
}

::placeholder {
  color: lightgray;
  font-size: 12px;
}

.search-text-box::placeholder {
  color: lightgray;
  font-size: 12px;
}

.grid-instructions {
  color: #A7A9AD;
}

.left-div .right-div {
  flex: 1;
}

.right-div {
  p {
    margin: 5px;
  }
}
</style>
