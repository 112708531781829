<template>
  <div v-if="isDataLoaded">
    <div>
      <div class="company-summary-header-container">
        <div class="company-summary-header-title">Company Summary</div>
      </div>
      <div class="company-summary-content">
        <div class="tile company-summary-container">
          <!-- Capital -->
          <div class="company-items-container">
            <div class="chart-main-title">Capital</div>
            <CompanyItem title="Debt" :items="investorSponsorTypes.lenders" />
            <CompanyItem title="Equity" :items="investorSponsorTypes.investors" />
          </div>
          <!-- Management -->
          <div class="company-items-container">
            <div class="chart-main-title">Management</div>
            <CompanyItem title="Developer(s)" :items="investorSponsorTypes.developers" />
            <CompanyItem title="General Contractor(s)" :items="consultantTypesData.subcontracts" />
            <CompanyItem title="Owner's Representative" :items="consultantTypesData.owners_reps" />
          </div>
          <!-- Design -->
          <div class="company-items-container">
            <div class="chart-main-title">Design</div>
            <CompanyItem title="Design Architect" :items="consultantTypesData.design_architects" />
            <CompanyItem title="Architect of Record" :items="consultantTypesData.architects" />
            <CompanyItem title="Interior Design" :items="consultantTypesData.interior_designers" />
            <CompanyItem title="Landscape Architect" :items="consultantTypesData.landscape_architects" />
          </div>
          <!-- Engineering -->
          <div class="company-items-container">
            <div class="chart-main-title">Engineering</div>
            <div class="engineering-items-container">
              <div>
                <CompanyItem title="Civil" :items="consultantTypesData.civil_engineers" />
                <CompanyItem title="Structural" :items="consultantTypesData.structural_engineers" />
                <CompanyItem title="Mechanical" :items="consultantTypesData.mechanical_engineers" />
                <CompanyItem title="Electrical" :items="consultantTypesData.electrical_engineers" />
              </div>
              <div>
                <CompanyItem title="Plumbing" :items="consultantTypesData.plumbing_engineers" />
                <CompanyItem title="Fire Protection" :items="consultantTypesData.fire_protection_engineers" />
                <CompanyItem title="Environmental" :items="consultantTypesData.environmental_engineers" />
                <CompanyItem title="Geotechnical" :items="consultantTypesData.geotechnical_engineers" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO: Uncomment this if the table is ready to be use again -->
    <!-- Table Grid -->
    <!-- <div>
      <div class="company-tracking-header-container">
        <div class="company-tracking-header-title">Company Tracking</div>
      </div>
      <div class="grid-container" id="companyViewGrid">
        <ag-grid :row-height="20" :row-data="leafParentData" :columnDefs="columnDefs" :treeData="true"
          :auto-group-column-def="autoGroupColumnDef" :key="`${leafParentDataCtr};`" @setChartData="setChartData"/>
      </div>
    </div> -->
  </div>
  <div v-else class="no-data-loaded"> An error occurred. No data has been fetched.
  </div>
</template>
<script setup>
/* Please note that we are using the examples in the My Data View Data since we don't know yet where exactly we should pull the data */
/* eslint no-undef: 0 */
import { computed, onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from "@/stores/auth_store";
import { useErrorStore } from "@/stores/error_store";
// import { cleanupHierarchyNames } from "@/data/data";
import { axiosQuery} from "@/assets/js/utils"; //, currencyFormat, percentFormat } from "@/assets/js/utils";
// import AgGrid from "../../components/AgGrid.vue";
import { OWNER_ALLOCATIONS, CONSULTANT_TYPES } from "@/types/Constants";
import CompanyItem from '@/components/mydata/CompanyItem.vue';
// Ag-Grid Settings
// Need to ask what endpoint should I be getting these values to indicate the fields as well
// NOTE: if adding more sumAggregate or other aggregate functions, please adjust leafParentData accordingly
// const sumAggregateCols = ['original_budget', 'total_adjustments', 'current_budget', 'total_spent']
// const autoGroupColumnDef = {
//   headerName: 'Description of Work',
//   minWidth: 350,
//   pinned: 'left', // freeze/pin itemHierarchy to the left so it can be seen when scrolling (esp. for Schedule page)
//   cellRendererParams: {
//     suppressCount: true,
//   },
// }
// const columnDefs = [
//   {
//     headerName: 'Scopes of Work',
//     headerClass: 'center-align-header',
//     children: [
//       {
//         headerName: 'Company',
//         field: 'col2',
//         cellStyle: { color: '#999', backgroundColor: '#f0f0f0' },
//         headerClass: 'disabled-header',
//         sortable: false,
//         filter: false,
//         editable: false,
//         resizable: false,
//       }
//     ],
//   },
//   {
//     headerName: 'Performance',
//     headerClass: 'center-align-header',
//     children: [
//       {
//         headerName: "Budget",
//         field: "original_budget",
//         aggFunc: sumAggregate,
//         editable: false,
//         valueParser: "Number(newValue)",
//         valueFormatter: params => `${currencyFormat(params.value)}`,
//         type: 'rightAligned'
//       },
//       {
//         headerName: "Variance",
//         field: "variance",
//         headerClass: 'header-custom-lightblue',
//         aggFunc: varianceAggregate,
//         editable: false,
//         valueParser: "Number(newValue)",
//         valueFormatter: params => `${percentFormat(params.value)}`,
//         type: 'rightAligned'
//       },
//       {
//         headerName: "Schedule",
//         field: "current_budget",
//         aggFunc: sumAggregate,
//         editable: false,
//         valueParser: "Number(newValue)",
//         valueFormatter: params => `${currencyFormat(params.value)}`,
//         type: 'rightAligned'
//       },
//       {
//         headerName: "Variance",
//         field: "variance",
//         headerClass: 'header-custom-lightblue',
//         aggFunc: varianceAggregate,
//         editable: false,
//         valueParser: "Number(newValue)",
//         valueFormatter: params => `${percentFormat(params.value)}`,
//         type: 'rightAligned'
//       },
//     ],
//   },
// ];
// let leafParentData = ref([]);
// let leafParentDataCtr = ref(0)

// API Calls
const apiBase =
  process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/";
const authStore = useAuthStore();
const errorStore = useErrorStore();
let authToken = reactive({});
// let isNoDataFetch = ref(false);
// let isGridDataLoaded = ref(false);
// let isBVSVCostPerGSFDataLoaded = ref(false);
let isDataLoaded = computed(() => {
  return projectInformation.value !== null;
  //return isGridDataLoaded.value && isBVSVCostPerGSFDataLoaded.value
})
// let branchGridData = {}
// let leafParentNodesToData = {}
let projectInformation = ref()
async function fetchProjectInformation() {
  return new Promise((resolve) => {
    axiosQuery(
      `${apiBase}projects/${selectedId.value}`,
      authToken,
      (data) => {
        projectInformation.value = data
        resolve(projectInformation.value.id)
      },
      errorStore,
      'Error fetching project_id' + selectedId.value
    );
  })
}
// // fetch GridData first
// function fetchGridData() {
//   if (selectedId.value) {
//     axiosQuery(
//       `${apiBase}mvw_most_recent_by_projects/leaves_sum_grouped_by_term?project_ids=${selectedId.value}`,
//       authToken,
//       (data) => {
//         if (data.length === 0) {
//           isNoDataFetch.value = true
//         } else {
//           branchGridData = {}
//           leafParentNodesToData = {}
//           let leafParentDataTemp = []
//           data?.forEach((row) => {
//             const aRow = {
//               dda_term_id: row.dda_term_id,
//               hiddenHierarchy: cleanupHierarchyNames(row.itemHierarchy), // TODO Need to retain this hiddenHierarchy?
//               itemHierarchy: cleanupHierarchyNames(row.itemHierarchy),
//               original_budget: row.original_budget,
//               total_adjustments:
//                 parseFloat(row.current_budget || 0) -
//                 parseFloat(row.original_budget || 0),
//               current_budget: row.current_budget || 0,
//               variance: ((row.current_budget || 0) - row.original_budget) / (row.original_budget || 1),
//               draw_summary: row.current_request,
//               total_spent:
//                 parseFloat(row.previously_funded || 0) +
//                 parseFloat(row.current_request || 0),
//               percent_complete: row.total_completed_to_date_pct_avg,
//             }
//             // Add this row everywhere in the hierarchy
//             aRow.hiddenHierarchy.forEach((term, idx) => {
//               branchGridData[term] ||= []
//               branchGridData[term].push(aRow)
//               // Add leaf parent data -- ie the node just above a leaf node (2nd to the bottom)
//               if (aRow.hiddenHierarchy.length - 2 === idx) {
//                 leafParentNodesToData[aRow.hiddenHierarchy[idx]] ||= []
//                 leafParentNodesToData[aRow.hiddenHierarchy[idx]].push(aRow)
//               }
//             })
//           })
//           // Do the summation and other aggregate math on the leaf parent nodes as AG Grid will not call this automatically
//           Object.keys(leafParentNodesToData).forEach(leafParentTerm => {
//             // Remove the leaf node as we don't show these.
//             // Ensure we clone the objects so we don't destroy the hierarchy for other queries
//             let aRow = { ...leafParentNodesToData[leafParentTerm][0] }
//             aRow.hiddenHierarchy = [...aRow.hiddenHierarchy]
//             aRow.hiddenHierarchy.pop()
//             aRow.itemHierarchy = [...aRow.itemHierarchy]
//             aRow.itemHierarchy.pop()
//             // Calculate subtotals at the non-leaf node level
//             sumAggregateCols.forEach(colId => {
//               aRow[colId] = sumAggregate({
//                 rowNode: { key: leafParentTerm },
//                 column: { colId: colId }
//               })
//             })
//             aRow.variance = varianceAggregate({
//               rowNode: { key: leafParentTerm },
//               column: { colId: 'variance' }
//             })
//             aRow.percent_complete = pctCompleteAggregate({
//               rowNode: { key: leafParentTerm },
//               column: { colId: 'percent_complete' }
//             })
//             leafParentDataTemp.push(aRow)
//           })
//           leafParentData.value = leafParentDataTemp
//           leafParentDataCtr.value++
//           isNoDataFetch.value = false
//         }
//       },
//       errorStore
//     );
//   } else {
//     isNoDataFetch.value = true
//   }
//   isGridDataLoaded.value = true
// }
// // Calculate HC/SC Avg Summary Costs Per GSF
// let hcAvgOriginalCostPerGSF = ref(0)
// let hcAvgRevisedCostPerGSF = ref(0)
// let scAvgOriginalCostPerGSF = ref(0)
// let scAvgRevisedCostPerGSF = ref(0)
// function calculateHCSCAvgSummaryCostsPerGSF() {
//   isBVSVCostPerGSFDataLoaded.value = false
//   let HCTotalOriginalCostPerGSF = 0
//   let HCTotalRevisedCostPerGSF = 0
//   let SCTotalOriginalCostPerGSF = 0
//   let SCTotalRevisedCostPerGSF = 0
//   if (selectedId.value) {
//     axiosQuery(
//       `${apiBase}mvw_most_recent_by_projects/fetch_hc_sc_costs_per_project?project_ids=${selectedId.value}`,
//       authToken,
//       (data) => {
//         const validIds = selectedId.value
//         let hardCostsData = data.hard
//         let softCostsData = data.soft
//         let projectGSF = parseFloat(projectInformation.value.total_gsf || 0)
//         HCTotalOriginalCostPerGSF += parseFloat(hardCostsData[selectedId.value]['total_original'] || 0) / (projectGSF || 1)
//         HCTotalRevisedCostPerGSF += parseFloat(hardCostsData[selectedId.value]['total_revised'] || 0) / (projectGSF || 1)
//         SCTotalOriginalCostPerGSF += parseFloat(softCostsData[selectedId.value]['total_original'] || 0) / (projectGSF || 1)
//         SCTotalRevisedCostPerGSF += parseFloat(softCostsData[selectedId.value]['total_revised'] || 0) / (projectGSF || 1)
//         hcAvgOriginalCostPerGSF.value = HCTotalOriginalCostPerGSF / validIds.length
//         hcAvgRevisedCostPerGSF.value = HCTotalRevisedCostPerGSF / validIds.length
//         scAvgOriginalCostPerGSF.value = SCTotalOriginalCostPerGSF / validIds.length
//         scAvgRevisedCostPerGSF.value = SCTotalRevisedCostPerGSF / validIds.length
//       },
//       errorStore
//     );
//   }
//   isBVSVCostPerGSFDataLoaded.value = true
// }
// // Save the sum aggregates so they can be used to calculate variance
// let sumAggregates = {}
// function sumAggregate(params) {
//   // Go through the full sum of each child item, then divide by # of projects
//   let otherTotal = 0.0;
//   (branchGridData[params.rowNode.key] || []).forEach(value => otherTotal += parseFloat(value[params.column.colId] || 0.0))
//   otherTotal = otherTotal / (Object.keys(selectedId.value).filter(id => selectedId.value[id]).length || 1)
//   sumAggregates[params.column.colId] ||= {}
//   sumAggregates[params.column.colId][params.rowNode.key] = otherTotal
//   return otherTotal
// }
// // Uses the sum aggregates so they can be used to calculate variance
// // NOTE: this only works because the sum aggregate columns are calculated first!
// function varianceAggregate(params) {
//   const originalBudget = sumAggregates['original_budget'][params.rowNode.key]
//   const currentBudget = sumAggregates['current_budget'][params.rowNode.key]
//   return ((currentBudget || 0.0) - (originalBudget || 0.0)) / (parseFloat(originalBudget) || 1.0)
// }
// // Uses the sum aggregates so they can be used to calculate % complete
// // NOTE: this only works because the sum aggregate columns are calculated first!
// function pctCompleteAggregate(params) {
//   const currentBudget = sumAggregates['current_budget'][params.rowNode.key]
//   const totalSpent = sumAggregates['total_spent'][params.rowNode.key]
//   return (totalSpent || 0.0) / (parseFloat(currentBudget) || 1.0)
// }
const selectedId = ref(null);
const route = useRoute();
const ownerAllocationData = ref(null);
const consultantsData = ref(null);
let investorSponsorTypes = ref({
  lenders: [],
  investors: [],
  developers: []
})
function fetchOwnerAllocation() {
  axiosQuery(
    `${apiBase}owner_allocations/fetch_project_data?project_ids=${selectedId.value}`,
    authToken,
    (data) => {
      ownerAllocationData.value = data;
      data.forEach(item => {
        const investmentRole = item.investment_role_id_s;
        const sponsorId = item.investor_sponsor_id_s;
        addSponsorIdToRole(investmentRole, sponsorId);
      });
    },
    errorStore
  );
}
function addSponsorIdToRole(investmentRole, sponsorId) {
  const roleMapping = {
    [OWNER_ALLOCATIONS.LENDER]: 'lenders',
    [OWNER_ALLOCATIONS.INVESTOR]: 'investors',
    [OWNER_ALLOCATIONS.DEVELOPER]: 'developers'
  };
  const arrayName = roleMapping[investmentRole];
  if (arrayName && !investorSponsorTypes.value[arrayName].includes(sponsorId)) {
    investorSponsorTypes.value[arrayName].push(sponsorId);
  }
}
let consultantTypesData = ref({
  subcontracts: [],
  owners_reps: [],
  third_party_pm: [],
  architects: [],
  design_architects: [],
  civil_engineers: [],
  structural_engineers: [],
  mechanical_engineers: [],
  electrical_engineers: [],
  plumbing_engineers: [],
  fire_protection_engineers: [],
  interior_designers: [],
  landscape_architects: [],
  geotechnical_engineers: [],
  environmental_engineers: [],
})
function fetchConsultants() {
  axiosQuery(
    `${apiBase}consultant_projects/fetch_project_data?project_ids=${selectedId.value}`,
    authToken,
    (data) => {
      consultantsData.value = data;
      
      data.forEach(item => {
        const consultantRole = item.consultant_type_id_s;
        const consultantName = item.consultant_id_s;
        addConsultant(consultantRole, consultantName);
      });
    },
    errorStore
  );
}
function addConsultant(consultantRole, consultantName) {
  const roleMapping = {
    [CONSULTANT_TYPES.SUBCONTRACTOR]: 'subcontracts',
    [CONSULTANT_TYPES.OWNERS_REPRESENTATIVE]: 'owners_reps',
    [CONSULTANT_TYPES.THIRD_PARTY_PROJECT_MANAGER]: 'third_party_pm',
    [CONSULTANT_TYPES.ARCHITECT]: 'architects',
    [CONSULTANT_TYPES.DESIGN_ARCHITECT]: 'design_architects',
    [CONSULTANT_TYPES.CIVIL_ENGINEER]: 'civil_engineers',
    [CONSULTANT_TYPES.STRUCTURAL_ENGINEER]: 'structural_engineers',
    [CONSULTANT_TYPES.MECHANICAL_ENGINEER]: 'mechanical_engineers',
    [CONSULTANT_TYPES.ELECTRICAL_ENGINEER]: 'electrical_engineers',
    [CONSULTANT_TYPES.PLUMBING_ENGINEER]: 'plumbing_engineers',
    [CONSULTANT_TYPES.FIRE_PROTECTION_ENGINEER]: 'fire_protection_engineers',
    [CONSULTANT_TYPES.INTERIOR_DESIGN]: 'interior_designers',
    [CONSULTANT_TYPES.LANDSCAPE_ARCHITECT]: 'landscape_architects',
    [CONSULTANT_TYPES.GEOTECHNICAL_ENGINEER]: 'geotechnical_engineers',
    [CONSULTANT_TYPES.ENVIRONMENTAL_ENGINEER]: 'environmental_engineers'
  };
  const arrayName = roleMapping[consultantRole];
  if (arrayName && !consultantTypesData.value[arrayName].includes(consultantName)) {
    consultantTypesData.value[arrayName].push(consultantName);
  }
}
onMounted(async () => {
  authToken.value = authStore.getAuthTokenHeader;
  selectedId.value = route.params.selectedId;
  await fetchProjectInformation();
  await fetchOwnerAllocation();
  await fetchConsultants();
  // await fetchGridData();
  // await calculateHCSCAvgSummaryCostsPerGSF();
});
</script>
<style scoped lang="scss">
@import "./src/assets/main.scss";
.hidden {
  display: none;
}
.company-summary-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 2fr; /* 3 columns of equal width and 1 column with double width */
  gap: 1rem; /* Adjust the gap between the grid items as needed */
}
.tile {
  background-color: $white1;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
}
/* Company Summary Style - Start */
.company-summary-header-container {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  .company-summary-header-title {
    font-size: 22px;
    font-weight: bolder;
    color: $navyBlue;
    margin-right: 20px;
  }
}
.company-summary-content {
  display: flex;
  flex-direction: row;
}
.chart-main-title {
  font-size: 18px;
  font-weight: bolder;
}
.chart-title {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.engineering-items-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
/* Company Summary Style - End */

/* Company Tracking Style - Start */
// .company-tracking-header-container {
//   margin-top: 30px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   width: 100%;
//   .company-tracking-header-title {
//     font-size: 22px;
//     font-weight: bolder;
//     color: $navyBlue;
//     margin-right: 20px;
//   }
// }
// .company-tracking-subtitle {
//   font-size: 14px;
//   margin: 0px 10px;
//   font-weight: bold;
// }
/* Company Tracking Style - End */

/* AG Grid Styles - Start */
// .disabled-header {
//   color: #999 !important;
//   background-color: #f0f0f0 !important;
//   cursor: not-allowed;
//   pointer-events: none;
// }
/* AG Grid Styles - End */
.no-data-loaded {
  padding: 10px;
}
</style>
