<template>
  <OverlaySpinner v-if="isLoading" />
  <div class="compsearch-page-container">
    <div class="comp-search-modal" :class="{ 'open': isLeftOpen }">
      <CompSearch @resize-sidebar-comp-search="openLeftModal" @comp-search-submit="showCompSetSummaryModal" />
    </div>
    <div class="map-container" :class="{ 'right-open': isRightOpen && isRightVisible }">
      <div id="map" style="height:100%; width:100%;"></div>
    </div>
    <div class="comp-summary-modal" :class="{ 'open': isRightOpen }" v-if="isRightVisible">
      <CompSummary @resize-modal="toggleRightModal" />
    </div>
  </div>
</template>

<script setup>
/* eslint no-undef: 0 */
import { onMounted, nextTick, ref, reactive, watch } from 'vue';
import { useCompSetStore } from "@/stores/comp_set_store";
import { useErrorStore } from "@/stores/error_store";
import { axiosQuery, inRange, numberFormat } from "@/assets/js/utils";
import CompSearch from "@/components/comp-search/CompSearch.vue"
import CompSummary from "@/components/comp-search/CompSummary.vue"
import OverlaySpinner from "@/components/helpers/OverlaySpinner.vue"

const compSetStore = useCompSetStore();
const errorStore = useErrorStore();
const apiBase = process.env.VUE_APP_ENV_API_BASE || "http://localhost:3001/api/v1/"
let authToken = reactive({});

const metersInMile = 1609.34
let defaultMapRadiusMeters = metersInMile * 7.0 // Default to miles radius for map
let mapRadiusMeters = defaultMapRadiusMeters
let circleCenterLocation = { lat: 40.7240, lng: -73.9710 } // New York City to start
const pinSvgString =
  '<svg xmlns="http://www.w3.org/2000/svg" width="5.6444445mm" height="9.847393mm" viewBox="0 0 20 34.892337" version="1.1" >\n' +
  '  <g transform="translate(-814.59595,-274.38623)">\n' +
  '    <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">\n' +
  '      <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="display:inline;opacity:1;fill:#4A5FD5;fill-opacity:1;stroke:#3b73a8;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>\n' +
  '      <circle r="3.0355" cy="288.25278" cx="823.03064" style="display:inline;opacity:1;fill:#fff;fill-opacity:1;stroke-width:0"/>\n' +
  '    </g>\n' +
  '  </g>\n' +
  '</svg>\n';
const fadedPinSvgString =
  '<svg xmlns="http://www.w3.org/2000/svg" width="5.6444445mm" height="9.847393mm" viewBox="0 0 20 34.892337" version="1.1" >\n' +
  '  <g transform="translate(-814.59595,-274.38623)">\n' +
  '    <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">\n' +
  '      <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="display:inline;opacity:1;fill:#FEB465;fill-opacity:0.8;stroke:#3b73a8;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>\n' +
  '      <circle r="3.0355" cy="288.25278" cx="823.03064" style="display:inline;opacity:1;fill:#fff;fill-opacity:1;stroke-width:0"/>\n' +
  '    </g>\n' +
  '  </g>\n' +
  '</svg>\n';

const isGoogleMapsMounted = ref(false)
const isLoading = ref(true)
onMounted(() => {
   // Ensure the DOM is fully updated before initializing the map
   nextTick(() => {
    // Timeout for loading Google Maps
    setTimeout(() => {
      loadGoogleMaps();
    }, 500);

    // Timeout for setting isLoading to false
    setTimeout(() => {
      isLoading.value = false;
    }, 1500); // Adjust the timeout duration as needed
  });
})

// Declare variables for global use in this file
let map
let markers = []
let infoWindows = []
let drawnCircle = null
let drawingManager = null
let mapCenter = []
let mapBounds = []

watch(() => compSetStore.location, (newLocation) => {
  if (isGoogleMapsMounted.value) {
    reloadMap(newLocation)
  } else {
    loadGoogleMaps()
    reloadMap(newLocation)
  }
});
// Ensure we get any updated queries so markers are changed
watch(() => compSetStore.getQueryMapExpanded, () => {
  loadMarkers()
});

function loadGoogleMaps() {
  if (typeof google !== 'undefined') {
    const mapOptions = {
      center: circleCenterLocation,
      zoom: 12,
      mapId: 'CompSearchMap'
    };

    map = new google.maps.Map(document.getElementById('map'), mapOptions);
    // Listen to any circle event that would move the circle and start over
    google.maps.event.addListener(map, 'bounds_changed', () => {
      const boundsNE = map.getBounds()?.getNorthEast()
      const boundsSW = map.getBounds()?.getSouthWest()
      mapBounds = [boundsNE?.lat(), boundsNE?.lng(), boundsSW?.lat(), boundsSW?.lng()]
      compSetStore.setMapExpandedQuery(`location=${mapBounds.join(',')}`)
      // Before a circle is drawn, we need to query the whole visible part of the map
      if (boundsNE && boundsSW && !drawnCircle) {
        mapCenter = map.getCenter()
        compSetStore.setMapQuery(`location=${mapBounds.join(',')}`)
      }
      loadMarkers()
    })

    // Allow circles to be drawn on the map ad hoc
    circleDrawingComponent()

    // AdvancedMarker: https://developers.google.com/maps/documentation/javascript/advanced-markers/basic-customization
    if (compSetStore.location) {
      new google.maps.marker.AdvancedMarkerElement({
        position: compSetStore.location.geometry.location,
        map: map,
      });
    }

    isGoogleMapsMounted.value = true
    reloadMap(compSetStore.location)
  } else {
    errorStore.setError('Google Map Error', 'Unknown error opening Google Maps', 'error')
  }
}

function circleDrawingComponent() {
  // delete old one in case it exists
  if (drawnCircle) {
    drawnCircle.setMap(null)
  }
  if (drawingManager) {
    drawingManager.setMap(null)
    drawingManager = null
  }

  // See https://developers.google.com/maps/documentation/javascript/examples/drawing-tools
  // and https://developers.google.com/maps/documentation/javascript/drawinglayer#overview
  drawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: null,
    drawingControl: true,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [
        google.maps.drawing.OverlayType.CIRCLE
      ],
    },
    circleOptions: {
      strokeColor: '#4A5FD5',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#4A5FD5',
      fillOpacity: 0.25,
      clickable: false,
      draggable: true,
      editable: true,
      zIndex: 1,
    },
  })

  drawingManager.setMap(map)

  // Listen to any new circle created by the drawing manager
  // For circles, see https://developers.google.com/maps/documentation/javascript/reference/polygon#Circle
  google.maps.event.addListener(drawingManager, 'circlecomplete', (drawn) => {
    // delete old one in case it exists as we need only 1 circle
    if (drawnCircle) {
      drawnCircle.setMap(null)
    }
    moveCircle(drawn)
    // Listen to any circle event that would move the circle and start over
    google.maps.event.addListener(drawn, 'draggend', () => {
      moveCircle(drawn)
    })
    google.maps.event.addListener(drawn, 'center_changed', () => {
      moveCircle(drawn)
    })
    google.maps.event.addListener(drawn, 'radius_changed', () => {
      moveCircle(drawn)
    })
  })
}
function moveCircle(drawn) {
  drawnCircle = drawn
  mapRadiusMeters = drawnCircle.getRadius()
  const lat = drawnCircle.center.lat()
  const lng = drawnCircle.center.lng()

  if (lat && lng) {
    mapCenter = mapRadiusMeters ? [lat, lng, mapRadiusMeters] : [lat, lng]
  }
  const mapQuery = mapCenter ? `location=${mapCenter.join(',')}` : null
  // CompSearch needs to query only what is in the encircled data
  compSetStore.setMapQuery(mapQuery)

  // Only redraw map after we recenter it to fit the circle
  map.fitBounds(drawn.getBounds())
}

function reloadMap(newLocation) {
  if (newLocation?.geometry) {
    // Center the map on the new location
    const lat = newLocation.geometry?.location ? newLocation.geometry.location.lat() : null
    const lng = newLocation.geometry?.location ? newLocation.geometry.location.lng() : null
    if (lat && lng) {
      mapCenter = [lat, lng]
      const mapCenterLocation = new google.maps.LatLng(mapCenter[0], mapCenter[1])
      map.setCenter(mapCenterLocation)
    }

    circleDrawingComponent()
  }
}

function loadMarkers() {
  // Clear the old markers, info windows, and circle
  infoWindows.forEach(infoWindow => infoWindow.close())
  markers.forEach(marker => marker.setMap(null))

  // Reset the values
  markers = [];
  infoWindows = [];

  const compSetAndMapExpandedQuery = compSetStore.getQueryMapExpanded

  // Get projects filtered by location
  axiosQuery(
    `${apiBase}projects/released_projects?${compSetAndMapExpandedQuery}`,
    authToken,
    (data) => {
      const allProjects = []
      data.forEach((result) => {
        const mapCenterLocation = new google.maps.LatLng(mapCenter[0], mapCenter[1])
        const lat = parseFloat(result.latitude);
        const lng = parseFloat(result.longitude);

        if (isNaN(lat) || isNaN(lng)) {
          console.error('Invalid latitude or longitude:', result);
          return;
        }

        const location = { lat, lng };

        // See https://developers.google.com/maps/documentation/javascript/advanced-markers/graphic-markers
        const parser = new DOMParser()
        let pinSvg;
        let inSelectedArea;
        if (drawnCircle) {
          // Circular radius check
          const distance = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(location),
            mapCenterLocation
          );
          inSelectedArea = distance <= mapRadiusMeters
        } else {
          // is the location in the rectangular area
          inSelectedArea = inRange(location.lat, mapBounds[0], mapBounds[2]) &&
            inRange(location.lng, mapBounds[1], mapBounds[3])
        }

        if (inSelectedArea) {
          allProjects.push(result)
          // Item fits into the circle
          pinSvg = parser.parseFromString(
            pinSvgString,
            "image/svg+xml",
          ).documentElement
        } else {
          // item does not fit into the circle
          pinSvg = parser.parseFromString(
            fadedPinSvgString,
            "image/svg+xml",
          ).documentElement
        }
        const marker = new google.maps.marker.AdvancedMarkerElement({
          position: location,
          map: map,
          content: pinSvg
        })

        // TODO Dynamically require the image and get its URL
        const imageUrl = require('../assets/building_assets/property1.jpg');

        const infoWindow = new google.maps.InfoWindow({
          content: `<div style="background: ${inSelectedArea ? '#3b73a8' : '#FEB465'}; color: #fff; font-size: 12px; display: flex; align-items: center; height: 100%; padding: 5px; width: 265px">
                          <div><img style="width:100px" src="${imageUrl}"></div>
                          <div style="padding-left: 5px;">
                            <div style="white-space:nowrap;"><strong>${result.address_line_1}</strong></div>
                            <div style="display: flex; align-items: center;">
                              <label style="width: 100%;">Construction Type:</label>
                              <div style="white-space:nowrap;">${result.project_construction_types_s ? result.project_construction_types_s[0] : '-'}</div>
                            </div>
                            <div style="display: flex; align-items: center;">
                              <label style="width: 100%;">Total GSF:</label>
                              <div style="white-space:nowrap;">${result.total_gsf ? numberFormat(result.total_gsf, 0) : '-'}</div>
                            </div>
                            <div style="display: flex; align-items: center;">
                              <label style="width: 100%;">Unit Count:</label>
                              <div style="white-space:nowrap;">${result.total_num_units ? numberFormat(result.total_num_units, 0) : '-'}</div>
                            </div>
                          </div>
                        </div>`,
        });

        // Only set selected projects as the ones actually in the selected area
        compSetStore.setAllProjects(allProjects);
        compSetStore.setIncludedProjects(allProjects);

        // Set all projects as selected
        const selectedProjects = Object.fromEntries(allProjects.map(project => [project.id, true]));
        compSetStore.setSelectedProjects(selectedProjects);

        marker.addListener('click', () => {
          infoWindow.open({
            anchor: marker,
            map,
            shouldFocus: true,
          });
        });

        markers.push(marker);
        infoWindows.push(infoWindow);
      })

      // After processing all markers, check if any were added
      if (markers.length > 0) {
        // TODO do anything here?  Shouldn't happen
      } else {
        const centerLat = mapCenter[0] || map.getCenter().lat()
        const centerLng = mapCenter[0] || map.getCenter().lng()
        const mapCenterLocation = new google.maps.LatLng(centerLat, centerLng)
        // If there are no markers, add a marker at the searched location with "No available projects here"
        const marker = new google.maps.marker.AdvancedMarkerElement({
          position: mapCenterLocation,
          map: map,
        });

        const infoWindow = new google.maps.InfoWindow({
          content: `<h1>No available projects here</h1>`,
        });

        marker.addListener('click', () => {
          infoWindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
          });
        });

        markers.push(marker);
        infoWindows.push(infoWindow);
      }
    },
    errorStore
  );
}

// Sidebar Functions
let isLeftOpen = ref(true);
let isRightOpen = ref(false);
let isRightVisible = ref(false);
function openLeftModal() {
  isLeftOpen.value = !isLeftOpen.value;
}
function showCompSetSummaryModal() {
  if (!isRightVisible.value) {
    isRightVisible.value = !isRightVisible.value;
    isRightOpen.value = true;
  }

}
function toggleRightModal() {
  isRightOpen.value = !isRightOpen.value;
}
</script>

<style scoped lang="scss">
@import "./src/assets/main.scss";

.compsearch-page-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  height: 100vh;
  margin-top: 53px;
  font-family: 'Inter';
  position: relative;
}

.comp-search-modal {
  overflow: hidden;
  width: 0;
}

.comp-search-modal.open {
  width: 310px;
}

.map-container {
  flex: 1;
  width: 100%;
  height: 100vh;
  margin-left: 10px;
  transition: margin 0.3s ease;
}

.map-container.right-open {
  margin-right: 650px;
}

.comp-summary-modal {
  margin-left: 30px;
}

.comp-summary-modal.open {
  margin-left: 0px;
}
</style>