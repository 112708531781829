<template>
  <div class="gantt-cell" @mouseover="topWidth || bottomWidth ? showTooltip($event, params) : null" @mouseout="topWidth || bottomWidth ? hideTooltip($event, params) : null">
    <svg v-if="topWidth || bottomWidth" height="34" width="35.5" viewBox="0 0 35.5 34" xmlns="http://www.w3.org/2000/svg">
      <g v-if="topWidth && isTopRightAligned" transform="translate(36.25, 6) scale(-1, 1)" >
        <rect height="8" :width="topWidth" :fill="topFill"/>
      </g>
      <g v-else-if="topWidth" transform="translate(-0.75, 6)" >
        <rect height="8" :width="topWidth" :fill="topFill"/>
      </g>

      <g v-if="bottomWidth && isBottomRightAligned" transform="translate(36.25, 14) scale(-1, 1)" >
        <rect height="8" :width="bottomWidth" :fill="bottomFill"/>
      </g>
      <g v-else-if="bottomWidth" transform="translate(-0.75, 14)" >
        <rect height="8" :width="bottomWidth" :fill="bottomFill"/>
      </g>
    </svg>
  </div>
</template>
<script setup>
import {defineProps, onMounted, ref} from "vue";
import { useGanttTooltipStore } from "@/stores/gantt_tooltip_store";

const props = defineProps({
  params: Object
})

const topFill = ref(null)
const bottomFill = ref(null)

const fullWidth = 35.5; // 34px is full width + .75px times 2 to cover borders -- max border width is 1.5px
const topWidth = ref(null)
const bottomWidth = ref(null)

const isTopRightAligned = ref(null)
const isBottomRightAligned = ref(null)

onMounted(() => {
  topFill.value = props.params.node?.allChildrenCount ? '#4A5FD5' : '#B0C8F1' // $brightBlue1 : $brightBlue2
  bottomFill.value = props.params.node?.allChildrenCount ? '#84CADE' : '#D3F1F9' // $lightBlue1 : $brightAqua2

  topWidth.value = props.params.topWidthPct ? (fullWidth * parseFloat(props.params.topWidthPct)) : 0
  bottomWidth.value = props.params.bottomWidthPct ? (fullWidth * parseFloat(props.params.bottomWidthPct)) : 0

  isTopRightAligned.value = props.params.isTopRightAligned
  isBottomRightAligned.value = props.params.isBottomRightAligned
})

const tooltipStore = useGanttTooltipStore()

function showTooltip($event, params) {
  if (params.projectedProjectMonths || params.actualProjectMonths) {
    let html = `<div><b>${params.node?.key}</b></div>`
    html = html + `<div><b>Projected: </b>${params.projectedProjectMonths} ${params.projectedProjectMonths ? 'months' : ''} (${params.projectedMonthsIn})<br/>`
    html = html + `<b>Actual:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>${params.actualProjectMonths} ${params.actualProjectMonths ? 'months' : ''} (${params.actualMonthsIn})</div>`
    tooltipStore.setValuesAndShow($event.pageY, $event.pageX, html)
  }
}

function hideTooltip() {
  tooltipStore.hide()
}
</script>
<style lang="scss">
.gantt-cell {
  width: 34px;
  position: absolute;
  left: 0;
  top: 0;
  // Keeep above month lines
  z-index: 1000;
}
.hidden-action-button {
  display: none;
}
</style>