import { defineStore } from "pinia"

// The GanttTooltipStore has the positioning and data for the current Gantt Tooltip
export const useGanttTooltipStore = defineStore("GanttTooltipStore", {
  id: 'ganttTooltipStore',
  state: () => ({
    isShow: false,
    top: null,
    left: null,
    html: null
  }),
  getters: {
    getIsShow: (state) => {
      return state.isShow
    },
    getTop: (state) => {
      return state.top
    },
    getLeft: (state) => {
      return state.left
    },
    getHtml: (state) => {
      return state.html
    },
  },
  actions: {
    hide() {
      this.isShow = false
    },
    setValuesAndShow(top, left, html) {
      this.top = top
      this.left = left
      this.html = html
      this.isShow = true
    }
  },
})
