// src/stores/AuthStore.js (Pinia)
import { defineStore } from "pinia"

export const useErrorStore = defineStore("ErrorStore", {
  id: 'error',
  state: () => ({
    header: null,
    message: null,
    errorLevel: 'error', // success, notice, warning, error, critical
    hasConfirm: false,
    clearedStatus: null,
    isLoggedOutMessage: false
  }),
  getters: {
    getLoggedOutMessage: () => {
      return 'Please log out and back in again.'
    },
    hasLoggedOutMessage: (state) => {
      return state.isLoggedOutMessage
    },
    getHeader: (state) => {
      return state.header
    },
    getMessage: (state) => {
      return state.message
    },
    getErrorLevel: (state) => {
      return state.errorLevel
    },
    getConfirmation: (state) => {
      return state.hasConfirm
    },
    getClearedStatus: (state) => {
      return state.clearedStatus
    },
  },
  actions: {
    setError (header, message, errorLevel = 'error', hasConfirm = false, overrideCurrentError = false) {
      // Do not override a current message if we already have one.
      if (!overrideCurrentError && (this.message && !this.clearedStatus)) {
        return
      }
      this.header = header
      this.message = message
      const errorLevels = ['success', 'notice', 'warning', 'error', 'critical']
      if (errorLevels.includes(errorLevel)) {
        this.errorLevel = errorLevel
      } else {
        this.errorLevel = 'error'
      }
      this.hasConfirm = hasConfirm
      this.clearedStatus = null // reset cleared status when setting a new error
      this.isLoggedOutMessage = message === this.getLoggedOutMessage
    },
    clearLoggedOutMessage() {
      this.header = null
      this.message = null
      this.errorLevel = 3
      this.hasConfirm = false
      this.clearedStatus = null
      this.isLoggedOutMessage = null
    },
    cancelError() {
      this.header = null
      this.message = null
      this.errorLevel = 3
      this.hasConfirm = false
      this.clearedStatus = 'cancel'
    },
    confirmError() {
      this.header = null
      this.message = null
      this.errorLevel = 3
      this.hasConfirm = false
      this.clearedStatus = 'confirm'
    },
  },
})
